import React, { useState } from "react";
import AccountsCard from "../DashboardComponents/AccountsCard";
import LoanCard from "../DashboardComponents/LoanCard";
import AccountChart from "../DashboardComponents/AccountChart";
import DonutChart from "../DashboardComponents/DonutChart";
import SAccountsCard from "../DashboardComponents/SAccountsCard";
import AccountsCreditCard from "../DashboardComponents/AccountsCreditCard";

import { useMediaQuery } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import NewDesignCards from "./NewDesignCards";

function CreditMix({ creditMixdata,mainBlockWidth }) {
  const isMobile = useMediaQuery("(max-width:990px)");
  const [expandedPanel, setExpandedPanel] = useState(null);

  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpandedPanel(isExpanded ? panel : null);
  };

  // const cardData = [
  //   {
  //     id: 1,
  //     title: "Total Amount",
  //     amount: creditMixdata?.totalAmount,
  //     addedAmt: creditMixdata?.amountAddedLastThreeMonths,
  //     preAmt:
  //       creditMixdata?.totalAmount - creditMixdata?.amountAddedLastThreeMonths,
  //   },

  //   {
  //     id: 2,
  //     title: "Total Outstanding Amount",
  //     amount: creditMixdata?.totalOutstandingAmount,
  //     addedAmt: creditMixdata?.outstandingAmountAddedLastThreeMonths,
  //     preAmt:
  //       creditMixdata?.totalOutstandingAmount -
  //       creditMixdata?.outstandingAmountAddedLastThreeMonths,
  //   },

  //   {
  //     id: 3,
  //     title: "Total Collateral Amount",
  //     amount: creditMixdata?.totalCollateralAmount,
  //     addedAmt: creditMixdata?.collateralAmountAddedInLastThreeMonths,
  //     preAmt:
  //       creditMixdata?.totalCollateralAmount -
  //       creditMixdata?.collateralAmountAddedInLastThreeMonths,
  //   },
  // ];
  return (
    <>
      <div style={{ background: "#FFFF", borderRadius: "24px" }}>
        {/* card section start */}
        {/* <div className="topdiv">
          <DonutChart creditMixdata={creditMixdata} />

          {cardData.map((card) => (
            <div className="card" key={card.id}>
              <div className="cardchild">
                <div className="text-of-card">{card.title}</div>
                <div className="imgsection">
                  <div>
                    <AccountChart data1={card} />
                  </div>
                  {card?.preAmt || card?.addedAmt ? (
                    <>
                      <div className="textDiv">
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <div
                            style={{
                              width: "10px",
                              height: "10px",
                              backgroundColor: "#17B26A",
                              marginRight: "5px",
                              borderRadius: "50%",
                            }}
                          />
                          <div className="text-div-2">Latest Amount Added</div>
                        </div>

                        <div className="text-div-amt">
                          ₹{card.addedAmt?.toLocaleString("en-IN")}
                        </div>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginTop: "15px",
                          }}
                        >
                          <div
                            style={{
                              width: "10px",
                              height: "10px",
                              backgroundColor: "#C3D3EF",
                              marginRight: "5px",
                              borderRadius: "50%",
                            }}
                          />
                          <div className="text-div-2">Previous Amount </div>
                        </div>
                        <div className="text-div-amt">
                          ₹{card.preAmt?.toLocaleString("en-IN")}
                        </div>
                        {card?.preAmt || card?.addedAmt ? (
                          <></>
                        ) : (
                          <>
                            <div
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <div
                                style={{
                                  width: "10px",
                                  height: "10px",
                                  backgroundColor: "#907AD6",
                                  marginRight: "5px",
                                }}
                              />
                              <span
                                style={{
                                  marginLeft: "5px",
                                  fontFamily: "Nunito Sans",
                                  fontWeight: "600",
                                  fontSize: "11px",
                                }}
                              >
                                No Data available
                              </span>
                            </div>
                          </>
                        )}
                      </div>
                    </>
                  ) : (
                    <>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <div
                          style={{
                            width: "10px",
                            height: "10px",
                            backgroundColor: "#17B26A",
                            marginRight: "5px",
                            borderRadius: "50%",
                          }}
                        />
                        <span
                          style={{
                            marginLeft: "5px",
                            fontFamily: "inter",
                            fontWeight: "400",
                            fontSize: "12px",
                            color: "#033B52",
                          }}
                        >
                          No Data available
                        </span>
                      </div>
                    </>
                  )}
                </div>

                <div className="totalamount">
                  <div className="cardammount">
                    ₹{card.amount?.toLocaleString("en-IN")}
                  </div>
                  <div className="tip">Total amount you have till date</div>
                </div>
              </div>
            </div>
          ))}

        </div> */}
        <NewDesignCards mainBlockWidth={mainBlockWidth} creditMixdata={creditMixdata}/>

        <div className="loanType">
          {isMobile ? (
            <>
              <Accordion
              elevation={0} 
                expanded={expandedPanel === "secured"}
                onChange={handleAccordionChange("secured")}
                style={{
                  marginTop: "5px",
                  borderRadius: "12px",
                  overflow: "hidden",
                }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1-content"
                  id="panel1-header"
                 
                  style={{
                    background: "#F8F9FC",
                    marginTop: expandedPanel === "secured" ? "20px" : "0px", // Condition applied
                    borderRadius:'18px 18px 0 0'
                  }}
                  sx={{
                    "& .MuiAccordionSummary-content": {
                      margin:  "18px 0 18px 0" , 
                    },
                  }}
                >
                  Secured
                </AccordionSummary>
                <AccordionDetails style={{ padding: "0" }} >
                  <div className="Secured">
                    <AccountsCard creditMixdata={creditMixdata} />
                    { creditMixdata?.secureLoanAccountListDTOS?.length >  0 && (                
                    <div
                      style={{
                        marginTop: "20px",
                        // minHeight:
                        //   creditMixdata?.secureLoanAccountListDTOS?.length > 0
                        //     ? "50vh"
                        //     : "0vh",
                         height:"auto",
                        overflow: "auto",
                        maxHeight: "50vh",
                        borderRadius: "10px",
                        padding: "10px",
                        scrollbarWidth: "thin",
                      }}
                    >
                      {creditMixdata?.secureLoanAccountListDTOS
                        ?.sort((a, b) =>
                          a.status === "ACTIVE" ||
                          a.status === "SF/WD/WO/SETTLED"
                            ? -1
                            : 1
                        )
                        .map((loan) => (
                          <LoanCard loan={loan} />
                        ))}
                    </div>
                        )}
                  </div>
                </AccordionDetails>
              </Accordion>
            </>
          ) : (
            <>
              <div className="Secured">
                <AccountsCard creditMixdata={creditMixdata} />
                {creditMixdata?.secureLoanAccountListDTOS.length >= 1 && (             
                <div className="custom-scrollbar">
                  {creditMixdata?.secureLoanAccountListDTOS
                    ?.sort((a, b) =>
                      a.status === "ACTIVE" || a.status === "SF/WD/WO/SETTLED"
                        ? -1
                        : 1
                    )
                    .map((loan) => (
                      <LoanCard loan={loan} />
                    ))}
                </div>
                   )}
              </div>
            </>
          )}

          {isMobile ? (
            <>
              <Accordion
                 elevation={0} 
                expanded={expandedPanel === "unsecured"}
                onChange={handleAccordionChange("unsecured")}
                style={{
                  marginTop: "5px",
                  borderRadius: "12px",
                  overflow: "hidden",
                }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1-content"
                  id="panel1-header"
                  style={{
                    background: "#F8F9FC",
                    marginTop: expandedPanel === "secured" ? "20px" : "0px", // Condition applied
                    borderRadius:'18px 18px 0 0'
                  }}
                  sx={{
                    "& .MuiAccordionSummary-content": {
                      margin:  "18px 0 18px 0" , 
                    },
                  }}
                >
                  Unsecured
                </AccordionSummary>
                <AccordionDetails style={{ padding: "0" }}>
                  <div className="Unsecured">
                    <SAccountsCard creditMixdata={creditMixdata} />
                    { creditMixdata?.unsecureLoanAccountListDTOS?.length > 0 && (<>
                    <div
                      style={{
                        marginTop: "20px",
                        // minHeight:
                        //   creditMixdata?.unsecureLoanAccountListDTOS?.length > 0
                        //     ? "50vh"
                        //     : "10vh",
                        overflow: "auto",
                        maxHeight: "50vh",
                        borderRadius: "10px",                   
                        scrollbarWidth: "thin",
                        height:"auto"
                        
                      }}
                      className="surced-card-accounts-main-div-with-mobile"
                    >
                      {creditMixdata?.unsecureLoanAccountListDTOS
                        ?.sort((a, b) =>
                          a.status === "ACTIVE" ||
                          a.status === "SF/WD/WO/SETTLED"
                            ? -1
                            : 1
                        )
                        .map((loan, index) => (
                          <LoanCard
                            loan={loan}
                            key={index + "creditmix-data"}
                          />
                        ))}
                    </div>
                    </>)}
                  </div>
                </AccordionDetails>
              </Accordion>
            </>
          ) : (
            <>
              <hr style={{ border: "-1px solid #044A67", marginTop: "20px" }} />

              <div className="Unsecured">
                <SAccountsCard creditMixdata={creditMixdata} />
                <div
             
                  className="custom-scrollbar"
                >
                  {creditMixdata?.unsecureLoanAccountListDTOS
                    ?.sort((a, b) =>
                      a.status === "ACTIVE" || a.status === "SF/WD/WO/SETTLED"
                        ? -1
                        : 1
                    ) 
                    .map((loan, index) => (
                      <LoanCard loan={loan} key={index + "creditmix-data"} />
                    ))}
                </div>
              </div>
            </>
          )}

          {isMobile ? (
            <>
              <Accordion
                 elevation={0} 
                expanded={expandedPanel === "creditCards"}
                onChange={handleAccordionChange("creditCards")}
                style={{
                  marginTop: "5px",
                  borderRadius: "12px",
                  overflow: "hidden",
                }}
                sx={{
                  "& .MuiAccordionSummary-content": {
                    margin:  "18px 0 18px 0" , 
                  },
                }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1-content"
                  id="panel1-header"
                  style={{
                    background: "#F8F9FC",
                    marginTop: expandedPanel === "secured" ? "20px" : "0px", // Condition applied
                    borderRadius:'18px 18px 0 0'
                  }}
                >
                  Credit Cards
                </AccordionSummary>
                <AccordionDetails style={{ padding: "0" }}>
                  <div className="CreditCards">
                    <AccountsCreditCard creditMixdata={creditMixdata} />
                    {creditMixdata?.creditAccountListDTOS?.length > 0 && (<>
                    <div
                      style={{
                        marginTop: "20px",
                        // minHeight:
                        //   creditMixdata?.creditAccountListDTOS?.length > 0
                        //     ? "50vh"
                        //     : "10vh",
                        height:'auto',
                        overflow: "auto",
                        maxHeight: "50vh",
                        borderRadius: "10px",                      
                        scrollbarWidth: "thin",
                      }}
                       className="surced-card-accounts-main-div-with-mobile"
                    >
                      {creditMixdata?.creditAccountListDTOS
                        ?.sort((a, b) =>
                          a.status === "ACTIVE" ||
                          a.status === "SF/WD/WO/SETTLED"
                            ? -1
                            : 1
                        )
                        .map((loan) => (
                          <LoanCard loan={loan} />
                        ))}
                    </div>
                    </>)}
                  </div>
                </AccordionDetails>
              </Accordion>
            </>
          ) : (
            <>
              <hr style={{ border: "-1px solid #044A67", marginTop: "20px" }} />

              <div className="CreditCards">
                <AccountsCreditCard creditMixdata={creditMixdata} />
                <div className="custom-scrollbar">
                  {creditMixdata?.creditAccountListDTOS
                    ?.sort((a, b) =>
                      a.status === "ACTIVE" || a.status === "SF/WD/WO/SETTLED"
                        ? -1
                        : 1
                    )
                    .map((loan) => (
                      <LoanCard loan={loan} />
                    ))}
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default CreditMix;
