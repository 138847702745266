import React from "react";
import img1 from "../assets/imgs/car-1.webp";
import { Box, Grid, Typography } from "@mui/material";
import "./style.css"

const Page1 = () => {
  return (
    <Box  >
      <Grid container >
        <Grid
          item
          xs={0}
          sm={6}
          lg={6}
          xl={5}
          sx={{ display: { xs: "none", sm: "block", lg: "block" } }}
        >
          <Box
           
            className="desktop-site-div-112"
          >
            <div style={{height:"67%", display:"flex", alignContent:"center", justifyContent:"center"}} className="tdf-div-123">            
            <img 
            src={img1}
             alt="mobile" className="img-div-112-tdf"  loading="lazy"  style={{ minHeight: '395px' }}    />
            </div>
          </Box>
        </Grid>
        <Grid
          item
          xs={0}
          sm={6}
          lg={6}
          xl={7}
          sx={{ display: { xs: "none", sm: "flex", lg: "flex" },background:"#bde4f7", justifyContent:"center",alignItems:"center"}}
        >
          <Box
            sx={{ display:"flex",
              flexDirection:"column",
              justifyContent:"center",  textAlign: "justify", padding: "0 100px 30px 45px", position: "relative" }}
          >
            <Box
             
              className="num-of-carousel-div-112"
            >
              1
            </Box>
            <Typography style={{ fontSize: "26px",    fontFamily: "CustomFontMedium", }}>How It Works?</Typography>
            <Typography
              className="at-due-factory-text"
            >
              At Due Factory we help you bring up your sub-optimal credit score
              which is a result of your financial history. Missed EMIs on a
              previous loan? Forgot to pay your CC bill months ago? Having
              difficulty in getting a new loan?
            </Typography>
            <Box  className="crasoul-div-heading-112">
              <Typography  className="crasoul-text-div-123">Pull your score</Typography>
              <Typography  
               className="at-due-factory-text"
               >Once you fill up our form, we analyze your credit profile.</Typography>
              {/* <Typography style={{fontSize:"25px",fontFamily: "CustomFontMedium",marginTop:"-5px"}}>credit profile.</Typography> */}
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Page1;
