import React from "react";
import HeaderCoponent from "../Components/HeaderCoponent";
import { Box, Typography } from "@mui/material";
import FotterComponent from "../Components/FotterComponent";
import { useLocation } from "react-router-dom";

function TermsConditions() {
  const location = useLocation();
  const pathname = location.pathname;
  return (
    <>
      {pathname.includes("terms-&-conditions") ? (
        <>
          <HeaderCoponent />
        </>
      ) : (
        <></>
      )}

      <Box style={{ marginTop: "20px" }}>
        <Box>
          <Box
            style={{
              width: "100%",
              height: "198px",
              background: "rgba(32, 175, 229, .16)",
              // paddingTop: "100px",
              // paddingBottom: "50px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography
              style={{
                fontSize: "24px",
                fontWeight: "400",
                fontFamily: "CustomFontMedium",
              }}
            >
              Terms & Conditions
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            // marginTop: {
            //   lg: "0",
            //   sm: "40px",
            //   xs: "50px",
            // },
            padding: { xs: "20px 15px", md: "20px 40px" },
            background: "rgb(248, 248, 248)",
            textAlign: "justify",
          }}
        >
          <Box>
            <Typography>
              <span
                style={{ fontWeight: "600", fontFamily: "CustomFontMedium" }}
              >
                CIN:
              </span>
              U74999HR2021PTC096349
            </Typography>
            <Typography>
              <span
                style={{ fontWeight: "600", fontFamily: "CustomFontMedium" }}
              >
                Due Factory Services Private Limited
              </span>
            </Typography>
            <Typography style={{ fontFamily: "CustomFontMedium" }}>
              Corporate office: 806-807, Udyog Vihar Phase V, Sector 19,
              Gurugram, Haryana – 122016
            </Typography>
          </Box>
          <Box>
            <Typography
              style={{
                fontSize: "24px",
                marginTop: "16px",
                fontFamily: "CustomFontMedium",
              }}
            >
              Terms Of Use
            </Typography>
          </Box>
          <Box style={{ marginTop: "10px" }}>
            <Typography style={{ fontFamily: "CustomFontMedium" }}>
              Due Factory Services Private Limited, a company registered under
              the Companies Act 2013; pursuant to sub-section (2) of section 7
              of the Companies Act, 2013 and rule 8 of the Companies
              (Incorporation) Rules, 2014, having its registered office at
              806-807, Udyog Vihar Phase V, Sector 19, Gurugram, Haryana –
              122016 providing credit related solutions under the Brand name
              hereinafter referred to as "Due factory " (which expression shall
              unless repugnant to the context or meaning thereof be deemed to
              mean and include its successors and assigns) of the ONE PART; AND
              You (hereinafter referred to as the "User") of the OTHER PART. DUE
              FACTORY and THE USER(S) hereinafter individually referred to as a
              "Party" and collectively as the "Parties". <br /> <br />
              Whereas Due factory is in the business of providing various Credit
              advisory Services more particularly stated under Scope of services
              hereto and has a team of highly qualified and experienced
              professionals with domain expertise in providing such Services.
              The USER has approached Due factory, and on the request of the
              USER, Due factory has agreed to provide Services (as defined
              herein) and the Parties are therefore now desirous of entering
              into this Agreement, inter alia, to record the terms and
              conditions of their understanding and the scope and extent of
              Services (as defined herein) to be rendered by Due factory. Now
              therefore, in consideration of, and subject to, the terms and
              conditions herein contained, each of the Parties hereto hereby
              agrees as follows: A person accessing or using the Services
              ("User") is agreeing to be bound by these Terms and the other
              policies listed on www.duefactory.com including but not limited to
              the Privacy Policy, whether he/she is a "Visitor" (which means
              that the User simply browses www.duefactory.com, or otherwise uses
              www.duefactory.com without being registered with Due factory ) or
              is a "Member" (which means that User has registered with Due
              factory ). Due factory reserves the right to modify these Terms
              from time to time without prior notice, and each such modification
              shall be effective upon posting on Www.duefactory.com . Due
              factory may, in its sole discretion, inform its Members of such
              changes through e-mails, notices posted on www.duefactory.com, or
              through other means available through its Services. Continued use
              of www.duefactory.com following any such modification constitutes
              User's acceptance to be bound by these Terms as so modified.{" "}
              <br /> <br />
              It is, therefore, important that User reviews these Terms
              regularly. If User does not agree to be bound by these Terms,
              other policies on www.duefactory.com and all applicable laws,
              he/she must discontinue use of www.duefactory.com immediately.
              Notwithstanding anything to the contrary in these Terms, Due
              factory reserves the right to accept or reject a User from
              registering on www.duefactory.com without assigning any reason
              thereof. In some instances, both these Terms and separate
              governing documents setting forth additional conditions may apply
              to a particular service or product offered via the Services
              ("Additional Terms"). The Additional Terms are incorporated by
              reference into these Terms. To the extent there is a conflict
              between these Terms and any Additional Terms, the Additional Terms
              will prevail unless the Additional Terms expressly state
              otherwise.
            </Typography>
          </Box>
          <Box>
            <Typography
              style={{
                fontSize: "24px",
                marginTop: "16px",
                fontFamily: "CustomFontMedium",
              }}
            >
              1. Definitions
            </Typography>
          </Box>
          <Box style={{ marginTop: "10px" }}>
            <Typography style={{ fontFamily: "CustomFontMedium" }}>
              The following words and terms shall have the meanings set forth
              below:- "Act" shall mean the Credit Information Companies
              (Regulation) Act, 2005 and the rules and regulations made therein.
              "Authorized Personnel" shall mean the employees, officers, agents
              and/or representatives of Due factory who need to know or have
              access to the Confidential Information (as defined herein) to
              discharge their duty as a part of Due factory and whose possession
              of such Confidential Information (as defined herein) will not give
              rise to a conflict of interest or to any misuse whatsoever.
              "Confidential Information" shall mean all information (including
              CIRs) whether written, in electronic form, or oral or digital or
              printed information which is provided and/or disclosed by the User
              to Due factory and/or its directors, officers, employees,
              consultants, representatives, directly or indirectly, including,
              without limitation, all personal documents and credit data and/or
              technical and/or business and/ or commercial and/ or financial
              data including that relating to the business of the User,
              including User Information (as defined under the Act). "Credit
              Information Company or CIC" shall mean companies formed and
              registered under the Companies Act, 1956 and granted certificate
              of registration under CICRA. "Credit Information Report or CIR"
              shall mean a report issued by the Credit Information Companies in
              India like CIBIL, Experian, Equifax etc., <br /> <br />
              containing factual record of a borrower's credit payment history
              compiled from information received from different Credit
              Institution's. "Credit Institution" or "Creditor" shall means a
              banking company and includes – Any non-banking financial company
              as defined under clause (f) of Section 45-I of the Reserve Bank of
              India Act, 1934. Any corresponding new bank, the State Bank of
              India, a subsidiary bank, a co-operative bank, the National Bank
              and regional rural bank. Any public financial institution referred
              to in Section 4-A of the Companies Act, 1956. Any financial
              corporation established by a State under Section 3 of the State
              Financial Corporation Act, 1951. Any housing finance institution
              referred to in clause (d) of Section 2 of the National Housing
              Bank Act, 1987. Companies engaged in the business of credit cards
              and other similar cards and companies dealing with distribution of
              credit in any other manner. Any other institution which the
              Reserve Bank may specify,
              <br /> <br />
              from time to time, for the purposes of this clause. "Credit
              Scoring" means a system which enables the Credit Institution to
              assess the creditworthiness and capacity of a borrower to repay
              his/her loan and advances and discharge his other obligations in
              respect of credit facility availed or to be availed by him/ her.
              "Person" includes any individual, partnership, corporation,
              company, society, unincorporated organization or association,
              Hindu undivided family, joint venture company, joint stock
              company, trust or <br /> <br /> other entity, whether incorporated
              or not. "Partners" shall mean various other entity partners of Due
              factory who have entered into an agreement with Due factory to
              provide either free or paid for services to Users such as credit
              report analysis, credit score retrieval assistance, credit
              advisory, credit reporting, credit line access, credit lending and
              other such related services "Services" shall have the meaning
              ascribed to it under Clause 6 herein as may be amended or modified
              by Due factory from time to time. The Services contain materials
              including, without limitation, graphics, layout, text, content,
              instructions, images, audio, videos, designs, advertising copy,
              trademarks, logos, domain names, trade names, service marks and
              trade identities, any and all copyrightable material (including
              source and object code), the "look and feel" of the Services; the
              compilation, assembly and arrangement of the materials of the
              Services; and all other materials related to the Services
              (collectively, the "Content"). The Services also provide functions
              and services for which you can register – such as to receive your
              Score planner for free, related analysis of information in
              connection therewith and, if you so choose, to participate in our
              valuable products and services.
            </Typography>
          </Box>
          <Box>
            <Typography
              style={{
                fontSize: "24px",
                marginTop: "16px",
                fontFamily: "CustomFontMedium",
              }}
            >
              2. Eligibility
            </Typography>
          </Box>
          <Box style={{ marginTop: "10px" }}>
            <Typography style={{ fontFamily: "CustomFontMedium" }}>
              The use of Due factory ' Services and registration on
              www.duefactory.com is not permitted where it is prohibited by law.
              By using the Services, the User represents and warrants the
              following: The User has right, authority, and capacity to enter
              into these Terms; All information submitted by the User is
              truthful and accurate; The User is neither a competitor of Due
              factory nor is using the Services for reasons that are in
              competition with Due factory ; The User is entitled to submit the
              information to Due factory and that such information is not
              confidential, and not in violation of any contractual restrictions
              or other third party rights; The User understands and accepts that
              the accuracy and completeness of the information they provide
              determines the value and usefulness of the results; The User shall
              not utilize the Services if he/she is under 18 years of age; and,
              The use of the Services by User does not violate any applicable
              laws. Any User's profile may be deleted and membership may be
              terminated without warning, if Due factory believes that the User
              is under 18 years of age and/or is falsely representing
              himself/herself. The User also represents and warrants to Due
              factory that he/she will use www.duefactory.com in a manner
              consistent with all applicable laws and regulations.
            </Typography>
          </Box>
          <Box>
            <Typography
              style={{
                fontSize: "24px",
                marginTop: "16px",
                fontFamily: "CustomFontMedium",
              }}
            >
              3. Registration Through the Services
            </Typography>
          </Box>
          <Box style={{ marginTop: "10px" }}>
            <Typography style={{ fontFamily: "CustomFontMedium" }}>
              A User may register himself/herself on www.duefactory.com to
              receive various settlement offers, credit scores and related
              offers on the basis of the information supplied by such User.
              Further, the User may receive valuable offers for products and
              services from time to time from Due factory ' partners
              (collectively, the "Partners") on registering himself/herself on
              www.duefactory.com. The decision to provide information to Due
              factory is purely optional; however, if User elects not to provide
              such information, he/she may not be able to access certain
              Services or certain features or functions thereof. However, it
              shall be noted that while providing such aforementioned Services,
              Due factory will not share any User's personally identifiable
              information with its Partners without the User's express
              permission. If a User registers on www.duefactory.com and creates
              a personal account, he/she agrees to accept responsibility for all
              activities that occur under his/her account on www.duefactory.com
              or password provided therein, and agrees that he/she will not
              sell, transfer or assign his/her account or any account rights.
              Users are responsible for maintaining the confidentiality of their
              passwords, and for restricting access to their computers, mobile
              devices, or other internet access devices so that others may not
              access the password-protected portion of the Services using User's
              name and password. Due factory reserves the right to terminate a
              User's account or otherwise deny the User access to the Services
              at its sole discretion, without notice and liability. If User
              provides any information to Due factory solely for using the
              Services in a fraudulent way, Due factory , in its sole
              discretion, has the right to suspend or terminate User's exercise
              of any Services or refusal of all current or future access to
              www.duefactory.com or terminate any portion thereof. Further, User
              agrees that Due factory shall not be liable to him/her or any
              third party if Due factory suspends or terminates his/her access
              to the Services for any reason.
            </Typography>
          </Box>
          <Box>
            <Typography
              style={{
                fontSize: "24px",
                marginTop: "16px",
                fontFamily: "CustomFontMedium",
              }}
            >
              4. User's Access
            </Typography>
          </Box>
          <Box style={{ marginTop: "10px" }}>
            <Typography style={{ fontFamily: "CustomFontMedium" }}>
              Subject to User's compliance on a continuing basis with all of the
              terms and conditions of these Terms, Due factory hereby grants the
              User permission to use www.duefactory.com only as set forth in
              these Terms, and provided that: User will not copy or distribute
              any part of www.duefactory.com in any medium; User will not alter
              or modify any part of www.duefactory.com other than as expressly
              authorized and then only for such express purpose; User will
              otherwise comply with these Terms; User will not circumvent,
              disable, violate or attempt to violate, or otherwise interfere
              with the security or integrity of www.duefactory.com, the proper
              operation of www.duefactory.com, the features that prevent or
              restrict use or copying of any content or enforce limitations on
              use of www.duefactory.com therein, or interfere with any activity
              being conducted on www.duefactory.com; User will not violate any
              laws, third party rights, or Due factory ' policies; User will not
              post false, inaccurate, misleading, defamatory, or libelous
              content (including personal information); User will not
              distribute, host, or post spam, chain letters, pyramid schemes,
              viruses, unsolicited emails or any other technologies that may
              harm Due factory , or the interests or property of other Users;
              User will not harvest or otherwise collect information about other
              Users, including email addresses, without their consent; Verbal,
              physical, written or other abuse (including threats of abuse or
              retribution) of any Due factory ' customers, employees, members,
              or officers by the User may result in immediate account
              termination.
            </Typography>
          </Box>
          <Box>
            <Typography
              style={{
                fontSize: "24px",
                marginTop: "16px",
                fontFamily: "CustomFontMedium",
              }}
            >
              5. Usage Restriction
            </Typography>
          </Box>
          <Box style={{ marginTop: "10px" }}>
            <Typography style={{ fontFamily: "CustomFontMedium" }}>
              Users shall not use www.duefactory.com or Service including any
              content or information provided by Due factory : for any unlawful
              purpose or in violation of any applicable law, regulation,
              international law or laws of any other country; or in a manner
              that will violate the privacy, publicity, other personal rights or
              trade secrets of any third parties; or, Users are also prohibited
              from violating or attempting to violate the security of Due
              factory , including, without limitation the following activities:
              accessing data not intended for such User or logging into a server
              or account which the User is not authorized to access; attempting
              to probe, scan or test the vulnerability of a system or network or
              to breach security or authentication measures without proper
              authorization; attempting to interfere with service to any User,
              host or network, including, without limitation, via means of
              submitting a virus to www.duefactory.com, overloading, "flooding",
              "spamming", "mailbombing", "hacking" or "crashing"; or forging any
              TCP/IP packet header or any part of the header information in any
              email or newsgroup posting. Further, violations of system or
              network security may result in civil or criminal liability.
              Specific Restrictions. In addition to the above, the Users shall
              not: modify, adapt, translate, or reverse engineer any portion of
              www.duefactory.com and/or Services; remove any copyright,
              trademark or other proprietary rights notices contained in or on
              www.duefactory.com and/or Services; use any robot, spider, site
              search/retrieval application, or other device to retrieve or index
              any portion of www.duefactory.com and/or Services or for crawling
              www.duefactory.com and scraping content or to circumvent the
              technological methods adopted by www.duefactory.com to prevent
              such prohibited use; reformat or frame any portion of the web
              pages that are part of www.duefactory.com and/or Services; create
              Member accounts by automated means or under false or fraudulent
              pretenses; create or transmit unwanted electronic communications
              such as "spam" to other Users or members of www.duefactory.com
              and/or Service or otherwise interfere with other Visitor's or
              Member's enjoyment of www.duefactory.com and/or Service; submit
              any content or material that falsely expresses, or implies, that
              such content or material is sponsored or endorsed by Due factory
              or www.duefactory.com ; transmit any viruses, worms, defects,
              Trojan horses or other items of a destructive nature; take any
              action that imposes, or may impose, in Due factory ' sole
              discretion, an unreasonable or disproportionately large load on
              Due factory ' IT infrastructure.
            </Typography>
          </Box>
         
          <Box>
            <Typography
              style={{
                fontSize: "24px",
                marginTop: "16px",
                fontFamily: "CustomFontMedium",
              }}
            >
              6. Subscription & Cancellation Policy
            </Typography>
          </Box>
          <Box style={{ marginTop: "10px" }}>
            <Typography style={{ fontFamily: "CustomFontMedium" }}>
              1. Subscription Overview
            </Typography>
            <ul>
              <li>
                {" "}
                <Typography style={{ fontFamily: "CustomFontMedium" }}>
                  The Premium Insights Dashboard Subscription provides users
                  with monthly insights based on their Experian credit report.
                </Typography>
              </li>
              <li>
                {" "}
                <Typography style={{ fontFamily: "CustomFontMedium" }}>
                  Users receive insights on their current credit report, and at
                  the start of each new subscription period, the dashboard will
                  update with fresh insights based on their latest Experian
                  credit report.
                </Typography>
              </li>
            </ul>
          </Box>
          <Box style={{ marginTop: "10px" }}>
            <Typography style={{ fontFamily: "CustomFontMedium" }}>
            2. Eligibility and Subscription Activation
            </Typography>
            <ul>
              <li>
                <Typography style={{ fontFamily: "CustomFontMedium" }}>
                Only one subscription is allowed per user, linked to their mobile number or registered email ID.
                </Typography>
              </li>
              <li>
                <Typography style={{ fontFamily: "CustomFontMedium" }}>
                The credit repair platform reserves the right to cancel any subscription if it determines that a user has fraudulently obtained multiple subscriptions.
                </Typography>
              </li>
            </ul>
          </Box>
          <Box style={{ marginTop: "10px" }}>
            <Typography style={{ fontFamily: "CustomFontMedium" }}>
            3. Payment Plans
            </Typography>
            <ul>
              <li>
                <Typography style={{ fontFamily: "CustomFontMedium" }}>
                Monthly Recurring Plan: Users may select the Monthly Recurring Plan, where they pay the subscription amount for each month.
                </Typography>
              </li>
              <li>
                <Typography style={{ fontFamily: "CustomFontMedium" }}>
                Subscription renewal is subject to an automatic recurring payment service enabled on the user’s payment method, such as credit/debit card or UPI. Users cannot subscribe without recurring payment services enabled.
                </Typography>
              </li>
            </ul>
          </Box>
          <Box style={{ marginTop: "10px" }}>
            <Typography style={{ fontFamily: "CustomFontMedium" }}>
            4. Subscription Renewal and Cancellation Policy
            </Typography>
            <ul>
              <li>
                <Typography style={{ fontFamily: "CustomFontMedium" }}>
                The Monthly Recurring Subscription renews automatically unless the user cancels the subscription through their account settings on the platform. Users may also cancel by instructing their bank to stop recurring payments.
                </Typography>
              </li>
              <li>
                <Typography style={{ fontFamily: "CustomFontMedium" }}>
                Upon cancellation, the subscription will remain active until the end of the current month’s billing cycle, and the user will continue to receive insights for that period.
                </Typography>
              </li>
            </ul>
          </Box>
          <Box style={{ marginTop: "10px" }}>
            <Typography style={{ fontFamily: "CustomFontMedium" }}>
            5. Refund Policy
            </Typography>
            <ul>
              <li>
                <Typography style={{ fontFamily: "CustomFontMedium" }}>
                Once a payment is processed, it is non-refundable.
                </Typography>
              </li>
              <li>
                <Typography style={{ fontFamily: "CustomFontMedium" }}>
                For subscriptions under the Monthly Recurring Plan, cancellation will take effect at the end of the current billing cycle, and no partial refunds will be issued.
                </Typography>
              </li>
            </ul>
          </Box>
          <Box style={{ marginTop: "10px" }}>
            <Typography style={{ fontFamily: "CustomFontMedium" }}>
            6. Failure to Pay and Subscription Termination
            </Typography>
            <ul>
              <li>
                <Typography style={{ fontFamily: "CustomFontMedium" }}>
                If a payment fails to process on the scheduled renewal date due to insufficient funds, expired card details, or other issues, the subscription may be canceled automatically.
                </Typography>
              </li>
              <li>
                <Typography style={{ fontFamily: "CustomFontMedium" }}>
                Users may reactivate the subscription by updating their payment information or ensuring sufficient funds.
                </Typography>
              </li>
            </ul>
          </Box>
          <Box style={{ marginTop: "10px" }}>
            <Typography style={{ fontFamily: "CustomFontMedium" }}>
            7. Subscription Benefits and Conditions
            </Typography>
            <ul>
              <li>
                <Typography style={{ fontFamily: "CustomFontMedium" }}>
                The report generated is based on information available with the respective credit bureaus. Due Factory cannot be held liable for any such inaccuracy in the data available to it through the credit bureau.
                </Typography>
              </li>
              <li>
                <Typography style={{ fontFamily: "CustomFontMedium" }}>
                The Premium Insights provided are strictly for personal use and cannot be shared or distributed.
                </Typography>
              </li>
              <li>
                <Typography style={{ fontFamily: "CustomFontMedium" }}>
                Insights are valid only within the subscription period, and each month provides new insights based on the user’s updated credit report.
                </Typography>
              </li> <li>
                <Typography style={{ fontFamily: "CustomFontMedium" }}>
                Any enhancements to the Premium Insights dashboard, including but not limited to new features or functionality, are provided at the discretion of the platform.
                </Typography>
              </li>
            </ul>
          </Box>
          <Box style={{ marginTop: "10px" }}>
            <Typography style={{ fontFamily: "CustomFontMedium" }}>
            8. General Terms
            </Typography>
            <ul>
              <li>
                <Typography style={{ fontFamily: "CustomFontMedium" }}>
                Recommendations made by Due Factory are indicative and directional in nature. Users are advised to exercise caution before acting on these recommendations and validate the impact before taking any financial action.
                </Typography>
              </li>
              <li>
                <Typography style={{ fontFamily: "CustomFontMedium" }}>
                before taking any financial action.
The financial liability of Due Factory under any circumstances is limited to  the amount of fee it has charged from the respective user.

                </Typography>
              </li>
              <li>
                <Typography style={{ fontFamily: "CustomFontMedium" }}>
                Subscription terms and conditions, including pricing, benefits, and features, are subject to change at the platform’s discretion. Users will be notified of any changes in advance.
                </Typography>
              </li> <li>
                <Typography style={{ fontFamily: "CustomFontMedium" }}>
                These terms align with the overarching policies and guidelines of the credit repair platform.
                </Typography>
              </li>
            </ul>
          </Box>
         

          <Box>
            <Typography
              style={{
                fontSize: "24px",
                marginTop: "16px",
                fontFamily: "CustomFontMedium",
              }}
            >
              7. Scope of Services
            </Typography>
          </Box>
          <Box style={{ marginTop: "10px" }}>
            <Typography style={{ fontFamily: "CustomFontMedium" }}>
              The Services rendered by Due factory are Liaison with Credit
              Institutions to settle the past outstanding dues of the User. Use
              the best endeavors to promote the interest of the User. Liaison
              with the CIC's and/or Credit Institutions and rectify the CIR
              and/or Credit Scoring of User. Review all the credit information
              provided by the User during the Term of this Agreement. Follow-up
              and review all correspondence received by the User from the Credit
              Institutions/CICs and prepare follow-up strategies accordingly.
              The User acknowledges and agrees that by agreeing to the Terms
              contained herein and using any Services provided by Due factory ,
              Due factory is by no means guaranteeing the improvement of the
              User's credit history or credit scoring or procurement of
              financial assistance, as the affectivity of such Services are
              dependent upon various factors beyond its control. Due factory
              provides some of its Services to the Users free of cost on
              www.duefactory.com . However, access to or use of certain
              Services, or any features thereof, might be restricted, as may be
              applicable for the access to, or use of, such Services. On
              registering with www.duefactory.com for the use of any Service,
              the User shall be provided a unique identification number ("Cust
              ID") for each such Service opted for by the User. The Cust ID
              shall then be used for and in all future correspondence and
              communication between Due factory and the User with regard to the
              said Service. The User may also be provided a facility to store
              all documents pertaining to the Services opted for by the User.
              Such a facility may be provided to the User based on the Service
              opted for, for such other terms as may be present in addition to
              the Terms herein, and unless otherwise stated, shall be for the
              duration of the Service. Such other services as mutually agreed
              between the Parties, The services setout hereinabove shall be
              collectively referred to as "Services". Due factory hereby agrees
              to render the Services to the User during the Term of this
              Agreement on the terms and conditions set out herein.
            </Typography>
          </Box>
          <Box>
            <Typography
              style={{
                fontSize: "24px",
                marginTop: "16px",
                fontFamily: "CustomFontMedium",
              }}
            >
              8. Duties and Obligations of Due factory
            </Typography>
          </Box>
          <Box style={{ marginTop: "10px" }}>
            <Typography style={{ fontFamily: "CustomFontMedium" }}>
              Due factory here by undertakes that the information disclosed by
              the User to Due factory pursuant to this Agreement shall be used
              only for the purposes as contemplated under such Agreement. Due
              factory shall make its best endeavors to render its Services to
              the User and get the desired results for the User. At all times,
              during the Term of this Agreement, be compliant to the good
              industry practices followed by any institution or body performing
              similar functions or services and all the laws as may be
              applicable to it from time to time. Due factory declares and the
              User acknowledges that by way of entering into this Agreement, Due
              factory is by no means guaranteeing savings or the improvement of
              the User's credit history or Credit Scoring or procurement of
              financial assistance, since this is dependent upon various factors
              beyond its control.
            </Typography>
          </Box>
          <Box>
            <Typography
              style={{
                fontSize: "24px",
                marginTop: "16px",
                fontFamily: "CustomFontMedium",
              }}
            >
              9. Duties and Obligations of the User
            </Typography>
          </Box>
          <Box style={{ marginTop: "10px", fontFamily: "CustomFontMedium" }}>
            <Typography  style={{ fontFamily: "CustomFontMedium" }}>
              The User expressly agrees and undertakes to provide to Due factory
              all his personal credit information and all such other details as
              solicited by Due factory from time to time and that the rendering
              and fulfillment of Services by Due factory is subject to valid,
              complete and accurate information being provided by the User to
              Due factory. The User is required to produce Due factory with
              right documentary evidence for the claims he/she makes. Due
              factory is under no obligation to verify information supplied by
              the User, but may do so at its sole discretion. The User further
              agrees that owing to a delay in performance of his/her obligations
              by the User, Due factory shall not be liable for the delay in
              fulfillment of its Services hereunder as a result of the same and
              the period of such Services shall be automatically extended for
              such period of delay on the part of the User. The User agrees that
              it shall be his/her responsibility to provide a copy of all the
              correspondence with the credit institution, CIC's and the CIR's
              created by such CIC's to Due factory as required. However, the
              User may also expressly request Due factory to procure such
              reports from Credit Institutions/CIC's on the User's behalf, if
              the same is permissible under law and by the Credit
              Institutions/CIC's. The User also further agrees to promptly
              notify Due factory of his/her communication with any credit
              institution, CIC's during the term of this Agreement. The User
              agrees to immediately forward to Due factory all the reports and
              other documents/communication received by the User from either the
              CIC's or Credit Institutions. Due factory shall not be responsible
              and/or liable for any deficiency in Services due to wrong
              information provided and/or misrepresentation made by the User to
              Due factory. Subject to the same being permissible under law, the
              User shall authorize Due factory as his/her representative to
              liaison with the Credit Institutions/CIC's on his/her behalf, if
              necessary, to request an investigation to verify the negative
              items/remarks in the User's credit records for the purpose of
              improvising and/or rectifying it. The Parties shall complete all
              the formalities and execute all necessary documents as may be
              necessary for such purpose. The User hereby acknowledges and
              undertakes that his/her relationship with Due factory will be
              fiduciary in nature and on receipt of his/her account status/CIR
              from the Credit Institution/CIC, he/she shall identify in writing
              any such negative information which to the best of his/her
              knowledge is true and accurate so that such items are disputed
              with the concerned Credit Institutions. The User understands and
              acknowledges that Due factory is not providing any legal advice to
              the User nor is it rendering Services in the capacity of legal
              advisors. Further, it is expressly agreed by the User that he/she
              shall not hold Due factory responsible for any legal action that
              is taken by any creditor/lender against the User either prior,
              during or after the Term of this Agreement. Due factory makes no
              representations regarding the frequency with which it . comupdates
              User data and may at its discretion temporarily stop or
              permanently discontinue updating User data at any time. The User
              shall immediately notify, Due factory of any change of
              communication address.
            </Typography>
          </Box>
          <Box>
            <Typography
              style={{
                fontSize: "24px",
                marginTop: "16px",
                fontFamily: "CustomFontMedium",
              }}
            >
              10. Representations
            </Typography>
          </Box>
          <Box style={{ marginTop: "10px", marginBottom:"40px" }}>
            <Typography style={{ fontFamily: "CustomFontMedium" }}>
              Due factory hereby represents, warrants, undertakes and covenants
              to the User that: It shall perform the Services in good faith and
              in a professional manner. It is free to enter into this Agreement
              and is not under any disability, restriction or prohibition which
              might prevent it from performing or observing any of its
              obligations under the said Agreement. That no proceedings are
              pending against Due factory which shall have material adverse
              impact on the implementation of this Agreement or on the
              obligations of Due factory under the said Agreement. Due factory
              hereby represents and warrants that it has all the requisite
              expertise to perform the Services contemplated under this
              Agreement and shall make best efforts to rectify the Credit
              Scoring of the User. The User represents, warrants, undertakes and
              covenants to Due factory that: The User shall provide full
              co-operation, information, documentation and/or assistance as may
              be necessary for Due factory to render its Services hereunder. The
              User shall comply with all reasonable requirements, specifications
              and guidelines agreed to between the Parties in the performance of
              its obligations under this Agreement. This Agreement is a legal
              and binding obligation of the User, enforceable against the User
              in accordance with its terms, except to the extent enforceability
              is modified by bankruptcy and other similar laws affecting the
              rights of creditors generally and by general principles of equity.
              The User shall promptly provide and sign any other documents as
              may be considered necessary by the User to effectively carry out
              provision of this Agreement. The User shall not disclose, reveal
              or make public, any information of whatsoever nature in connection
              with the terms of this Agreement. The User represents that the
              negative and disputed items/remarks in the CIR's of the User is,
              to the best of his/her knowledge, either incomplete, inaccurate,
              obsolete, unverifiable, or misleading. The User agrees and
              acknowledges that Due factory cannot guarantee the removal of
              items which are not incomplete, inaccurate, obsolete,
              unverifiable, misleading, or incorrect. The User further
              acknowledges that the considering the nature of Services and the
              discretion to rectify the same being in the hands of CIC and/or
              the Credit Institution(s), it is not feasible for Due factory to
              predict or guarantee the outcome of such Services and/or the
              improvement in the Credit Scoring of the User. <br /> <br /> 1.
              Confidentiality Both Parties shall, at all times during the
              continuance of this Agreement and for a period of one (1) year
              following its termination (howsoever occasioned) or expiry, keep
              confidential the contents of this Agreement, as well as all the
              information disclosed to or obtained by the Parties from each
              other pursuant to the said Agreement. Such confidential
              information shall be used solely for the purpose of satisfying the
              obligations set forth in the Agreement. The provisions of this
              Clause shall be subject to any information required under a court
              of law or that available in public domain. 1. Partner Offers
              Through the Services, you may register to receive valuable offers
              for products and services from time to time from our partners
              (collectively, the "Partners"). The decision to provide
              information to us is purely optional; however, if the User not to
              provide such information, the User may not be able to access
              certain features or functions of the Services. Due factory will
              match offers from Partners against the User's Registration Profile
              (as hereinafter defined), which would include User's credit
              information report and score along with other targeting criteria,
              in order to ensure that the User receives offers that are tailored
              to the User's specific needs. One of the underlying philosophies
              behind Due factory business, as reflected in the Due factory name,
              is that individuals who have through their efforts been able to
              work hard to rebuild their credit by repaying their outstanding
              dues should be able to reap the rewards of those efforts,
              including by receiving discounted and favorable pricing and rates
              on the products and services that they enjoy. While Due factory
              does not guarantee that the offers that the User receives from Due
              factory ' Partners will meet that goal, or that they will
              necessarily be the best offers available to the User, Due factory
              does represent and warrant to you that providing you with valuable
              offers for products and services from our Partners is one of the
              main animating objectives of Due factory ' business. Due factory '
              technology for matching the User's Registration Profile to the
              valuable offers for products and services provided by its Partners
              is proprietary and, in carrying out such matching, Due factory may
              elect to consider, ignore, emphasize, or deemphasize any relevant
              factors in its sole discretion. Due factory does not guarantee
              that the User will receive offers for any particular types of
              products or services. <br /> <br /> 2. Warranty Disclaimer The
              services are provided on an "as is" basis without warranties of
              any kind, either express or implied, including without limitation,
              warranties of title, accuracy, omissions, completeness or delays,
              or implied warranties of merchantability or fitness for a
              particular purpose. to the fullest extent permitted by law, Due
              factory, its affiliates and each of their respective officers,
              directors, employees, and agents disclaim all warranties, express
              or implied, in connection with www.duefactory.com and the User's
              use of the services, and products thereof including warranties of
              merchantability, fitness for a particular purpose and
              noninfringement. In addition, neither Due factory nor its
              affiliates, nor any of their respective officers, directors,
              employees, and agents, makes any warranties or representations
              about the accuracy or completeness of www.duefactory.com 's
              content, or the services or the products, or the content of any
              sites linked to www.duefactory.com , and assumes no liability or
              responsibility for any (a) errors, mistakes, or inaccuracies
              thereof; (b) personal injury or property damage, of any nature
              whatsoever, resulting from user's access to and use of
              www.duefactory.com ; (c) any interruption or cessation of
              transmission to or from www.duefactory.com ; (d) any bugs,
              viruses, trojan horses, or the like which may be transmitted to or
              through www.duefactory.com by any third party; and (e) any errors
              or omissions in any content or for any loss or damage of any kind
              incurred as a result of the use of any service or product offered
              on www.duefactory.com , content posted, emailed, transmitted, or
              otherwise made available via www.duefactory.com . Due factory does
              not warrant, endorse, guarantee, or assume responsibility for any
              product or service advertised or offered by a third party through
              www.duefactory.com or any hyperlinked website or featured in any
              banner or other advertising, and Due factory will not be a party
              to or in any way be responsible for monitoring any transaction
              between user and third-party providers of products or services.
              www.duefactory.com is controlled and offered by Due factory from
              its facilities in India. Due factory makes no representations that
              www.duefactory.com is appropriate or available for use in other
              locations. <br /> <br /> 3. Limitation of Liability Due factory
              declares and the User agrees that notwithstanding anything
              contained in this Agreement, Due factory shall have no liability
              to the User, in contract, tort or otherwise, for any indirect or
              consequential losses, damages, costs, charges, expenses or
              otherwise, which the User suffers or incurs arising out of or in
              connection with the provisions of or subscribing for the Services
              or use of the information provided by Due factory or any reports
              or howsoever under or in connection with or incidental to the said
              Agreement or the implementation thereof. <br /> <br /> 4.
              Indemnity The User hereby undertakes and agrees to indemnify Due
              factory and hold it harmless and keep it, at all times, fully
              indemnified and hold harmless from and against all actions,
              proceedings, claims, liabilities (including statutory liability),
              penalties, demands and costs (including, without limitation, legal
              costs incurred by Due factory ) awards, damages, losses and/or
              expenses, howsoever arising directly or indirectly as a result of
              any error, inaccuracy or misleading data or information contained
              in the personal or credit information furnished by the User
              causing Due factory to file infructuous letters to banks and
              financial institutions and thereby leading to loss of reputation
              of Due factory . <br /> <br /> 5. Intellectual Property
              Information and Restrictions Copyright: The copyright in the
              content is held by Due factory or by the original creator of the
              material and is protected by India and International copyright
              laws. The User is permitted to use the content delivered to it
              through the Services only on the Services. The User agrees that
              the content may not be copied, reproduced, distributed,
              republished, displayed, posted or transmitted in any form or by
              any means, including, but not limited to, electronic, mechanical,
              photocopying, recording, or otherwise, without the express prior
              written consent of Due factory. The User acknowledges that the
              content is and shall remain the property of Due factory. The User
              may not modify, participate in the sale or transfer of, or create
              derivative works based on any Content, in whole or in part. The
              use of the content on any other web site, including by linking or
              framing, or in any networked computer environment for any purpose,
              is prohibited without Due factory ' prior written approval. The
              User also may not, without Due factory ' written permission,
              "mirror" any material contained on the Services on any other
              server. The User may not reverse engineer or reverse compile any
              of the Services technologies, including but not limited to, any
              Java applets associated with the Services. Any unauthorized use of
              any Content on the Services may violate copyright laws, trademark
              laws, the laws of privacy and publicity, and communications
              statutes and regulations. Trademarks: Due factory (including the
              Due factory logo), www.duefactory.com, and all related logos
              (collectively the " Due factory Trademarks") are trademarks or
              service marks of Due factory. Other company, product, and service
              names and logos used and displayed on the Services may be
              trademarks or service marks owned by Due factory or others.
              Nothing in the Services should be construed as granting, by
              implication, estoppel, or otherwise, any license or right to use
              any of the Due factory Trademarks displayed on the Services
              without our prior written permission in each instance. The User
              may not use, copy, display, distribute, modify or reproduce any of
              the Due factory ' Trademarks found on the Services unless in
              accordance with written authorization by us. We prohibit use of
              any of the Due factory Trademarks as part of a link to or from any
              site unless establishment of such a link is approved in writing by
              us in advance. Any questions concerning any Due factory
              Trademarks, or whether any mark or logo is a Due factory
              Trademark, should be referred to Due factory. <br /> <br /> 6.
              Communication User agrees and understands that all communication
              between Due factory and the User shall be through electronic
              medium, such as emails or notices placed on www.duefactory.com.
              User consents to receive communications via electronic medium from
              Due factory periodically and as and when required. User also
              agrees and acknowledges that all communication between Due factory
              and the User in such format shall satisfy all legal requirements
              pertaining to such exchange. <br /> <br /> 7. Modification Due
              factory may, at any time, modify these Terms to reflect changes to
              the law or any change in its Services. User should look at the
              Terms regularly. Due factory may post notice of modifications to
              these Terms on www.duefactory.com and such changes will become
              effective from the date they are posted on www.duefactory.com. If
              User does not agree to the modified Terms, he/she should
              discontinue their use of www.duefactory.com. Amendment to or
              modification of these Terms will be considered accepted and
              binding upon the User if the User continues to use the Services
              and www.duefactory.com even after such changes to these Terms.{" "}
              <br /> <br /> 8. Term and Termination These Terms shall remain in
              full force and effect for the duration of the availed Service or
              unless and until User's account is terminated as provided herein.
              User may terminate his/her account as per the instructions
              provided in www.duefactory.com and end their use of Due factory '
              Services at any time. Due factory has the right (at its sole
              discretion) for any reason to delete, disable or deactivate User's
              account, block User's email or IP address, or otherwise terminate
              User's access to or use of Www.duefactory.com , remove and discard
              any code or content within any network or anywhere on Due factory
              ' platform or shut down a network, including permanent deletion of
              any network data, with or without notice, on Due factory '
              platform and with no liability of any kind to User. In the event
              of any breach of trust and security, User shall agree to absolve
              Due factory and its affiliates, and their respective directors,
              employees, information providers, and officers of any
              responsibility. User consents that Due factory and its affiliates
              and their respective directors, employees, information providers,
              and officers shall be free from harm, and any or all liabilities,
              and cost incurred by Due factory and its affiliates and their
              respective directors, employees, information providers, and
              officers shall be duly paid or reimbursed as per the directive of
              the appropriate court of law. Due factory also reserves the right
              to assume the defense and control of any matter pertaining to
              breach of trust and security, at its own expense. User shall not
              settle any such case without the prior written consent of Due
              factory. These Terms (other than User's right to use the Services)
              shall survive termination or expiration of www.duefactory.com and
              any Service or product offered on www.duefactory.com . <br />{" "}
              <br /> 9. Governing Law, Jurisdiction and Arbitration All disputes
              or claims between the Parties, hereto related to this Agreement,
              shall be settled through arbitration only, by the sole arbitrator
              sitting at Delhi, who shall be solely appointed by Due factory and
              the User has no objection to the same. And for any other or
              further proceedings, the courts at Delhi shall alone have
              exclusive jurisdiction in any matter between the parties. Awards
              relating to any dispute shall be final, conclusive and binding on
              the Parties to such dispute as from the date they are made. The
              Parties agree and undertake to carry out any decision or award of
              the arbitrator relating to such dispute without delay, and further
              agree that there will be no appeal and/ or challenge in any Court
              of Law or other judicial authority. 1. Miscellaneous Entire
              Agreement: This Agreement represents the entire Agreement between
              the Parties hereto with reference to the subject matter hereof and
              supersedes, replaces and cancels any and all arrangements,
              agreements or understandings between the Parties, which may have
              been previously in existence with reference to the subject matter
              hereof. Amendment The provisions of this Agreement may only be
              altered, modified or amended by an instrument in writing duly
              executed by the Parties. Partial Invalidity If, at any time, any
              provision of this Agreement is or becomes illegal, invalid or
              unenforceable in any respect under any law of any jurisdiction,
              neither the legality, validity or enforceability of the remaining
              provisions nor the legality, validity or enforceability of such
              provision under the law of any other jurisdiction will in any way
              be affected or impaired. Assignment The Parties shall not assign
              any of their rights under this Agreement to any person/entity.
              Further Assurances The Parties agree to do all such further and
              other things, execute and deliver all such additional documents,
              to give full effect to the terms of this Agreement. Binding Nature
              The provisions of this Agreement shall be legally binding on the
              Parties hereto. 1. Grievance Officer In accordance with
              Information Technology Act 2000 and rules made there under, the
              name and contact details of the Grievance Officer are provided
              below: Regd off: 806-807, Udyog Vihar Phase V, Sector 19,
              Gurugram, Haryana – 122016 Name: Grievance Officer Address: DUE
              FACTORY SERVICES PRIVATE LIMITED, 806-807, Udyog Vihar Phase V, Sector 19, Gurugram, Haryana – 122016 Email id: care@theduefactory.com
            </Typography>
          </Box>
        </Box>
      </Box>
      {pathname.includes("terms-&-conditions") ? (
        <>
          <FotterComponent />
        </>
      ) : (
        <></>
      )}
    </>
  );
}

export default TermsConditions;
