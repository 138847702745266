import React, { useState } from "react";
import { Box, Grid, Typography, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import TDFControl from "../assets/homePage2/control.webp";
import { toast } from "react-toastify";
import { profileApiData } from "../service/ApiList";
import CircularProgress from "@mui/material/CircularProgress";

function AdvertiseSection() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const handleUnathorized = () => {
    toast.info("Unauthorized. Please log in again.");
    localStorage.clear();
  };
  const gotoLogin = () => {
    const TDFToken = localStorage.getItem("TDFToken");
    
    if (TDFToken) {
      setLoading(true);
      profileApiData({}, (res, err) => {
        if (!res) {
          // toast.error("Something went wrong. Please try again.");
          setLoading(false);
          localStorage.clear("")
          navigate("/login")
          return;
        }
      
        if(typeof res?.data?.status === 'number' && res?.data?.success === false){
          localStorage.clear("")
          navigate("/login");
        }
        if (res?.status === 200 && (typeof res?.data?.status === 'string' || res?.data?.status === null)) {
          localStorage.setItem("StatusLS", res?.data?.status);
          setLoading(false);
          const status = res?.data?.status;
          switch (status) {
            case "BUREAU_FETCH":
              navigate("/home/dashboard");
              break;

            case "ENHANCED_FETCH_REPORT_OTP_VALIDATED":
            case "NORMAL_FETCH_REPORT_OTP_SENT":
            case "NORMAL_FETCH_REPORT_OTP_VALIDATED":
              toast.info("Please complete your profile.");
              navigate("/login/experianfullform", {
                state: { userProfileData: res?.data },
              });
              break;

            case "ENHANCED_FETCH_REPORT_OTP_SENT":
            case "REVOKE_CONSENT":
            case "BUREAU_DELETED":
            case "OTP_VALIDATED":
              toast.info("Please complete your profile.");
              navigate("/login/user", {
                state: { userProfileData: res?.data },
              });
              break;
            case "":
              toast.info("Please complete your profile.");
              navigate("/login/user", {
                state: { userProfileData: res?.data },
              });
              break;
            case null:
              toast.info("Please complete your profile.");
              navigate("/login/user", {
                state: { userProfileData: res?.data },
              });
              break;

            default:
              toast.error("Something went wrong. Please try again.");
              break;
          }
        } else {
          setLoading(false);        
        }
      });
    } else {
      navigate("/login");
    }
  };
  return (
    <>
      <Box sx={{ padding: { xs: "none", sm: "none", lg: "50px 83px 0" } }}>
        <Grid container sx={{ paddingTop: "10px" }}>
          <Grid
            item
            xs={12}
            sm={12}
            lg={6}
            md={6}
            sx={{
              display: { xs: "block", sm: "block", lg: "flex", md: "flex" },
              padding: { xs: "20px", sm: "20px", lg: "20px", md: "20px" },
              alignItems: { lg: "center", md: "center" },
              background: "#044a67",
              borderRadius: {
                xs: "10px",
                sm: "10px",
                lg: "10px 0 0 10px",
                md: "10px 0 0 10px",
              },
            }}
          >
            <Box
              sx={{
                marginLeft: {
                  xs: "none",
                  sm: "none",
                  lg: "40px",
                },
              }}
            >
              <Box>
                <Typography
                  sx={{
                    fontFamily: "CustomFontMedium",
                    color: "#fff",
                    fontSize: {
                      xs: "27px",
                      sm: "35px",
                      md: "42px",
                      lg: "50px",                    
                    },
                    textAlign: {
                      xs: "center",
                      sm: "center",
                      lg: "start",
                      md: "start",
                    },
                    lineHeight: 1.2,
                  }}
                >
                  Take Control Of Your
                  <br />
                  Credit Health Today
                </Typography>
              </Box>

              <Box
                sx={{
                  textAlign: {
                    xs: "center",
                    sm: "center",
                    lg: "justify",
                    md: "justify",
                  },
                }}
              >
                <Button
                  sx={{
                    "&:hover": {
                      background: "#f0f0f0",
                      color: "#333",
                    },
                    textTransform: "capitalize",
                    background: "#fff",
                    color: "black",
                    width: {
                      xs: "none",
                      sm: "210px",
                      lg: "210px",
                      md: "210px",
                    },
                    padding: {
                      xs: "0 25px",
                      sm: "5px 30px",
                      lg: "5px 30px",
                      md: "5px 30px",
                    },
                    fontWeight: { xs: "400", sm: "500", lg: "500", md: "500" },
                    marginTop: "10px",
                    borderRadius: "35px",
                    fontSize: "20px",
                  }}
                  size="large"
                  onClick={gotoLogin}
                >
                  {loading ? (
                    <>
                      <CircularProgress size={"25px"} />
                    </>
                  ) : (
                    <>Register Now</>
                  )}
                </Button>
              </Box>
            </Box>
          </Grid>
          <Grid
            item
            xs={0}
            sm={6}
            lg={6}
            md={6}
            sx={{
              display: { xs: "none", sm: "none", lg: "block", md: "block" },
            }}
          >
            <img
              src={TDFControl}
              alt="TDF"
              width={"100%"}
              height={"100%"}
              style={{ borderRadius: "0 10px 10px 0" }}
              loading="lazy"
            />
          </Grid>
        </Grid>
      </Box>
    </>
  );
}

export default AdvertiseSection;
