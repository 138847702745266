import React, { useEffect, useRef, useState } from "react";
import "./PremiumDashboard.css";
import PremiumReport from "../CentralMenu/PremiumReport";
import CreditMix from "./CreditMix";
import Loading from "../Components/Loading";
import HeaderComponent from "../Components/HeaderCoponent";
// import FotterComponent from "../Components/FotterComponent";
// import CreditMixNew from "../CentralMenu/CreditMixNew";
import { GET_INSIGHTS } from "../service/ApiList";
import { useNavigate } from "react-router-dom";
import { generalSwitch } from "../utils/generalSwitch";
import { toast } from "react-toastify";

function PremiumDashboard() {
  const [activeTab, setActiveTab] = useState("All");
  const [insightData, setInsightData] = useState("");
  const [loading, setLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  const navigate = useNavigate();

  const topDivRef = useRef(null); // Ref for "top-div-pre"
  const lastScrollY = useRef(0); // Track the last scroll position

  const handleSwitch = (tab) => {
    setActiveTab(tab);
  };
  const insightReportApi = () => {
    setLoading(true);
    GET_INSIGHTS({}, (res,err) => {
      console.log("res");
      if (res?.data?.success && res?.data?.message === "Success") {
        setInsightData(res?.data?.response);
        setLoading(false);
      } else if (res?.data?.status && res?.data?.success === false) {
        setLoading(false);
        const status = res?.data?.status;      
        generalSwitch(status, navigate);
      } else {
        setLoading(false);
       toast.error(err?.errorMessage);
      //  setIsError(true)
      }
    });
  };
  const getLeftPosition = () => {
    switch (activeTab) {
      case "All":
        return "1%";
      case "Good":
        return "33.33%";
      case "Bad":
        return "65.66%";
      default:
        return "0%";
    }
  };
  const renderContent = () => {
    switch (activeTab) {
      case "All":
        return <CreditMix creditMixdata={insightData?.insightCreditMix?.ALL} />;
      case "Good":
        return insightData?.insightCreditMix?.GOOD?.totalAccounts >= 1 ? (
          <>
            <CreditMix creditMixdata={insightData?.insightCreditMix?.GOOD} />
          </>
        ) : (
          <>
            <div style={{ height: "50vh" }}>
              <div
                style={{
                  height: "210px",
                  background: "#ECF0F6",
                  borderRadius: "24px",
                  padding: "10px 20px",
                  margin: "10px",
                }}
              >
                <p className="bad-account-msg">
                  😞 “It looks like there are some challenges with your current
                  loans. But remember, it’s never too late to make a positive
                  change. We’re here to support you on your path to financial
                  stability.”
                </p>
              </div>
            </div>
          </>
        );
      case "Bad":
        return insightData?.insightCreditMix?.BAD?.totalAccounts >= 1 ? (
          <>
            <CreditMix creditMixdata={insightData?.insightCreditMix?.BAD} />
          </>
        ) : (
          <>
            <div style={{ height: "50vh" }}>
              <div
                style={{
                  height: "210px",
                  background: "#ECF0F6",
                  borderRadius: "24px",
                  padding: "10px 20px",
                  margin: "10px",
                }}
              >
                <p className="bad-account-msg">
                  🌟 “Impressive! Zero bad loans on your record—your financial
                  game is on point! Keep it going strong!”
                </p>
              </div>
            </div>
          </>
        );

      default:
        return null;
    }
  };
  useEffect(() => {
    insightReportApi();
  }, []);
  useEffect(() => {
    const handleScroll = () => {
      if (window.innerWidth <= 1350) {
        return;
      }

      if (topDivRef.current) {
        const rect = topDivRef.current.getBoundingClientRect();
        const elementHeight = rect.height;
        const elementTop = rect.top;

        // Detect scroll direction
        const currentScrollY = window.scrollY;
        const isScrollingDown = currentScrollY > lastScrollY.current;
        lastScrollY.current = currentScrollY;

        // Trigger when the element is at least half visible
        const isHalfVisible =
          elementTop < window.innerHeight &&
          elementTop + elementHeight / 2 <= window.innerHeight;

        if (isScrollingDown && isHalfVisible) {
          // Smooth scroll to the element when it becomes half visible
          const scrollOffset =
            rect.bottom - window.innerHeight > 0
              ? rect.bottom - window.innerHeight
              : 0;

          window.scrollBy({
            top: scrollOffset,
            behavior: "smooth",
          });
        }
      }
    };

    // Add scroll event listener
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const [counter, setCounter] = useState(5); // 5 seconds countdown

  useEffect(() => {
    if (counter > 0) {
      const timer = setTimeout(() => setCounter(counter - 1), 1000);
      return () => clearTimeout(timer);
    }
  }, [counter]);

  return (
    <>
      {loading ? (
        <>
          <Loading />
        </>
      ) : isError ? (
        <>
          <Loading />
        </>
      ) : (
        <>
          <div style={{ paddingBottom: "1.5rem" }}>
            <HeaderComponent />
          </div>
          <>
            {/* <CreditMixNew  data={insightData}/> */}
            <PremiumReport data={insightData} />
            <div
              style={{
                background: "#ECF0F6",
                paddingBottom: "30px",
                paddingTop: "10px",
              }}
            >
              <div className="top-div-pre" ref={topDivRef}>
                <div className="parentDiv1">
                  <div className="credit-mix-desktop-div-1">
                    <span>Credit Mix</span>
                  </div>
                  <div className="switch-container-top">
                    <div className="switch-container">
                      <div
                        className="switch-background"
                        style={{ left: getLeftPosition() }}
                      ></div>
                      <div
                        className={`switch-btn ${
                          activeTab === "All" ? "active" : ""
                        }`}
                        onClick={() => handleSwitch("All")}
                      >
                        {` All Accounts (${insightData?.insightCreditMix?.ALL?.totalAccounts})`}
                      </div>
                      <div
                        className={`switch-btn ${
                          activeTab === "Good" ? "active" : ""
                        }`}
                        onClick={() => handleSwitch("Good")}
                      >
                        {`Good Accounts (${insightData?.insightCreditMix?.GOOD?.totalAccounts})`}
                      </div>
                      <div
                        className={`switch-btn ${
                          activeTab === "Bad" ? "active" : ""
                        }`}
                        onClick={() => handleSwitch("Bad")}
                      >
                        {`Bad Accounts (${insightData?.insightCreditMix?.BAD?.totalAccounts})`}
                      </div>
                    </div>
                  </div>
                  <div>{renderContent()}</div>
                </div>
              </div>
            </div>

            {/* <FotterComponent /> */}
          </>
        </>
      )}
    </>
  );
}

export default PremiumDashboard;
