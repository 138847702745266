import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { useLocation } from "react-router-dom";

const HelmetWrapper = () => {
  const location = useLocation();

  // Define meta title based on the path
  const getTitle = (path) => {
    switch (path) {
      case "/login":
        return "Due Factory | Log in";
      case "/about-us":
        return "Due Factory | About Us";
      case "/contact-us":
        return "Due Factory | Contact Us";
      case "/terms-&-conditions":
        return "Due Factory | Terms & Conditions";
      case "/faq":
        return "Frequently Asked Questions - Due Factory";
        case "/grievance-redressal":
          return "Due Factory | Grievance Redressal Mechanism";  
      default:
        return "Due Factory - Credit Score Improvement | Credit Repair | Debt Relief Platform";
    }
  };
  const getDescription = (path) => {
    switch (path) {
      case "/login":
        return "Log in to Due Factory to manage your credit score and debts easily.";
      case "/about-us":
        return "Learn more about Due Factory, our mission, and how we help you improve your credit score.";
      case "/contact-us":
        return "Get in touch with Due Factory for support and inquiries.";
      case "/terms-&-conditions":
        return "Read the terms and conditions of using Due Factory's credit score improvement platform.";
      case "/faq":
        return "Find answers to frequently asked questions about credit scores and debt relief at Due Factory.";
        case "/grievance-redressal":
          return "Submit your complaints or grievances regarding credit score management with Due Factory.";        
      default:
        return "Check credit score, Settle your old outstanding loans, follow our recommendations and boost credit score with Due Factory. Pay in full or in parts.";
    }
  };

  // const description =
  //   "Check credit score, Settle your old outstanding loans, follow our recommendations and boost credit score with Due Factory. Pay in full or in parts.";
    const description = getDescription(location.pathname);
  const title = getTitle(location.pathname);

  return (
    <HelmetProvider>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
      </Helmet>
    </HelmetProvider>
  );
};

export default HelmetWrapper;
