import React, { useEffect, useState } from 'react'
import "./style.css";
import { IoClose } from 'react-icons/io5';
import { GoChevronRight } from 'react-icons/go';
import { postSaveUserAction } from '../../../../service/ApiList';
import CircularProgress from '@mui/material/CircularProgress';

const CounterOfferScreen = ({ data, handleCloseModal }) => {
    const [accountDetails, setAccountDetails] = useState([])
    const [loading, setLoading] = useState(false);
    const [offerAmt, setOfferAmt] = useState(0);
    const [resText, setResText] = useState("")
    const [error, setError] = useState({
        msg: "",
        state: false
    })
    const { id, bankName, loanType } = data;
    const handleClose = () => {
        handleCloseModal();
    };
    const formatAmount = (amount) => {
        if (amount == null || isNaN(amount)) return "₹-"; // Handle null/undefined values
        return `₹${Number(amount).toLocaleString("en-IN")}`; // Convert to Indian format
    };
    useEffect(() => {
        if (data?.data) {
            const loanDetails = data?.data;
            let detailsArray = [];

            if (loanDetails.loanType === "CREDIT_CARD") {
                const { amountPastDue, daysPastDue, creditLimit, limitUtilized } = loanDetails;
                setOfferAmt(amountPastDue)
                detailsArray = [
                    { key: "amountPastDue", label: "Amount Past Due", value: formatAmount(amountPastDue) },
                    { key: "daysPastDue", label: "Days Past Due", value: daysPastDue ?? "N/A" },
                    { key: "creditLimit", label: "Credit Limit", value: formatAmount(creditLimit) },
                    { key: "limitUtilized", label: "Limit Utilized", value: formatAmount(limitUtilized) },
                ];
            } else {
                const { amountPastDue, outstandingLoanAmount, totalLoanAmount, daysPastDue } = loanDetails;
                setOfferAmt(amountPastDue)
                detailsArray = [
                    { key: "amountPastDue", label: "Amount Past Due", value: formatAmount(amountPastDue) },
                    { key: "outstandingLoanAmount", label: "Outstanding Loan Amount", value: formatAmount(outstandingLoanAmount) },
                    { key: "totalLoanAmount", label: "Total Loan Amount", value: formatAmount(totalLoanAmount) },
                    { key: "daysPastDue", label: "Days Past Due", value: daysPastDue ?? "N/A" },
                ];
            }

            setAccountDetails(detailsArray);
        }
    }, [data]);
    const handleAmt = (e) => {
        const { value } = e.target;
        if (value === "" || value === "0") {
            setError({
                state: true,
                msg: "Please add offer amount"
            })
            setOfferAmt(value);
        } else {
            setError({
                msg: "",
                state: false
            })
            setOfferAmt(value);
        }


    }
    const submitOffer = () => {
        if (!(offerAmt === "" || offerAmt === "0")) {
            let params = {
                accountDetailsId: id,
                bankName: bankName,
                amount: offerAmt,
                userActionType: "COUNTER_OFFER"
            }
            setLoading(true);
            setResText("Loading ...")
            postSaveUserAction(params, (res) => {
                if (res && res.data.success) {
                    setLoading(false);
                    setResText("Concern raised successfully.")
                    setTimeout(() => {
                        setResText("")
                    }, 4000);
                } else if (res && !res.data.success) {
                    setResText("Concern already raised.")
                    setTimeout(() => {
                        setResText("")
                    }, 4000);
                    setLoading(false);
                } else {
                    setResText("Something went wrong , please try after sometime .")
                    setLoading(false);
                }
            })
        } else {
            setError({
                state: true,
                msg: "Please add offer amount"
            })
        }

    }
    useEffect(() => {
        let params = {
            accountDetailsId: id,
            bankName: bankName,
            userActionType: "SETTLE"
        }
        postSaveUserAction(params, (res) => {
            if (res) {
                console.log(res)
            } else {
                console.log("err=" + res)
            }
        })
    }, [])

    return (
        <>
            <div className='counter-offer-screen-container'>
                <div className='counter-offer-screen-body'>
                    <button
                        disabled={loading}
                        className='counter-offer-screen-body-btn'
                        onClick={handleClose}>
                        <IoClose />
                    </button>
                    <span className='counter-offer-screen-bankname'>
                        {bankName}
                    </span>
                    <span className='counter-offer-screen-loantype'>
                        Loan Type : {loanType}
                    </span>
                    <span className='counter-offer-screen-loantype'>
                        Account No : {data?.data?.accountNumber}
                    </span>

                    <div className='counter-offer-screen-body-content'>
                        {
                            accountDetails.map((item, index) => (
                                <div className='counter-offer-screen-body-content-elm'>
                                    <span>{item.label}</span>
                                    <p>{item.value}</p>
                                </div>
                            ))
                        }
                    </div>
                    <div className='counter-offer-screen-input'>
                        <span>Seeking amount offer</span>
                        <input
                            type="number"
                            placeholder='Amount'
                            value={offerAmt}
                            disabled={loading}
                            onChange={(e) => handleAmt(e)}
                        />

                    </div>
                    {error.state && <p className='counter-offer-screen-error'>{error.msg}</p>}
                    {<p className='counter-offer-screen-error'
                        style={{ color: "black", fontWeight: "500", fontSize: 12 }}
                    >{resText}</p>}
                    <div className='counter-offer-screen-submit-btn'>
                        <button
                            disabled={loading}
                            onClick={submitOffer}>
                            {
                                loading ?
                                    <>
                                        Laoding ...
                                        <CircularProgress
                                            style={{
                                                color: "white",
                                                height: 20,
                                                width: 20
                                            }}
                                        />
                                    </>
                                    :
                                    <>
                                        Submit Offer
                                        <GoChevronRight />
                                    </>
                            }
                        </button>
                    </div>

                </div>
                {/* <button
                    className='counter-offer-screen-body-btn'
                    onClick={handleClose}>
                    Close <IoClose />
                </button> */}
            </div>
        </>
    )
}

export default CounterOfferScreen