import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  IconButton,
  Popover,
  Grid,
  CircularProgress,
  Badge,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import logo from "../assets/imgs/DUE-FACTORY.png";
import whiteLogo from "../assets/imgs/DUE-FACTORY-white-logo.webp";
import LogoutIcon from "@mui/icons-material/Logout";
import Face6Icon from "@mui/icons-material/Face6";
import DashboardIcon from "@mui/icons-material/Dashboard";
import InfoIcon from "@mui/icons-material/Info";
import QuizIcon from "@mui/icons-material/Quiz";
import ArticleIcon from "@mui/icons-material/Article";
import { profileApiData } from "../service/ApiList";
import { toast } from "react-toastify";
import NotificationsIcon from "@mui/icons-material/Notifications";
import "./style.css";
import { generalSwitch } from "../utils/generalSwitch";

function HeaderComponent() {
  const [anchorEl, setAnchorEl] = useState(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const token = localStorage.getItem("TDFToken");
  const StatusLS = localStorage.getItem("StatusLS");
  // const StatusLS = decryptValues({key:"StatusLS"})

  // localStorage.setItem("StatusLS","ENHANCED_FETCH_REPORT_OTP_VALIDATED")
  useEffect(() => {
    const TDFToken = localStorage.getItem("TDFToken");
    if(TDFToken){       
    profileApiData({}, (res, err) => {      
      if(res?.data?.success === false && res?.data?.status === 401){
        localStorage.clear()
        window.location.reload();
      }
    });
  }
  }, []);

  const handleLogout = () => {
    if (token) {
      localStorage.clear("experianData");
      localStorage.clear("TDFToken");
      localStorage.clear("loginConfirm");
      localStorage.clear();
      navigate("/");
    } else {
      navigate("/login");
    }
  };
  const location = useLocation();
  const pathname = location.pathname;
  const handlegoContact = () => {
    navigate("/contact-us");
  };
  const handlegoAbout = () => {
    navigate("/about-us");
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const handlegoHome = () => {
    navigate("/");
  };
  const handleGoBlog = () => {
    // navigate("/blog/")
    window.location.href = "/blog/";
  };
  const gotoDashboard = () => {
    setLoading(true);
    const TDFToken = localStorage.getItem("TDFToken");
    if (TDFToken) {
      profileApiData({}, (res, err) => {
        // encryptionValues({ key: "StatusLS", value: res?.data?.status });
        if (res?.status === 200 && res?.data?.status !== 401) {
          localStorage.setItem("StatusLS", res?.data?.status);
          setLoading(false);
          const status = res?.data?.status;
          switch (status) {
            case "BUREAU_FETCH":
              navigate("/home/dashboard");
              break;

            case "ENHANCED_FETCH_REPORT_OTP_VALIDATED":
            case "NORMAL_FETCH_REPORT_OTP_SENT":
            case "NORMAL_FETCH_REPORT_OTP_VALIDATED":
              toast.info("Please complete your profile.");
              navigate("/login/experianfullform", {
                state: { userProfileData: res?.data },
              });
              break;

            case "ENHANCED_FETCH_REPORT_OTP_SENT":
            case "REVOKE_CONSENT":
            case "BUREAU_DELETED":
            case "OTP_VALIDATED":
              toast.info("Please complete your profile.");
              navigate("/login/user", {
                state: { userProfileData: res?.data },
              });
              break;
            case "":
              toast.info("Please complete your profile.");
              navigate("/login/user", {
                state: { userProfileData: res?.data },
              });
              break;
            case null:
              toast.info("Please complete your profile.");
              navigate("/login/user", {
                state: { userProfileData: res?.data },
              });
              break;

            default:
              toast.error("Something went wrong. Please try again.");
              break;
          }
        } else {
          setLoading(false);
          if (res?.data?.status && res?.data?.success === false) {
            const status = res.data.status;
            generalSwitch(status, navigate);
          } else {
            toast.error("Something went wrong. Please try again.");
            if (err.status) {
              localStorage.clear("");
            }
          }
        }
      });
    } else {
      navigate("/home/dashboard");
    }
  };
  const gofaq = () => {
    navigate("/faq");
  };
  const handleNotification = () => {
    toast.info("Please complete your profile.");
  };


  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: { xs: "0 16px", sm: "0 16px", md: "0 35px", lg: "0 35px" },
          marginTop: "1.5rem",
          background:
            pathname.includes("/login/user") ||
            pathname.includes("/login/experianfullform") ||
            pathname.includes("/login/otpverification")
              ? "#044A67"
              : "",
        }}
      >
        <Box tyle={{ padding: "20px" }}>
          {/* {pathname.includes("AboutUs") && ( */}
          <img
            src={
              pathname === "/" ||
              pathname === "/home2" ||
              pathname.includes("/login/user") ||
              pathname.includes("/login/experianfullform") ||
              pathname.includes("/login/otpverification")
                ? whiteLogo
                : logo
            }
            alt="logo"
            style={{
              width: "145px",
              height: "auto",
              cursor: "pointer",
              // marginLeft:"20px",
            }}
            loading="lazy"
            onClick={handlegoHome}
          />
          {/* )} */}
        </Box>
        {pathname === "/login/otpverification" ||
        pathname === "/login/user" ||
        pathname === "/login/experianfullform" ||
        pathname === "/login" ? (
          ""
        ) : (
          <>
            {/* Show buttons on larger screens */}
            <Box
              sx={{ display: { xs: "none", md: "flex" }, alignItems: "center" }}
            >
              <Box className="header-div-class-1">
                {token ? (
                  <>
                    {/* {pathname.includes("/creditdetails") ? (<>
            

                   </>):(<> */}
                    <Button
                      sx={{
                        fontSize: "16px",
                        color:
                          pathname === "/" ||
                          pathname === "/home2" ||
                          pathname.includes("/login/user") ||
                          pathname.includes("/login/experianfullform") ||
                          pathname.includes("/login/otpverification")
                            ? "hsla(0,0%,100%,.5)"
                            : "black",

                        textTransform: "capitalize",
                        "&:hover": {
                          color:
                            pathname === "/" ||
                            pathname.includes("/login/user") ||
                            pathname.includes("/login/experianfullform") ||
                            pathname.includes("/login/otpverification")
                              ? "#fff"
                              : "", // Keeps the background white on hover
                        },
                        mx: 1,
                        fontFamily: "CustomFontMedium",
                      }}
                      onClick={gotoDashboard}
                    >
                      {loading ? (
                        <>
                          <CircularProgress size={"20px"} />
                        </>
                      ) : (
                        <>
                          {" "}
                          {StatusLS !== "BUREAU_FETCH" && StatusLS ? (
                            <>Complete your profile</>
                          ) : (
                            <>Dashboard</>
                          )}
                        </>
                      )}
                    </Button>

                    {/* </>)} */}
                  </>
                ) : (
                  <></>
                )}
                {pathname.includes("/about-us") ||
                pathname.includes("/login/experianfullform") ? (
                  <></>
                ) : (
                  <>
                    <Button
                      sx={{
                        fontSize: "16px",

                        color:
                          pathname === "/" ||
                          pathname === "/home2" ||
                          pathname.includes("/login/user") ||
                          pathname.includes("/login/experianfullform") ||
                          pathname.includes("/login/otpverification")
                            ? "hsla(0,0%,100%,.5)"
                            : "black",
                        textTransform: "capitalize",
                        mx: 1,
                        fontFamily: "CustomFontMedium",
                        "&:hover": {
                          color:
                            pathname === "/" ||
                            pathname === "/home2" ||
                            pathname.includes("/login/user") ||
                            pathname.includes("/login/experianfullform") ||
                            pathname.includes("/login/otpverification")
                              ? "#ffff"
                              : "", // Keeps the background white on hover
                        },
                      }}
                      onClick={handlegoAbout}
                    >
                      About Us
                    </Button>
                  </>
                )}
                {pathname.includes("/contact-us") ||
                pathname.includes("/login/experianfullform") ? (
                  <></>
                ) : (
                  <>
                    <Button
                      sx={{
                        fontSize: "16px",

                        color:
                          pathname === "/" ||
                          pathname === "/home2" ||
                          pathname.includes("/login/user") ||
                          pathname.includes("/login/experianfullform") ||
                          pathname.includes("/login/otpverification")
                            ? "hsla(0,0%,100%,.5)"
                            : "black",
                        "&:hover": {
                          color:
                            pathname === "/" ||
                            pathname === "/home2" ||
                            pathname.includes("/login/user") ||
                            pathname.includes("/login/experianfullform") ||
                            pathname.includes("/login/otpverification")
                              ? "#ffff"
                              : "", // Keeps the background white on hover
                        },

                        textTransform: "capitalize",
                        mx: 1,
                        fontFamily: "CustomFontMedium",
                      }}
                      onClick={handlegoContact}
                    >
                      Contact Us
                    </Button>
                  </>
                )}

                {pathname.includes("/faq") ||
                pathname.includes("/login/experianfullform") ? (
                  <></>
                ) : (
                  <>
                    <Button
                      sx={{
                        fontSize: "16px",

                        color:
                          pathname === "/" ||
                          pathname === "/home2" ||
                          pathname.includes("/login/user") ||
                          pathname.includes("/login/experianfullform") ||
                          pathname.includes("/login/otpverification")
                            ? "hsla(0,0%,100%,.5)"
                            : "black",
                        textTransform: "capitalize",
                        "&:hover": {
                          color:
                            pathname === "/" ||
                            pathname === "/home2" ||
                            pathname.includes("/login/user") ||
                            pathname.includes("/login/experianfullform") ||
                            pathname.includes("/login/otpverification")
                              ? "#ffff"
                              : "", // Keeps the background white on hover
                        },

                        mx: 1,
                        fontFamily: "CustomFontMedium",
                      }}
                      onClick={gofaq}
                    >
                      FAQ's
                    </Button>
                  </>
                )}
                {pathname.includes("/login/experianfullform") ? (
                  <></>
                ) : (
                  <Button
                    sx={{
                      fontSize: "16px",

                      color:
                        pathname === "/" ||
                        pathname === "/home2" ||
                        pathname.includes("/login/user") ||
                        pathname.includes("/login/experianfullform") ||
                        pathname.includes("/login/otpverification")
                          ? "hsla(0,0%,100%,.5)"
                          : "black",

                      textTransform: "capitalize",
                      mx: 1,
                      fontFamily: "CustomFontMedium",
                      "&:hover": {
                        color:
                          pathname === "/" ||
                          pathname === "/home2" ||
                          pathname.includes("/login/user") ||
                          pathname.includes("/login/experianfullform") ||
                          pathname.includes("/login/otpverification")
                            ? "#ffff"
                            : "", // Keeps the background white on hover
                      },
                    }}
                    onClick={handleGoBlog}
                  >
                    Blogs
                  </Button>
                )}
                {StatusLS !== "BUREAU_FETCH" && StatusLS && (
                  <>
                    {pathname === "/" ||
                    pathname === "/faq" ||
                    pathname === "/about-us" ||
                    pathname === "/contact-us" ||
                    pathname === "/terms-&-conditions" ? (
                      <Button onClick={handleNotification}>
                        <Badge
                          badgeContent={1}
                          color="error"
                          overlap="circular"
                        >
                          <NotificationsIcon
                            style={{
                              color: pathname === "/" ? "#FFFF" : "black",
                            }}
                          />
                        </Badge>
                      </Button>
                    ) : (
                      <></>
                    )}
                  </>
                )}
              </Box>
              {pathname.includes("login") ? (
                <></>
              ) : (
                <>
                  <Button
                    sx={{
                      fontSize: "14px",
                      borderRadius: "26px",
                      color: [
                        "/login/user",
                        "/login/experianfullform",
                        "/login/otpverification",
                        "/contact-us",
                        "/about-us",
                        "/grievance-redressal",
                        "/faq",
                        "/terms-&-conditions",
                        "/creditdetails",
                        "/home",
                      ].some((path) => pathname.includes(path))
                        ? "#fff" // Set color to white for specific pages
                        : pathname.includes("/login")
                        ? "hsla(0,0%,100%,.5)"
                        : "black",
                      background: [
                        "/contact-us",
                        "/about-us",
                        "/grievance-redressal",
                        "/faq",
                        "/terms-&-conditions",
                      ].includes(pathname)
                        ? "black" // Black background for specific pages
                        : pathname === "/" || pathname === "/home2"
                        ? "#fff"
                        : "black",
                      height: pathname === "/" ? "28px" : "",
                      "&:hover": {
                        background: [
                          "/contact-us",
                          "/about-us",
                          "/grievance-redressal",
                          "/faq",
                          "/terms-&-conditions",
                          "/creditdetails",
                          "/home/dashboard-2",
                        ].includes(pathname)
                          ? "black" // Keep black background on hover
                          : "#fff",
                        color: [
                          "/contact-us",
                          "/about-us",
                          "/grievance-redressal",
                          "/faq",
                          "/terms-&-conditions",
                          "/creditdetails",
                        ].includes(pathname)
                          ? "#fff" // Ensure color stays white on hover
                          : "",
                      },
                      textTransform: "capitalize",
                      mx: 1,
                      fontFamily: "CustomFontMedium",
                      padding: "0 20px",
                    }}
                    onClick={handleLogout}
                  >
                    {token ? "Log out" : "Login"}
                  </Button>
                </>
              )}
            </Box>

            {/* Show 3-dot icon on mobile screens */}
            {pathname.includes("/login/experianfullform") ? (
              <></>
            ) : (
              <>
                <Box sx={{ display: { xs: "flex", md: "none" } }}>
                  {StatusLS !== "BUREAU_FETCH" && StatusLS && (
                    <>
                      {pathname === "/" ||
                      pathname === "/faq" ||
                      pathname === "/about-us" ||
                      pathname === "/contact-us" ||
                      pathname === "/terms-&-conditions" ? (
                        <Button onClick={handleNotification}>
                          <Badge
                            badgeContent={1}
                            color="error"
                            overlap="circular"
                            sx={{
                              "& .MuiBadge-badge": {
                                fontSize: "10px",
                                minWidth: "16px",
                                height: "16px",
                                padding: "0",
                              },
                            }}
                          >
                            <NotificationsIcon
                              style={{
                                color: pathname === "/" ? "#FFFF" : "black",
                              }}
                              fontSize="small"
                            />
                          </Badge>
                        </Button>
                      ) : (
                        <></>
                      )}
                    </>
                  )}
                  <IconButton
                    onClick={handleClick}
                    style={{
                      color:
                        pathname === "/" ||
                        pathname === "/home2" ||
                        pathname.includes("/login/user") ||
                        pathname.includes("/login/experianfullform") ||
                        pathname.includes("/login/otpverification")
                          ? "#fff"
                          : "",
                    }}
                  >
                    <MoreVertIcon />
                  </IconButton>
                </Box>
              </>
            )}
          </>
        )}
      </Box>

      {/* Popover for mobile view */}
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Box sx={{ p: 2 }}>
          <Grid container direction="column" spacing={1}>
            {token ? (
              <>
                <Grid item>
                  <Button
                    sx={{
                      color: "black",
                      textTransform: "capitalize",
                      fontFamily: "CustomFontMedium",
                      width: "100%",
                    }}
                    onClick={gotoDashboard}
                  >
                    {loading ? (
                      <>
                        <CircularProgress size={"20px"} />
                      </>
                    ) : (
                      <>
                        <DashboardIcon
                          style={{
                            cursor: "pointer",
                            position: "relative",
                            right: "10px",
                            fontSize: "16px",
                          }}
                        />
                        {StatusLS !== "BUREAU_FETCH" && StatusLS ? (
                          <>Complete Profile</>
                        ) : (
                          <>Dashboard</>
                        )}
                      </>
                    )}
                  </Button>
                </Grid>
              </>
            ) : (
              <></>
            )}

            <Grid item>
              <Button
                sx={{
                  color: "black",
                  textTransform: "capitalize",
                  fontFamily: "CustomFontMedium",
                }}
                onClick={handlegoAbout}
              >
                <InfoIcon
                  style={{
                    cursor: "pointer",
                    position: "relative",
                    right: "10px",
                    fontSize: "16px",
                  }}
                />
                About Us
              </Button>
            </Grid>
            <Grid item>
              <Button
                sx={{
                  color: "black",
                  textTransform: "capitalize",
                  fontFamily: "CustomFontMedium",
                }}
                onClick={handlegoContact}
              >
                <Face6Icon
                  style={{
                    cursor: "pointer",
                    position: "relative",
                    right: "10px",
                    fontSize: "16px",
                  }}
                />{" "}
                Contact Us
              </Button>
            </Grid>
            <Grid item>
              <Button
                sx={{
                  color: "black",
                  textTransform: "capitalize",
                  fontFamily: "CustomFontMedium",
                }}
                onClick={gofaq}
              >
                <QuizIcon
                  style={{
                    cursor: "pointer",
                    position: "relative",
                    right: "10px",
                    fontSize: "16px",
                  }}
                />
                FAQ's
              </Button>
            </Grid>
            <Grid item>
              <Button
                sx={{
                  color: "black",
                  textTransform: "capitalize",
                  fontFamily: "CustomFontMedium",
                }}
                onClick={handleGoBlog}
              >
                <ArticleIcon
                  style={{
                    cursor: "pointer",
                    position: "relative",
                    right: "10px",
                    fontSize: "16px",
                  }}
                />
                Blogs
              </Button>
            </Grid>
            {pathname.includes("/login/user") ||
            pathname.includes("/login/experianfullform") ||
            pathname.includes("/login") ||
            pathname.includes("/login/otpverification") ? (
              ""
            ) : (
              <Grid item>
                <Button
                  sx={{
                    color: "black",
                    textTransform: "capitalize",
                    // mx: 1,
                    fontFamily: "CustomFontMedium",
                  }}
                  onClick={handleLogout}
                >
                  <LogoutIcon
                    style={{
                      cursor: "pointer",
                      position: "relative",
                      right: "10px",
                      fontSize: "16px",
                    }}
                  />{" "}
                  {token ? "Log out" : "Login"}
                </Button>
              </Grid>
            )}
          </Grid>
        </Box>
      </Popover>
    </>
  );
}

export default HeaderComponent;
