import React, { useEffect, useRef, useState } from "react";
import "./style.css";
import { Box } from "@mui/material";

function CustomTabs(props) {
  const { children, accountData } = props;
  const [tabsHeaders, setTabsHeaders] = useState([]);
  const [contentMap, setContentMap] = useState({});
  const [activeTab, setActiveTab] = useState("");
  const tabsRefs = useRef({});

  useEffect(() => {
    const headers = [];
    const map = {};

    React.Children.forEach(children, (element) => {
      if (!React.isValidElement(element)) return;
      const { title } = element.props;
      headers.push(title);
      map[title] = element.props.children;
    });

    setTabsHeaders(headers);
    setContentMap(map);
    if (Number(accountData?.creditAccountDefault) >= 1) {
      setActiveTab(headers[0]);
    } else if (Number(accountData?.creditAccountActive) >= 1) {
      setActiveTab(headers[1]);
    } else if (Number(accountData?.creditAccountTotal) >= 1) {
      setActiveTab(headers[2]);
    } else if (Number(accountData?.creditAccountClosed) >= 1) {
      setActiveTab(headers[3]);
    } else {
      setActiveTab(headers[0]);
    }
  }, [children, accountData]);

  useEffect(() => {
    if (tabsRefs.current[activeTab]) {
      tabsRefs.current[activeTab].scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "center",
      });
    }
  }, [activeTab]);
  const changeTab = (header) => {
    setActiveTab(header);
  };

  return (
    <>
      <Box
        sx={{
          display: { xs: "flex", overflow: "auto" },
          background: "#EBEBEB",
          padding: "5px",
          borderRadius: "10px 10px 0 0",
        }}
      >
        {tabsHeaders.map((header) => (
          <button
            className={activeTab === header ? "selected" : "notSelected"}
            ref={(el) => (tabsRefs.current[header] = el)}
            style={{
              margin: "5px",
              padding: "15px 25px",
              border: "none",
              borderRadius: "17px",
              cursor: "pointer",
              whiteSpace: "pre",
            }}
            key={header}
            onClick={() => changeTab(header)}
          >
            {header}
          </button>
        ))}
      </Box>
      <div>
        {Object.keys(contentMap).map((key) => {
          if (key === activeTab) {
            return <div key={key}>{contentMap[key]}</div>;
          }
          return null;
        })}
      </div>
    </>
  );
}

export default CustomTabs;
