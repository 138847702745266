import React, { useEffect, useState } from 'react'
import "./style.css"
import { IoMdTime } from 'react-icons/io';
import { VscGraphLine } from 'react-icons/vsc';
import { LuUsers } from 'react-icons/lu';
import { MdOutlineSwitchAccount } from 'react-icons/md';
import { PiShareFat, PiWarningCircleLight } from 'react-icons/pi';
import ScoreGauge from '../../CentralMenu/ScoreGauge';
import { RxReload } from 'react-icons/rx';
import { AiOutlineCloudDownload } from 'react-icons/ai';
import { FaAngleLeft, FaAngleRight, FaChevronDown, FaChevronLeft } from 'react-icons/fa';
import mark_1 from "../../assets/genInsImgs/mark1.png";
import mark_2 from "../../assets/genInsImgs/mark2.png";
import mark_3 from "../../assets/genInsImgs/mark3.png";
import mark_4 from "../../assets/genInsImgs/mark4.png";
import payment_acct_mark1 from "../../assets/genInsImgs/payment-acct-mark1.png"
import payment_acct_mark2 from "../../assets/genInsImgs/payment-acct-mark2.png"
import payment_acct_mark3 from "../../assets/genInsImgs/payment-acct-mark3.png"
import payment_acct_mark4 from "../../assets/genInsImgs/payment-acct-mark4.png"
import { BANK_IMG_STACK } from '../../DashboardComponents/LoanCard';
import { generic_stacked_record } from '../../utils/GenericInsightStack';
import { useNavigate } from 'react-router-dom';
import { RiFileCopy2Line, RiFolderCloseLine } from 'react-icons/ri';
import { callBackApi, getPaymentlinkdata, postSaveUserAction } from '../../service/ApiList';
import CallMeBackAction from "./Actions/CallMeBackAction";
import DownloadReport from './Actions/DownloadReport';
import PaynowAction from './Actions/PaynowAction';
import CounterOfferScreen from './Actions/Settlement/CounterOfferScreen';
const CommonLandScreen = ({ badLoanCount, isLoading, mainData, mainBlockWidth }) => {
    const navigate = useNavigate();
    const [showModal, setShowModal] = useState(false);
    const [modalChild, setModalChild] = useState(null);

    const handleChild = (type) => {
        switch (type) {
            case 'download_report':
                return <DownloadReport handleCloseModal={handleCloseModal} />;
            default:
                return null;
        }
    }
    const handleAction = (type) => {
        const child = handleChild(type)
        setModalChild(child);
        setShowModal(true);
    };
    const handleCloseModal = () => setShowModal(false);
    const goToBadLoans = () => {
        navigate('/home/badloans')
    }
    const {
        creditRemarks,
        dateOfReport,
        creditScore
    } = mainData || { creditRemarks: "N/A", dateOfReport: "", creditScore: "" }
    if (isLoading) {
        return (<>
            <div className='landing-page-common-main'
                style={{ height: 260 }}
            >
                <div className='landing-page-common'>
                    <div style={{ display: "flex", flexDirection: "column", gap: 10 }}>
                        <div className='skeleton-generic' style={{ height: 160, width: 160, borderRadius: "50%" }}></div>
                        <div className='skeleton-generic' style={{ height: 30, width: 160 }}></div>
                        <div className='skeleton-generic' style={{ height: 12, width: 160 }}></div>
                    </div>
                    <p style={{ height: "auto", width: "100%", display: window.innerWidth <= 650 ? "none" : "flex", flexDirection: "column", gap: 10 }}>
                        <p>
                            <div className='skeleton-generic' style={{ height: 18, width: "100%" }}></div>
                            <div className='skeleton-generic' style={{ height: 18, width: "100%" }}></div>
                            <div className='skeleton-generic' style={{ height: 18, width: "100%" }}></div>
                            <div className='skeleton-generic' style={{ height: 18, width: "50%" }}></div>
                        </p>
                        <div className='landing-page-common-btns' style={{ gap: 10 }}>
                            <div className='skeleton-generic' style={{ height: 35, width: 160, borderRadius: 48 }}></div>
                            <div className='skeleton-generic' style={{ height: 35, width: 160, borderRadius: 48 }}></div>
                        </div>
                    </p>

                </div>

            </div>
        </>)
    } else {
        return (
            <>
                {
                    showModal
                        ?
                        <div className='custom-alert-box-2'>
                            {modalChild}
                        </div>
                        :
                        null
                }
                <div
                    style={{ border: mainBlockWidth >= 900 ? "1px solid #f3f4f4" : "none" }}
                    className='landing-page-common-main'>
                    <div className='landing-page-common'>
                        <ScoreGauge score={creditScore} isLoading={isLoading} lastUpdated={dateOfReport} />
                        <p>
                            {creditRemarks}
                            {/* {
                                window.innerWidth >= 950
                                    ?
                                    <div className='landing-page-common-btns'>
                                        {badLoanCount > 0 ?
                                            <button onClick={goToBadLoans} style={{ border: "none" }}>
                                                <RiFolderCloseLine style={{ fontSize: 16 }} />
                                                Bad Loans
                                            </button> :
                                            null
                                        }
                                        <button onClick={() => handleAction("download_report")} style={{ border: "none" }}>
                                            <RxReload style={{ fontSize: 16 }} />
                                            Refresh
                                        </button>
                                        <button onClick={() => handleAction("download_report")} style={{ border: "none" }}>
                                            <PiShareFat style={{ fontSize: 16 }} />
                                            Share
                                        </button>
                                        <button onClick={() => handleAction("download_report")} style={{ border: "none" }}>
                                            <AiOutlineCloudDownload style={{ fontSize: 16 }} />
                                            Download Report
                                        </button>
                                    </div>
                                    :
                                    <div className='landing-page-common-btns-n'>
                                        <div style={{ justifyContent: "flex-end" }}>
                                            {badLoanCount > 0 ?
                                                <button onClick={goToBadLoans} style={{ border: "none" }}>
                                                    <RiFolderCloseLine style={{ fontSize: 16 }} />
                                                    Bad Loans
                                                </button> :
                                                null
                                            }
                                            <button onClick={() => handleAction("download_report")} style={{ border: "none" }}>
                                                <RxReload style={{ fontSize: 16 }} />
                                                Refresh
                                            </button>
                                        </div>
                                        <div>
                                            <button onClick={() => handleAction("download_report")} style={{ width: "40%" }}>
                                                <PiShareFat style={{ fontSize: 16 }} />
                                                Share
                                            </button>
                                            <button onClick={() => handleAction("download_report")} style={{ width: "60%" }}>
                                                <AiOutlineCloudDownload style={{ fontSize: 16 }} />
                                                Download Report
                                            </button>
                                        </div>
                                    </div>
                            } */}
                        </p>

                    </div>

                </div>
            </>
        )
    }

}
const reorderArray = (arr) => {
    return arr.sort((a, b) => {
        if (a.status === "Active") return -1; // Active should come first
        if (b.status === "Active") return 1;
        if (a.status === "Closed") return 1; // Closed should come last
        if (b.status === "Closed") return -1;
        return 0; // Keep others in between
    });
};


const ActionButtonBlock = ({ data }) => {
    const [showModal, setShowModal] = useState(false);
    const [modalChild, setModalChild] = useState(null);
    const currentId = data?.id || "0";
    const status = data?.status || "";
    const handleReportAnError = () => {
        const { id, bankName, loanType } = data;
        let params = {
            accountDetailsId: id,
            bankName: bankName,
            userActionType: "REPORT_ERROR"
        }
        postSaveUserAction(params, (res) => {
            if (res) {
                console.log(res)
            } else {
                console.log("err=" + res)
            }
        })
        window.open(
            "https://consumer.experian.in/ECSINDIA-DCE/view/angular/index.html#!/utiERNPage",
            "_blank"
        );
    };
    const handleChild = (type) => {
        switch (type) {
            case 'call_me_back':
                return <CallMeBackAction data={data} handleCloseModal={handleCloseModal} id={currentId} />;
            case 'pay_now':
                return <PaynowAction data={data} handleCloseModal={handleCloseModal} />;
            case 'want_to_settle':
                return <CounterOfferScreen data={data} handleCloseModal={handleCloseModal} />;
            default:
                return null;
        }
    }
    const handleAction = (type) => {
        const child = handleChild(type)
        setModalChild(child);
        setShowModal(true);
    };
    const handleCloseModal = () => setShowModal(false);
    const handlePayNow = () => {
        const { id, bankName, loanType } = data || {}
        const params = {
            productType: loanType.toUpperCase().replace(/\s+/g, "_"),
            accountDetailsId: id,
            bankName: bankName,
        }
        getPaymentlinkdata(params, (res, err) => {
            if (res && res?.data?.link) {
                window.open(res?.data?.link);
                handleAction("pay_now");
            } else {
                handleAction("pay_now");
            }
        });
    };
    return (
        <>
            {
                showModal
                    ?
                    <div className='custom-alert-box-2'>
                        {modalChild}
                    </div>
                    :
                    null
            }
            <div className="mobile-action-btn-main">
                <div
                    style={{ display: status === "Closed" ? "none" : "" }}
                    className="mobile-action-btn-para">
                    Act promptly and follow your plan to achieve desired results.
                </div>
                <div
                    style={{ display: status === "Closed" ? "none" : "" }}
                    className="mobile-action-btn-block">
                    {data?.isAccountBad && <button onClick={() => handleAction("want_to_settle")}>Want to Settle?</button>}
                    <button onClick={handlePayNow}>Pay now</button>
                    <button onClick={() => handleAction("call_me_back")}>
                        Call back
                    </button>
                </div>

                <div className="mobile-action-btn-para">
                    Inconsistent information? You can rectify it by reporting the discrepancies to the relevant credit bureau.
                </div>
                <button
                    onClick={handleReportAnError}
                    className="mobile-action-btn-report-an-error"
                >
                    Report an error
                </button>
            </div>
        </>
    );
};
const GenericTopSection = ({ insightType, sectionType, data, mainBlockWidth, isLoading }) => {
    const [blockWidth, setBlockWidth] = useState(300);
    const {
        main_color,
        first_line,
        first_img,
        head_main,
        head_para,
        indicator_block,
        two_stats,
        mobile_design
    } = generic_stacked_record(insightType, sectionType, data)
    useEffect(() => {
        setBlockWidth(mainBlockWidth)
    }, [mainBlockWidth])
    const convertDateToFormat = (timestamp) => {
        const date = new Date(timestamp);
        const options = { day: '2-digit', month: 'short', year: 'numeric' };
        return date.toLocaleDateString('en-GB', options);
    }
    if (isLoading) {
        return (<>
            <div
                className='mobile-generic-impact-box-top-text skeleton-generic'
                style={{ width: 300, height: 0, margin: "10px 0px" }}
            ></div>
            <div className='mobile-generic-impact-box'
                style={{
                    backgroundColor: main_color.background,
                    border: `.5px solid ${main_color.border_color}`,
                    flexDirection: "column",
                    alignItems: "center"
                }}
            >
                <div className='mobile-generic-impact-box-left-sts'>
                    <div style={{ color: `${head_main.text_color}` }}>
                        <p style={{ height: 16, width: 120, marginBottom: 5 }} className='skeleton-generic'></p>
                        <p style={{ height: 14, width: 150 }} className='skeleton-generic'></p>
                    </div>
                    <div className='mobile-generic-impact-box-highlighter skeleton-generic'
                        style={{
                            height: 24, width: 120,
                            border: "1px solid #c4c4c46e",
                            background: "#eeeeee"
                        }}
                    >

                    </div>
                </div>
                <div className='mobile-generic-impact-box-mid'>
                    {
                        two_stats.map((stat, index) =>
                            <><div key={index} >
                                <span style={{ height: 22, width: 60, marginBottom: 5 }} className='skeleton-generic'></span>
                                <p style={{ height: 16, width: 150 }} className='skeleton-generic'></p>
                            </div>
                                {index === 0 ? <span className='mobile-generic-impact-box-mid-line'></span> : <></>}
                            </>
                        )

                    }
                </div>

            </div>
            <div className='mobile-generic-impact-box-last' style={{ display: "flex", flexDirection: "column", gap: 10, marginTop: 20 }} >
                <p
                    style={{ height: 0, width: "88%", margin: 0 }} className='skeleton-generic'
                >
                </p>
                <p
                    style={{ height: 0, width: "81%", margin: 0 }} className='skeleton-generic'
                >
                </p>
                <p
                    style={{ height: 0, width: "78%", margin: 0 }} className='skeleton-generic'
                >
                </p>
                <p
                    style={{ height: 0, width: "59%", margin: 0 }} className='skeleton-generic'
                >
                </p>

            </div>
        </>)
    } else {
        return (<>
            <div className='mobile-generic-impact-box-top-text'>{first_line.text}</div>
            <div className='mobile-generic-impact-box'
                style={{
                    backgroundColor: main_color.background,
                    border: `.5px solid ${main_color.border_color}`,
                    flexDirection: "column",
                    alignItems: "center"
                }}
            >
                <div
                    style={{ background: mobile_design?.background_color_1 }}
                    className='mobile-generic-impact-box-left-sts'>
                    <p style={{ color: `${head_main.text_color}` }}>
                        {head_main.text}
                        <p>Last updated on {convertDateToFormat(data?.lastUpdated)}</p>
                    </p>
                    <div className='mobile-generic-impact-box-highlighter'
                        style={{
                            background: indicator_block.background,
                            border: `1px solid ${indicator_block.border_color}`,
                        }}
                    >
                        <img src={indicator_block.second_img.src} alt={indicator_block.second_img.alt} />
                        <span style={{ color: `${indicator_block.impact_text.text_color}` }}>{indicator_block.impact_text.text}</span>
                    </div>
                </div>
                <div
                    style={{ background: mobile_design?.background_color_2 }}
                    className='mobile-generic-impact-box-mid'>
                    {
                        two_stats.map((stat, index) =>
                            <><div key={index} >
                                <span style={{ color: `${stat.impact_value.text_color}` }}>{stat.impact_value.text} </span>
                                <p style={{ color: `${stat.impact_text.text_color}` }}>{stat.impact_text.text}</p>
                            </div>
                                {index === 0 ? <span className='mobile-generic-impact-box-mid-line'></span> : <></>}
                            </>
                        )

                    }
                </div>

            </div>
            <div className='mobile-generic-impact-box-last' >
                <p
                    style={{
                        color: `${head_para.text_color}`,
                        // background: indicator_block.background
                    }}
                >
                    {head_para.text}
                </p>
            </div>
        </>)
    }
}

const PaymentBodyPart = ({ propData }) => {
    const [mainReport, setMainReport] = useState({})
    useEffect(() => {
        setMainReport(propData)
    }, [propData])
    const Delinquency = () => {
        const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

        // Reverse years to show the latest year first
        const years = Object.keys(mainReport?.details?.delinquencyData ?? [])
            .map(Number) // Convert string keys to numbers
            .sort((a, b) => b - a); // Sort in descending order (latest year first)

        const [currentYearIndex, setCurrentYearIndex] = useState(0);

        const elem = [
            { key: "elem-1", label: "Paid On Time", src: mark_1, alt: "mark_1" },
            { key: "elem-2", label: "1-89 Days Late", src: mark_2, alt: "mark_2" },
            { key: "elem-3", label: "90+ Days Late", src: mark_3, alt: "mark_3" },
            { key: "elem-4", label: "Not Available", src: mark_4, alt: "mark_4" }
        ];

        // Adjusted button logic
        const handleNextYear = () => {
            if (currentYearIndex > 0) { // Moving to older years
                setCurrentYearIndex(currentYearIndex - 1);
            }
        };

        const handlePreviousYear = () => {
            if (currentYearIndex < years.length - 1) { // Moving to newer years
                setCurrentYearIndex(currentYearIndex + 1);
            }
        };

        const convertDelinquencyData = (data) => {
            const delinquencyData = {};
            const threshold = 3; // Values 3 and above are marked as "late"
            const currentYear = new Date().getFullYear();
            const currentMonth = new Date().getMonth();

            Object.keys(data).forEach((year) => {
                const yearData = data[year];

                delinquencyData[year] = months.map((month, index) => {
                    let value = 'blank';
                    if (yearData && index < yearData.length) {
                        const status = yearData[index];
                        if (status === 0) {
                            value = 'on-time';
                        } else if (status === -1) {
                            value = 'blank';
                        } else if (status >= 1 && status <= 2) {
                            value = 'amber';
                        } else if (status >= threshold) {
                            value = 'late';
                        }
                    }

                    // Limit current year data to months up to the current month
                    if (parseInt(year) === currentYear && index > currentMonth) {
                        value = 'blank';
                    }

                    return { month, value };
                });
            });

            return delinquencyData;
        };

        const delinquencyDataMobile = convertDelinquencyData(mainReport?.details?.delinquencyData ?? {});

        const check_Type = (type) => {
            switch (type) {
                case 'on-time':
                    return mark_1;
                case 'amber':
                    return mark_2;
                case 'late':
                    return mark_3;
                case 'blank':
                    return mark_4;
                default:
                    return;
            }
        };

        return (
            <>
                <div className='mobile-payment-del-sub-main'>
                    <div className='mobile-payment-del-sub-head'>
                        <div className='mobile-payment-del-sub-btn'>
                            <button
                                onClick={handlePreviousYear}
                                disabled={currentYearIndex === years.length - 1}
                            >
                                <FaAngleLeft className={currentYearIndex === years.length - 1 ? "class-btn-inactive" : "class-btn-active"} />
                            </button>
                            <span>{years[currentYearIndex]}</span>
                            <button
                                onClick={handleNextYear}
                                disabled={currentYearIndex === 0}
                            >
                                <FaAngleRight className={currentYearIndex === 0 ? "class-btn-inactive" : "class-btn-active"} />
                            </button>
                        </div>
                    </div>
                    <hr className='line-break-hr' style={{ marginTop: 10 }} />
                    <div className='mobile-payment-del-sub-content-blocks'>
                        {delinquencyDataMobile[years[currentYearIndex]]?.map((item, index) => (
                            <div key={index + "payment-del-content"} className='mobile-payment-del-sub-content-block'>
                                <p>{item?.month}</p>
                                <img src={check_Type(item?.value)} alt={item?.value} />
                            </div>
                        ))}
                    </div>
                    <div className='mobile-payment-del-sub-marks'>
                        {elem.map((item) =>
                            <div key={item.key}>
                                <img src={item.src} alt={item.alt} />
                                <p>{item.label}</p>
                            </div>
                        )}
                    </div>
                </div>
            </>
        );
    };


    const check_Verbiage = (acct_type = "type_fair") => {

        const checkTypeForThis = (typ) => {
            switch (typ) {
                case "type_perfect":
                    return {
                        background: "#ebf9f3",
                        src: payment_acct_mark1,
                        alt: "Perfect",
                        label: "Perfect !",
                        para: "You’ve successfully maintained on-time payments for the past three months. Keep it up!"
                    }
                case "type_good":
                    return {
                        background: "#ebf9f3",
                        src: payment_acct_mark2,
                        alt: "Good",
                        label: "Good !",
                        para: "For this account, you’ve been timely with most payments. To enhance your credit standing, it’s important to make sure that you do not miss any future payments and maintain a perfect payment history. This account is impacting your credit score negatively. "
                    }
                case "type_fair":
                    return {
                        background: "#FEF0C7",
                        src: payment_acct_mark3,
                        alt: "Fair",
                        label: "Fair !",
                        para: "For this account, there have been occasional missed payments. Fund your account much ahead of due date, set reminders or automate payments to improve on time payment record. This account is impacting your credit score negatively. "
                    }
                case "type_poor":
                    return {
                        background: "#FFE9E7",
                        src: payment_acct_mark4,
                        alt: "Poor",
                        label: "Poor !",
                        para: "For this account, several payments have been missed, placing the account in a high-risk category and worsening your overall credit standing."
                    }
                default:
                    return {}
            }
        }
        const {
            background,
            src,
            alt,
            label,
            para
        } = checkTypeForThis(acct_type);
        return (<>
            <div className='mobile-payment-acct-verbiage'
                style={{ background: background }}
            >
                {/* <img src={src} alt={alt} /> */}
                {/* {acct_type === "type_perfect" ? <b>{label}</b> : null} */}
                <p>{para}</p>
            </div>
            <hr style={{ marginTop: 10 }} className='hr-line-break' />
        </>)
    }

    const convertDateToFormat = (timestamp) => {
        const date = new Date(timestamp);
        const options = { day: '2-digit', month: 'short', year: 'numeric' };
        return date.toLocaleDateString('en-GB', options);
    }

    return (<>
        <div className='mobile-gyaab-payments'>
            <div className='mobile-gyaab-payments-head'>Payment History for this account</div>
            <span className='mobile-gyaab-payments-sub-head'>Last Update on {convertDateToFormat(mainReport?.dateReported)} as reported by Experian Bureau</span>
            {Delinquency()}
            {mainReport?.status === "Closed" ? null : check_Verbiage(mainReport?.accountType)}
            <ActionButtonBlock data={mainReport} />
        </div>
    </>)
}
const CheckLimitBar = ({ value }) => {
    const [animatedValue, setAnimatedValue] = useState(0);

    useEffect(() => {
        setTimeout(() => {
            if (value > 100) {
                setAnimatedValue(100);
            } else {
                setAnimatedValue(value);
            }

        }, 300);
    }, [value]);

    return (
        <div className="mobile-check-limit-block-bar">
            <div className="mobile-check-limit-block-bar-elm"

            >
                {/* Static mark at 30% */}
                <div className="static-marker" style={{ left: "30%" }}>
                    <span className="marker-text">30%</span>
                </div>

                {/* Dynamic pointer */}
                <div
                    className="mobile-check-limit-block-bar-elm-pointer"
                    style={{
                        left: `${animatedValue}%`,
                        border: `4px solid ${value < 30 ? "#53ba6e" : "red"}`
                    }}
                >
                    <div className="mobile-tooltip"
                        style={{
                            transform: `translateX(${animatedValue < 30 ? 0 : (-100)}%)`
                        }}

                    >{value > 100 ? ">100" : (typeof animatedValue === "number" && !isNaN(animatedValue) ? animatedValue.toFixed(1) : "0")}% Utilised</div>
                </div>
            </div>
        </div>
    );
};
const CreditUtilisationBodyPart = ({ propData }) => {
    const [mainReport, setMainReport] = useState({})
    useEffect(() => {
        setMainReport(propData)
    }, [propData])

    const check_Verbiage = (acct_type, limit) => {

        const checkTypeForThis = (typ) => {
            switch (typ) {
                case "type_perfect":
                    return {
                        background: "#ebf9f3",
                        src: payment_acct_mark1,
                        alt: "Perfect",
                        label: "Perfect !",
                        para: "For this credit card, your utilisation has remained well below the ideal threshold, reflecting disciplined usage and contributing to a strong credit profile."
                    }
                case "type_high":
                    return {
                        background: "#ebf9f3",
                        src: payment_acct_mark2,
                        alt: "high",
                        label: "High !",
                        para: `For this credit card, your credit utilization ratio is at ${Number(limit) ? Number(limit).toFixed(1) : "-"}%, which may impact your overall creditworthiness. Managing your spending, increasing your credit limit or making part payments during the month can help improve your credit profile on this parameter. However, please ignore this input if your overall credit utlisation ratio is well within 30%. `
                    }
                case "type_extreme_high":
                    return {
                        background: "#FFE9E7",
                        src: payment_acct_mark4,
                        alt: "Perfect",
                        label: "Extreme High !",
                        para: `For this credit card, your utilization is above ${Number(limit) ? Number(limit).toFixed(1) : "-"}%, placing considerable strain on your credit profile. Reducing this balance will help mitigate further negative impact on your score. Howver, please ignore this input if your overall credit utlisation ratio is well within 30%`
                    }
                default:
                    return {}
            }
        }
        const {
            background,
            src,
            alt,
            label,
            para
        } = checkTypeForThis(acct_type);
        return (<>
            <hr style={{ marginTop: 10 }} className='hr-line-break' />
            <div className='mobile-payment-acct-verbiage'
                style={{ background: background }}
            >
                <img src={src} alt={alt} />
                <p>{para}</p>
            </div>
            <hr style={{ marginTop: 10 }} className='hr-line-break' />
        </>)
    }

    const convertDateToFormat = (timestamp) => {
        const date = new Date(timestamp);
        const options = { day: '2-digit', month: 'short', year: 'numeric' };
        return date.toLocaleDateString('en-GB', options);
    }

    const checkLimitBlock = (data) => {
        if (!data) return null;
        const { limitUtilized = 0, creditLimit = 0 } = data.details || {};
        return (<>
            <hr style={{ marginTop: 10 }} className='hr-line-break' />
            <div className="mobile-check-limit-block" >
                <CheckLimitBar value={data?.limitUtilized || 0} />
                <div className='mobile-check-limit-block-stats'>
                    <div>
                        <span>₹ {limitUtilized}</span>
                        <p>Limit Used</p>
                    </div>
                    <hr className='line-break-hr-2' />
                    <div>
                        <span>₹ {creditLimit}</span>
                        <p>Credit Limit</p>
                    </div>
                </div>
            </div>
        </>
        );
    };

    return (<>
        <div className='mobile-gyaab-payments'>
            <div className='mobile-gyaab-payments-head'>Credit Utilization</div>
            <span className='mobile-gyaab-payments-sub-head' >
                Last Update on&nbsp;
                {convertDateToFormat(mainReport?.dateReported)}
                &nbsp;
                as reported by Experian Bureau . &nbsp;
                {/* <span>
                    Stay under 30% credit use for good credit score
                </span> */}
            </span>
            {
                mainReport?.status === "Closed"
                    ? null
                    :
                    <>
                        {checkLimitBlock(mainReport)}
                        {check_Verbiage(mainReport?.accountType, mainReport?.limitUtilized)}
                    </>
            }
            <div style={{ marginTop: mainReport?.status === "Closed" ? 20 : "" }}></div>
            <ActionButtonBlock data={mainReport} />
        </div>
    </>)
}
const AccountAgeBodyPart = ({ propData }) => {
    const [mainReport, setMainReport] = useState({})
    useEffect(() => {
        setMainReport(propData)
    }, [propData])

    const check_Verbiage = (acct_type = "type_fair") => {
        const checkTypeForThis = (typ) => {
            switch (typ) {
                case "type_perfect":
                    return {
                        background: "#ebf9f3",
                        src: payment_acct_mark1,
                        alt: "Perfect",
                        label: "Perfect !",
                        para: "You’ve successfully maintained on-time payments for the past three months. Keep it up!"
                    }
                case "type_good":
                    return {
                        background: "#ebf9f3",
                        src: payment_acct_mark2,
                        alt: "Good",
                        label: "Good !",
                        para: "For this account, you’ve been timely with most payments. To enhance your credit standing, it’s important to make sure that you do not miss any future payments and maintain a perfect payment history. This account is impacting your credit score negatively. "
                    }
                case "type_fair":
                    return {
                        background: "#FEF0C7",
                        src: payment_acct_mark3,
                        alt: "Fair",
                        label: "Fair !",
                        para: "For this account, there have been occasional missed payments. Fund your account much ahead of due date, set reminders or automate payments to improve on time payment record. This account is impacting your credit score negatively. "
                    }
                case "type_poor":
                    return {
                        background: "#FFE9E7",
                        src: payment_acct_mark4,
                        alt: "Poor",
                        label: "Poor !",
                        para: "For this account, several payments have been missed, placing the account in a high-risk category and worsening your overall credit standing."
                    }
                default:
                    return {}
            }
        }
        const {
            background,
            src,
            alt,
            label,
            para
        } = checkTypeForThis(acct_type);
        return (<>
            <div className='payment-acct-verbiage'
                style={{ background: background, marginTop: 20 }}
            >
                <img src={src} alt={alt} />
                <p>{para}</p>
            </div>
        </>)
    }

    const convertDateToFormat = (timestamp) => {
        const date = new Date(timestamp);
        const options = { day: '2-digit', month: 'short', year: 'numeric' };
        return date.toLocaleDateString('en-GB', options);
    }

    return (<>
        <div className='mobile-gyaab-payments'>
            <div className='mobile-gyaab-payments-head'>Account Details</div>
            <span className='mobile-gyaab-payments-sub-head'>
                Last Update on&nbsp;
                {convertDateToFormat(mainReport?.dateReported)}
                &nbsp;
                as reported by Experian Bureau . &nbsp;
            </span>
            <hr style={{ marginTop: 10 }} className='hr-line-break' />
            {/* {check_Verbiage(mainReport?.accountType, mainReport?.limitUtilized)} */}
            <div style={{ margin: 10 }}></div>
            <ActionButtonBlock data={mainReport} />
        </div>
    </>)
}
const AccountsBodyPart = ({ propData }) => {
    const [mainReport, setMainReport] = useState({})
    useEffect(() => {
        setMainReport(propData)
    }, [propData])

    const check_Verbiage = (acct_type = "type_fair") => {
        const checkTypeForThis = (typ) => {
            switch (typ) {
                case "type_perfect":
                    return {
                        background: "#ebf9f3",
                        src: payment_acct_mark1,
                        alt: "Perfect",
                        label: "Perfect !",
                        para: "You’ve successfully maintained on-time payments for the past three months. Keep it up!"
                    }
                case "type_good":
                    return {
                        background: "#ebf9f3",
                        src: payment_acct_mark2,
                        alt: "Good",
                        label: "Good !",
                        para: "For this account, you’ve been timely with most payments. To enhance your credit standing, it’s important to make sure that you do not miss any future payments and maintain a perfect payment history. This account is impacting your credit score negatively. "
                    }
                case "type_fair":
                    return {
                        background: "#FEF0C7",
                        src: payment_acct_mark3,
                        alt: "Fair",
                        label: "Fair !",
                        para: "For this account, there have been occasional missed payments. Fund your account much ahead of due date, set reminders or automate payments to improve on time payment record. This account is impacting your credit score negatively. "
                    }
                case "type_poor":
                    return {
                        background: "#FFE9E7",
                        src: payment_acct_mark4,
                        alt: "Poor",
                        label: "Poor !",
                        para: "For this account, several payments have been missed, placing the account in a high-risk category and worsening your overall credit standing."
                    }
                default:
                    return {}
            }
        }
        const {
            background,
            src,
            alt,
            label,
            para
        } = checkTypeForThis(acct_type);
        return (<>
            <div className='payment-acct-verbiage'
                style={{ background: background, marginTop: 20 }}
            >
                <img src={src} alt={alt} />
                <p>{para}</p>
            </div>
        </>)
    }

    const convertDateToFormat = (timestamp) => {
        const date = new Date(timestamp);
        const options = { day: '2-digit', month: 'short', year: 'numeric' };
        return date.toLocaleDateString('en-GB', options);
    }

    const details_block = (items = []) => {
        return (<>
            <hr className='hr-line-break' />
            <div className="mobile-gyaab-enquiry-elemets">
                {items.map((it, i) =>
                    <div key={i + it}>
                        <span>{it.label}</span>
                        <p>{it.value || "-"}</p>
                    </div>
                )}
            </div>
            <hr className='hr-line-break' />
        </>)
    }

    return (<>
        <div className='mobile-gyaab-payments'>
            <div className='mobile-gyaab-payments-head'>Account Details</div>
            <span className='mobile-gyaab-payments-sub-head'>
                Last Update on&nbsp;
                {convertDateToFormat(mainReport?.dateReported)}
                &nbsp;
                as reported by Experian Bureau . &nbsp;
            </span>
            {details_block(mainReport?.details?.elements)}
            <div style={{ margin: 10 }}></div>
            {/* {check_Verbiage(mainReport?.accountType, mainReport?.limitUtilized)} */}
            <ActionButtonBlock data={mainReport} />
        </div>
    </>)
}
const EnquiriesBodyPart = ({ }) => {
    return (<>
        <h1>5</h1>
    </>)
}

const GeneralElementHandler = ({ type, data }) => {
    const getDetails = (val) => {
        switch (val) {
            case "type_payment":
                return <PaymentBodyPart propData={data} />
            case "type_credit_utilisation":
                return <CreditUtilisationBodyPart propData={data} />
            case "type_account_age":
                return <AccountAgeBodyPart propData={data} />
            case "type_accounts":
                return <AccountsBodyPart propData={data} />
            case "type_enquiries":
                return <EnquiriesBodyPart propData={data} />
            default:
                return <></>;
        }
    }

    return (<>
        <div className="gnrl-your-account-accordion-body">
            <hr className='line-break-hr' />
            {getDetails(type)}
        </div>
    </>)
}

const HandleGeneralAccounts = ({ accounts, type, mainLoading }) => {
    const [searchQuery, setSearchQuery] = useState("");
    const [openAccordionIndex, setOpenAccordionIndex] = useState(null);
    const [filteredAccounts, setFilteredAccounts] = useState(accounts);
    const trim_text = (text) => {
        if (!text) return "";
        const baseLimit = 22; // Default character limit
        const extraChars = window.innerWidth > 400 ? Math.min(10, (window.innerWidth - 400) / 50) : 0;
        const charLimit = baseLimit + Math.floor(extraChars); // Adding a small buffer for wider screens
        return text.length > charLimit ? text.substring(0, charLimit) + "..." : text;
    };

    useEffect(() => {
        const timeout = setTimeout(() => {
            const lowerCaseQuery = searchQuery.toLowerCase();
            const filtered = accounts.filter(account =>
                account.bankName.toLowerCase().includes(lowerCaseQuery) ||
                account.loanType.toLowerCase().includes(lowerCaseQuery)
            );
            setFilteredAccounts(filtered);
        }, 300); // Debounce delay: 300ms

        return () => clearTimeout(timeout); // Cleanup the timeout on query change
    }, [searchQuery, accounts]);

    const toggleAccordion = (index) => {
        setOpenAccordionIndex(openAccordionIndex === index ? null : index);
    };

    const getColor = (it) => {
        switch (it) {
            case "Active":
                return "green";
            case "Closed":
                return "red";
            default:
                return "";
        }
    }

    const getValue = (it) => {
        if (it.length > 6) {
            return it.slice(0, 6);
        }
        switch (it) {
            case "Active":
                return "Active";
            case "Closed":
                return "Closed";
            default:
                return it;
        }
    }
    const shortenText = (text) => {
        if (text.length <= 10) return text; // If length is 10 or less, return as is
        const lastSix = text.slice(-4); // Get last 6 characters
        const hiddenPart = "X".repeat(6); // Replace the first part with 'X'
        return hiddenPart + lastSix;
    };

    const shortenText2 = (text) => {
        if (text.length <= 7) return text;
        const lastSix = text.slice(0, 7);
        return lastSix;
    };

    const getColorFontPercentage = (account) => {
        const per = account.paymentPercentage * 100;
        const status = account.status;
        if (status === "Closed") {
            return { color: "#918b8b", fontWeight: "700" }
        } else {
            if (per >= 100) {
                return { color: "#75c258", fontWeight: "700" }
            } else if (per < 100 && per > 90) {
                return { color: "rgb(240, 194, 75)", fontWeight: "700" }
            } else if (per <= 90 && per > 70) {
                return { color: "#f9955b", fontWeight: "700" }
            } else if (per <= 70) {
                return { color: "#ff0000", fontWeight: "700" }
            }
        }
    }

    const getCreditPercentageColor = (account) => {
        const per = account.limitUtilized;
        if (per <= 30) {
            return "green"
        } else if (per > 30 && per < 70) {
            return "rgb(240, 194, 75)"
        } else if (per >= 70) {
            return "red"
        }
    }
    const getLabelColor = (account, status) => {
        if (account.label === "Timely payments") {
            return getColorFontPercentage(account).color;
        } else if (account.label === "Limit Used") {
            return getCreditPercentageColor(account);
        }
    };




    return (<>
        <div className='landing-page-handle-general-accounts'>
            <div className='landing-page-handle-general-accounts-top'>
                <span> <b>Your Accounts</b> {`(${filteredAccounts.length})`}</span>
                <input
                    type='text'
                    placeholder='🔍 Search bank'
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                />
            </div>
            <div className="landing-page-handle-general-accounts-body">
                {
                    filteredAccounts.map((account, index) =>
                        <div key={index} className='lphga-element' >
                            <div className='lphga-element-head'>
                                <span>{account.loanType}</span>
                                <button style={{}} onClick={() => toggleAccordion(index)}>
                                    <FaChevronDown
                                        style={{
                                            transform: openAccordionIndex === index ? 'rotate(180deg)' : 'rotate(0deg)',
                                            transition: 'transform 0.3s ease',
                                            color: "#757B78"
                                        }}
                                    />
                                </button>
                            </div>
                            <div className='lphga-element-visible-part'>
                                <div className='lphga-element-visible-part-left'>
                                    <img src={BANK_IMG_STACK(account.bankName)} alt={account.bankName + " icons"} />
                                    <div className='lphga-element-visible-part-left-info'>
                                        <span>{trim_text(account.bankName)}</span>
                                        <div className='lphga-element-visible-part-left-info-elm'>
                                            <div className='lphga-element-visible-part-left-info-elm-1'>{shortenText(account.accountNumber)}</div>
                                            <span style={{ fontSize: 12 }}> | </span>
                                            <div
                                                title={account.label || "-"}
                                                style={{ color: getColor(account.status) }}
                                                className='lphga-element-visible-part-left-info-elm-2'
                                            >{shortenText2(account.status)}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='lphga-element-visible-part-right'>
                                    <div
                                        title={account.label || "-"}
                                        style={{ color: getLabelColor(account, account.status) }}
                                        className='lphga-element-visible-part-right-1'
                                    >{account.value}</div>
                                    <div className='lphga-element-visible-part-right-2'>{account.label}</div>
                                </div>
                            </div>
                            {openAccordionIndex === index && (
                                <GeneralElementHandler key={index} type={type} data={account} />
                            )}
                        </div>
                    )
                }

            </div>
        </div>
    </>)
}

const PaymentBody = ({ isLoading, mainData, mainBlockWidth, callBackBtn }) => {
    const [insightType, setInsightType] = useState("type_perfect");
    const [loading, setLoading] = useState(true);
    const [details, setDetails] = useState([]);
    const handleBack = () => {
        callBackBtn()
    }
    const checkType = () => {
        const { totalPayments = 0, onTimePayments = 0 } = mainData || {};
        // Handle cases where mainData is empty or missing required fields
        if (!totalPayments || !onTimePayments) {
            setInsightType("type_poor"); // Default or fallback value
            return;
        }

        const res = onTimePayments / totalPayments;

        if (res === 1) {
            setInsightType("type_perfect");
        } else if (res < 1 && res >= 0.9) {
            setInsightType("type_good");
        } else if (res < 0.9 && res >= 0.7) {
            setInsightType("type_fair");
        } else if (res < 0.7) {
            console.log(totalPayments, onTimePayments)
            setInsightType("type_poor");
        }
    };
    const checkSubType = (data) => {
        const { totalPayments, onTimePayments } = data || {};

        // Handle cases where mainData is empty or missing required fields
        if (!totalPayments || !onTimePayments) {
            return "type_poor";
        }
        const res = onTimePayments / totalPayments;

        if (res === 1) {
            return "type_perfect"
        } else if (res < 1 && res >= 0.9) {
            return "type_good"
        } else if (res < 0.9 && res >= 0.7) {
            return "type_fair"
        } else if (res < 0.7) {
            return "type_poor"
        }
    };
    const calculatePercentage = (a, b) => {
        if (b === 0) {
            return 'Error: Division by zero';
        }
        const percentage = (a / b) * 100;
        return percentage.toFixed(0);
    }

    useEffect(() => {
        checkType();
        const { insightAccountDetails = [] } = mainData;
        createDetails(insightAccountDetails)
    }, [mainData]); // Re-run when mainData changes


    const createDetails = (data) => {
        const { mobile } = mainData || {};
        setLoading(true);
        const formatText = (text) => {
            return text
                .toLowerCase() // Convert to lowercase
                .replace(/[_\.]+/g, " ") // Replace underscores and dots with spaces
                .split(" ") // Split into words
                .map(word => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize first letter
                .join(" "); // Join words back into a sentence
        };
        const shortenText = (text) => {
            if (text.length <= 10) return text; // If length is 10 or less, return as is
            const lastSix = text.slice(-4); // Get last 6 characters
            const hiddenPart = "X".repeat(6); // Replace the first part with 'X'
            return hiddenPart + lastSix;
        };
        let res = data.map((item) => ({
            data: item,
            id: item.id,
            mobileNumber: mobile,
            bankName: item?.subscriberName || "-",
            loanType: formatText(item?.loanCategory) || "-",
            fields: [
                { label: "Account No", value: item?.accountNumber || "-" },
                { label: "On-Time Payments", value: `${item?.onTimePayments}/${item?.totalPayments} ` || "0/0" },
                { label: "Status", value: formatText(item?.status) || "-" }
            ],
            dateReported: item?.dateReported || "0",
            paymentPercentage: `${item?.onTimePayments / (item?.totalPayments)}`,
            accountType: checkSubType(item),
            status: formatText(item?.status),
            accountNumber: item?.accountNumber || "-",
            label: "Timely payments",
            value: `${item?.onTimePayments}/${item?.totalPayments} ` || "0/0",
            isAccountBad: item?.isAccountBad || false,
            details: {
                delinquencyData: item?.delinquencyData || {}
            }
        }))
        let finalRes = reorderArray(res)
        setDetails(finalRes);
        setLoading(false);
    }


    return (
        <>
            <div className='mobile-landing-payment-main'>
                <div className='mobile-landing-payment-head'>
                    <button onClick={() => handleBack()}><FaChevronLeft style={{ fontSize: 14 }} /></button>
                    <span>Credit Insights</span>
                </div>
                <div className='mobile-landing-tab-head'>
                    <IoMdTime style={{ fontSize: 20 }} />
                    Payments
                </div>
                <GenericTopSection
                    insightType={insightType}
                    sectionType={"type_payment"}
                    mainBlockWidth={mainBlockWidth}
                    isLoading={isLoading}
                    data={{
                        first_line: 100,
                        a: calculatePercentage(mainData?.latePayments, (mainData?.totalPayments)),
                        b: calculatePercentage(mainData?.onTimePayments, (mainData?.totalPayments)),
                        lastUpdated: mainData?.lastUpdated || "0"
                    }}
                />
                <HandleGeneralAccounts
                    type={"type_payment"}
                    mainLoading={isLoading}
                    accounts={details}
                />

            </div>
        </>
    );
}
const CreditUtilisationBody = ({ isLoading, mainData, mainBlockWidth, callBackBtn }) => {
    const [insightType, setInsightType] = useState("type_perfect");
    const [loading, setLoading] = useState(true);
    const [details, setDetails] = useState([]);
    const calculateInsightType = () => {
        const { limitUtilized = 0, creditLimit = 1 } = mainData;
        const keyPer = (limitUtilized / creditLimit) * 100;
        if (keyPer < 30) return "type_perfect";
        if (keyPer < 70) return "type_high";
        return "type_extreme_high";
    };

    const checkSubType = (data) => {
        const { limitUtilized = 0, creditLimit = 1 } = data;
        const keyPer = (limitUtilized / creditLimit) * 100;
        if (keyPer < 30) return "type_perfect";
        if (keyPer < 70) return "type_high";
        return "type_extreme_high";
    };

    useEffect(() => {
        const newInsightType = calculateInsightType();
        if (newInsightType !== insightType) {
            setInsightType(newInsightType);
        }
    }, [mainData, insightType]);


    useEffect(() => {
        const { insightAccountDetails = [] } = mainData;
        createDetails(insightAccountDetails)
    }, [mainData]); // Re-run when mainData changes


    const createDetails = (data) => {
        const { mobile } = mainData || {};
        setLoading(true);
        const formatText = (text) => {
            return text
                .toLowerCase() // Convert to lowercase
                .replace(/[_\.]+/g, " ") // Replace underscores and dots with spaces
                .split(" ") // Split into words
                .map(word => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize first letter
                .join(" "); // Join words back into a sentence
        };
        let res = data
            .filter((item) => item?.loanType === "CREDIT_CARD")
            .map((item) => ({
                data: item,
                id: item.id,
                mobileNumber: mobile,
                bankName: item?.subscriberName || "-",
                loanType: formatText(item?.loanCategory) || "-",
                fields: [
                    { label: "Account No", value: item?.accountNumber || "-" },
                    { label: "Limit Used", value: `${(item?.limitUtilizedPercentage * 100).toFixed(2) + "%" || "0"}` },
                    { label: "Status", value: formatText(item?.status) || "-" }
                ],
                dateReported: item?.dateReported || "-",
                accountType: checkSubType(item),
                status: formatText(item?.status) || "-",
                accountNumber: item?.accountNumber || "-",
                limitUtilized: item?.limitUtilizedPercentage * 100 || "0",
                label: "Limit Used",
                value: `${(item?.limitUtilizedPercentage * 100).toFixed(2) + "%" || "0"}`,
                isAccountBad: item?.isAccountBad || false,
                details: {
                    limitUtilized: item?.limitUtilized.toLocaleString("en-IN") || "0",
                    creditLimit: item?.creditLimit.toLocaleString("en-IN") || "0",
                }
            }));
        let finalRes = reorderArray(res)
        setDetails(finalRes);
        setLoading(false);
    }
    const handleBack = () => {
        callBackBtn()
    }
    return (<>
        <div className='mobile-landing-payment-main'>
            <div className='mobile-landing-payment-head'>
                <button onClick={() => handleBack()}><FaChevronLeft style={{ fontSize: 14 }} /></button>
                <span>Credit Insights</span>
            </div>
            <div className='mobile-landing-tab-head'>
                <VscGraphLine style={{ fontSize: 20 }} />
                Credit Utilisation
            </div>
            <GenericTopSection
                insightType={insightType}
                sectionType="type_credit_utilisation"
                mainBlockWidth={mainBlockWidth}
                isLoading={isLoading}
                data={{
                    first_line: 100,
                    a: mainData?.limitUtilized != null ? mainData?.limitUtilized.toLocaleString("en-IN") : "-",
                    b: mainData?.creditLimit != null ? mainData?.creditLimit.toLocaleString("en-IN") : "-",
                    lastUpdated: mainData?.lastUpdated || "0"
                }}
            />
            <HandleGeneralAccounts
                type={"type_credit_utilisation"}
                mainLoading={isLoading}
                accounts={details}
            />
        </div>
    </>)
}
const CreditAgeBody = ({ isLoading, mainData, mainBlockWidth, callBackBtn }) => {
    const [insightType, setInsightType] = useState("type_perfect")
    const [loading, setLoading] = useState(true);
    const [details, setDetails] = useState([]);
    const checkType = () => {
        const { age } = mainData || {};
        if (age < 12) {
            setInsightType("type_poor");
            return;
        } else if (age >= 12 && age < 24) {
            setInsightType("type_fair");
        } else if (age >= 24 && age < 60) {
            setInsightType("type_good");
        } else if (age >= 60) {
            setInsightType("type_perfect");
        }
    };
    const checkSubType = (data) => {
        const { age } = data || {};
        if (age < 12) {
            return "type_poor";
        }
        if (age >= 12 && age < 24) {
            return "type_fair";
        } else if (age >= 24 && age < 60) {
            return "type_good";
        } else if (age >= 60) {
            return "type_perfect";
        }
    };

    useEffect(() => {
        checkType();
        const { insightAccountDetails = [] } = mainData;
        createDetails(insightAccountDetails)
    }, [mainData]); // Re-run when mainData changes


    const convertMonthsToYears = (months) => {
        const years = Math.floor(months / 12);
        const remainingMonths = months % 12;
        return `${years}Y ${remainingMonths > 0 ? `& ${remainingMonths}M` : ""}`;
    };

    const createDetails = (data) => {
        const { mobile } = mainData || {};
        setLoading(true);
        const formatText = (text) => {
            return text
                .toLowerCase() // Convert to lowercase
                .replace(/[_\.]+/g, " ") // Replace underscores and dots with spaces
                .split(" ") // Split into words
                .map(word => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize first letter
                .join(" "); // Join words back into a sentence
        };
        const shortenText = (text) => {
            if (text.length <= 10) return text; // If length is 10 or less, return as is
            const lastSix = text.slice(-4); // Get last 6 characters
            const hiddenPart = "X".repeat(6); // Replace the first part with 'X'
            return hiddenPart + lastSix;
        };
        let res = data.map((item) => ({
            data: item,
            id: item.id,
            mobileNumber: mobile,
            bankName: item?.subscriberName || "-",
            loanType: formatText(item?.loanCategory) || "-",
            fields: [
                { label: "Account No", value: item?.accountNumber || "-" },
                { label: "Age of account", value: `${convertMonthsToYears(item?.age) || "-"}` },
                { label: "Status", value: formatText(item?.status) || "-" }
            ],
            dateReported: item?.dateReported || "-",
            status: formatText(item?.status) || "-",
            accountNumber: item?.accountNumber || "-",
            label: "Age of account",
            value: `${convertMonthsToYears(item?.age) || "-"}`,
            accountType: checkSubType(item),
            isAccountBad: item?.isAccountBad || false,
            details: {}
        }))
        let finalRes = reorderArray(res)
        setDetails(finalRes);
        setLoading(false);
    }
    const handleBack = () => {
        callBackBtn()
    }
    return (<>
        <div className='mobile-landing-payment-main'>
            <div className='mobile-landing-payment-head'>
                <button onClick={() => handleBack()}><FaChevronLeft style={{ fontSize: 14 }} /></button>
                <span>Credit Insights</span>
            </div>
            <div className='mobile-landing-tab-head'>
                <LuUsers style={{ fontSize: 20 }} />
                Account Age
            </div>
            <GenericTopSection
                insightType={insightType}
                sectionType={"type_account_age"}
                mainBlockWidth={mainBlockWidth}
                isLoading={isLoading}
                data={
                    {
                        first_line: 100,
                        oldest_account_age: convertMonthsToYears(mainData?.age) || "-",
                        a: convertMonthsToYears(mainData?.age) || "-",
                        b: mainData?.activeAccounts || "0",
                        lastUpdated: mainData?.lastUpdated || "0"
                    }
                }
            />
            <HandleGeneralAccounts
                type={"type_account_age"}
                mainLoading={isLoading}
                accounts={details}
            />
        </div>
    </>)
}
const AccountsBody = ({ isLoading, mainData, mainBlockWidth, callBackBtn }) => {
    const [insightType, setInsightType] = useState("type_perfect");
    const [accountType, setAccountType] = useState("type_a")
    const [loading, setLoading] = useState(true);
    const [details, setDetails] = useState([]);
    const { activeAccounts,
        closedAccounts,
        activeLoanAccounts,
        activeSecureLoanAccounts,
        activeUnsecureLoanAccounts,
        activeCreditCards
    } = mainData || {};
    const checkType = () => {
        if (
            activeSecureLoanAccounts >= 1 &&
            activeUnsecureLoanAccounts >= 1 &&
            activeCreditCards >= 1
        ) {
            setInsightType("type_perfect");
        } else if (
            (
                activeSecureLoanAccounts >= 1 &&
                activeUnsecureLoanAccounts >= 1
            )
            ||
            (
                activeSecureLoanAccounts >= 1 &&
                activeCreditCards >= 1)
            ||
            (
                activeCreditCards >= 1 &&
                activeUnsecureLoanAccounts >= 1)
        ) {
            setInsightType("type_good");
        } else if (
            activeSecureLoanAccounts >= 1
        ) {
            setInsightType("type_fair");
        } else if (activeUnsecureLoanAccounts >= 1 || activeCreditCards >= 1 || closedAccounts >= 1) {
            setInsightType("type_poor");
        }
    };

    const checkSubType = (data) => {
        const { age } = data || {};
        if (age < 12) {
            return "type_poor";
        }
        if (age >= 12 && age < 24) {
            return "type_fair";
        } else if (age >= 24 && age < 60) {
            return "type_good";
        } else if (age >= 60) {
            return "type_perfect";
        }
    };
    const checkAccountType = () => {
        if (activeSecureLoanAccounts >= 1 && activeUnsecureLoanAccounts >= 1) {
            setAccountType("type_a")
        } else if (activeCreditCards >= 1 && activeUnsecureLoanAccounts >= 1) {
            setAccountType("type_b")
        } else if (activeSecureLoanAccounts >= 1 && activeCreditCards >= 1) {
            setAccountType("type_c")
        }
    }

    useEffect(() => {
        checkType();
        checkAccountType();
        const { insightAccountDetails = [] } = mainData;
        createDetails(insightAccountDetails)
    }, [mainData]); // Re-run when mainData changes

    const convertMonthsToYears = (months) => {
        const years = Math.floor(months / 12);
        const remainingMonths = months % 12;
        return `${years}Y ${remainingMonths > 0 ? `& ${remainingMonths}M` : ""}`;
    };
    const convertDateToFormat = (timestamp) => {
        const date = new Date(timestamp);
        const options = { day: '2-digit', month: 'short', year: 'numeric' };
        return date.toLocaleDateString('en-GB', options);
    }

    const createDetails = (data) => {
        const { mobile } = mainData || {};
        setLoading(true);
        const formatText = (text) => {
            return text
                .toLowerCase() // Convert to lowercase
                .replace(/[_\.]+/g, " ") // Replace underscores and dots with spaces
                .split(" ") // Split into words
                .map(word => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize first letter
                .join(" "); // Join words back into a sentence
        };
        const shortenText = (text) => {
            if (text.length <= 10) return text; // If length is 10 or less, return as is
            const lastSix = text.slice(-4); // Get last 6 characters
            const hiddenPart = "X".repeat(6); // Replace the first part with 'X'
            return hiddenPart + lastSix;
        };
        let res = data.map((item) => ({
            data: item,
            id: item.id,
            mobileNumber: mobile,
            bankName: item?.subscriberName || "-",
            loanType: formatText(item?.loanCategory) || "-",
            fields: [
                { label: "Account No", value: item?.accountNumber || "--" },
                { label: "Account Type", value: `${formatText(item?.loanType) || "-"}` },
                { label: "Status", value: formatText(item?.status) || "-" }
            ],
            dateReported: item?.dateReported || "-",
            status: formatText(item?.status) || "-",
            accountType: checkSubType(item),
            accountNumber: item?.accountNumber || "-",
            label: "Account Type",
            value: `${formatText(item?.loanType) || "-"}`,
            isAccountBad: item?.isAccountBad || false,
            details: {
                elements: item?.loanCategory === "CREDIT_CARD" ?
                    [
                        { label: "Account Open Date", value: convertDateToFormat(item?.openDate) || "-" },
                        { label: "Limit Utilised", value: `₹ ${item?.limitUtilized ? item?.limitUtilized.toLocaleString() : "-"}` },
                        { label: "Credit Limit", value: `₹ ${item?.creditLimit ? item?.creditLimit.toLocaleString() : "-"}` },
                    ] :
                    [
                        { label: "Outstanding Amount", value: `₹ ${(item?.outstandingLoanAmount ? item?.outstandingLoanAmount.toLocaleString() : "-")}` },
                        { label: "Loan Amount", value: `₹ ${item?.totalLoanAmount ? item?.totalLoanAmount.toLocaleString() : "-"}` },
                        { label: "Account Open Date", value: convertDateToFormat(item?.openDate) || "-" },
                        { label: "ROI", value: item?.rateOfInterest ? item?.rateOfInterest + "%" : "-" },
                        { label: "Tenure", value: convertMonthsToYears(item?.tenure) || "-" },
                    ]
            }
        }))
        let finalRes = reorderArray(res)
        setDetails(finalRes);
        setLoading(false);
    }
    const handleBack = () => {
        callBackBtn()
    }
    return (<>
        <div className='mobile-landing-payment-main'>
            <div className='mobile-landing-payment-head'>
                <button onClick={() => handleBack()}><FaChevronLeft style={{ fontSize: 14 }} /></button>
                <span>Credit Insights</span>
            </div>
            <div className='mobile-landing-tab-head'>
                <MdOutlineSwitchAccount style={{ fontSize: 20 }} />
                Credit Mix
            </div>
            <GenericTopSection
                insightType={insightType}
                sectionType={"type_accounts"}
                mainBlockWidth={mainBlockWidth}
                isLoading={isLoading}
                data={
                    {
                        first_line: 100,
                        noOfAccounts: activeAccounts || "0",
                        type: accountType || "-",
                        a: activeAccounts || "0",
                        b: closedAccounts || "0",
                        lastUpdated: mainData?.lastUpdated || "0"
                    }
                }
            />
            <HandleGeneralAccounts
                type={"type_accounts"}
                mainLoading={isLoading}
                accounts={details}
            />
        </div>
    </>)
}
const EnquiriesBody = ({ isLoading, mainData, mainBlockWidth, callBackBtn }) => {
    const [insightType, setInsightType] = useState("type_perfect");
    const [loading, setLoading] = useState(true);
    const [details, setDetails] = useState([]);
    const {
        loanEnquiries,
        cardEnquiries
    } = mainData || {};
    const checkType = () => {
        let sum = loanEnquiries + cardEnquiries;
        if (sum >= 0 && sum < 2) {
            setInsightType("type_perfect")
        } else if (sum >= 2 && sum < 5) {
            setInsightType("type_good")
        } else if (sum >= 5 && sum <= 6) {
            setInsightType("type_fair")
        } else if (sum > 6) {
            setInsightType("type_poor")
        }
    };

    useEffect(() => {
        checkType();
        const { bureauInquiries = [] } = mainData;
        createDetails(bureauInquiries)
    }, [mainData]); // Re-run when mainData changes
    const convertDateToFormat = (timestamp) => {
        const date = new Date(timestamp);
        const options = { day: '2-digit', month: 'short', year: 'numeric' };
        return date.toLocaleDateString('en-GB', options);
    }

    const createDetails = (data) => {
        const { mobile } = mainData || {};
        setLoading(true);
        const formatText = (text) => {
            return text
                .toLowerCase() // Convert to lowercase
                .replace(/[_\.]+/g, " ") // Replace underscores and dots with spaces
                .split(" ") // Split into words
                .map(word => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize first letter
                .join(" "); // Join words back into a sentence
        };
        const shortenText = (text) => {
            if (text.length <= 10) return text; // If length is 10 or less, return as is
            const lastSix = text.slice(-4); // Get last 6 characters
            const hiddenPart = "X".repeat(6); // Replace the first part with 'X'
            return hiddenPart + lastSix;
        };
        let res = data.map((item) => ({
            data: item,
            mobileNumber: mobile,
            bankName: item?.subscriberName || "-",
            loanType: formatText(item?.enquiryReason) || "-",
            fields: [
                { label: "Enquiry Date", value: convertDateToFormat(item?.inquiryDate) || "-" },
                { label: "Product Type", value: `${formatText(item?.enquiryReason) || "-"}` },
                { label: "Amount", value: `₹ ${item?.amount || "-"} ` }
            ],
            accountNumber: `${formatText(item?.enquiryReason) || "-"}`,
            label: "Amount",
            value: `₹ ${item?.amount || "-"} `,
            status: "Enquired on " + convertDateToFormat(item?.inquiryDate) || "-",
            details: {}
        }))
        setDetails(res);
        setLoading(false);
    }
    const handleBack = () => {
        callBackBtn()
    }
    return (<>
        <div className='mobile-landing-payment-main'>
            <div className='mobile-landing-payment-head'>
                <button onClick={() => handleBack()}><FaChevronLeft style={{ fontSize: 14 }} /></button>
                <span>Credit Insights</span>
            </div>
            <div className='mobile-landing-tab-head'>
                <PiWarningCircleLight style={{ fontSize: 20 }} />
                Enquiries
            </div>
            <GenericTopSection
                insightType={insightType}
                sectionType={"type_enquiries"}
                mainBlockWidth={mainBlockWidth}
                isLoading={isLoading}
                data={
                    {
                        first_line: 100,
                        a: mainData?.loanEnquiries ?? "0",
                        b: mainData?.cardEnquiries || "0",
                        lastUpdated: mainData?.lastUpdated || "0"
                    }
                }
            />
            <HandleGeneralAccounts
                type={"type_enquiries"}
                mainLoading={isLoading}
                accounts={details}
            />
        </div>
    </>)
}
const GreetBlock = ({ isLoading, mainData }) => {
    const { userName } = mainData || { userName: "" };
    if (isLoading) {
        return (<><div className='greet-block-main' style={{ padding: 5 }}>
            <div className="greet-block-name">
                {/* <span><b>Hello, </b> Gaurav Badgujar</span>
            <p>It's good to see you again.</p> */}
                <span className='skeleton-generic' style={{ height: 20, width: 100 }}></span>
                <p className='skeleton-generic' style={{ height: 20, width: 120 }}> </p>
            </div>
            {/* <div className="greet-block-date" >
                <span className='skeleton-generic' style={{ height: 20, width: 120 }}> </span>
                <p className='skeleton-generic' style={{ height: 20, width: 120 }}> </p>
            </div> */}
        </div>
            <hr className='hr-line-break' />
        </>)
    } else
        return (<>
            <div className='greet-block-main'>
                <div className="greet-block-name">
                    {/* <span><b>Hello, </b> Gaurav Badgujar</span>
                <p>It's good to see you again.</p> */}
                    <span>Hello, <b>{userName}!</b> </span>
                    <p> Good to see you again.</p>
                </div>
                {/* <div className="greet-block-date">
                    <span>Last Updated on : </span>  <p> 21 sept, 24</p>
                </div> */}
            </div>
            <hr className='hr-line-break' />
        </>)
}
function MobileLandingDashboard({ badLoanCount, isLoading, mainData, mainBlockWidth }) {
    const [localData, setLocalData] = useState({
        totalPayments: 1,
        latePayments: "",
        onTimePayments: 0,
        limitUtilized: 0,
        creditLimit: 1,
        age: "",
        activeAccounts: "",
        closedAccounts: "",
        activeLoanAccounts: "",
        activeSecureLoanAccounts: "",
        activeUnsecureLoanAccounts: "",
        activeCreditCards: "",
        loanEnquiries: "",
        cardEnquiries: "",
    })
    useEffect(() => {
        setLocalData(mainData)
    }, [mainData])


    const checkHighlights = (type) => {
        const {
            totalPayments,
            latePayments,
            onTimePayments,
            limitUtilized,
            creditLimit,
            age,
            activeAccounts,
            closedAccounts,
            activeLoanAccounts,
            activeSecureLoanAccounts,
            activeUnsecureLoanAccounts,
            activeCreditCards,
            loanEnquiries,
            cardEnquiries,
        } = localData;
        const getColorFontPercentage = () => {
            const per = (onTimePayments / totalPayments) * 100;
            if (per >= 100) {
                return { color: "#75c258", fontWeight: "700" }
            } else if (per < 100 && per > 90) {
                return { color: "rgb(240, 194, 75)", fontWeight: "700" }
            } else if (per <= 90 && per > 70) {
                return { color: "#f9955b", fontWeight: "700" }
            } else if (per <= 70) {
                return { color: "#ff0000", fontWeight: "700" }
            }
        }
        const getCreditPercentageColor = () => {
            const per = ((limitUtilized / creditLimit) * 100)
            if (per <= 30) {
                return "#75c258"
            } else if (per > 30 && per < 70) {
                return "rgb(240, 194, 75)"
            } else if (per >= 70) {
                return "red"
            }
        }
        const convertMonthsToYears = () => {
            const years = Math.floor(age / 12);
            const remainingMonths = (age) % 12;
            return `${years}Y ${remainingMonths > 0 ? `& ${remainingMonths}M` : ""}`;
        };
        const checkAccountAgeColor = (data) => {
            if (age < 12) {
                return "#D92D20";
            }
            if (age >= 12 && age < 24) {
                return "#F79009";
            } else if (age >= 24 && age < 60) {
                return "#17B26A";
            } else if (age >= 60) {
                return "#75c258";
            }
        };

        const checkAccountsColor = () => {
            if (
                activeSecureLoanAccounts >= 1 &&
                activeUnsecureLoanAccounts >= 1 &&
                activeCreditCards >= 1
            ) {
                return "rgb(117 194 88)";
            } else if (
                (
                    activeSecureLoanAccounts >= 1 &&
                    activeUnsecureLoanAccounts >= 1
                )
                ||
                (
                    activeSecureLoanAccounts >= 1 &&
                    activeCreditCards >= 1)
                ||
                (
                    activeCreditCards >= 1 &&
                    activeUnsecureLoanAccounts >= 1)
            ) {
                return "rgb(117 194 88)";
            } else if (
                activeSecureLoanAccounts >= 1
            ) {
                return "#F79009"
            } else if (activeUnsecureLoanAccounts >= 1 || activeCreditCards >= 1 || closedAccounts >= 1) {
                return "#D92D20";
            }
        };
        const checkEnquiriesColor = () => {
            let sum = loanEnquiries + cardEnquiries;
            if (sum >= 0 && sum < 2) {
                return "#75c258"
            } else if (sum >= 2 && sum < 5) {
                return "#17B26A"
            } else if (sum >= 5 && sum <= 6) {
                return "#F79009"
            } else if (sum > 6) {
                return "#D92D20"
            }
        };

        switch (type) {
            case "type_payment":
                return { value: ((onTimePayments / totalPayments) * 100).toFixed(2) + "%", color: getColorFontPercentage()?.color };
            case "type_credit_utilisation":
                return { value: limitUtilized || creditLimit ? ((limitUtilized / creditLimit) * 100).toFixed(2) + "%" : "-", color: getCreditPercentageColor() };
            case "type_account_age":
                return { value: convertMonthsToYears(), color: checkAccountAgeColor() };
            case "type_accounts":
                return { value: activeAccounts, color: checkAccountsColor() };
            case "type_enquiries":
                return { value: loanEnquiries + cardEnquiries, color: checkEnquiriesColor() };
            default:
                return { value: '', color: "" }

        }
    }
    const iconStyle = { fontSize: 20, lineHeight: 20, color: "#033B52" };
    const [activeTab, setActiveTab] = useState(localStorage.getItem("menu_tab") || null);
    const tabs = [
        {
            key: "type_payment",
            label: "Payments",
            impact: "High Impact",
            subLabel: "Timely payments",
            value: checkHighlights("type_payment")?.value,
            color: checkHighlights("type_payment")?.color,
            icon: <IoMdTime style={{ ...iconStyle, strokeWidth: 10 }} />
        },
        {
            key: "type_credit_utilisation",
            label: "Credit Utilisation",
            impact: "High Impact",
            subLabel: "Limit used",
            value: checkHighlights("type_credit_utilisation")?.value,
            color: checkHighlights("type_credit_utilisation")?.color,
            icon: <VscGraphLine style={{ ...iconStyle, strokeWidth: .5 }} />
        },
        {
            key: "type_account_age",
            label: "Account Age",
            impact: "Medium Impact",
            subLabel: "Age of accounts",
            value: checkHighlights("type_account_age")?.value,
            color: checkHighlights("type_account_age")?.color,
            icon: <LuUsers style={{ ...iconStyle, strokeWidth: 2 }} />
        },
        {
            key: "type_accounts",
            label: "Credit Mix",
            impact: "Low Impact",
            subLabel: "Active accounts",
            value: checkHighlights("type_accounts")?.value,
            color: checkHighlights("type_accounts")?.color,
            icon: <MdOutlineSwitchAccount style={{ ...iconStyle, strokeWidth: 0 }} />
        },
        {
            key: "type_enquiries",
            label: "Enquiries",
            impact: "Low Impact",
            subLabel: "Total Enquiries",
            value: checkHighlights("type_enquiries")?.value,
            color: checkHighlights("type_enquiries")?.color,
            icon: <PiWarningCircleLight style={{ ...iconStyle, strokeWidth: 10 }} />
        },
    ]
    useEffect(() => {
        let current = localStorage.getItem("menu_tab");
        if (activeTab) {
            localStorage.setItem("menu_tab", activeTab);
        } else if (current) {
            localStorage.setItem("menu_tab", current);
        } else if (activeTab === null) {
            localStorage.removeItem("menu_tab");
        } else {
            localStorage.removeItem("menu_tab");
        }
    }, [activeTab]);
    const handleBackBtn = () => {
        setActiveTab(null)
        localStorage.removeItem("menu_tab");
    }
    const handleBodyType = (type) => {
        switch (type) {
            case "type_payment":
                return <PaymentBody isLoading={isLoading} mainData={mainData} mainBlockWidth={mainBlockWidth} callBackBtn={handleBackBtn} />;
            case "type_credit_utilisation":
                return <CreditUtilisationBody isLoading={isLoading} mainData={mainData} mainBlockWidth={mainBlockWidth} callBackBtn={handleBackBtn} />;
            case "type_account_age":
                return <CreditAgeBody isLoading={isLoading} mainData={mainData} mainBlockWidth={mainBlockWidth} callBackBtn={handleBackBtn} />;
            case "type_accounts":
                return <AccountsBody isLoading={isLoading} mainData={mainData} mainBlockWidth={mainBlockWidth} callBackBtn={handleBackBtn} />;
            case "type_enquiries":
                return <EnquiriesBody isLoading={isLoading} mainData={mainData} mainBlockWidth={mainBlockWidth} callBackBtn={handleBackBtn} />;
            default:
                return <></>;
        }
    }
    return (
        <>
            {/* */}
            {!activeTab ? (
                <>
                    <GreetBlock isLoading={isLoading} mainData={mainData} />
                    <CommonLandScreen badLoanCount={badLoanCount} isLoading={isLoading} mainData={mainData} mainBlockWidth={mainBlockWidth} />
                    {isLoading ?
                        (<>
                            <div className="mobile-land-main">
                                <div className="mobile-land-tabs">
                                    {[...Array(5)].map((item) => (
                                        <div
                                            key={item}
                                            style={{ borderLeft: `6px solid #80808059`, height: 40 }}
                                            className="mobile-land-tab-s skeleton-generic"

                                        >
                                            <div className="mobile-land-tab-1">
                                                <div className="mobile-land-tab-1-1">
                                                    <span style={{ height: 24, width: 120, color: "#80808059" }} className='skeleton-generic'></span>
                                                    <p style={{ height: 20, width: 100, color: "#80808059" }} className='skeleton-generic'></p>
                                                </div>
                                                <div className="mobile-land-tab-1-2">
                                                    <span style={{ height: 24, width: 120, color: "#80808059" }} className='skeleton-generic'></span>
                                                    <p style={{ height: 20, width: 100, color: "#80808059" }} className='skeleton-generic'></p>
                                                </div>
                                            </div>
                                            <span style={{ height: 20, width: 20, borderRadius: "10%", color: "#80808059" }} className='skeleton-generic'></span>
                                        </div>
                                    ))}
                                </div>
                            </div>

                        </>)
                        :
                        <>
                            <div className="mobile-land-main">
                                <div className="mobile-land-tabs">
                                    {tabs.map((item) => (
                                        <div
                                            key={item.key}
                                            onClick={() => setActiveTab(item.key)}
                                            style={{ borderLeft: `6px solid ${item.color}` }}
                                            className="mobile-land-tab"
                                        >
                                            <div className="mobile-land-tab-1">
                                                <div className="mobile-land-tab-1-1">
                                                    <span>{item.label}</span>
                                                    <p>{item.impact}</p>
                                                </div>
                                                <div className="mobile-land-tab-1-2">
                                                    <span
                                                    // style={{ color: `${item.color}` }}
                                                    >{item.value}</span>
                                                    <p>{item.subLabel}</p>
                                                </div>
                                            </div>
                                            {item.icon}
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </>
                    }
                </>
            ) : (
                <>
                    <hr className='hr-line-break' />
                    {handleBodyType(activeTab)}
                </>
            )}
        </>
    );

}

export default MobileLandingDashboard