import React from 'react'
import "./founders.css"
import manish from "../assets/imgs/manish.webp";
import prateek from "../assets/imgs/prateek.webp";
function Founders() {
    return (
        <>
            <div className="grid-container">
                <div className="grid-item">
                    <div className='card-founder'>
                        <div className='img'>
                            <img src={manish} alt="manish" height="300px" className='imgs'    loading="lazy"  />
                        </div>

                        <div className='text-container'>
                            <div>                           
                            <p className='title'>Co-Founder</p>
                            <p className='founderName'>Manish Kathuria</p>
                            </div>
                            <p className='detail'>Manish has over 20 years of consolidated experience. Before becoming an entrepreneur in 2017, he took on leadership roles in the sales and collections functions in Telecom, DTH, Credit cards, Auto loans, Personal loans, Mortgages etc.
                            At The Due Factory (TDF), Manish takes care of the business strategy, bank partnerships as well as scaling the business.</p>
                        </div>
                    </div>
                </div>
                <div className="grid-item">
                    <div className='card-founder'>
                        <div className='img'>
                            <img src={prateek} alt="manish" height="300px" className='imgs'    loading="lazy"  />
                        </div>

                        <div className='text-container'>
                            <p className='title'>Co-Founder</p>
                            <p className='founderName'>Prateek Upadhyay</p>
                            <p className='detail'>With more than 15 years of vertical experience, Prateek is an expert in the credit collections domain. He is skilled in executing business plans with expertise in digital collection tools. Previously, he was in the collections team in SBI Cards, as an Associate Director with PayU and then as the Collections Head with Cred.
                            Currently, he is responsible for developing the collections framework, process, policies and models for TDF.</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Founders