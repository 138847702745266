import React, { useEffect, useState } from 'react';
import "./style2.css";
import TDF_CROPPED_LOGO from '../../assets/imgs/TDFLogo.png';
import TDF_LOGO from '../../assets/imgs/DUE-FACTORY.png';
import QUICK_TIP_IMG_2 from '../../assets/img/SidebarQuickTips2.png';
import { MdCall, MdOutlineKeyboardArrowRight, MdSettingsSuggest } from 'react-icons/md';
import { toggleSidebar, setScreenSize } from "../../store/store";
import { useSelector, useDispatch } from "react-redux";
import { RiFileCopy2Line, RiFolderCloseLine } from 'react-icons/ri';
import { useLocation, useNavigate } from 'react-router-dom';
import { TbFileDescription } from 'react-icons/tb';
import { BiSolidOffer } from 'react-icons/bi';
import { FaRegQuestionCircle } from 'react-icons/fa';
const SidebarScreen = () => {
    const [innerWidth, setinnerWidth] = useState(0);
    const [currentHighlight, setCurrentHighlight] = useState("");
    const [showTip, setShowTip] = useState(true);
    const location = useLocation();
    const navigate = useNavigate();
    const isOpen = useSelector((state) => state.sidebar.isOpen);
    const isHalfOpen = useSelector((state) => state.sidebar.isHalfOpen);
    const dispatch = useDispatch();
    const { sidebarWidth } = useSelector((state) => state.sidebar);
    useEffect(() => {
        const handleResize = () => {
            setinnerWidth(window.innerWidth);
            dispatch(setScreenSize(window.innerWidth));

            let zoomLevel = Math.round((window.outerWidth / window.innerWidth) * 100);
            if (zoomLevel > 125 || zoomLevel < 80) {
                setShowTip(false)
            } else {
                setShowTip(true)
            }
        };

        handleResize(); // Check on mount
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    const checkRequired = () => {
        return (innerWidth >= 900 && isHalfOpen) ? TDF_CROPPED_LOGO : TDF_LOGO;
    }
    const checkMargin = () => {
        const WIDTH = sidebarWidth;
        if (isHalfOpen) {
            return WIDTH + 45
        } else {
            return WIDTH - 5
        }
    }

    const SidebarListElements = [
        { key: "dash-credit-report", title: "Credit Report", path: "/home/dashboard", icon: <RiFileCopy2Line /> },
        { key: "dash-credit-report-1", title: "Credit Insights", path: "/home/insights", icon: <TbFileDescription /> },
        { key: "dash-faq", title: "FAQ's", path: "/faq", icon: <FaRegQuestionCircle /> },
        { key: "dash-contact", title: "Contact Us", path: "/contact-us", icon: <MdCall /> },
        // { key: "dash-credit-bad-loans", title: "Bad Loans", path: "/home/badloans", icon: <RiFolderCloseLine /> },
        // { key: "dash-credit-report-2", title: "Recommendations  ", path: "/home/dashboard-3", icon: <MdSettingsSuggest /> },
        // { key: "dash-credit-report-4", title: "Settlements", path: "/home/dashboard-4", icon: <BiSolidOffer /> },
    ]

    const goToNav = (path) => {
        navigate(path)
        if (window.innerWidth <= 900) {
            dispatch(toggleSidebar());
        }
    }


    const checkActivePath = (screen) => {
        const str = location.pathname.split("/");
        const path = screen.split("/")
        if (
            str[0] === path[0] &&
            str[1] === path[1] &&
            str[2] === path[2]) {
            return true;
        } else {
            return false;
        }

    }

    const handleCurrentHighlight = (item) => {
        setCurrentHighlight(item)
    }

    const checkBottomMargin = () => {
        let zoomLevel = Math.round((window.outerWidth / window.innerWidth) * 100);
        switch (zoomLevel) {
            case 100:
                return 25
            case 90:
                return 30
            case 80:
                return 40
            case 110:
                return 20
            case 125:
                return 20
            default:
                return 30
        }
    }

    const checkHeightMargin = () => {
        let zoomLevel = Math.round((window.outerWidth / window.innerWidth) * 100);
        if (isHalfOpen) {
            return "auto";
        } else {
            switch (zoomLevel) {
                case 100:
                    return 460
                case 90:
                    return 560
                case 80:
                    return 660
                case 110:
                    return 380
                case 125:
                    return 300
                default:
                    return "52vh"
            }
        }
    }
    return (
        <>
            <div
                style={{
                    display:
                        innerWidth == 900
                            ?
                            (isOpen ? "flex" : "none")
                            :
                            (isOpen || isHalfOpen ? "flex" : "none"),
                    width: sidebarWidth,
                    flexDirection: 'column',
                    gap: showTip ? 0 : 20
                }}
                className="home-dashboard-sidebar">
                <span className='home-dashboard-sidebar-btn'
                    style={{
                        marginLeft: checkMargin(),
                        // display: innerWidth > 900 ? "flex" : "none",
                        display: innerWidth >= 1200 ? "flex" : "none",
                        transform: `rotate(${isHalfOpen ? 0 : -180}deg)`,
                        background: isHalfOpen ? "#00b8ff29" : "",
                    }}
                    onClick={() => dispatch(toggleSidebar())}
                >
                    <MdOutlineKeyboardArrowRight />
                </span>
                <div className='home-dashboard-sidebar-head'>
                    <img src={checkRequired()} alt="logo" />
                </div>
                <div className='home-dashboard-sidebar-body'
                    style={{
                        margin: isHalfOpen ? "20px 0px 0px" : "",
                    }}
                >
                    {
                        isHalfOpen && currentHighlight ? <span className='hover-tooltip'>{currentHighlight}</span> : <></>
                    }
                    <div
                        style={{
                            padding: isHalfOpen ? "0px 0px 20px" : "20px",
                            height: showTip ? checkHeightMargin() : "auto",
                        }}
                        className='home-dashboard-sidebar-body-inner'>
                        {SidebarListElements.map((item) =>
                            <div
                                key={item.key}
                                onClick={() => goToNav(item.path)}
                                style={{
                                    width: isHalfOpen ? "auto" : ""
                                }}
                                className={
                                    checkActivePath(item.path)
                                        ?
                                        "active-elem"
                                        :
                                        ""
                                }
                                onMouseEnter={() => handleCurrentHighlight(item.title)}
                                onMouseLeave={() => handleCurrentHighlight("")}
                            >
                                {item.icon}
                                {
                                    !isHalfOpen ? <span>{item.title}</span> : <></>
                                }
                            </div>

                        )}
                    </div>
                </div>
            </div>
            <div className='sidebar-2-quick-tip-block'
                style={{
                    transition: "opacity .1s ease-in-out, visibility .3s ease-in-out",
                    opacity: !isHalfOpen && showTip && !(window.innerWidth <= 900) ? 1 : 0,
                    visibility: !isHalfOpen && showTip && !(window.innerWidth <= 900) ? "visible" : "hidden",
                    display: !isHalfOpen && showTip && !(window.innerWidth <= 900) ? "inline-block" : "none",
                    bottom: checkBottomMargin(),
                    left: 20
                }}
            >
                <img src={QUICK_TIP_IMG_2} alt='img-quick-tip' />
                <div>
                    <p>How Due Factory can help</p>
                    <ul>
                        <li>We can assist you in correcting any potential errors on your credit report that could affect your score.</li>
                    </ul>
                </div>
            </div>
        </>
    )
}

export default SidebarScreen