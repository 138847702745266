import React, { useEffect, useRef, useState } from 'react';
import "./style.css";
import ScoreGauge from '../../CentralMenu/ScoreGauge';
import { AiOutlineCloudDownload } from 'react-icons/ai';
import { IoMdTime } from 'react-icons/io';
import { VscGraphLine } from 'react-icons/vsc';
import { LuUsers } from 'react-icons/lu';
import { MdOutlineSwitchAccount } from 'react-icons/md';
import { PiShareFat, PiWarningCircleLight } from 'react-icons/pi';
import { useSelector } from 'react-redux';
import { generic_stacked_record, } from '../../utils/GenericInsightStack';
import GeneralYourAccounts from './GeneralYourAccounts';
import { postGenericInsights } from '../../service/ApiList';
import { RxReload } from 'react-icons/rx';
import MobileLandingDashboard from './MobileLandingDashboard';
import { RiFolderCloseLine } from 'react-icons/ri';
import { useNavigate } from 'react-router-dom';
import DownloadReport from './Actions/DownloadReport';
const GreetBlock = ({ isLoading, mainData }) => {
    const { userName } = mainData || { userName: "" };
    if (isLoading) {
        return (<><div className='greet-block-main' style={{ padding: 5 }}>
            <div className="greet-block-name">
                {/* <span><b>Hello, </b> Gaurav Badgujar</span>
            <p>It's good to see you again.</p> */}
                <span className='skeleton-generic' style={{ height: 20, width: 100 }}></span>
                <p className='skeleton-generic' style={{ height: 20, width: 120 }}> </p>
            </div>
            {/* <div className="greet-block-date" >
                <span className='skeleton-generic' style={{ height: 20, width: 120 }}> </span>
                <p className='skeleton-generic' style={{ height: 20, width: 120 }}> </p>
            </div> */}
        </div>
            <hr className='hr-line-break' />
        </>)
    } else
        return (<>
            <div className='greet-block-main'>
                <div className="greet-block-name">
                    {/* <span><b>Hello, </b> Gaurav Badgujar</span>
                <p>It's good to see you again.</p> */}
                    <span>Hello, <b>{userName}!</b> </span>
                    <p> Good to see you again.</p>
                </div>
                {/* <div className="greet-block-date">
                    <span>Last Updated on : </span>  <p> 21 sept, 24</p>
                </div> */}
            </div>
            <hr className='hr-line-break' />
        </>)
}
const CommonLandScreen = ({ isLoading, mainData, mainBlockWidth, badLoanCount }) => {
    const navigate = useNavigate();
    const [showModal, setShowModal] = useState(false);
    const [modalChild, setModalChild] = useState(null);

    const handleChild = (type) => {
        switch (type) {
            case 'download_report':
                return <DownloadReport handleCloseModal={handleCloseModal} />;
            default:
                return null;
        }
    }
    const handleAction = (type) => {
        const child = handleChild(type)
        setModalChild(child);
        setShowModal(true);
    };
    const handleCloseModal = () => setShowModal(false);
    const goToBadLoans = () => {
        navigate('/home/badloans')
    }

    const {
        creditRemarks,
        dateOfReport,
        creditScore
    } = mainData || { creditRemarks: "N/A", dateOfReport: "", creditScore: "" }
    if (isLoading) {
        return (<>
            <div className='landing-page-common-main'
                style={{ height: 260 }}
            >
                <div className='landing-page-common'>
                    <div style={{ display: "flex", flexDirection: "column", gap: 10 }}>
                        <div className='skeleton-generic' style={{ height: 160, width: 160, borderRadius: "50%" }}></div>
                        <div className='skeleton-generic' style={{ height: 30, width: 160 }}></div>
                        <div className='skeleton-generic' style={{ height: 12, width: 160 }}></div>
                    </div>
                    <p style={{ height: "auto", width: "100%", display: window.innerWidth <= 650 ? "none" : "flex", flexDirection: "column", gap: 10 }}>
                        <p>
                            <div className='skeleton-generic' style={{ height: 18, width: "100%" }}></div>
                            <div className='skeleton-generic' style={{ height: 18, width: "100%" }}></div>
                            <div className='skeleton-generic' style={{ height: 18, width: "100%" }}></div>
                            <div className='skeleton-generic' style={{ height: 18, width: "50%" }}></div>
                        </p>
                        <div className='landing-page-common-btns' style={{ gap: 10 }}>
                            <div className='skeleton-generic' style={{ height: 35, width: 160, borderRadius: 48 }}></div>
                            <div className='skeleton-generic' style={{ height: 35, width: 160, borderRadius: 48 }}></div>
                        </div>
                    </p>

                </div>

            </div>
        </>)
    } else {
        return (
            <>
                {
                    showModal
                        ?
                        <div className='custom-alert-box-2'>
                            {modalChild}
                        </div>
                        :
                        null
                }

                <div
                    style={{ border: mainBlockWidth >= 900 ? "1px solid #f3f4f4" : "none" }}
                    className='landing-page-common-main'>
                    <div className='landing-page-common'>
                        <ScoreGauge score={creditScore} isLoading={isLoading} lastUpdated={dateOfReport} />
                        <p>
                            {creditRemarks}
                            {
                                window.innerWidth >= 740
                                    ?
                                    null
                                    // <div className='landing-page-common-btns'>
                                    //     {badLoanCount > 0 ?
                                    //         <button onClick={goToBadLoans} style={{ border: "none" }}>
                                    //             <RiFolderCloseLine style={{ fontSize: 16 }} />
                                    //             Bad Loans
                                    //         </button> :
                                    //         null
                                    //     }
                                    //     <button onClick={() => handleAction("download_report")} style={{ border: "none" }}>
                                    //         <RxReload style={{ fontSize: 16 }} />
                                    //         Refresh
                                    //     </button>
                                    //     <button onClick={() => handleAction("download_report")}>
                                    //         <PiShareFat style={{ fontSize: 16 }} />
                                    //         Share
                                    //     </button>
                                    //     <button onClick={() => handleAction("download_report")}>
                                    //         <AiOutlineCloudDownload style={{ fontSize: 16 }} />
                                    //         Download Report
                                    //     </button>
                                    // </div>
                                    :
                                    null
                            }
                        </p>

                    </div>

                </div>
            </>
        )
    }

}

const GenericTopSection = ({ insightType, sectionType, data, mainBlockWidth, isLoading }) => {
    const [blockWidth, setBlockWidth] = useState(300);
    const {
        main_color,
        first_line,
        first_img,
        head_main,
        head_para,
        indicator_block,
        two_stats,
    } = generic_stacked_record(insightType, sectionType, data)
    useEffect(() => {
        setBlockWidth(mainBlockWidth)
    }, [mainBlockWidth])
    const getBrowserType = () => {
        const userAgent = navigator.userAgent;
        if (userAgent.includes("Chrome")) return "Chrome";
        if (userAgent.includes("Firefox")) return "Firefox";
        if (userAgent.includes("Safari") && !userAgent.includes("Chrome")) return "Safari";
        if (userAgent.includes("Edge")) return "Edge";
        if (userAgent.includes("MSIE") || userAgent.includes("Trident")) return "Internet Explorer";
        return "Unknown";
    };

    if (isLoading) {
        return (<>
            <div className='generic-impact-box-top-text skeleton-generic'
                style={{ height: 0, width: "70%", margin: "10px 0px" }} ></div>
            <div className='generic-impact-box skeleton-generic'
                style={{
                    height: 60,
                    display: 'flex',
                    borderLeft: "6px solid rgb(217 213 213)",
                    borderRadius: 24,
                    width: "auto"
                }}
            >

                <div style={{
                    display: 'flex',
                    flexDirection: "column",
                    gap: 5,
                    alignItems: "center",
                    justifyContent: "center"
                }}>
                    <div className="skeleton-generic"
                        style={{
                            height: 25,
                            display: 'flex',
                            width: 120,
                            background: "rgb(232 226 226)",
                            border: "1px solid rgb(216 209 209)",
                            borderRadius: 32
                        }}

                    ></div>
                    <div className="skeleton-generic"
                        style={{
                            height: 15,
                            display: 'flex',
                            width: 70,
                            background: "rgb(232 226 226)"
                        }}
                    > </div>
                </div>
                <div className='generic-impact-box-mid' style={{ gap: 10 }}>
                    {
                        two_stats.map((stat, index) =>
                            <><div key={index} >
                                <span className='skeleton-generic' style={{ color: `${stat.impact_value.text_color}`, width: 80, height: 16, background: "rgb(232 226 226)", }}> </span>
                                <p className='skeleton-generic' style={{ color: `${stat.impact_text.text_color}`, width: 120, height: 14, background: "rgb(232 226 226)", }}></p>
                            </div>
                                {index === 0 ? <span className='generic-impact-box-mid-line'></span> : <></>}
                            </>
                        )

                    }
                </div>
                <div className='generic-impact-box-last' >
                    <p
                        className='skeleton-generic'
                        style={{ color: `${head_para.text_color}`, width: "60%", height: 14, background: "rgb(232 226 226)", }}
                    >

                    </p>
                </div>
            </div>
        </>)
    } else {
        return (<>
            <div className='generic-impact-box-top-text'>{first_line.text}</div>
            <div className='generic-impact-box'

                style={{
                    backgroundColor: main_color.background,
                    border: `.5px solid ${main_color.border_color}`,
                    flexDirection: blockWidth <= 848 ? "column" : "row",
                    borderLeft: `6px solid ${indicator_block.border_color}`,
                    height: getBrowserType() === "Safari" ? 80 : "",
                }}
            >
                <div className='generic-impact-box-left-sts'>
                    <div className='generic-impact-box-highlighter'
                        style={{
                            background: indicator_block.background,
                            border: `1px solid ${indicator_block.border_color}`,
                        }}
                    >
                        <img src={indicator_block.second_img.src} alt={indicator_block.second_img.alt} />
                        <span style={{ color: `${indicator_block.impact_text.text_color}` }}>{indicator_block.impact_text.text}</span>
                    </div>
                    <p style={{ color: `${head_main.text_color}` }}>{head_main.text}</p>
                </div>
                <div className='generic-impact-box-mid'>
                    {
                        two_stats.map((stat, index) =>
                            <><div key={index} >
                                <span style={{ color: `${stat.impact_value.text_color}` }}>{stat.impact_value.text} </span>
                                <p style={{ color: `${stat.impact_text.text_color}` }}>{stat.impact_text.text}</p>
                            </div>
                                {index === 0 ? <span className='generic-impact-box-mid-line'></span> : <></>}
                            </>
                        )

                    }
                </div>
                <div className='generic-impact-box-last' >
                    <p
                        style={{ color: `${head_para.text_color}` }}
                    >
                        {head_para.text}
                    </p>
                </div>
            </div>
        </>)
    }
}
const reorderArray = (arr) => {
    return arr.sort((a, b) => {
        if (a.status === "Active") return -1; // Active should come first
        if (b.status === "Active") return 1;
        if (a.status === "Closed") return 1; // Closed should come last
        if (b.status === "Closed") return -1;
        return 0; // Keep others in between
    });
};


const PaymentSection = ({ mainBlockWidth, isLoading, mainData }) => {
    const [insightType, setInsightType] = useState("type_perfect");

    const [loading, setLoading] = useState(true);
    const [details, setDetails] = useState([]);
    const checkType = () => {
        const { totalPayments, onTimePayments } = mainData || {};
        if (totalPayments === 0) {
            setInsightType("type_perfect");
            return;
        }
        if (!totalPayments || !onTimePayments) {
            setInsightType("type_poor"); // Default or fallback value
            return;
        }
        const res = onTimePayments / totalPayments;
        if (res === 1) {
            setInsightType("type_perfect");
        } else if (res < 1 && res >= 0.9) {
            setInsightType("type_good");
        } else if (res < 0.9 && res >= 0.7) {
            setInsightType("type_fair");
        } else if (res < 0.7) {
            setInsightType("type_poor");
        }
    };
    const checkSubType = (data) => {
        const { totalPayments, onTimePayments } = data || {};

        // Handle cases where mainData is empty or missing required fields
        if (!totalPayments || !onTimePayments) {
            return "type_poor";
        }
        const res = onTimePayments / totalPayments;

        if (res === 1) {
            return "type_perfect"
        } else if (res < 1 && res >= 0.9) {
            return "type_good"
        } else if (res < 0.9 && res >= 0.7) {
            return "type_fair"
        } else if (res < 0.7) {
            return "type_poor"
        }
    };
    const calculatePercentage = (a, b) => {
        if (b === 0) {
            return "100";
        }
        const percentage = (a / b) * 100;
        return percentage.toFixed(0);
    }

    useEffect(() => {
        checkType();
        const { insightAccountDetails = [] } = mainData;
        createDetails(insightAccountDetails)
    }, [mainData]); // Re-run when mainData changes


    const createDetails = (data) => {
        const { mobile } = mainData || {};
        setLoading(true);
        const formatText = (text) => {
            return text
                .toLowerCase() // Convert to lowercase
                .replace(/[_\.]+/g, " ") // Replace underscores and dots with spaces
                .split(" ") // Split into words
                .map(word => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize first letter
                .join(" "); // Join words back into a sentence
        };
        const shortenText = (text) => {
            if (text.length <= 10) return text; // If length is 10 or less, return as is
            const lastSix = text.slice(-4); // Get last 6 characters
            const hiddenPart = "X".repeat(6); // Replace the first part with 'X'
            return hiddenPart + lastSix;
        };
        let res = data.map((item) => ({
            data: item,
            mobileNumber: mobile,
            id: item.id,
            isAccountBad: item?.isAccountBad,
            bankName: item?.subscriberName || "-",
            loanType: formatText(item?.loanCategory) || "-",
            fields: [
                { label: "Account No", value: item?.accountNumber || "-" },
                { label: "On-Time Payments", value: `${item?.onTimePayments}/${item?.totalPayments} ` || "0/0" },
                { label: "Status", value: formatText(item?.status) || "-" }
            ],
            dateReported: item?.dateReported || "0",
            paymentPercentage: `${item?.onTimePayments / (item?.totalPayments)}`,
            accountType: checkSubType(item),
            status: formatText(item?.status),
            details: {
                delinquencyData: item?.delinquencyData || {}
            }
        }))
        let finalRes = reorderArray(res)
        setDetails(finalRes);
        setLoading(false);
    }


    return (
        <>
            <div className="generic-payment-sec-main">
                <GenericTopSection
                    insightType={insightType}
                    sectionType={"type_payment"}
                    mainBlockWidth={mainBlockWidth}
                    isLoading={isLoading}
                    data={{
                        first_line: 100,
                        a: calculatePercentage(mainData?.latePayments, (mainData?.totalPayments === 0 ? 1 : mainData?.totalPayments)),
                        b: calculatePercentage(mainData?.onTimePayments === 0 ? 1 : mainData?.onTimePayments, (mainData?.totalPayments === 0 ? 1 : mainData?.totalPayments)),
                    }}
                />
                {loading ? (
                    <p>Loading...</p>
                ) :
                    <GeneralYourAccounts
                        type={"type_payment"}
                        mainLoading={isLoading}
                        accounts={details}
                    />
                }

            </div>
        </>
    );
};

const CreditUtilisationSection = ({ mainData, mainBlockWidth, isLoading }) => {
    const [insightType, setInsightType] = useState("type_perfect");
    const [loading, setLoading] = useState(true);
    const [details, setDetails] = useState([]);
    const calculateInsightType = () => {
        const { limitUtilized = 0, creditLimit = 1 } = mainData;
        const keyPer = (limitUtilized / creditLimit) * 100;
        if (keyPer < 30) return "type_perfect";
        if (keyPer < 70) return "type_high";
        return "type_extreme_high";
    };

    const checkSubType = (data) => {
        const { limitUtilized = 0, creditLimit = 1 } = data;
        const keyPer = (limitUtilized / creditLimit) * 100;
        if (keyPer < 30) return "type_perfect";
        if (keyPer < 70) return "type_high";
        return "type_extreme_high";
    };

    useEffect(() => {
        const newInsightType = calculateInsightType();
        if (newInsightType !== insightType) {
            setInsightType(newInsightType);
        }
    }, [mainData, insightType]);


    useEffect(() => {
        const { insightAccountDetails = [] } = mainData;
        createDetails(insightAccountDetails)
    }, [mainData]); // Re-run when mainData changes


    const createDetails = (data) => {
        const { mobile } = mainData || {};
        setLoading(true);
        const formatText = (text) => {
            return text
                .toLowerCase() // Convert to lowercase
                .replace(/[_\.]+/g, " ") // Replace underscores and dots with spaces
                .split(" ") // Split into words
                .map(word => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize first letter
                .join(" "); // Join words back into a sentence
        };
        let res = data
            .filter((item) => item?.loanType === "CREDIT_CARD")
            .map((item) => ({
                data: item,
                id: item.id,
                mobileNumber: mobile,
                isAccountBad: item?.isAccountBad,
                bankName: item?.subscriberName || "-",
                loanType: formatText(item?.loanCategory) || "-",
                fields: [
                    { label: "Account No", value: item?.accountNumber || "-" },
                    { label: "Limit Used", value: `${(item?.limitUtilizedPercentage * 100).toFixed(2) + "%" || "0"}` },
                    { label: "Status", value: formatText(item?.status) || "-" }
                ],
                dateReported: item?.dateReported || "-",
                accountType: checkSubType(item),
                status: formatText(item?.status) || "-",
                limitUtilized: item?.limitUtilizedPercentage * 100 || "0",
                details: {
                    limitUtilized: item?.limitUtilized.toLocaleString("en-IN") || "0",
                    creditLimit: item?.creditLimit.toLocaleString("en-IN") || "0",
                }
            }));
        let finalRes = reorderArray(res)
        setDetails(finalRes);
        setLoading(false);
    }
    if (details.length < 1) {
        return (<>
            <p className='if-credit-card-does-not-exit'>You do not have any active credit cards.</p>
        </>)
    } else {
        return (
            <div className="generic-payment-sec-main">
                <GenericTopSection
                    insightType={insightType}
                    sectionType="type_credit_utilisation"
                    mainBlockWidth={mainBlockWidth}
                    isLoading={isLoading}
                    data={{
                        first_line: 100,
                        a: mainData?.limitUtilized.toLocaleString("en-IN"),
                        b: mainData?.creditLimit.toLocaleString("en-IN"),
                    }}
                />
                <GeneralYourAccounts
                    type={"type_credit_utilisation"}
                    mainLoading={isLoading}
                    accounts={details}
                />
            </div>
        );
    }

};

const AccountAgeSection = ({ mainData, mainBlockWidth, isLoading }) => {
    const [insightType, setInsightType] = useState("type_perfect")
    const [loading, setLoading] = useState(true);
    const [details, setDetails] = useState([]);
    const checkType = () => {
        const { age } = mainData || {};
        if (age < 12) {
            setInsightType("type_poor");
            return;
        } else if (age >= 12 && age < 24) {
            setInsightType("type_fair");
        } else if (age >= 24 && age < 60) {
            setInsightType("type_good");
        } else if (age >= 60) {
            setInsightType("type_perfect");
        }
    };
    const checkSubType = (data) => {
        const { age } = data || {};
        if (age < 12) {
            return "type_poor";
        }
        if (age >= 12 && age < 24) {
            return "type_fair";
        } else if (age >= 24 && age < 60) {
            return "type_good";
        } else if (age >= 60) {
            return "type_perfect";
        }
    };

    useEffect(() => {
        checkType();
        const { insightAccountDetails = [] } = mainData;
        createDetails(insightAccountDetails)
    }, [mainData]); // Re-run when mainData changes


    const convertMonthsToYears = (months) => {
        const years = Math.floor(months / 12);
        const remainingMonths = months % 12;
        return `${years}Y ${remainingMonths > 0 ? `& ${remainingMonths}M` : ""}`;
    };

    const createDetails = (data) => {
        const { mobile } = mainData || {};
        setLoading(true);
        const formatText = (text) => {
            return text
                .toLowerCase() // Convert to lowercase
                .replace(/[_\.]+/g, " ") // Replace underscores and dots with spaces
                .split(" ") // Split into words
                .map(word => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize first letter
                .join(" "); // Join words back into a sentence
        };
        const shortenText = (text) => {
            if (text.length <= 10) return text; // If length is 10 or less, return as is
            const lastSix = text.slice(-4); // Get last 6 characters
            const hiddenPart = "X".repeat(6); // Replace the first part with 'X'
            return hiddenPart + lastSix;
        };
        let res = data.map((item) => ({
            data: item,
            id: item.id,
            mobileNumber: mobile,
            isAccountBad: item?.isAccountBad,
            bankName: item?.subscriberName || "-",
            loanType: formatText(item?.loanCategory) || "-",
            fields: [
                { label: "Account No", value: item?.accountNumber || "-" },
                { label: "Age of account", value: `${convertMonthsToYears(item?.age) || "-"}` },
                { label: "Status", value: formatText(item?.status) || "-" }
            ],
            dateReported: item?.dateReported || "-",
            status: formatText(item?.status) || "-",
            accountType: checkSubType(item),
            details: {}
        }))
        let finalRes = reorderArray(res)
        setDetails(finalRes);
        setLoading(false);
    }
    return (<>
        <div className='generic-payment-sec-main'>
            <GenericTopSection
                insightType={insightType}
                sectionType={"type_account_age"}
                mainBlockWidth={mainBlockWidth}
                isLoading={isLoading}
                data={
                    {
                        first_line: 100,
                        oldest_account_age: convertMonthsToYears(mainData?.age) || "-",
                        a: convertMonthsToYears(mainData?.age) || "-",
                        b: mainData?.activeAccounts || "0",
                    }
                }
            />
            <GeneralYourAccounts
                type={"type_account_age"}
                mainLoading={isLoading}
                accounts={details}
            />
        </div>
    </>)
}
const AccountsSection = ({ mainData, mainBlockWidth, isLoading }) => {
    const [insightType, setInsightType] = useState("type_perfect");
    const [accountType, setAccountType] = useState("type_a")
    const [loading, setLoading] = useState(true);
    const [details, setDetails] = useState([]);
    const { activeAccounts,
        closedAccounts,
        activeLoanAccounts,
        activeSecureLoanAccounts,
        activeUnsecureLoanAccounts,
        activeCreditCards
    } = mainData || {};
    const checkType = () => {
        if (
            activeSecureLoanAccounts >= 1 &&
            activeUnsecureLoanAccounts >= 1 &&
            activeCreditCards >= 1
        ) {
            setInsightType("type_perfect");
        } else if (
            (
                activeSecureLoanAccounts >= 1 &&
                activeUnsecureLoanAccounts >= 1
            )
            ||
            (
                activeSecureLoanAccounts >= 1 &&
                activeCreditCards >= 1)
            ||
            (
                activeCreditCards >= 1 &&
                activeUnsecureLoanAccounts >= 1)
        ) {
            setInsightType("type_good");
        } else if (
            activeSecureLoanAccounts >= 1
        ) {
            setInsightType("type_fair");
        } else if (activeUnsecureLoanAccounts >= 1 || activeCreditCards >= 1 || closedAccounts >= 1) {
            setInsightType("type_poor");
        }
    };

    const checkSubType = (data) => {
        const { age } = data || {};
        if (age < 12) {
            return "type_poor";
        }
        if (age >= 12 && age < 24) {
            return "type_fair";
        } else if (age >= 24 && age < 60) {
            return "type_good";
        } else if (age >= 60) {
            return "type_perfect";
        }
    };
    const checkAccountType = () => {
        if (activeSecureLoanAccounts >= 1 && activeUnsecureLoanAccounts >= 1) {
            setAccountType("type_a")
        } else if (activeCreditCards >= 1 && activeUnsecureLoanAccounts >= 1) {
            setAccountType("type_b")
        } else if (activeSecureLoanAccounts >= 1 && activeCreditCards >= 1) {
            setAccountType("type_c")
        }
    }

    useEffect(() => {
        checkType();
        checkAccountType();
        const { insightAccountDetails = [] } = mainData;
        createDetails(insightAccountDetails)
    }, [mainData]); // Re-run when mainData changes

    const convertMonthsToYears = (months) => {
        const years = Math.floor(months / 12);
        const remainingMonths = months % 12;
        return `${years}Y ${remainingMonths > 0 ? `& ${remainingMonths}M` : ""}`;
    };
    const convertDateToFormat = (timestamp) => {
        const date = new Date(timestamp);
        const options = { day: '2-digit', month: 'short', year: 'numeric' };
        return date.toLocaleDateString('en-GB', options);
    }

    const createDetails = (data) => {
        const { mobile } = mainData || {};
        setLoading(true);
        const formatText = (text) => {
            return text
                .toLowerCase() // Convert to lowercase
                .replace(/[_\.]+/g, " ") // Replace underscores and dots with spaces
                .split(" ") // Split into words
                .map(word => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize first letter
                .join(" "); // Join words back into a sentence
        };
        const shortenText = (text) => {
            if (text.length <= 10) return text; // If length is 10 or less, return as is
            const lastSix = text.slice(-4); // Get last 6 characters
            const hiddenPart = "X".repeat(6); // Replace the first part with 'X'
            return hiddenPart + lastSix;
        };
        let res = data.map((item) => ({
            data: item,
            id: item.id,
            mobileNumber: mobile,
            isAccountBad: item?.isAccountBad,
            bankName: item?.subscriberName || "-",
            loanType: formatText(item?.loanCategory) || "-",
            fields: [
                { label: "Account No", value: item?.accountNumber || "--" },
                { label: "Account Type", value: `${formatText(item?.loanType) || "-"}` },
                { label: "Status", value: formatText(item?.status) || "-" }
            ],
            dateReported: item?.dateReported || "-",
            status: formatText(item?.status) || "-",
            accountType: checkSubType(item),
            details: {
                elements: item?.loanCategory === "CREDIT_CARD" ?
                    [
                        { label: "Account Open Date", value: convertDateToFormat(item?.openDate) || "-" },
                        { label: "Limit Utilised", value: `₹ ${item?.limitUtilized ? item?.limitUtilized.toLocaleString() : "-"}` },
                        { label: "Credit Limit", value: `₹ ${item?.creditLimit ? item?.creditLimit.toLocaleString() : "-"}` },
                    ] :
                    [
                        { label: "Outstanding Amount", value: `₹ ${(item?.outstandingLoanAmount ? item?.outstandingLoanAmount.toLocaleString() : "-")}` },
                        { label: "Loan Amount", value: `₹ ${item?.totalLoanAmount ? item?.totalLoanAmount.toLocaleString() : "-"}` },
                        { label: "Account Open Date", value: convertDateToFormat(item?.openDate) || "-" },
                        { label: "ROI", value: item?.rateOfInterest ? item?.rateOfInterest + "%" : "-" },
                        { label: "Tenure", value: convertMonthsToYears(item?.tenure) || "-" },
                    ]
            }
        }))
        let finalRes = reorderArray(res)
        setDetails(finalRes);
        setLoading(false);
    }
    return (<>
        <div className='generic-payment-sec-main'>
            <GenericTopSection
                insightType={insightType}
                sectionType={"type_accounts"}
                mainBlockWidth={mainBlockWidth}
                isLoading={isLoading}
                data={
                    {
                        first_line: 100,
                        noOfAccounts: activeAccounts || "0",
                        type: accountType || "-",
                        a: activeAccounts || "0",
                        b: closedAccounts || "0",
                    }
                }
            />
            <GeneralYourAccounts
                type={"type_accounts"}
                mainLoading={isLoading}
                accounts={details}
            />
        </div>
    </>)
}
const EnquiriesSection = ({ mainData, mainBlockWidth, isLoading }) => {
    const [insightType, setInsightType] = useState("type_perfect");
    const [loading, setLoading] = useState(true);
    const [details, setDetails] = useState([]);
    const {
        loanEnquiries,
        cardEnquiries
    } = mainData || {};
    const checkType = () => {
        let sum = loanEnquiries + cardEnquiries;
        if (sum >= 0 && sum < 2) {
            setInsightType("type_perfect")
        } else if (sum >= 2 && sum < 5) {
            setInsightType("type_good")
        } else if (sum >= 5 && sum <= 6) {
            setInsightType("type_fair")
        } else if (sum > 6) {
            setInsightType("type_poor")
        }
    };

    useEffect(() => {
        checkType();
        const { bureauInquiries = [] } = mainData;
        createDetails(bureauInquiries)
    }, [mainData]); // Re-run when mainData changes
    const convertDateToFormat = (timestamp) => {
        const date = new Date(timestamp);
        const options = { day: '2-digit', month: 'short', year: 'numeric' };
        return date.toLocaleDateString('en-GB', options);
    }

    const createDetails = (data) => {
        const { mobile } = mainData || {};
        setLoading(true);
        const formatText = (text) => {
            return text
                .toLowerCase() // Convert to lowercase
                .replace(/[_\.]+/g, " ") // Replace underscores and dots with spaces
                .split(" ") // Split into words
                .map(word => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize first letter
                .join(" "); // Join words back into a sentence
        };
        const shortenText = (text) => {
            if (text.length <= 10) return text; // If length is 10 or less, return as is
            const lastSix = text.slice(-4); // Get last 6 characters
            const hiddenPart = "X".repeat(6); // Replace the first part with 'X'
            return hiddenPart + lastSix;
        };
        let res = data.map((item) => ({
            data: item,
            id: item.id,
            mobileNumber: mobile,
            bankName: item?.subscriberName || "-",
            loanType: formatText(item?.enquiryReason) || "-",
            fields: [
                { label: "Enquiry Date", value: convertDateToFormat(item?.inquiryDate) || "-" },
                { label: "Product Type", value: `${formatText(item?.enquiryReason) || "-"}` },
                { label: "Amount", value: `₹ ${item?.amount || "-"} ` }
            ],
            status: formatText(item?.status),
            details: {}
        }))
        setDetails(res);
        setLoading(false);
    }
    return (<>
        <div className='generic-payment-sec-main'>
            <GenericTopSection
                insightType={insightType}
                sectionType={"type_enquiries"}
                mainBlockWidth={mainBlockWidth}
                isLoading={isLoading}
                data={
                    {
                        first_line: 100,
                        a: mainData?.loanEnquiries ?? "0",
                        b: mainData?.cardEnquiries || "0",
                    }
                }
            />
            <GeneralYourAccounts
                type={"type_enquiries"}
                accounts={details}
                mainLoading={isLoading}
            />
        </div>
    </>)
}

const RenderBlock = ({ mainBlockWidth = 300, isLoading, mainData }) => {
    const [activeTab, setActiveTab] = useState(0);
    const [activeTabBody, setActiveTabBody] = useState("payments");
    const [blockWidth, setBlockWidth] = useState(300);
    const isOpen = useSelector((state) => state.sidebar.isOpen);
    const isHalfOpen = useSelector((state) => state.sidebar.isHalfOpen);

    const tabs = [
        { key: "payments", label: "Payments", value: 294, icon: <IoMdTime /> },
        { key: "credit_utilisation", label: "Credit Utilisation", value: 234, icon: <VscGraphLine /> },
        { key: "account_age", label: "Account Age", value: 233, icon: <LuUsers /> },
        { key: "accounts", label: "Credit Mix", value: 948, icon: <MdOutlineSwitchAccount /> },
        { key: "enquiries", label: "Enquiries", value: 294, icon: <PiWarningCircleLight /> },
    ];
    const tabRefs = useRef([]);

    const handleTabClick = (index, type) => {
        setActiveTabBody(type);
        setActiveTab(index);
        const container = document.querySelector(".tab-navigation"); // Adjust selector to your navigation container
        const targetTab = tabRefs.current[index];

        if (container && targetTab) {
            const containerRect = container.getBoundingClientRect();
            const targetRect = targetTab.getBoundingClientRect();
            const offsetLeft = targetRect.left - containerRect.left + container.scrollLeft;

            container.scrollTo({
                left: offsetLeft,
                behavior: "smooth",
            });
        }
    };
    useEffect(() => {
        if (mainBlockWidth >= 1200) {
            setBlockWidth("auto")
        } else if (mainBlockWidth < 1200 && mainBlockWidth >= 978) {
            setBlockWidth(878)
        } else {
            setBlockWidth(mainBlockWidth - 98)
        }
    }, [mainBlockWidth])


    const GenericInsightBody = (type) => {
        switch (type) {
            case "payments":
                return <PaymentSection mainData={mainData} mainBlockWidth={mainBlockWidth} isLoading={isLoading} />;
            case "credit_utilisation":
                return <CreditUtilisationSection mainData={mainData} mainBlockWidth={mainBlockWidth} isLoading={isLoading} />;
            case "account_age":
                return <AccountAgeSection mainData={mainData} mainBlockWidth={mainBlockWidth} isLoading={isLoading} />;
            case "accounts":
                return <AccountsSection mainData={mainData} mainBlockWidth={mainBlockWidth} isLoading={isLoading} />;
            case "enquiries":
                return <EnquiriesSection mainData={mainData} mainBlockWidth={mainBlockWidth} isLoading={isLoading} />;
            default:
                return <div>Not Found</div>;
        }
    }
    const formatString = (input) => {
        return input
            .toLowerCase() // Convert to lowercase for consistency
            .split('_') // Split by underscore
            .map(word => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize each word
            .join(' '); // Join words with a space
    }
    if (isLoading) {
        return (
            <>
                <div className="tab-navigation-container">
                    <div className="tab-navigation">
                        <ul style={{ width: blockWidth }}>
                            {tabs.map((tab, index) => (
                                <li
                                    key={index}
                                    ref={(el) => (tabRefs.current[index] = el)}
                                    className={`tab-item ${activeTab === index ? "skeleton-generic-active" : "skeleton-generic"}`}
                                    onClick={() => handleTabClick(index, tab.key)}
                                    style={{ height: 36, width: 160 }}
                                >

                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
                {GenericInsightBody(activeTabBody)}
            </>
        );
    } else {
        return (
            <>
                <div className="tab-navigation-container">
                    <div className="tab-navigation">
                        <ul style={{ width: 780 }}>
                            {tabs.map((tab, index) => (
                                <li
                                    key={index}
                                    ref={(el) => (tabRefs.current[index] = el)}
                                    className={`tab-item ${activeTab === index ? "active-tab" : ""}`}
                                    onClick={() => handleTabClick(index, tab.key)}
                                >
                                    <span className="tab-icon">{tab.icon}</span>
                                    <span className="tab-label">{tab.label}</span>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
                {GenericInsightBody(activeTabBody)}
            </>
        );
    }

};


const LandingDashboard = () => {
    const mainBlockRef = useRef(null);
    const [blockWidth, setBlockWidth] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [mainData, setMainData] = useState({})
    const [badLoanCount, setBadLoanCount] = useState(0)
    const [error, setError] = useState({
        hasError: false,
        errorMessage: "",
        errorType: "error",
    })
    const navigate = useNavigate();
    useEffect(() => {
        const handlePopState = (event) => {
            event.preventDefault();
            navigate("/home/dashboard");
        };
        // Push a new entry to the history stack
        window.history.pushState(null, null, window.location.href);

        // Add event listener for the 'popstate' event
        window.addEventListener("popstate", handlePopState);

        return () => {
            // Clean up the event listener when the component unmounts
            window.removeEventListener("popstate", handlePopState);
        };
    }, [navigate]);

    useEffect(() => {
        const userConfirms = "loginConfirm";
        localStorage.setItem("loginConfirm", userConfirms);
        const updateWidth = () => {
            if (mainBlockRef.current) {
                setBlockWidth(mainBlockRef.current.offsetWidth);
            }
        };
        const resizeObserver = new ResizeObserver(() => {
            updateWidth();
        });
        if (mainBlockRef.current) {
            resizeObserver.observe(mainBlockRef.current);
            updateWidth(); // Initial width check
        }

        // Cleanup observer on unmount
        return () => {
            if (mainBlockRef.current) {
                resizeObserver.unobserve(mainBlockRef.current);
            }
        };
    }, []);
    const CalculateBadLoans = () => {
        const badLoans = mainData?.insightAccountDetails?.filter((it) => it.isAccountBad) || []
        setBadLoanCount(badLoans.length)// Update bad loan count
        setIsLoading(false);
    }
    useEffect(() => {
        if (mainData?.insightAccountDetails) {
            CalculateBadLoans();
        }
    }, [mainData]);
    useEffect(() => {
        setIsLoading(true);
        postGenericInsights("", (result) => {
            if (result && result.data.success) {
                setMainData(result.data.response)
            } else {
                setError({
                    hasError: true,
                    errorMessage: result.data.message + " , logout in few seconds",
                    errorType: "error",
                })
                setTimeout(() => {
                    window.location.href = '/'
                    localStorage.clear()
                }, 2500);
            }
        })
    }, [])
    return (
        <>
            {
                error.hasError
                    ?
                    <div className='custom-alert-box'>{error.errorMessage}</div>
                    :
                    null
            }
            <div ref={mainBlockRef} className="landing-page-main">

                {
                    blockWidth > 900 ? <>
                        <GreetBlock isLoading={isLoading} mainData={mainData} />
                        <CommonLandScreen badLoanCount={badLoanCount} isLoading={isLoading} mainData={mainData} mainBlockWidth={blockWidth} />
                        <div className='render-block-main'>
                            <RenderBlock mainData={mainData} mainBlockWidth={blockWidth} isLoading={isLoading} />
                        </div>
                    </> :
                        <MobileLandingDashboard badLoanCount={badLoanCount} mainData={mainData} mainBlockWidth={blockWidth} isLoading={isLoading} />
                }
            </div>
        </>
    );
};

export default LandingDashboard