import { API, PATHS } from "./ApiSupport";

export function GET_INSIGHTS(params, cb) {

  const payload = {};
  API.POST(PATHS.GET_INSIGHTS, payload, params, (res, err) => {
    if (err) {
      cb(null, err);
    } else {
      cb(res, null);
    }
  });
}
export function USER_REPORT(cb) {
  API.GET(PATHS.USER_GET_REPORT, {}, {}, function (res) {
    cb(res);
  });

}
export function GENERATEOTP(id, cb) {
  API.POST(PATHS.GET_OTP(id), {}, {}, function (res) {
    cb(res);
  });
}
export function USER_TRACK(id, cb) {
  API.GET(PATHS.GET_USER_TRACK(id), {}, {}, function (res) {
    cb(res);
  });
}
export function contactUsApiData(params, cb) {
  API.POST(PATHS.POST_CONTACT_MSG, params, {}, (res, err) => {
    if (err) {
      cb(null, err);
    } else {
      cb(res, null);
    }
  });
}
export function POST_REVOKECONSENT(params, cb) {
  const payload = {};
  API.POST(PATHS.POST_REVOKECONSENT, payload, params, (res, err) => {
    if (err) {
      cb(null, err);
    } else {
      cb(res, null);
    }
  });
}
export function AUTHTOKEN(params, cb) {
  API.POST(PATHS.POST_AUTH_TOKEN, params, {}, (res, err) => {
    if (err) {
      cb(null, err);
    } else {
      cb(res, null);
    }
  });
}
export function saveUserDataApi(params, cb) {
  const payload = {};
  API.POST(PATHS.POST_SAVE_USER_TRACK, payload, params, (res, err) => {
    if (err) {
      cb(null, err);
    } else {
      cb(res, null);
    }
  });
}
export function callBackApi(params, cb) {
  API.POST(PATHS.POST_CALL_ME_BACK, params, {}, (res, err) => {
    if (err) {
      cb(null, err);
    } else {
      cb(res, null);
    }
  });
}
export function getPaymentlinkdata(params, cb) {
  API.POST(PATHS.POST_GETING_PAYMENT_LINK, params, {}, (res, err) => {
    if (err) {
      cb(null, err);
    } else {
      cb(res, null);
    }
  });
}
export function counterOffterData(params, cb) {
  API.POST(PATHS.POST_COUNTER_OFFER, params, {}, (res, err) => {
    if (err) {
      cb(null, err);
    } else {
      cb(res, null);
    }
  });
}
export function settlementListingApiData(params, cb) {
  API.POST(PATHS.POST_SETTLEMENT_LISTING, params, {}, (res, err) => {
    if (err) {
      cb(null, err);
    } else {
      cb(res, null);
    }
  });
}
export function profileApiData(params, cb) {
  API.GET(PATHS.GET_PROFILE_DETAIL, params, {}, (res, err) => {
    if (err) {
      cb(null, err);
    } else {
      cb(res, null);
    }
  });

}
export function fetchReportData(params, cb) {
  API.POST(PATHS.POST_ENHANCED_FETCH_REPORT, params, {}, (res, err) => {
    if (err) {
      cb(null, err);
    } else {
      cb(res, null);
    }
  });

}
export function indianStateApiData(params, cb) {
  API.GET(PATHS.GET_GET_STATTE, params, {}, (res, err) => {
    if (err) {
      cb(null, err);
    } else {
      cb(res, null);
    }
  });
}
export function tdfFetchReport(params, cb) {
  API.POST(PATHS.POST_FETCH_REPORT, params, {}, (res, err) => {
    if (err) {
      cb(null, err);
    } else {
      cb(res, null);
    }
  });
}

export function postUserProfile(params, cb) {
  API.POST(PATHS.POST_USER_PROFILE, params, {}, (res, err) => {
    if (err) {
      cb(null, err);
    } else {
      cb(res, null);
    }
  });
}
export function postCreditOtp(params, cb) {
  API.POST(PATHS.POST_VALIDATE_CREDIT_OTP, params, {}, (res, err) => {
    if (err) {
      cb(null, err);
    } else {
      cb(res, null);
    }
  });


}


// generic insights 
export function postGenericInsights(mob, cb) {
  API.POST(PATHS.POST_GENERAL_INSIGHTS(mob), {}, {}, (res) => {
    if (res) {
      cb(res);
    } else {
      cb(null);
    }
  });
}

// call settle post api

export function postCallSettleDetails(params, cb) {
  API.POST(PATHS.POST_CALL_SETTLE_DETAILS, params, {}, (res) => {
    if (res) { cb(res); } else { cb(null); }
  });
}

export function postSaveUserAction(params, cb) {
  API.POST(PATHS.POST_USER_SAVE_ACTION, params, {}, (res) => {
    if (res) { cb(res); } else { cb(null); }
  });
}