import { toast } from "react-toastify";

export const generalSwitch = (status, navigate) => {
  switch (status) {
    case 400:
      toast.error("Bad Request. Please check your input.");
      break;
    case 401:
    case 419:
      toast.error("Session expired. Please login again.");
      navigate("/login");
      localStorage.clear();
      break;
    case 403:
      toast.error("Forbidden. You do not have access.");
      break;
    case 404:
      toast.error("Resource not found.");
      break;
    case 500:
      toast.error("Server error. Please try again later.");
      break;
    default:
      toast.error("An unexpected error occurred.");
      break;
  }
};
