import React, { useEffect, useState } from "react";
import "./style.css";
import { IoClose } from "react-icons/io5";
import { callBackApi, postSaveUserAction } from "../../../service/ApiList";
import CircularProgress from "@mui/material/CircularProgress";

function CallMeBackAction({ handleCloseModal, data }) {
 
  const [loading, setLoading] = useState(false);
  const [countdown, setCountdown] = useState(5); // Countdown starts from 5

  useEffect(() => {
    const { id,  } = data;
    setLoading(true);
    callBackApi({ accountDetailsId: id }, (res, err) => {
      if (res && res?.data?.msg === "Your request is accepted.") {
        setLoading(false);
        startCountdown();
      } else {
        setLoading(false);
        startCountdown();
      }
    });
  }, [data]);

  // Function to start countdown and close modal after 5s
  const startCountdown = () => {
    let timeLeft = 5;
    setCountdown(timeLeft);

    const interval = setInterval(() => {
      timeLeft -= 1;
      setCountdown(timeLeft);

      if (timeLeft === 0) {
        clearInterval(interval);
        handleCloseModal(); // Close modal after 5s
      }
    }, 1000);
  };

  const handleClose = () => {
    handleCloseModal();
  };
  useEffect(() => {
    console.log(data)
    const { id, bankName } = data;
    let params = {
      accountDetailsId: id,
      bankName: bankName,
      userActionType: "CALL_ME_BACK"
    }
    postSaveUserAction(params, (res) => {
      if (res) {
        console.log(res)
      } else {
        console.log("err=" + res)
      }
    })
  }, [data])

  return (
    <div className="call-me-back-main">
      {loading ? (
        <CircularProgress />
      ) : (
        <>
          <p>Your Response has been recorded</p>
          <p>Closing in ({countdown}s)...</p> {/* Countdown Display */}
          <button onClick={handleClose}>Close <IoClose /></button>
        </>
      )}
    </div>
  );
}

export default CallMeBackAction;
