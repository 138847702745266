import React, { useState } from "react";
import "./style2.css";
import CreditMixlayout from "../../Dashboard/CreditMix";

function CreditMixScreen({ insightData ,mainBlockWidth}) {

  const [activeTab, setActiveTab] = useState("All");
  const handleSwitch = (tab) => {
    setActiveTab(tab);
  };
  const getLeftPosition = () => {
    switch (activeTab) {
      case "All":
        return "1%";
      case "Good":
        return "33.33%";
      case "Bad":
        return "65.66%";
      default:
        return "0%";
    }
  };
  const renderContent = () => {
    switch (activeTab) {
      case "All":
        return <CreditMixlayout creditMixdata={insightData?.insightCreditMix?.ALL} mainBlockWidth={mainBlockWidth} />;
      case "Good":
        return insightData?.insightCreditMix?.GOOD?.totalAccounts >= 1 ? (
          <>
            <CreditMixlayout creditMixdata={insightData?.insightCreditMix?.GOOD}  mainBlockWidth={mainBlockWidth}  />
          </>
        ) : (
          <>
            <div style={{ height: "50vh" }}>
              <div
                style={{
                  height: "210px",
                  background: "#ECF0F6",
                  borderRadius: "24px",
                  padding: "10px 20px",
                  margin: "10px",
                }}
              >
                <p className="bad-account-msg">
                  😞 “It looks like there are some challenges with your current
                  loans. But remember, it’s never too late to make a positive
                  change. We’re here to support you on your path to financial
                  stability.”
                </p>
              </div>
            </div>
          </>
        );
      case "Bad":
        return insightData?.insightCreditMix?.BAD?.totalAccounts >= 1 ? (
          <>
            <CreditMixlayout creditMixdata={insightData?.insightCreditMix?.BAD}  mainBlockWidth={mainBlockWidth} />
          </>
        ) : (
          <>
            <div style={{ height: "50vh" }}>
              <div
                style={{
                  height: "210px",
                  background: "#ECF0F6",
                  borderRadius: "24px",
                  padding: "10px 20px",
                  margin: "10px",
                }}
              >
                <p className="bad-account-msg">
                  🌟 “Impressive! Zero bad loans on your record—your financial
                  game is on point! Keep it going strong!”
                </p>
              </div>
            </div>
          </>
        );

      default:
        return null;
    }
  };
  return (
    <div className="first-div-credit-mix">
      <div className="div-of-credit-mix-1">
        <div>
          {/* buttons start */}
          <div className="credit-mix-button-div">
            <div className="switch-container">
              <div
                className="switch-background"
                style={{ left: getLeftPosition() }}
              ></div>
              <div
                className={`switch-btn ${activeTab === "All" ? "active" : ""}`}
                onClick={() => handleSwitch("All")}
              >
                {` All Accounts (${
                  insightData?.insightCreditMix?.ALL?.totalAccounts || 0
                })`}
              </div>
              <div
                className={`switch-btn ${activeTab === "Good" ? "active" : ""}`}
                onClick={() => handleSwitch("Good")}
              >
                {`Good Accounts (${
                  insightData?.insightCreditMix?.GOOD?.totalAccounts || 0
                })`}
              </div>
              <div
                className={`switch-btn ${activeTab === "Bad" ? "active" : ""}`}
                onClick={() => handleSwitch("Bad")}
              >
                {`Bad Accounts (${
                  insightData?.insightCreditMix?.BAD?.totalAccounts || 0
                })`}
              </div>
            </div>
          </div>
          {/* buttons end */}

          {/* charts start */}
          <div className="credit-mix-charts-grid">          
          </div>
          {/* charts end */}
          <div>{renderContent()}</div>
        </div>
      </div>
    </div>
  );
}

export default CreditMixScreen;
