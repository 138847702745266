import React, { useEffect, useState } from "react";
import pnb from "../../src/assets/imgs/pnbLogo.png";
import STloan from "./../assets/LoanType/shortTerm.png";
// import loanimg from "./../assets/LoanType/loan.png";
import CONSUMER from "./../assets/LoanType/CONSUMER LOAN.png";
import CREDITCARD from "./../assets/LoanType/CREDITCARD.png";
import SecuredCreditCard from "./../assets/LoanType/SecuredCreditCard.png";

import Aditiya from "./../assets/Bankimgs/AditiyaB.png";
import IDFC from "./../assets/Bankimgs/IDFC.png";
import bankLogo from "./../assets/Bankimgs/bank1.png";
import AMEX from "./../assets/Bankimgs/AME.png";
import icici from "./../assets/Bankimgs/icici.png";
import union from "./../assets/Bankimgs/union.png";

import navi from "./../assets/Bankimgs/navi.png";
import NDXP from "./../assets/Bankimgs/NDXP.png";
import Nort from "./../assets/Bankimgs/Nort.png";
import mPocket from "./../assets/Bankimgs/mPocket.webp";

import SBM from "./../assets/Bankimgs/SBM.png";
import hdfc from "./../assets/Bankimgs/hdfcb.ico";
import fedral from "./../assets/Bankimgs/fedral.jpg";
import qua from "./../assets/Bankimgs/qua.jpg";
import sbi from "./../assets/Bankimgs/SBI.webp";
import sbiCard from "./../assets/Bankimgs/sbicard.png";
import kvb from "./../assets/Bankimgs/kvb.png";
import homeCredit from "./../assets/Bankimgs/home.svg";
import payu from "./../assets/Bankimgs/payu.png";
import fed from "./../assets/Bankimgs/fed.ico";
import RBL from "./../assets/Bankimgs/RBL.ico";
import Kotak from "./../assets/Bankimgs/kotakBank.png";
import trueB from "./../assets/Bankimgs/true.png";
import indus from "./../assets/Bankimgs/indus.webp";
import dhani from "./../assets/Bankimgs/dhani.webp";
import Bajaj from "./../assets/Bankimgs/Bajaj.webp";
import akar from "./../assets/Bankimgs/akar.png";
import carzy from "./../assets/Bankimgs/carzy.ico";
import hero from "./../assets/Bankimgs/hero.ico";
import canara from "./../assets/Bankimgs/canara.png";
import central from "./../assets/Bankimgs/central.ico";
import BOI from "./../assets/Bankimgs/BOI.ico";
import IndianBank from "./../assets/Bankimgs/IndianBank.webp";
import YesBank from "./../assets/Bankimgs/YesBank.ico";
import UCO from "./../assets/Bankimgs/UCO.png";
import BOM from "./../assets/Bankimgs/BOM.png";
import SIB from "./../assets/Bankimgs/SIB.png";
import CUB from "./../assets/Bankimgs/CUB.jpg";
import dhan from "./../assets/Bankimgs/dhan.png";
import JK from "./../assets/Bankimgs/J&K.ico";
import PS from "./../assets/Bankimgs/P&S.ico";
import DBD from "./../assets/Bankimgs/DBD.ico";
import TMBL from "./../assets/Bankimgs/TMBL.png";
import scb from "./../assets/Bankimgs/SCB.png";
import axisBankLogo from "./../assets/Bankimgs/axisBankLogo.png";

import Muthoot from "./../assets/Bankimgs/Muthoot.svg";
import shree from "./../assets/Bankimgs/shree.webp";
import mahindra from "./../assets/Bankimgs/mahindra.webp";
import MuthootFincorp from "./../assets/Bankimgs/MuthootFincorp.webp";
import vivriticapital from "./../assets/Bankimgs/vivriticapital.png";
import generalImg from "./../assets/Bankimgs/bank1.png";
import Tooltip from "@mui/material/Tooltip";

const bankImgs = {
  "Punjab National Bank": pnb,
  "Short Term Personal Loan [Unsecured]": STloan,
  "CONSUMER LOAN": CONSUMER,
  "CREDIT CARD": CREDITCARD,
  "Secured Credit Card": SecuredCreditCard,
  "ICICI Bank": icici,
  "Union Bank of India": union,
  "American Express Banking Corp": AMEX,
  "Aditya Birla Finance ltd": Aditiya,
  "Northern Arc Capital Limited": Nort,
  "Mpokket Financial Services Pvt Ltd": mPocket,
  "NAVI FINSERV LIMITED": navi,
  "IDFC FIRST BANK LIMITED": IDFC,
  "HDFC Bank Ltd": hdfc,
  "Federal Bank": fedral,
  "Axis Bank": axisBankLogo,
  "SBM Bank (Mauritius) Ltd": SBM,
  "QUADRILLION FINANCE PVT LTD": qua,
  "NDX P2P PRIVATE LIMITED": NDXP,
  "State Bank of India": sbi,
  "SBI Cards and Payment Services Private Limited": sbiCard,
  "Karur Vysya Bank Ltd": kvb,
  "Home Credit India Finance Pvt. Ltd": homeCredit,
  "PayU Finance India PVT Ltd": payu,
  "IndusInd Bank Limited": fed,
  "RBL Bank": RBL,
  "Kotak Mahindra Bank Limited": Kotak,
  "True Credits Private Limited": trueB,
  "Indiabulls Housing Finance Limited": indus,
  "Dhani Loans and Services Limited": dhani,
  "Bajaj Finserv": Bajaj,
  "Akara Capital Advisors Private Limited": akar,
  "Krazybee Services Private Limited": carzy,
  "Hero FinCorp Ltd": hero,
  "Canara Bank": canara,

  "Bank of India": BOI,
  "Central Bank of India": central,
  "Indian Bank": IndianBank,
  "Yes Bank": YesBank,
  "UCO Bank": UCO,
  "Bank of Maharashtra": BOM,
  "South Indian Bank": SIB,
  "City Union Bank": CUB,
  "Dhanlaxmi Bank": dhan,
  "Jammu & Kashmir Bank": JK,
  "Punjab & Sind Bank": PS,
  "Lakshmi Vilas Bank": DBD,
  "Tamilnad Mercantile Bank": TMBL,
  "DBS Bank India": DBD,
  "DBS Bank Ltd": DBD,
  "Standard Chartered Bank India": scb,

  "Muthoot Finance Limited": Muthoot,
  "Mahindra and Mahindra Finance ltd": mahindra,
  "Shriram Finance Limited": shree,
  "Muthoot Fincorp ltd": MuthootFincorp,
  "VIVRITI CAPITAL LIMITED": vivriticapital,
  "HDB Financial Services Limited": hdfc,
};

function LoanCard({ loan }) {
  // const loanImageSrc = loanCategoryImages[loan?.loanCategory] || loanimg;

  // const bankImgsrc = bankImgs[loan?.name ? loan?.name : loan?.cardName] || bankLogo;

  // Function to find the matching bank image by partial name
  const findBankImage = (loanName) => {
    for (const [bankName, imgSrc] of Object.entries(bankImgs)) {
      if (loanName?.includes(bankName)) {
        return imgSrc;
      }
    }
    return bankLogo;
  };

  // Get the bank name or card name from the loan object
  const bankName = loan?.name || loan?.cardName;

  // Get the bank image source based on partial match
  const bankImgsrc = findBankImage(bankName);
  const [showTooltip, setShowTooltip] = useState(false);

  useEffect(() => {
    const checkWidth = () => {
      const width = window.innerWidth;
      setShowTooltip(width >= 990 && width <= 1319);
    };

    checkWidth(); // Initial check
    window.addEventListener("resize", checkWidth); // Listen for window resize

    return () => {
      window.removeEventListener("resize", checkWidth);
    };
  }, []);

  const text = loan?.name ? loan.name : loan?.cardName;
  const truncatedText = text?.length > 20 ? `${text.slice(0, 20)}...` : text;

  return (
    <>
      {
        <div className="loancard">
          {/* <div
            className="loan-card-text-div"
          >
            {loan?.loanCategory}
          </div> */}
          <div className="loan-card-text-div">
            {loan?.loanCategory
              ?.toLowerCase()
              .replace(/\b\w/g, (char) => char.toUpperCase())}
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              // alignItems: "center",
              // marginTop:"5px",
              lineHeight: "1rem",
              padding: loan?.status === "ACTIVE" ? "8px 0" : "8px 0",
            }}
          >
            <div style={{ display: "flex", gap: "10px" }}>
              <div>
                <img
                  src={bankImgsrc}
                  width={"20px"}
                  height={"20px"}
                  alt={`icon`}
                  // style={{border:"1px solid #f4efef"}}
                  loading="lazy"
                />
              </div>
              <div>
                <div
                  style={{
                    fontSize: "12px",
                    fontWeight: "500",
                    fontFamily: "inter",
                    color: "#000000C7",
                    textTransform: "capitalize",
                    padding: "0 5px 0 0",
                  }}
                >
                  {/* <span>{loan?.name ? loan?.name : loan?.cardName}</span> */}
                  {/* 990 to 1319*/}
                  {showTooltip ? (
        <Tooltip title={text} arrow>
          <span>{truncatedText}</span>
        </Tooltip>
      ) : (
        <span>{text}</span>
      )}
                </div>
                {loan?.status === "ACTIVE" ? (
                  <>
                    <div
                   
                      className="user-account-credit-insigt-impotant-details"
                    >
                      <div>
                        <div className="user-account-credit-insigt-impotant-details-text-1">
                          {loan?.cardName ? (
                            <>Limit Utilized: </>
                          ) : (
                            <>Outstanding:{"  "}</>
                          )}
                        </div>
                        <div className="user-account-credit-insigt-impotant-details-text-2">Amount Due:{"  "}</div>
                        {loan?.interestRate >= 1 ? (
                          <>
                            <div className="user-account-credit-insigt-impotant-details-text-3">Interest Rate:{"  "}</div>
                          </>
                        ) : (
                          <></>
                        )}
                      </div>

                      <div style={{ marginLeft: "5px" }}>
                        <div
                          style={{
                            color: "#00000091",
                            fontFamily: "inter",
                          }}
                        >
                          {loan?.cardName ? (
                            <>
                              {loan?.limitUtilized
                                ? loan?.limitUtilized?.toLocaleString("en-IN")
                                : 0}
                            </>
                          ) : (
                            <>
                              {loan?.outStandingLoanAmount
                                ? loan?.outStandingLoanAmount?.toLocaleString(
                                    "en-IN"
                                  )
                                : 0}
                            </>
                          )}
                        </div>
                        <div
                          style={{
                            color: "#00000091",
                            fontFamily: "Nunito Sans, sans-serif",
                          }}
                        >
                          {loan?.amountPastDue
                            ? loan?.amountPastDue?.toLocaleString("en-IN")
                            : 0}
                        </div>
                        {loan?.interestRate >= 1 ? (
                          <>
                            <div
                              style={{
                                color: "#00000091",
                                fontFamily: "Nunito Sans, sans-serif",
                              }}
                            >
                              {loan?.interestRate
                                ? `${loan?.interestRate?.toLocaleString(
                                    "en-IN"
                                  )}%`
                                : loan?.interestRate}
                            </div>
                          </>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                  </>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div
              style={{
                display: "flex",
                fontFamily: "Nunito Sans, sans-serif",
                fontSize: "12px",
              }}
            >
              <div
                style={{
                  color: "#00000091",
                  fontFamily: "inter",
                  fontWeight: "400",
                  fontSize: "12px",
                }}
              >
                Status:{" "}
              </div>
              <div
                style={{
                  marginLeft: "5px",
                  fontFamily: "Nunito Sans, sans-serif",
                }}
              >
                {loan?.status === "ACTIVE" ? (
                  <>
                    <span className="account-status-of-creditinsight-2">
                      ACTIVE
                    </span>
                  </>
                ) : loan?.status === "CLOSED" ? (
                  <>
                    <span className="status-of-the-account-loanCard">
                      CLOSED
                    </span>
                  </>
                ) : (
                  <>
                    <span style={{ color: "blue" }}> {loan?.status}</span>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      }
    </>
  );
}
export const BANK_IMG_STACK = (name) => {
  const normalizedInput = name.toLowerCase().trim();

  // Find the first key that includes the user input
  const matchedKey = Object.keys(bankImgs).find((key) =>
    key.toLowerCase().includes(normalizedInput)
  );

  if (matchedKey) {
    return bankImgs[matchedKey];
  } else {
    return generalImg;
  }
};

export default LoanCard;
