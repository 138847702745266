import {React,useState} from "react";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import IconButton from "@mui/material/IconButton";

import { Box, Typography } from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { profileApiData } from "../service/ApiList";

export default function CustomizedInputBase() {
  const [mobile, setMobile] = useState("");
  const navigate = useNavigate();

  const validateIndianMobile = (number) => {
    const indianMobileRegex = /^[6-9]\d{0,9}$/; 
    return indianMobileRegex.test(number);
  };
  
  const gotoLogin = () => {
    const TDFToken = localStorage.getItem("TDFToken");
    if (mobile.length === 10 && validateIndianMobile(mobile)) {    
      const statusLS = localStorage.getItem("StatusLS");
      if (TDFToken && statusLS) {
                  // setLoading(true);
                  profileApiData({}, (res, err) => {
                    if (!res) {                    
                      localStorage.clear("")
                      navigate("/login", { state: { mobile: mobile } });
                      return;
                    }
                    if(typeof res?.data?.status === 'number' && res?.data?.success === false){
                      localStorage.clear("")
                      navigate("/login");
                    }
                   
                    if (res?.status === 200 && typeof res?.data?.status === 'string') {
                      localStorage.setItem("StatusLS", res?.data?.status);
                      const status = res?.data?.status;
                      switch (status) {
                        case "BUREAU_FETCH":
                          navigate("/home/dashboard");
                          break;
            
                        case "ENHANCED_FETCH_REPORT_OTP_VALIDATED":
                        case "NORMAL_FETCH_REPORT_OTP_SENT":
                        case "NORMAL_FETCH_REPORT_OTP_VALIDATED":
                          toast.info("Please complete your profile.");
                          navigate("/login/experianfullform", {
                            state: { userProfileData: res?.data },
                          });
                          break;
            
                        case "ENHANCED_FETCH_REPORT_OTP_SENT":
                        case "REVOKE_CONSENT":
                        case "BUREAU_DELETED":
                        case "OTP_VALIDATED":
                          toast.info("Please complete your profile.");
                          navigate("/login/user", {
                            state: { userProfileData: res?.data },
                          });
                          break;
                        case "":
                          toast.info("Please complete your profile.");
                          navigate("/login/user", {
                            state: { userProfileData: res?.data },
                          });
                          break;
                        case null:
                          toast.info("Please complete your profile.");
                          navigate("/login/user", {
                            state: { userProfileData: res?.data },
                          });
                          break;
            
                        default:
                          toast.error("Something went wrong. Please try again.");
                          break;
                      }
                    } 
                  });
                } else {
                  navigate("/login", { state: { mobile: mobile } });

                }
      
    } else if (TDFToken) {
      profileApiData({}, (res, err) => {
        if (!res) {                    
          localStorage.clear("")
          navigate("/login", { state: { mobile: mobile } });
          return;
        }
        else if(typeof res?.data?.status === 'number' && res?.data?.success === false){
          localStorage.clear("")
          navigate("/login");
        }
       
        else if (res?.status === 200 && (typeof res?.data?.status === 'string' || res?.data?.status === null)) {
          localStorage.setItem("StatusLS", res?.data?.status);
          const status = res?.data?.status;
          switch (status) {
            case "BUREAU_FETCH":
              navigate("/home/dashboard");
              break;

            case "ENHANCED_FETCH_REPORT_OTP_VALIDATED":
            case "NORMAL_FETCH_REPORT_OTP_SENT":
            case "NORMAL_FETCH_REPORT_OTP_VALIDATED":
              toast.info("Please complete your profile.");
              navigate("/login/experianfullform", {
                state: { userProfileData: res?.data },
              });
              break;

            case "ENHANCED_FETCH_REPORT_OTP_SENT":
            case "REVOKE_CONSENT":
            case "BUREAU_DELETED":
            case "OTP_VALIDATED":
              toast.info("Please complete your profile.");
              navigate("/login/user", {
                state: { userProfileData: res?.data },
              });
              break;
            case "":
              toast.info("Please complete your profile.");
              navigate("/login/user", {
                state: { userProfileData: res?.data },
              });
              break;
            case null:
              toast.info("Please complete your profile.");
              navigate("/login/user", {
                state: { userProfileData: res?.data },
              });
              break;

            default:
              toast.error("Something went wrong. Please try again.");
              break;
          }
        } 
        else{
          navigate("/login", {      
            state: { userProfileData: res?.data },
          });
          localStorage.clear("")

        }
      });     
    }else{
      toast.error("Please enter a valid 10-digit phone number.");
    }
  };
  const handleChange = (e) => {
    const value = e.target.value;

    if (validateIndianMobile(value) || value === "" ) {
      setMobile(value);
    } else {
      // toast.error("Invalid input. Please enter a valid Indian mobile number.");
    }
  };
  const handleKeyPress = (e) => { 
      if (e.key === "Enter" && mobile.length === 10 && validateIndianMobile(mobile)) {
      gotoLogin();
    }
  };
  const handleFormSubmit = (e) => {
    e.preventDefault(); // Prevent form submission and page refresh
    gotoLogin();
  };
  


  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        padding: { xs: "10px", sm: "none", lg: "none" },
      }}
    >
      <Paper
        component="form"
        onSubmit={handleFormSubmit}
        sx={{  display: "flex", alignItems: "center", width: 445 ,background:"black",borderRadius:"10px", height:{xs:"50",lg:"73px"}}}
      >
        <InputBase
          sx={{ ml: 3, flex: 1,color:"#fff",fontFamily: "CustomFontMedium",  textAlign: "center",
            "& input::placeholder": {
              textAlign: "start", // Center the placeholder text
            },}}
          placeholder="Enter Mobile Number"
          inputProps={{ "aria-label": "Enter Mobile Number" }}
          value={mobile}
          onChange={handleChange}
          onKeyDown={handleKeyPress}
          type="number"          
          id="phone-input"
        />

        <IconButton
          color="primary"
          sx={{
            p: "10px",
            background: "#044A67 0% 0% no-repeat padding-box",
            color: "#fff",
            borderRadius: "5px",
            margin: "5px",
            "&:hover": {
              background: "#044A67 0% 0% no-repeat padding-box", // Change this to the color you want on hover
              color: "#fff", // Optional: Change text color on hover
            },
            width:{xs:"135px", lg:"197px"},
            height:{xs:"43px",lg:"60px"}
         
          }}
          aria-label="directions"
          onClick={gotoLogin}
     
        >
          <Typography
            sx={{
              display: "inline-block",
              fontFamily: "CustomFontMedium",
              fontSize:{xs:"10px", lg:"18px"} ,
              fontWeight: "bold",
            }}
          >
            Get Started
          </Typography>{" "}
          <ArrowForwardIcon
            sx={{ background: "#19A6B7", fontSize: "25px", marginLeft: "10px",borderRadius:"5px", padding:{xs:"none", lg:"10px"}}}
          />
        </IconButton>
      </Paper>
    </Box>
  );
}
