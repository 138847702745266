import React from "react";
import { Navigate } from "react-router-dom";

const RedirectIfAuthenticated = ({ children }) => {
  const TDFToken = localStorage.getItem("TDFToken");
  // const StatusLS = localStorage.getItem("StatusLS");
  // const isLogined = localStorage.getItem("loginConfirm");
  // if (StatusLS === "BUREAU_FETCH" && isLogined === "loginConfirm" && TDFToken) {
  //   return <Navigate to="/home/dashboard" replace />;
  // }
  if (TDFToken) {
    return <Navigate to="/home/dashboard" replace />;
  }

  return children;
};

export default RedirectIfAuthenticated;
