import React, { useEffect, useRef, useState } from "react";
import DonutChart from "../DashboardComponents/DonutChart";
import "./style2.css";
import AccountChart from "../DashboardComponents/AccountChart";

function NewDesignCards({ creditMixdata,mainBlockWidth }) {
  const [blockWidth, setBlockWidth] = useState(0);
  const mainBlockRef = useRef(null);
  useEffect(() => {
    const updateWidth = () => {
      if (mainBlockRef.current) {
        setBlockWidth(mainBlockRef.current.offsetWidth);
      }
    };
    const resizeObserver = new ResizeObserver(() => {
      updateWidth();
    });
    if (mainBlockRef.current) {
      resizeObserver.observe(mainBlockRef.current);
      updateWidth(); // Initial width check
    }

    // Cleanup observer on unmount
    return () => {
      if (mainBlockRef.current) {
        resizeObserver.unobserve(mainBlockRef.current);
      }
    };
  }, []);
//   useEffect(()=>{
//     setBlockWidth(mainBlockWidth)
//   },[])

  const checkTemplateCol = () => {
    let fullWidth = window.innerWidth
    if(fullWidth <= 784){
   return "repeat(1, 1fr)"
    }
    const zoomLevel = Math.round((window.outerWidth / window.innerWidth) * 100);
    switch (true) {
      case zoomLevel == 125:
      case zoomLevel == 175:
      case zoomLevel == 150:
        return "repeat(2, 1fr)";
      case zoomLevel == 200:
      case zoomLevel == 250:
      case zoomLevel == 300:
      case zoomLevel == 400:
      case zoomLevel == 500:
        return "repeat(1, 1fr)";
      case zoomLevel == 25:
        return "repeat(4, 1fr)";
      case zoomLevel == 33:
        return "repeat(4, 1fr)";
      case zoomLevel == 50:
        return "repeat(4, 1fr)";
      case zoomLevel == 67:
        return "repeat(4, 1fr)";
      case zoomLevel == 33:
        return "repeat(4, 1fr)";
      case zoomLevel == 50:
        return "repeat(4, 1fr)";
      case zoomLevel == 67:
        return "repeat(4, 1fr)";
      case zoomLevel == 75:
        return "repeat(4, 1fr)";
      case zoomLevel == 80:
        return "repeat(4, 1fr)";
      case zoomLevel == 90:
      case zoomLevel == 100:
        return blockWidth >= 1370
          ? "402.28px repeat(3, 1fr)"
          : blockWidth <= 700
          ? "repeat(1, 1fr)"
          : blockWidth <= 1100
          ? "repeat(2, 1fr)"
          : "repeat(4, 1fr)";

      case zoomLevel >= 110:
        return "repeat(2, 1fr)";
      default:
        return "repeat(4, 1fr)";
    }
  };
  //   1238 to 1101

  const cardData = [
    {
      id: 1,
      title: "Total Amount",
      amount: creditMixdata?.totalAmount,
      addedAmt: creditMixdata?.amountAddedLastThreeMonths,
      preAmt:
        creditMixdata?.totalAmount - creditMixdata?.amountAddedLastThreeMonths,
    },

    {
      id: 2,
      title: "Total Outstanding Amount",
      amount: creditMixdata?.totalOutstandingAmount,
      addedAmt: creditMixdata?.outstandingAmountAddedLastThreeMonths,
      preAmt:
        creditMixdata?.totalOutstandingAmount -
        creditMixdata?.outstandingAmountAddedLastThreeMonths,
    },

    {
      id: 3,
      title: "Total Collateral Amount",
      amount: creditMixdata?.totalCollateralAmount,
      addedAmt: creditMixdata?.collateralAmountAddedInLastThreeMonths,
      preAmt:
        creditMixdata?.totalCollateralAmount -
        creditMixdata?.collateralAmountAddedInLastThreeMonths,
    },
  ];
  return (
    <div
      ref={mainBlockRef}
      style={{
        display: "grid",
        gridTemplateColumns: checkTemplateCol(),
        gap: blockWidth >= 1370 ? "24px" : "21px",
      }}
    >
      <div>
        <DonutChart creditMixdata={creditMixdata} />
      </div>
      {cardData.map((card) => (
        <div style={{ background: "#DFE8F8", borderRadius: "8px" }}>
          <div className="user-information-div-of-account">
            <div className="total-ammount-of-new-div-1">{card.title}</div>
            <div className="main-information-of-user-with-chart">
              <div className="chart-of-user-information-div-child-1">
                <AccountChart data1={card} blockWidth={blockWidth} />
              </div>
             { card?.preAmt || card?.addedAmt ? (<>
          
              <div className="main-imporatant-infomation-of-user-loan">
                <div>
                  <div className="user-added-ammount-div">
                    ₹{card.addedAmt?.toLocaleString("en-IN")}
                  </div>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <div
                      style={{
                        width: "10px",
                        height: "10px",
                        backgroundColor: "#17B26A",
                        marginRight: "5px",
                        borderRadius: "50%",
                      }}
                    />

                    <div className="user-letest-amount-added-info">
                      Latest Amount Added
                    </div>
                  </div>
                </div>

                <div className="user-previous-ammount-div-1">
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <div
                      style={{
                        width: "10px",
                        height: "10px",
                        backgroundColor: "#C3D3EF",
                        marginRight: "5px",
                        borderRadius: "50%",
                      }}
                    />
                    <div className="user-previous-ammount-div-text">
                      Previous Amount
                    </div>
                  </div>
                  <div className="user-added-ammount-div-2">
                    ₹{card.preAmt?.toLocaleString("en-IN")}
                  </div>
                  {card?.preAmt || card?.addedAmt ? (
                          <></>
                        ) : (
                          <>
                            <div
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <div
                                style={{
                                  width: "10px",
                                  height: "10px",
                                  backgroundColor: "#907AD6",
                                  marginRight: "5px",
                                }}
                              />
                              <span
                                style={{
                                  marginLeft: "5px",
                                  fontFamily: "Nunito Sans",
                                  fontWeight: "600",
                                  fontSize: "11px",
                                }}
                              >
                                No Data available
                              </span>
                            </div>
                          </>
                        )}
                </div>
              </div>
              </>):(<>
                <div style={{ display: "flex", alignItems: "center" }}>
                        <div
                          style={{
                            width: "10px",
                            height: "10px",
                            backgroundColor: "#17B26A",
                            marginRight: "5px",
                            borderRadius: "50%",
                          }}
                        />
                        <span
                          style={{
                            marginLeft: "5px",
                            fontFamily: "inter",
                            fontWeight: "400",
                            fontSize: "12px",
                            color: "#033B52",
                          }}
                        >
                          No Data available
                        </span>
                      </div>
              </>)}


            </div>
            <div className="user-amount-you-have-till-date">
              <div className="main-info-user-total-amount-inr">
                ₹{card.amount?.toLocaleString("en-IN")}
              </div>
              <div className="user-amount-you-have-till-date-static-text">
                Total amount you have till date
              </div>
            </div>
          </div>
        </div>
      ))}
    
    </div>
  );
}

export default NewDesignCards;