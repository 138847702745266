import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  Typography,
  Tooltip,
  useMediaQuery,
  Popover,
} from "@mui/material";
import { toast } from "react-toastify";
import SettingsIcon from "@mui/icons-material/Settings";
import LogoutIcon from "@mui/icons-material/Logout";
import experianLogo from "../assets/imgs/Experian_logo.webp";
import { useNavigate } from "react-router-dom";
import CredilioCard from "./CredilioCard";
import CredilioCardPlus from "./CredilioCardPlus";
import TabsCoponent from "../DashboardComponents/TabsCoponent";
import Loading from "../Components/Loading";
import ScoreChart from "./ScoreChart";

import { USER_REPORT } from "../service/ApiList";
import { generalSwitch } from "../utils/generalSwitch";
// import CryptoJS from "crypto-js";

function CreditReport() {
  const isMobile = useMediaQuery("(max-width:1100px)");
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [experianData, setExperianData] = useState("");
  const [anchorEl, setAnchorEl] = useState(null); 

  useEffect(() => {
    setLoading(true);
    const userConfirms = "loginConfirm";
    localStorage.setItem("loginConfirm", userConfirms);
  
    // const SECRET_KEY = "secret_key_for_tdf_developers";
    const autoLogout =()=>{
      toast.error("Something went wrong. Please try again.");
      setTimeout(()=>{
        handleLogout()
      },5000)
    }
    USER_REPORT((res, err) => {    
      if (res?.status === 200 && res?.data?.data === true) {
        setLoading(false);
        setExperianData(res?.data?.myReport);
        toast.success(res?.data?.msg);
        localStorage.setItem("phoneNumber", res?.data?.myReport?.mobile);
      }    
      else if (res?.data.status && res?.data?.success === false  ) {      
         const status = res?.data?.status;
          generalSwitch(status, navigate);             
        setLoading(false);
      }else{
        autoLogout()
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleLogout = () => {
localStorage.clear("")
    navigate("/");
  };

  const handlegoProfile = () => {
    navigate("/dashboard/profile");
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget); // Open popover
  };

  const handleClose = () => {
    setAnchorEl(null); // Close popover
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  useEffect(() => {
    const handlePopState = (event) => {
      event.preventDefault();
      navigate("/");
    };
    // Push a new entry to the history stack
    window.history.pushState(null, null, window.location.href);

    // Add event listener for the 'popstate' event
    window.addEventListener("popstate", handlePopState);

    return () => {
      // Clean up the event listener when the component unmounts
      window.removeEventListener("popstate", handlePopState);
    };
  }, [navigate]);
  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <>
          <Box sx={{ padding: { xs: "20px", lg: "32px" } }}>
            {/* top nav bar start */}
            <Box style={{ display: "flex", justifyContent: "space-between" }}>
              <Box>
                <Typography
                  sx={{
                    color: "#044a67",
                    fontSize: { xs: "18px", sm: "20px", lg: "20px" },
                    fontFamily: "CustomFontMedium",
                  }}
                >
                  Hi {experianData?.name} {experianData?.surName}!
                </Typography>
                <Typography
                  sx={{
                    color: "#044a67",
                    fontSize: { xs: "18px", sm: "20px", lg: "20px" },
                    fontFamily: "CustomFontMedium",
                  }}
                >
                  It's good to see you again.
                </Typography>
              </Box>

              {isMobile ? (
                <></>
              ) : (
                <>
                  <Box style={{ display: "flex" }}>
                    <SettingsIcon
                      style={{ marginRight: "20px", cursor: "pointer" }}
                      onClick={handleClick} // Open the popover on click
                    />

                    <Tooltip title="Logout">
                      <LogoutIcon
                        onClick={handleLogout}
                        style={{ cursor: "pointer" }}
                      />
                    </Tooltip>
                  </Box>
                </>
              )}
            
            </Box>

            {/* Popover for Settings */}
            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
            >
              <Box p={2}>
                <Typography
                  onClick={handlegoProfile}
                  style={{ cursor: "pointer" }}
                >
                  Profile
                </Typography>
              </Box>
            </Popover>

            {/* Content Section */}
            <Box style={{ marginTop: "20px" }}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} lg={3}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <ScoreChart
                      score={experianData?.score?.score}
                    />
                  </Box>
                  {isMobile ? (
                    <>
                      <Box
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Typography
                          style={{
                            fontSize: "12px",
                            fontFamily: "CustomFontMedium",
                          }}
                        >
                          Powered By
                        </Typography>
                        <img src={experianLogo} alt="exprian" width="100" />
                      </Box>
                    </>
                  ) : (
                    <></>
                  )}
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  lg={9}
                  sx={{ display: { lg: "flex" }, alignItems: { lg: "center" } }}
                >
                  <Box
                    sx={{
                      width: { xs: "100%", sm: "100%", md: "95%", lg: "95%" },
                    }}
                  >
                    <Typography
                      sx={{
                     
                        fontFamily: "CustomFontMedium",
                        textAlign: "justify",
                        fontSize: "16px",
                        lineHeight: "30px",
                        fontWeight: "500px",
                      }}
                    >
                      {experianData?.score?.message}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
              {isMobile ? (
                <></>
              ) : (
                <>
                  <Grid container>
                    <Grid item xs={12} sm={6} lg={3}>
                      <Box
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Typography
                          style={{
                            fontSize: "12px",
                            fontFamily: "CustomFontMedium",
                          }}
                        >
                          Powered By
                        </Typography>
                        <img src={experianLogo} alt="exprian" width="100"  loading="lazy"  />
                      </Box>
                    </Grid>
                  </Grid>
                </>
              )}
            </Box>

            <Grid>
              <Grid item xs={12} sm={12} lg={12}>
                <Box style={{ marginTop: "20px" }}>
                  <TabsCoponent userData={experianData} />
                </Box>
              </Grid>
            </Grid>
            {experianData?.score?.score <= 650 ? (
              <Box style={{ marginTop: "25px" }}>
                <CredilioCard />
              </Box>
            ) : (
              <CredilioCardPlus />
            )}
          </Box>
        </>
      )}
    </>
  );
}

export default CreditReport;
