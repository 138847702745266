import React from "react";
import HeaderCoponent from "../Components/HeaderCoponent";
import {
  Box,
  Typography,
} from "@mui/material";

import FotterComponent from "../Components/FotterComponent";
import Founders from "./Founders";

function AboutUs() {
  return (
    <>
      <HeaderCoponent />
      <Box style={{ padding: "30px 10% 5% 10%", textAlign:"justify" }}>
        <Typography
          sx={{
            letterSpacing: "0px",
            lineHeight: "63px",
            whiteSpace: "pre-line",
            marginTop: "10px",
            color: "#040404",
            fontWeight: "bolder",
            marginBottom: "10px",
            borderBottom: "4px solid #1fafe5",
            borderRadius: "8px",
            padding: "0 15px",
            fontFamily: "CustomFontMedium",

            fontSize: {
              xs: "24px",
              sm: "30px",
              md: "30px",
            },
          }}
        >
          About DueFactory
        </Typography>
        <Typography
          style={{
            color: "#747678",
            fontSize: "15px",
            fontFamily: "CustomFontMedium",
          }}
        >
          At Due Factory, we understand the importance of good credit and how it
          can impact your financial well-being. Due Factory is a leading credit
          repair company dedicated to helping individuals and businesses regain
          control of their credit scores and achieve their financial goals.
        </Typography>
        <Box
          style={{
            fontSize: "18px",
            fontWeight: "700",
            color: "#5f5d5ddb",
            paddingBottom: "8px",
            paddingTop: "8px",
            fontFamily: "CustomFontMedium",
          }}
        >
          Our Mission
        </Box>
        <Typography
          style={{
            color: "#747678",
            fontSize: "15px",
            fontFamily: "CustomFontMedium",
          }}
        >
          Our mission is to empower our clients with the knowledge, tools, and
          expertise needed to improve their credit standing. Due Factory believe
          that everyone deserves a fair chance to access credit and build a
          secure financial future. We are committed to providing top-notch
          credit repair services that are tailored to each client's unique needs
          and circumstances.
        </Typography>
        <Box
          style={{
            fontSize: "18px",
            fontWeight: "700",
            color: "#5f5d5ddb",
            paddingBottom: "8px",
            paddingTop: "8px",
            fontFamily: "CustomFontMedium",
          }}
        >
          Expertise and Experience
        </Box>
        <Typography
          style={{
            color: "#747678",
            fontSize: "15px",
            fontFamily: "CustomFontMedium",
          }}
        >
          At Due Factory, we take great satisfaction in our depth of knowledge
          and skill in the credit repair sector. Our team of experts has worked
          hard for years to help numerous people and organisations overcome
          credit issues and see big increases in their credit scores. Our
          seasoned professionals have a thorough awareness of the complexities
          of credit repair. They keep up with the most recent trends, laws, and
          credit reporting procedures as well as the constantly shifting
          business environment. This dedication to lifelong learning guarantees
          that we provide the finest quality service and continue to lead the
          field in credit repair solutions. We use the depth of our experience
          and our knowledge of the market to create plans that are specifically
          customised to each client's situation. Our professionals carefully
          review credit reports to find any errors or anomalies and create
          unique action plans to efficiently deal with credit concerns.
          Additionally, our team's knowledge goes beyond credit repair. We are
          experts at analysing and interpreting data, which enables us to glean
          insightful information from credit data. This analytical approach
          equips us to develop wise strategies and make informed decisions that
          benefit our clients the most. You can be sure that when you work with
          Due Factory, you are collaborating with a group of experts who are
          committed to guiding you through the difficulties of credit
          restoration. We stand out as a leader in the sector thanks to our
          knowledge, data-driven insights, and capacity for sound judgement.
          Discover the Due Factory advantage, and let our professionals help you
          on the path to a more prosperous future.
        </Typography>
        <Box
          style={{
            fontSize: "18px",
            fontWeight: "700",
            color: "#5f5d5ddb",
            paddingBottom: "8px",
            paddingTop: "8px",
            fontFamily: "CustomFontMedium",
          }}
        >
          Comprehensive Credit Solutions
        </Box>
        <Typography
          style={{
            color: "#747678",
            fontSize: "15px",
            fontFamily: "CustomFontMedium",
          }}
        >
          At Due Factory, we offer a comprehensive range of credit repair
          services to address various credit-related issues. Whether you're
          struggling with late payments, collections, charge-offs, or errors on
          your credit report, our team will work diligently to identify and
          address these issues. Due Factory take a personalized approach,
          analyzing your credit history, and developing a customised strategy to
          optimise your credit score.
        </Typography>
        <Box
          style={{
            fontSize: "18px",
            fontWeight: "700",
            color: "#5f5d5ddb",
            paddingBottom: "8px",
            paddingTop: "8px",
            fontFamily: "CustomFontMedium",
          }}
        >
          Transparency and Compliance
        </Box>
        <Typography
          style={{
            color: "#747678",
            fontSize: "15px",
            fontFamily: "CustomFontMedium",
          }}
        >
          Due Factory value transparency and believe in keeping our clients
          well-informed throughout the credit repair process. Our team will
          guide you through each step, explaining the actions taken to repair
          your credit and providing regular updates on your progress. We adhere
          to all relevant laws and regulations, including the Fair Credit
          Reporting Act (FCRA), ensuring that our credit repair practices are
          ethical and compliant.
        </Typography>
        <Box
          style={{
            fontSize: "18px",
            fontWeight: "700",
            color: "#5f5d5ddb",
            paddingBottom: "8px",
            paddingTop: "8px",
            fontFamily: "CustomFontMedium",
          }}
        >
          Client Success Stories
        </Box>
        <Typography
          style={{
            color: "#747678",
            fontSize: "15px",
            fontFamily: "CustomFontMedium",
          }}
        >
          The success stories of our clients are a testament to our commitment
          to excellence. Due Factory take pride in the positive impact we've had
          on people's lives by helping them improve their creditworthiness.
          Visit our testimonials page to hear directly from our satisfied
          clients and see the results we've achieved together.
        </Typography>
        <Box
          style={{
            fontSize: "18px",
            fontWeight: "700",
            color: "#5f5d5ddb",
            paddingBottom: "8px",
            paddingTop: "8px",
            fontFamily: "CustomFontMedium",
          }}
        >
          Confidentiality and Security
        </Box>
        <Typography
          style={{
            color: "#747678",
            fontSize: "15px",
            fontFamily: "CustomFontMedium",
          }}
        >
          Due Factory understand the sensitive nature of the information you
          provide us, and we prioritize the confidentiality and security of your
          personal data. Our systems and processes are designed to safeguard
          your information and comply with industry-leading data protection
          standards.
        </Typography>
        <Box
          style={{
            fontSize: "18px",
            fontWeight: "700",
            color: "#5f5d5ddb",
            paddingBottom: "8px",
            paddingTop: "8px",
            fontFamily: "CustomFontMedium",
          }}
        >
          Get Started Today
        </Box>
        <Typography style={{ color: "#747678", fontSize: "15px" }}>
          If you're ready to take control of your credit and unlock new
          financial opportunities, Due Factory is here to help. Contact us today
          to schedule a free consultation with one of our credit repair experts.
          Let us partner with you on your journey to better credit and a
          brighter financial future.
        </Typography>
      </Box>

      <Box style={{ 
        // paddingTop: "30px",
         fontFamily: "CustomFontMedium" }}>
        <Typography
          style={{
            // margin: "0 0 16px",
            fontSize: "24px",
            fontWeight: "600",
            textAlign: "center",
            fontFamily: "CustomFontMedium",
          }}
        >
          Know more about our founders
        </Typography>
      </Box>  
      <Founders/>
      <FotterComponent />
    </>
  );
}

export default AboutUs;
