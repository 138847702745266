import React from "react";
import { Navigate } from "react-router-dom";
// import decryptValues from "../utils/decryptValues";

const RequireStatus = ({ children }) => {
  const StatusLS = localStorage.getItem("StatusLS");  
  // const StatusLS = decryptValues({key:"StatusLS"})

  if (StatusLS !== "BUREAU_FETCH") {
    return <Navigate to="/PageNotFound" />;
  }

  return children;
};

export default RequireStatus;
