import React, { useEffect, useState } from "react";
import "./LandingPageAnimation.css";
import HeaderComponent from "../Components/HeaderCoponent";
import TDFMobile from "../assets/homePage2/TDFphone.webp";
import TDFGirl from "../assets/imgs/TDFGirl.webp";
import HappyTDFGirl from "../assets/imgs/girl5.webp";
import TDFMobile2 from "../assets/homePage2/TDFPhone2.webp";
import Score702 from "../assets/imgs/mobile-hero-1.webp";
import SimpleSlider from "../Home/SimpleSlider";
import MyCarousel from "../Carousel/MyCarousel";
import WhyDueFactory from "../Home/WhyDueFactory";
import AdvertiseSection from "./AdvertiseSection";
import HomeFaq from "./HomeFaq";
import CustomizedInputBase from "./CustomizedInputBase";
import FotterComponent from "../Components/FotterComponent";
import { useNavigate, useSearchParams } from "react-router-dom";
import axiosInstance from "../service/axiosInstance";
import { toast } from "react-toastify";
import { profileApiData } from "../service/ApiList";

function LandingPageAnimation() {
  const [formData, setFormData] = useState({
    mobile: "",
  });
  const navigate = useNavigate()

  const [searchParams] = useSearchParams();
  const id = searchParams.get("id");
  const utm = searchParams.get("utm");

  const utm_source = searchParams.get("utm_source");
  const utm_medium = searchParams.get("utm_medium");
  const utm_campaign = searchParams.get("utm_campaign");
  const utm_content = searchParams.get("utm_content");

  if (utm_content || utm_medium || utm_campaign || utm_content) {
    localStorage.setItem("utm_source", utm_source);
    localStorage.setItem("utm_medium", utm_medium);
    localStorage.setItem("utm_campaign", utm_campaign);
    localStorage.setItem("utm_content", utm_content);
  }  
   var  isMobile = window.innerWidth <= 600

   const validateIndianMobile = (number) => {
    const indianMobileRegex = /^[6-9]\d{0,9}$/; 
    return indianMobileRegex.test(number);
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    if(validateIndianMobile(value) || value === "" ){
      setFormData({
        ...formData,
        [name]: value,
      });
    } 

  };
  const handleKeyPress = (e) => { 
    if (e.key === "Enter" && formData.mobile.length === 10) {
      handleSubmit();
    }
    else if (e.key === "Enter" ) {
      toast.error("Please enter a valid 10-digit phone number.");
    }
  };
  const handleSubmit = () => {
    const TDFToken = localStorage.getItem("TDFToken");
    if (formData.mobile.length === 10 ||TDFToken ) {  
        if (TDFToken) {
            profileApiData({}, (res, err) => {  
              if (!res) {           
                localStorage.clear("")
                navigate("/login", { state: { mobile: formData.mobile } });
                return;
              }
              if(typeof res?.data?.status === 'number' && res?.data?.success === false){
                localStorage.clear("")
                navigate("/login",{ state: { mobile: formData.mobile } });
              }             
              if (res?.status === 200 && (typeof res?.data?.status === 'string' || res?.data?.status === null)) {
                localStorage.setItem("StatusLS", res?.data?.status);
                const status = res?.data?.status;
                switch (status) {
                  case "BUREAU_FETCH":
                    navigate("/home/dashboard");
                    break;
      
                  case "ENHANCED_FETCH_REPORT_OTP_VALIDATED":
                  case "NORMAL_FETCH_REPORT_OTP_SENT":
                  case "NORMAL_FETCH_REPORT_OTP_VALIDATED":
                    toast.info("Please complete your profile.");
                    navigate("/login/experianfullform", {
                      state: { userProfileData: res?.data },
                    });
                    break;
      
                  case "ENHANCED_FETCH_REPORT_OTP_SENT":
                  case "REVOKE_CONSENT":
                  case "BUREAU_DELETED":
                  case "OTP_VALIDATED":
                    toast.info("Please complete your profile.");
                    navigate("/login/user", {
                      state: { userProfileData: res?.data },
                    });
                    break;
                  case "":
                    toast.info("Please complete your profile.");
                    navigate("/login/user", {
                      state: { userProfileData: res?.data },
                    });
                    break;
                  case null:
                    toast.info("Please complete your profile.");
                    navigate("/login/user", {
                      state: { userProfileData: res?.data },
                    });
                    break;
      
                  default:
                    toast.error("Something went wrong. Please try again.");
                    break;
                }
              } 
            });
          } else {
            navigate("/login", { state: { mobile: formData.mobile } });
          }

      
    } else {
      toast.error("Please enter a valid 10-digit phone number.");
    }
  };
  useEffect(() => { 
    if (utm || id) {
      axiosInstance
        .get(`/tdf/api/campaign/getCampaignUrl?${id ? "id" : "utm"}=${id ? id : utm}`)
        .then((res) => {
          const fullUrl = res?.data?.response;

          if (fullUrl) {
            const urlObj = new URL(fullUrl);
            const queryString = urlObj.search.slice(1); 
            localStorage.setItem("utmCampaign", queryString);
          }
        })
        .catch((err) => {
          console.error("Error fetching campaign URL:", err);
        });
    }
  }, [id, utm]);
  const [minHeight, setMinHeight] = useState("100vh");

  const updateMinHeight = () => {
 
    const isMobileDesktopView =
      navigator.userAgent.includes("Mobile") &&
      window.innerWidth >= 900 &&
      window.innerWidth <= 1100;

    // If in the specific range and in "Desktop site" mode
    if (isMobileDesktopView) {
      setMinHeight("100vh");
    } else {
      setMinHeight("auto");
    }
  };

  useEffect(() => {
    // Set initial minHeight
    updateMinHeight();

    // Add resize event listener
    window.addEventListener("resize", updateMinHeight);

    // Cleanup event listener on component unmount
    return () => window.removeEventListener("resize", updateMinHeight);
  }, []);
  const getMaxHeight = window.innerWidth <= 1100
  const getMinHeight = window.innerWidth >= 900



  

  
  return (
    <>
      <div className="landing-page-first-div-1" style={getMaxHeight && getMinHeight ? { minHeight } : undefined} >
        <HeaderComponent />
        <div className="landing-page-first-grid-div-1">

          <div className="pull-text-div-2">
            <div className="tdf-landing-page-text-div-1">

              <div className="text-parent-div-1-pull">
                <h1 className="text-div-for-mobile">
                <div  className="pull-your-score-text">
               <div>Pull your credit report,<span className="mobile-text-span"> free of cost</span> </div><div className="desktop-text-div-main">free of cost</div>
                </div>
                </h1>
                <div className="join-us-text-div-1">
                  Join us to get deep insights on your credit health. Free
                  bureau score, analysis, recommendation and support.
                </div>
                <div className="enter-num-and-btn-div">
                  <input type="number"
                   placeholder="Enter Phone number"
                   name="mobile"
                   value={formData.mobile}
                   onChange={handleChange}
                   onKeyPress={handleKeyPress}
                   />
                  <button  onClick={handleSubmit}>{isMobile ? "Get Your Report": "Get Your Credit Report"}</button>
                </div>
                <div className="we-take-care-mobile-div"> 

            
                <div className="join-us-text-div-1">
                  We take care of your credit health every step of the way.
                </div>
                </div>
              </div>     

            </div>
         
          </div>

          <div className="grid-2">
            <div className="mobile-wrapper-div" 
            style={{position:"relative"}}
            >
              <div>
                <img
                  src={TDFMobile}
                  alt="mobile"                
                  className="img-scr-1"     
              loading="lazy" 
                           
                />
                <div className="score-div-1">
                  <span className="your-score-text">Your Score</span>
                  
                </div>
                <div className="score-div-11">
                  <div className="your-score-count">657</div>
                  
                </div>
              </div>
            </div>
          </div>

          <div className="grid-3">
            <div className="tdf-girl-have-mobile">
              <img src={TDFGirl} className="tdf-first-bottom-girl" alt="tdf-girl" />
            </div>
          </div>

        </div>
        <div className="only-mobile-visibility-div-1">
                <div className="mobile-img-score-657">

                  <img src={Score702} alt="img-score-657"  height={"auto"} className="tdf-score-img-on-mobile" />
                </div>
              </div>
      </div>

      <div className="landing-page-first-div-2" style={getMaxHeight && getMinHeight ? { minHeight } : undefined} >
        <div className="landing-page-first-grid-div-1">

          <div className="pull-text-div-3">
            <div className="tdf-landing-page-text-div-1">
              <div
             
                className="text-parent-div-1-pull-2 animate-slide-up"
              >
                <div className="Better-credit-health-2">
                  Better credit health ensures you get higher credit limits on
                  cards, easier access to loans at lower rates.
                </div>
                <div className="join-us-text-div-2">
                  Your credit score is a snapshot of your financial history.
                  Check it now.
                </div>
                <div className="enter-num-and-btn-div-2">
                  <input type="text" placeholder="Enter Phone number"
                    name="mobile"
                    value={formData.mobile}
                    onChange={handleChange}
                    onKeyPress={handleKeyPress}
                   />
                  <button onClick={handleSubmit}>Get Your Credit Report</button>
                </div>
              </div>
            </div>
          </div>

          <div className="grid-2">
            <div className="mobile-wrapper-div">
              <div>
                <img
                  src={TDFMobile2}
                  alt="mobile"
                className="img-scr-1"
                />
                <div className="score-div-3">
                  <div className="your-score-text">Your Score</div>
                </div>
                <div className="score-div-2">
                  <div className="your-score-count">702</div>
                </div>
              </div>
            </div>
          </div>

          <div className="tdf-girl-have-mobile">
            <div className="HappyTDFGirl-div-1">
              <img
                src={HappyTDFGirl}
                className="tdf-first-bottom-girl-2"
                alt="girl"
                width={"90%"}
                height={"auto"}
              />
            </div>
          </div>

        </div>
      </div>
      <div  className="MyCarousel-1">
      <MyCarousel />
   
      </div>
      <div className="WhyDueFactory-1">
      <WhyDueFactory />

      </div>
      <div className="Top-Myths-about-credit-div">
        <div className="Top-Myths-about-credit-text">Top Myths about credit score</div>
      </div>
      <div className="simple-slider-blog-post">
      <SimpleSlider />
      </div>
      <div className="advertise-Section-div">
      <AdvertiseSection/>
      </div>
      <div className="home-page-faq-div">
      <HomeFaq/>
      </div>
      <div className="custom-button-div-1">
        <div className="center-custom-btn-title-div">
          <div className="width-on-mobile-and-desk">
            <div className="titile-of-the-btn-div">
            Connect with us to increase your credit score!
            </div>
          </div>

        </div>

      </div>
      <div className="CustomizedInputBase-div-1">
      <CustomizedInputBase />
      </div>
      <div className="FotterComponent">
      <FotterComponent />        
      </div>
    
      
    </>
  );
}

export default LandingPageAnimation;
