import axios from "axios";

const baseURL = process.env.REACT_APP_BASE_URL; // Ensure the base URL is set in your .env file

// Axios instance
const axiosInstance = axios.create({
  baseURL: baseURL,
  timeout: 10000,  // Set timeout to 10 seconds
  headers: {
    "Content-Type": "application/json",  // Default header
    // Add more headers if needed (e.g., authentication tokens)
  },
});

// Request interceptor for adding the authorization token if available
axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("TDFToken");  // Get token from localStorage
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;  // Add token to the request header
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Response interceptor for global error handling (optional)
axiosInstance.interceptors.response.use(
  (response) => response,  // If successful, return the response
  (error) => {
    // Handle specific status codes like 401 or 500 here
    if (error.response && error.response.status === 401) {
      // Handle unauthorized access
      // e.g., redirect to login
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
