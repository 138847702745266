import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Grid,
  TextField,
  Typography,
  MenuItem,
  FormControl,
  Select,
  Tooltip,
  useMediaQuery,
  FormHelperText,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
// import SettingsIcon from "@mui/icons-material/Settings";
import LogoutIcon from "@mui/icons-material/Logout";
import { toast } from "react-toastify";
// import axiosInstance from "../service/axiosInstance";
import formGirl from "../../src/assets/imgs/formgirl2.webp";
import {
  indianStateApiData,
  postUserProfile,
  profileApiData,
} from "../service/ApiList";
import "./style.css";
import { generalSwitch } from "../utils/generalSwitch";
import Loading from "../Components/Loading";

function Profile() {
  const isMobile = useMediaQuery("(max-width:1100px)");
  const navigate = useNavigate();
  const [getState, setGetState] = useState({});
  const handleLogout = () => {
    localStorage.clear("experianData");
    localStorage.clear("TDFToken");
    localStorage.clear("loginConfirm");
    navigate("/");
  };
  // const handlegoProfile = () => {
  //   navigate("/dashboard/profile");
  // };
  const [rawDateOfBirth, setRawDateOfBirth] = useState("");
  const [mobile, setMobile] = useState("");
  // const [isError, setIsError] = useState(false);
  const [error, setError] = useState(false);
  const [activePan, setActivepan] = useState(false);
  const [loading, setLoading] = useState(false);

  const [formData, setFormData] = useState({
    name: "",
    surName: "",
    gender: "",
    email: "",
    dob: "",
    pan: "",
    address: "",
    city: "",
    pinCode: "",
    state: "",
    employmentType: "",
    monthlyIncome: "",
  });
  const [errors, setErrors] = useState({
    name: "",
    surName: "",
    gender: "",
    email: "",
    dob: "",
    pan: "",
    address: "",
    city: "",
    pinCode: "",
    state: "",
    employmentType: "",
    monthlyIncome: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    const panPattern = /^[A-Z]{0,5}[0-9]{0,4}[A-Z]?$/;
    // For the phone number input, restrict the value to only numeric characters and a maximum of 6 digits
    if (name === "pinCode") {
      // Remove any non-digit characters and limit to 6 digits
      const numericValue = value.replace(/\D/g, "").slice(0, 6);

      // Update the form data
      setFormData({
        ...formData,
        [name]: numericValue,
      });

      // Validate for minimum 6 digits
      if (numericValue.length < 6) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          pinCode: "Pincode must be exactly 6 digits.",
        }));
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          pinCode: "",
        }));
      }
    } else if (name === "name" || name === "surName") {
      // const nameRegex = /^[A-Za-z]{0,25}$/;
      const nameRegex = /^[A-Za-z\s]{0,25}$/;

      if (nameRegex.test(value)) {
        const formattedValue =
          value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
        setFormData({ ...formData, [name]: formattedValue });
      }
    } else if (name === "mobile") {
      // Remove any non-digit characters
      const numericValue = value.replace(/\D/g, "").slice(0, 10); // Keep only digits and limit to 6
      setMobile(numericValue);
    } else if (name === "pan") {
      // Convert value to uppercase before validating
      const upperValue = value.toUpperCase();

      // Validate the PAN input as the user types
      if (panPattern.test(upperValue)) {
        setFormData({ ...formData, [name]: upperValue });
        setError(false);
      } else {
        setError(true);
      }

      // Check if the PAN input is complete
      if (
        upperValue.length === 10 &&
        /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/.test(upperValue)
      ) {
        setActivepan(true);
      } else {
        setActivepan(false);
      }
    } else if (name === "dob") {
      const rawDate = value;

      // Convert the raw date to 'DD-MMM-YYYY' format
      const date = new Date(rawDate);
      const options = { day: "2-digit", month: "short", year: "numeric" };
      let formattedDate = date.toLocaleDateString("en-GB", options).replace(/ /g, "-");      
      // Ensure "Sept" is converted to "Sep"
      formattedDate = formattedDate.replace("Sept-", "Sep-");
      
      setFormData({
        ...formData,
        dob: formattedDate, // Set the formatted date
      });
      
      setRawDateOfBirth(rawDate)
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  useEffect(() => {
    setLoading(true);
    profileApiData({}, (res, err) => {
      console.log("err:", err);
      if (res?.status === 200 && res?.data?.success === undefined) {
        setLoading(false);

        setFormData({
          ...formData,
          name: res?.data?.name,
          surName: res?.data?.surName,
          gender: res?.data?.gender,
          email: res?.data?.email,
          dob: res?.data?.dob,
          pan: res?.data?.pan,
          address: res?.data?.address,
          city: res?.data?.city,
          pinCode: res?.data?.pinCode,
          state: res?.data?.state,
          employmentType: res?.data?.employmentType,
          monthlyIncome: res?.data?.monthlyIncome,
        });
        if (res?.data?.pan?.length === 10) {
          setActivepan(true);
        }
        setMobile(res?.data?.mobile);
        const dob = res?.data?.dob;
        let formattedDob = dob;
        if (dob) {
          const [day, month, year] = dob.split("-");
          const date = new Date(`${month} ${day}, ${year}`);
          formattedDob = date.toISOString().split("T")[0];
        }
        setRawDateOfBirth(formattedDob);
      } else if (res?.data?.status && res?.data?.success === false) {
        setLoading(false);
        const status = res?.data?.status;
        generalSwitch(status, navigate);
      } else {
        setLoading(false);

        toast.error(err?.errorMessage);
      }
    });
  }, []);

  useEffect(() => {
    indianStateApiData({}, (res, err) => {
      if (res) {
        setGetState(res?.data);
      } else {
        toast.error(err?.message);
      }
    });
  }, []);
  const validate = () => {
    let isValid = true;
    let errors = {};

    if (!formData.name) {
      errors.name = "First Name is Required";
      isValid = false;
    }
    if (!formData.surName) {
      errors.surName = "Last Name is Required";
      isValid = false;
    }
    // if (!formData.mobile) {
    //   errors.mobile = "mobileNo is Required";
    //   isValid = false;
    // }
    if (!formData.gender) {
      errors.gender = "gender is Required";
      isValid = false;
    }
    if (!formData.email) {
      errors.email = "email is Required";
      isValid = false;
    }
    if (!formData.dob) {
      errors.dob = "dob is Required";
      isValid = false;
    }
    if (!formData.pan) {
      errors.pan = "Pan Number is Required";
      isValid = false;
    }
    if (!formData.address) {
      errors.address = "address is Required";
      isValid = false;
    }
    if (!formData.city) {
      errors.city = "city is Required";
      isValid = false;
    }
    if (!formData.address) {
      errors.address = "address is Required";
      isValid = false;
    }
    if (!formData.pinCode) {
      errors.pinCode = "pincode is Required";
      isValid = false;
    }
    if (!formData.state) {
      errors.state = "state is Required";
      isValid = false;
    }
    if (!formData.employmentType) {
      errors.employmentType = "Employment Type is Required";
      isValid = false;
    }
    if (!formData.monthlyIncome) {
      errors.monthlyIncome = "Monthly Income is Required";
      isValid = false;
    }
    setErrors(errors);
    return isValid;
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    // console.log("formData:",formData)

    if (validate()) {
      postUserProfile(formData, (res, err) => {
        if (res) {
          toast.success(res?.data?.message);
          navigate("/home/dashboard");
        } else {
          toast.error(err?.errorMessage);
        }
      });
    }
  };

  return (
    <>
    {loading ? (<>
    <Loading/>
    </>):(<>
      <Box className="profile-page-main-div-1">
        {/* <Box style={{ display: "flex", justifyContent: "space-between" }}>
          <Box>
            <Typography style={{ color: "#044a67", fontSize: "20px" }}>
              Hi ! {formData?.name} {formData?.surName}
            </Typography>
            <Typography style={{ color: "#044a67", fontSize: "20px" }}>
              It's good to see you again.
            </Typography>
          </Box>
          {!isMobile && (
            <Box style={{ display: "flex" }}>
              <Tooltip title="Logout">
                <LogoutIcon
                  onClick={handleLogout}
                  style={{ cursor: "pointer" }}
                />
              </Tooltip>
            </Box>
          )}
        </Box> */}

        <Box
          sx={{
            border: "2px solid whitesmoke",
            width: { sm: "100%", lg: "100%" },
            background: "#FFFFFF",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
            borderRadius: "20px",
            marginTop: "0px",
          }}
        >
          <Grid container>
            <Grid
              item
              xs={12}
              sm={12}
              md={5}
              lg={6}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img
                src={formGirl}
                alt="formimg"
                width={"100%"}
                height={"auto"}
                style={{ borderRadius: "20px" }}
                loading="lazy"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={7} lg={6}>
              <Box style={{ padding: "40px" }}>
                <Typography
                  variant="h5"
                  style={{
                    marginBottom: "20px",
                    fontSize: "24px",
                    fontWeight: "400",
                  }}
                >
                  User Details
                </Typography>
                <form onSubmit={handleSubmit}>
                  <Grid container spacing={{ xs: 3, sm: 3, md: 4, lg: 3 }}>
                    <Grid item xs={12} sm={6} lg={6}>
                      <div
                        style={{
                          fontFamily: "CustomFontMedium",
                          fontSize: "16px",
                          fontWeight: "400",
                          color: "RGB(33, 37, 41)",
                          background: "RGBA(0, 0, 0, 0)",
                        }}
                      >
                        First Name
                      </div>

                      <TextField
                        type="text"
                        style={{ width: "100%", marginTop: "5px" }}
                        placeholder="Enter First Name"
                        value={formData.name}
                        name="name"
                        onChange={handleChange}
                        size="small"
                        error={!!errors.name}
                        helperText={errors.name}
                        InputProps={{
                          style: {
                            color: "#212529", // Change the text color of the input field
                            fontFamily: "CustomFontMedium",
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} lg={6}>
                      <div
                        style={{
                          fontFamily: "CustomFontMedium",
                          fontSize: "16px",
                          fontWeight: "400",
                          color: "RGB(33, 37, 41)",
                          background: "RGBA(0, 0, 0, 0)",
                        }}
                      >
                        Last Name
                      </div>

                      <TextField
                        type="text"
                        style={{ width: "100%", marginTop: "5px" }}
                        placeholder="Enter Last Name"
                        value={formData.surName}
                        name="surName"
                        onChange={handleChange}
                        size="small"
                        error={!!errors.surName}
                        helperText={errors.surName}
                        InputProps={{
                          style: {
                            color: "#212529", // Change the text color of the input field
                            fontFamily: "CustomFontMedium",
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} lg={6}>
                      <div
                        style={{
                          fontFamily: "CustomFontMedium",
                          fontSize: "16px",
                          fontWeight: "400",
                          color: "RGB(33, 37, 41)",
                          background: "RGBA(0, 0, 0, 0)",
                        }}
                      >
                        Phone Number
                      </div>

                      <TextField
                        type="number"
                        style={{ width: "100%", marginTop: "5px" }}
                        placeholder="Enter Phone Number"
                        value={mobile}
                        name="mobile"
                        onChange={handleChange}
                        size="small"
                        id="phone-input"
                        // error={!!errors.mobile}
                        // helperText={errors.mobile}
                        disabled
                        InputProps={{
                          style: {
                            color: "#212529", // Change the text color of the input field
                            fontFamily: "CustomFontMedium",
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} lg={6}>
                      <div
                        style={{
                          fontFamily: "CustomFontMedium",
                          fontSize: "16px",
                          fontWeight: "400",
                          color: "RGB(33, 37, 41)",
                          background: "RGBA(0, 0, 0, 0)",
                        }}
                      >
                        Gender
                      </div>

                      <FormControl fullWidth>
                        <Select
                          value={formData.gender}
                          onChange={handleChange}
                          placeholder="Gender"
                          size="small"
                          style={{ marginTop: "5px" }}
                          name="gender"
                          displayEmpty
                          error={!!errors.gender}
                          // helperText={errors.gender}
                          InputProps={{
                            style: {
                              color: "#212529", // Change the text color of the input field
                              fontFamily: "CustomFontMedium",
                            },
                          }}
                        >
                          <MenuItem value="" disabled>
                            Select Gender
                          </MenuItem>
                          <MenuItem value={1}>Male</MenuItem>
                          <MenuItem value={2}>Female</MenuItem>
                        </Select>
                        {errors.gender && (
                          <FormHelperText>{errors.gender}</FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6} lg={6}>
                      <div
                        style={{
                          fontFamily: "CustomFontMedium",
                          fontSize: "16px",
                          fontWeight: "400",
                          color: "RGB(33, 37, 41)",
                          background: "RGBA(0, 0, 0, 0)",
                        }}
                      >
                        Email
                      </div>

                      <TextField
                        type="email"
                        style={{ width: "100%", marginTop: "5px" }}
                        placeholder="Enter Email"
                        value={formData.email}
                        name="email"
                        onChange={handleChange}
                        size="small"
                        error={!!errors.email}
                        helperText={errors.email}
                        InputProps={{
                          style: {
                            color: "#212529", // Change the text color of the input field
                            fontFamily: "CustomFontMedium",
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} lg={6}>
                      <div
                        style={{
                          fontFamily: "CustomFontMedium",
                          fontSize: "16px",
                          fontWeight: "400",
                          color: "RGB(33, 37, 41)",
                          background: "RGBA(0, 0, 0, 0)",
                        }}
                      >
                        DOB
                      </div>

                      <TextField
                        type="date"
                        style={{ width: "100%", marginTop: "5px" }}
                        placeholder="Enter DOB"
                        value={rawDateOfBirth}
                        name="dob"
                        onChange={handleChange}
                        size="small"
                        error={!!errors.dob}
                        InputProps={{
                          style: {
                            color: "#212529",
                            fontFamily: "CustomFontMedium",
                          },
                        }}
                        inputProps={{
                          max: new Date().toISOString().split("T")[0],
                        }}
                      />
                      {errors.dob && (
                        <FormHelperText style={{ color: "#d32f2f" }}>
                          {errors.dob}
                        </FormHelperText>
                      )}
                    </Grid>
                    <Grid item xs={12} sm={6} lg={6}>
                      <div
                        style={{
                          fontFamily: "CustomFontMedium",
                          fontSize: "16px",
                          fontWeight: "400",
                          color: "RGB(33, 37, 41)",
                          background: "RGBA(0, 0, 0, 0)",
                        }}
                      >
                        Pan Number
                      </div>

                      <TextField
                        type="text"
                        style={{ width: "100%", marginTop: "5px" }}
                        placeholder="Pan Number"
                        value={formData.pan}
                        name="pan"
                        onChange={handleChange}
                        size="small"
                        error={error && !activePan}
                        InputProps={{
                          maxLength: 10,
                          style: {
                            color: "#212529", // Change the text color of the input field
                            fontFamily: "CustomFontMedium",
                          },
                        }}
                      />
                      {error && !activePan && (
                        <FormHelperText error>
                          Invalid PAN format. Example: ABCDE1234F
                        </FormHelperText>
                      )}
                    </Grid>
                    <Grid item xs={12} sm={6} lg={6}>
                      <div
                        style={{
                          fontFamily: "CustomFontMedium",
                          fontSize: "16px",
                          fontWeight: "400",
                          color: "RGB(33, 37, 41)",
                          background: "RGBA(0, 0, 0, 0)",
                        }}
                      >
                        Address
                      </div>

                      <TextField
                        type="text"
                        style={{ width: "100%", marginTop: "5px" }}
                        placeholder="Address"
                        value={formData.address}
                        name="address"
                        onChange={handleChange}
                        size="small"
                        error={!!errors.address}
                        helperText={errors.address}
                        InputProps={{
                          style: {
                            color: "#212529", // Change the text color of the input field
                            fontFamily: "CustomFontMedium",
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} lg={6}>
                      <div
                        style={{
                          fontFamily: "CustomFontMedium",
                          fontSize: "16px",
                          fontWeight: "400",
                          color: "RGB(33, 37, 41)",
                          background: "RGBA(0, 0, 0, 0)",
                        }}
                      >
                        City
                      </div>

                      <TextField
                        type="text"
                        style={{ width: "100%", marginTop: "5px" }}
                        placeholder="Enter City"
                        value={formData.city}
                        name="city"
                        onChange={handleChange}
                        size="small"
                        error={!!errors.city}
                        helperText={errors.city}
                        InputProps={{
                          style: {
                            color: "#212529", // Change the text color of the input field
                            fontFamily: "CustomFontMedium",
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} lg={6}>
                      <Box style={{ marginTop: "5px" }}>
                        <div
                          style={{
                            fontFamily: "CustomFontMedium",
                            fontSize: "16px",
                            fontWeight: "400",
                            color: "RGB(33, 37, 41)",
                            background: "RGBA(0, 0, 0, 0)",
                          }}
                        >
                          State
                        </div>

                        <FormControl fullWidth>
                          <Select
                            value={formData.state}
                            onChange={handleChange}
                            name="state"
                            size="small"
                            error={!!errors.state}
                            // helperText={errors.state}
                            InputProps={{
                              style: {
                                color: "#212529", // Change the text color of the input field
                                fontFamily: "CustomFontMedium",
                              },
                            }}
                          >
                            {/* {Object.entries(getState).map(([code, name]) => (
                          <MenuItem key={code} value={code}>
                            {name}
                          </MenuItem>
                        ))} */}
                            {Object.entries(getState)
                              ?.sort((a, b) => a[1].localeCompare(b[1]))
                              ?.map(([code, name]) => (
                                <MenuItem key={code} value={code}>
                                  {name}
                                </MenuItem>
                              ))}
                          </Select>
                          {errors.state && (
                            <FormHelperText style={{ color: "#d32f2f" }}>
                              {errors.state}
                            </FormHelperText>
                          )}
                        </FormControl>
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={6} lg={6}>
                      <div
                        style={{
                          fontFamily: "CustomFontMedium",
                          fontSize: "16px",
                          fontWeight: "400",
                          color: "RGB(33, 37, 41)",
                          background: "RGBA(0, 0, 0, 0)",
                        }}
                      >
                        Pincode
                      </div>

                      <TextField
                        type="number"
                        style={{ width: "100%", marginTop: "5px" }}
                        placeholder="Enter Pincode"
                        value={formData.pinCode}
                        name="pinCode"
                        onChange={handleChange}
                        size="small"
                        error={!!errors.pinCode}
                        helperText={errors.pinCode}
                        InputProps={{
                          minLength: 10,

                          style: {
                            color: "#212529",
                            fontFamily: "CustomFontMedium",
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} lg={6}>
                      <div
                        style={{
                          fontFamily: "CustomFontMedium",
                          fontSize: "16px",
                          fontWeight: "400",
                          color: "RGB(33, 37, 41)",
                          background: "RGBA(0, 0, 0, 0)",
                        }}
                      >
                        Employment Type
                      </div>

                      <FormControl fullWidth>
                        <Select
                          value={formData.employmentType}
                          onChange={handleChange}
                          placeholder="Employment Type"
                          size="small"
                          style={{ marginTop: "5px" }}
                          name="employmentType"
                          displayEmpty
                          error={!!errors.employmentType}
                          InputProps={{
                            style: {
                              color: "#212529", // Change the text color of the input field
                              fontFamily: "CustomFontMedium",
                            },
                          }}
                          // helperText={errors.employmentType}
                        >
                          <MenuItem value="" disabled>
                            Employment Type
                          </MenuItem>
                          <MenuItem value={"SALARIED"}>SALARIED</MenuItem>
                          <MenuItem value={"SELF_EMPLOYED"}>
                            SELF EMPLOYED
                          </MenuItem>
                        </Select>
                        {errors.employmentType && (
                          <FormHelperText style={{ color: "#d32f2f" }}>
                            {errors.employmentType}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6} lg={6}>
                      <div
                        style={{
                          fontFamily: "CustomFontMedium",
                          fontSize: "16px",
                          fontWeight: "400",
                          color: "RGB(33, 37, 41)",
                          background: "RGBA(0, 0, 0, 0)",
                        }}
                      >
                        Monthly Income
                      </div>

                      <TextField
                        type="number"
                        style={{ width: "100%", marginTop: "5px" }}
                        placeholder="monthly Income"
                        value={formData.monthlyIncome}
                        name="monthlyIncome"
                        onChange={handleChange}
                        size="small"
                        error={!!errors.monthlyIncome}
                        helperText={errors.monthlyIncome}
                        InputProps={{
                          style: {
                            color: "#212529",
                            fontFamily: "CustomFontMedium",
                          },
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Button
                    variant="contained"
                    type="submit"
                    style={{
                      textTransform: "capitalize",
                      marginTop: "20px",
                      width: "150px",
                      background:
                        activePan &&
                        !errors.pinCode &&
                        formData.dob &&
                        formData.address &&
                        formData.city &&
                        formData.pinCode &&
                        formData.state &&
                        formData.employmentType &&
                        formData.monthlyIncome
                          ? "#044A67"
                          : "#d3d3d3",
                      color:
                        activePan &&
                        !errors.pinCode &&
                        formData.dob &&
                        formData.address &&
                        formData.city &&
                        formData.pinCode &&
                        formData.state &&
                        formData.employmentType &&
                        formData.monthlyIncome
                          ? "#FFFFFF"
                          : "rgba(0, 0, 0, .26)",
                    }}
                    disabled={
                      !activePan ||
                      errors.pinCode ||
                      !formData.dob ||
                      !formData.address ||
                      !formData.city ||
                      !formData.pinCode ||
                      !formData.state ||
                      !formData.employmentType ||
                      !formData.monthlyIncome
                    }
                  >
                    Submit
                  </Button>
                </form>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
      </>)}
    </>
  );
}

export default Profile;
