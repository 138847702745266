import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import { Chart, ArcElement, Tooltip, Legend } from 'chart.js';

// Register the components for Chart.js
Chart.register(ArcElement, Tooltip, Legend);

const DonutChart = ({creditMixdata,blockWidth}) => {
  
 const totalAccounts = creditMixdata?.totalAccounts ?? 1; 
const securedAccounts = creditMixdata?.securedAccounts ?? 0;
const SecuredPer = (securedAccounts / totalAccounts) * 100;


const unsecuredAccounts = creditMixdata?.unsecuredAccounts ?? 0;
const unsecuredper =  (unsecuredAccounts / totalAccounts) * 100;

const creditCardAccounts = creditMixdata?.creditCardAccounts ?? 0;
const creditper =  (creditCardAccounts / totalAccounts) * 100;
 

  const data = {
    labels: [
      'Secured',
      'Unsecured',      
      'Credit Card'
    ],
    datasets: [{
      // label: "Accounts",
      data: [(SecuredPer || 0).toFixed(2), unsecuredper.toFixed(2), creditper.toFixed(2)],
      backgroundColor: [
        '#5DA3C0',
        '#98DEFB',
        '#EBF2FD',
      ],
      borderWidth: 0, // ✅ Removes border
      hoverBorderWidth: 0, // ✅ Prevents border on hover
      hoverOffset: 0
    }]
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      tooltip: {
        enabled: false, // Disable default tooltip
        external: (context) => {
          const tooltipModel = context.tooltip;

          // Select or create the custom tooltip container
          let tooltipEl = document.getElementById('chartjs-tooltip');
          if (!tooltipEl) {
            tooltipEl = document.createElement('div');
            tooltipEl.id = 'chartjs-tooltip';
            tooltipEl.style.position = 'absolute';
            tooltipEl.style.background = 'rgba(0, 0, 0, 0.7)'; // Transparent black background
            tooltipEl.style.color = 'white';
            tooltipEl.style.padding = '8px';
            tooltipEl.style.borderRadius = '4px';
            tooltipEl.style.pointerEvents = 'none';
            tooltipEl.style.zIndex = '9999';
            document.body.appendChild(tooltipEl);
          }

          // Hide the tooltip if it's not visible
          if (tooltipModel.opacity === 0) {
            tooltipEl.style.opacity = '0';
            return;
          }

          // Set the tooltip content
          if (tooltipModel.body) {
            const titleLines = tooltipModel.title || [];
            const bodyLines = tooltipModel.body.map((b) => b.lines);

            let innerHtml = '<div style="text-align: left;">'; // Set smaller font size

            // Add title (labels)
            titleLines.forEach((title) => {
              innerHtml += `<div style="font-weight: bold; margin-bottom: 4px;">${title}</div>`;
            });

            // Add body (values)
            bodyLines.forEach((body, i) => {
              const backgroundColor = tooltipModel.labelColors[i].backgroundColor;
              const value = body[0];  // Extract the value (secured, unsecured, or credit card)

              // If value is 1 (for 'No Data'), show '0' instead
              const displayValue = value === '1' ? '0' : value;

              innerHtml += `
                <div style="display: flex; align-items: center;">
                  <span style="width: 12px; height: 12px; background: ${backgroundColor}; display: inline-block; margin-right: 6px;"></span>
                  ${displayValue}%
                </div>`;
            });

            innerHtml += '</div>';
            tooltipEl.innerHTML = innerHtml;
          }

          // Position the tooltip
          // const { offsetLeft, offsetTop } = context.chart.canvas;
          const position = context.chart.canvas.getBoundingClientRect();
          tooltipEl.style.opacity = '1';
          tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX + 'px';
          tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY + 'px';
        },
      },
      legend: {
        display: false, // Hide the legend
      },
    }
  };
  const customWidth =()=>{

  }
  const customHeight=()=>{

  }
  return (
    <div className='donut-chart-div-1'  style={{width:customWidth(), height:customHeight()}} >
      <div>
      <div className='chartTopDiv-1'> 
        <Doughnut data={data} options={options} />
      </div>
      </div>
    
      {/* Text Labels */}
      <div style={{fontFamily:'CustomFontMedium', fontSize:"12px", fontWeight:"400"  }}>
        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
          <div style={{ width: '10px', height: '10px', backgroundColor: '#5DA3C0', marginRight: '5px', borderRadius:"50%" }} />
          <span className='font-of-chart-card-div-1'>Secured ({(SecuredPer || 0).toFixed(2)}%)</span>
        </div>
        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
          <div style={{ width: '10px', height: '10px', backgroundColor: '#98DEFB', marginRight: '5px', borderRadius:"50%" }} />
          <span className='font-of-chart-card-div-1'>Unsecured ({(unsecuredper || 0).toFixed(2)}%)</span>
        </div>
        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
          <div style={{ width: '10px', height: '10px', backgroundColor: '#EBF2FD', marginRight: '5px', borderRadius:"50%" }} />
          <span className='font-of-chart-card-div-1'>Credit Card ({(creditper || 0).toFixed(2)}%)</span>
        </div>
      </div>
    </div>
  );
};

export default DonutChart;
