import React, { useState } from "react";
import {
  Box,
  Button,
  Grid,
  Typography,
  Paper,
  Modal,
  Container,
} from "@mui/material";
import SmsIcon from "@mui/icons-material/Sms";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { callBackApi, getPaymentlinkdata } from "../service/ApiList";
import { generalSwitch } from "../utils/generalSwitch";

function AccountCard({ userData, badLoans }) {
  const navigate = useNavigate();
  const [openModal, setOpenModal] = useState(false);

  // const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  if (!userData || !userData.accountDetails) {
    return null;
  }
  const handlePayNow = (productType, accountDetailsId, bankName) => {
    getPaymentlinkdata({ productType, accountDetailsId, bankName }, (res, err) => {
      if (res) {
        const resLink = res?.data?.link;
        if (resLink) {
          window.open(resLink);
        } else {
          toast.error("Link not found!", {
            hideProgressBar: true,
          });
        }
      } else {
      }
    });
  };

  const handleGosettlePage = (accountDetailsId) => {
    const stateData = {
      name: userData?.name,
      surName: userData?.surName,
      accountDetailsId: accountDetailsId,
      isMorebtn: false,
    };
    navigate(`/dashboard/offer/${accountDetailsId}`, { state: stateData });
  };
  const handleGoMorePage = (accountDetailsId) => {
    const stateData = {
      name: userData?.name,
      surName: userData?.surName,
      accountDetailsId: accountDetailsId,
      isMorebtn: true,
    };
    navigate(`/dashboard/offer/${accountDetailsId}`, { state: stateData });
  };
  const handleCallmeBack = (accountDetailsId) => {
    
    callBackApi({ accountDetailsId }, (res, err) => {
      
      if ( res?.status === 200 && res?.data?.msg === "Your request is accepted." ) {
        setOpenModal(true);
      } else if (res?.data?.status && res?.data?.success === false) {
        const status = res?.data?.status;
        generalSwitch(status, navigate);
      } else {
        toast.error("Something went wrong. Please try again.", {
          hideProgressBar: true,
        });
      }
    });
  };

  return (
    <>
      {userData.accountDetails.map((data, index) => (
        <Grid container spacing={2} key={index}>
          <Grid item xs={12}>
            <Paper sx={{ borderRadius: "20px" }}>
              <Box style={{ padding: "20px", marginBottom: "15px" }}>
                <Typography
                  style={{
                    fontSize: "24px",
                    color: "#044a67",
                    fontFamily: "Roboto,Helvetica Neue,sans-serif",
                  }}
                >
                  {data?.subscriber_Name}
                </Typography>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={4}>
                    <Box>
                      <Typography
                        style={{
                          fontSize: "16px",
                          color: "#044a67",
                          fontFamily: "Roboto,Helvetica Neue,sans-serif",
                        }}
                      >
                        {data?.loanType}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={8}>
                    <Box
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Button
                        sx={{
                          background: "#EF8062",
                          color: "#fff",
                          width: { xs: "50%", sm: "45%", lg: "45%" },
                          fontSize: { xs: "10px", sm: "" },
                          cursor: "default",
                          "&:hover": {
                            color: "#fff",
                            background: "#EF8062", // Keeps the background the same on hover
                          },
                          textTransform: "capitalize",
                        }}
                      >
                        {data?.priority} Priority
                      </Button>
                      <Button
                        style={{
                          background: badLoans ? "" : "",
                          color: badLoans ? "" : "#black",
                          border: "1px solid rgba(0,0,0,.12)",
                          width: "45%",
                          textTransform: "capitalize",
                        }}
                        // disabled={badLoans ? true : false
                        // }
                        onClick={() => {
                          handleGoMorePage(data?.id);
                        }}
                      >
                        More Info
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={4}>
                    <Box sx={{ marginTop: "10px" }}>
                      <Typography
                        style={{
                          fontSize: "16px",
                          color: "#044a67",
                          fontWeight: "500",
                          fontFamily: "Roboto,Helvetica Neue,sans-serif",
                        }}
                      >
                        Your Account Number
                      </Typography>
                      <Typography
                        style={{
                          fontSize: "16px",
                          color: "#044a67",
                          fontWeight: "500",
                          fontFamily: "Roboto,Helvetica Neue,sans-serif",
                        }}
                      >
                        {data?.account_Number}
                      </Typography>
                    </Box>
                  </Grid>

                  <Grid item xs={12} sm={8}>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginTop: { xs: "10px", lg: "none" },
                      }}
                    >
                      {badLoans ? (
                        <>
                          <Button
                            sx={{
                              background: "#044a67",
                              color: "#fff",
                              width: { xs: "50%", md: "45%" },
                              textTransform: "capitalize",
                              "&:hover": {
                                background: "#044a67", // Change background on hover
                                color: "#fff", // Change text color on hover
                              },
                            }}
                            onClick={() => {
                              handlePayNow(
                                data?.loanType,
                                data?.id,
                                data?.subscriber_Name
                              );
                            }}
                          >
                            Pay Now
                          </Button>

                          <Button
                            style={{
                              background: badLoans ? "" : "#fff",
                              color: badLoans ? "" : "black",
                              border: "1px solid rgba(0,0,0,.12)",
                              width: "45%",
                              textTransform: "capitalize",
                            }}
                            // disabled={badLoans ? true : false
                            // }

                            onClick={() => {
                              handleGosettlePage(data?.id);
                            }}
                          >
                            Settle
                          </Button>
                        </>
                      ) : (
                        <>
                          <Button
                            sx={{
                              background: "#fff",
                              color: "black",
                              border: "1px solid rgba(0,0,0,.12)",
                              width: {
                                xs: "100%",
                                lg: "45%",
                                sm: "45%",
                              },
                              textTransform: "capitalize",
                            }}
                            onClick={() => {
                              handleCallmeBack(data?.id);
                            }}
                          >
                            Call Me Back
                          </Button>
                        </>
                      )}
                    </Box>
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={4}>
                    <Box sx={{ marginTop: "10px" }}>
                      {data?.account_Type === "10" ? (
                        <>
                          <Typography
                            style={{
                              fontSize: "16px",
                              color: "#044a67",
                              fontFamily: "Roboto,Helvetica Neue,sans-serif",
                            }}
                          >
                            Total Credit Limit: ₹{data?.credit_Limit_Amount}
                          </Typography>
                          <Typography
                            style={{
                              fontSize: "16px",
                              color: "#044a67",
                              fontFamily: "Roboto,Helvetica Neue,sans-serif",
                            }}
                          >
                            Current Outstanding: ₹{data?.current_Balance}
                          </Typography>
                          <Typography
                            style={{
                              fontSize: "16px",
                              color: "#044a67",
                              fontFamily: "Roboto,Helvetica Neue,sans-serif",
                            }}
                          >
                            Last Billed Due: ₹{data?.amount_Past_Due}
                          </Typography>
                        </>
                      ) : data?.account_Type === "31" ? (
                        <>
                          <Typography
                            style={{
                              fontSize: "16px",
                              color: "#044a67",
                              fontFamily: "Roboto,Helvetica Neue,sans-serif",
                            }}
                          >
                            Total Credit Limit: ₹
                            {data?.highest_Credit_or_Original_Loan_Amount}
                          </Typography>
                          <Typography
                            style={{
                              fontSize: "16px",
                              color: "#044a67",
                              fontFamily: "Roboto,Helvetica Neue,sans-serif",
                            }}
                          >
                            Current Outstanding: ₹{data?.current_Balance}
                          </Typography>
                          <Typography
                            style={{
                              fontSize: "16px",
                              color: "#044a67",
                              fontFamily: "Roboto,Helvetica Neue,sans-serif",
                            }}
                          >
                            Last Billed Due: ₹{data?.amount_Past_Due}
                          </Typography>
                        </>
                      ) : (
                        <>
                          <Typography
                            style={{
                              fontSize: "16px",
                              color: "#044a67",
                              fontFamily: "Roboto,Helvetica Neue,sans-serif",
                            }}
                          >
                            Total Loan Amount: ₹
                            {data?.highest_Credit_or_Original_Loan_Amount}
                          </Typography>
                          <Typography
                            style={{
                              fontSize: "16px",
                              color: "#044a67",
                              fontFamily: "Roboto,Helvetica Neue,sans-serif",
                            }}
                          >
                            Outstanding Loan Amount: ₹{data?.current_Balance}
                          </Typography>
                          <Typography
                            style={{
                              fontSize: "16px",
                              color: "#044a67",
                              fontFamily: "Roboto,Helvetica Neue,sans-serif",
                            }}
                          >
                            Amount Past Due: ₹{data?.amount_Past_Due}
                          </Typography>
                        </>
                      )}
                    </Box>
                  </Grid>

                  <Grid item xs={12} sm={8}>
                    <Box
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginTop: "10px",
                        textTransform: "capitalize",
                      }}
                    >
                      {badLoans ? (
                        <>
                          <Button
                            sx={{
                              background: "#fff",
                              color: "black",
                              border: "1px solid rgba(0,0,0,.12)",
                              width: {
                                xs: "100%",
                                lg: "45%",
                                sm: "45%",
                              },
                              textTransform: "capitalize",
                            }}
                            onClick={() => {
                              handleCallmeBack(data?.id);
                            }}
                          >
                            Call Me Back
                          </Button>
                        </>
                      ) : (
                        <></>
                      )}
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Box style={{ display: "flex" }}>
                      <SmsIcon
                        style={{
                          fontSize: "14px",
                          marginTop: "3px",
                          color: "#044a67",
                        }}
                      />
                      <Typography
                        style={{
                          fontSize: "12px",
                          color: "#044a67",
                          marginLeft: "5px",
                          fontFamily: "Roboto,Helvetica Neue,sans-serif",
                        }}
                      >
                        {data?.msg}
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Paper>
          </Grid>
        </Grid>
      ))}
      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Container
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            height: "200px",
            backgroundColor: "white",
            padding: "20px",
            borderRadius: "8px",
            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
            // width: "640px",
            width: {
              lg: "50%",
              sm: "50%",
              xs: "90%",
            },
            // padding: "15px",
          }}
        >
          <Typography
            id="modal-title"
            variant="h6"
            component="h2"
            style={{ fontSize: "20px" }}
          >
            Thank you for your <b>call back</b> request.
          </Typography>
          <Typography
            id="modal-title"
            variant="h6"
            component="h2"
            style={{ fontSize: "16px" }}
          >
            Your credit health is our priority. One of our advisors will call
            you within 48 hours.
          </Typography>
          <Box style={{ display: "flex", alignSelf: "self-end" }}>
            <Button
              variant="outlined"
              color="secondary"
              onClick={handleCloseModal}
              style={{
                background: "#05517a",
                color: "white",
                borderRadius: "10px",
                marginTop: "20px",
              }}
            >
              Close
            </Button>
          </Box>
        </Container>
      </Modal>
    </>
  );
}

export default AccountCard;
