import React, { useEffect, useState } from "react";
import banner from "../assets/imgs/TDF-full-img.webp";
import { GiCheckMark } from "react-icons/gi";
import {
  Box,
  Button,
  Grid,
  TextField,
  Typography,
  MenuItem,
  FormControl,
  Select,
  FormControlLabel,
  Checkbox,
  Switch,
  Modal,
  Container,
  CircularProgress,
  FormHelperText,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import SSLLogo from "../assets/homePage2/SSL.webp";
import HeaderComponent from "../Components/HeaderCoponent";
import { indianStateApiData, tdfFetchReport } from "../service/ApiList";
import OtpVerification from "./OtpVerification";
import { generalSwitch } from "../utils/generalSwitch";

function ExperianFullForm() {
  const navigate = useNavigate();
  const location = useLocation();
  const userProfileDatas = location?.state?.userProfileData;
  if (userProfileDatas) {
    localStorage.setItem("userProfileData", JSON.stringify(userProfileDatas));
  }

  // State for text visibility
  const [showFirstSet, setShowFirstSet] = useState(true);

  const [isChecked, setIsChecked] = useState(true);
  const [checkedWhatsApp, setCheckedWhatsApp] = useState(true);
  // const [profileDetail, setProfileDetail] = useState({});
  const [formData, setFormData] = useState({
    firstName: "",
    surName: "",
    mobileNo: "",
    gender: "",
    email: "",
    whatsAppConsent: checkedWhatsApp,
    experianConsent: isChecked,
    dateOfBirth: "",
    pan: "",
    flatno: "",
    city: "",
    pincode: "",
    state: "",
  });
  const [rawDateOfBirth, setRawDateOfBirth] = useState("");
  const [fetchReportData, setFetchReportData] = useState("");
  const [stateLifitingUp, setStateLifitingUp] = useState(false);

  const [loading, setLoading] = useState(false);
  const [getState, setGetState] = useState({});
  const [openModal, setOpenModal] = useState(false);

  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);
  const [error, setError] = useState(false);
  const [activePan, setActivepan] = useState(false);

  // Toggle between first set and second set of texts
  useEffect(() => {
    const interval = setInterval(() => {
      setShowFirstSet((prevShowFirstSet) => !prevShowFirstSet);
    }, 4000);

    return () => clearInterval(interval); // Cleanup on unmount
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;

    // Regex for first name and surname validation
    const nameRegex = /^[A-Za-z\s]{0,25}$/;

    // Regex for PAN card number validation
    const panPattern = /^[A-Z]{0,5}[0-9]{0,4}[A-Z]?$/; // Modified to allow partial PAN inputs

    if (name === "firstName" || name === "surName") {
      if (nameRegex.test(value)) {
        const formattedValue =
          value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
        setFormData({ ...formData, [name]: formattedValue });
      }
    } else if (name === "pan") {
      // Convert value to uppercase before validating
      const upperValue = value.toUpperCase();

      // Validate the PAN input as the user types
      if (panPattern.test(upperValue)) {
        setFormData({ ...formData, [name]: upperValue });
        setError(false);
      } else {
        setError(true);
      }

      // Check if the PAN input is complete
      if (
        upperValue.length === 10 &&
        /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/.test(upperValue)
      ) {
        setActivepan(true);
      } else {
        setActivepan(false);
      }
    } else if (name === "dateOfBirth") {


      // Store the raw date in 'YYYY-MM-DD' format for input handling
      const rawDate = value;

      // Convert the raw date to 'DD-MMM-YYYY' format
      const date = new Date(rawDate);
      const options = { day: "2-digit", month: "short", year: "numeric" };
      let formattedDate = date.toLocaleDateString("en-GB", options).replace(/ /g, "-");

      // Ensure "Sept" instead of "Sep"
      formattedDate = formattedDate.replace("Sept-", "Sep-");

      setFormData({
        ...formData,
        dateOfBirth: formattedDate, // Set the formatted date
      });

      setRawDateOfBirth(rawDate)
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };
  // Toggle between first set and second set of texts
  useEffect(() => {
    const interval = setInterval(() => {
      setShowFirstSet((prevShowFirstSet) => !prevShowFirstSet);
    }, 4000);

    return () => clearInterval(interval); // Cleanup on unmount
  }, []);
  // Function to handle the change event of the WhatsApp switch
  const handleChangeCheckedWhats = (event) => {
    setCheckedWhatsApp(event.target.checked); // <-- Update state based on switch toggle
  };
  useEffect(() => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      whatsAppConsent: checkedWhatsApp,
    }));
  }, [checkedWhatsApp]);
  const handleExperianSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    tdfFetchReport(formData, (res, err) => {
      if (
        typeof res?.data?.status === "number" &&
        res?.data?.success === false
      ) {
        const status = res?.data?.status;
        generalSwitch(status, navigate);
      }
      else if (res) {
        localStorage.setItem("userProfileData", JSON.stringify(formData));
        setLoading(false);
        setStateLifitingUp(false);
        toast.success(res?.data?.msg);
        setFetchReportData(res);
      } else {
        setLoading(false);
        toast.error(err?.message);
      }
    });
  };

  useEffect(() => {
    const storedData = localStorage.getItem("userProfileData");
    if (storedData) {
      const res = JSON.parse(storedData);
      // setProfileDetail(res.data);
      setFormData({
        ...formData,
        firstName: res?.firstName || res?.name,
        surName: res?.surName,
        // mobileNo: res?.mobile,
        mobileNo: localStorage.getItem("mobile") || res?.mobile,
        gender: res?.gender,
        email: res?.email,
        city: res?.city,
        flatno: res?.address || res?.flatno,
        pan: res?.pan,
        dateOfBirth: res?.dob || res?.dateOfBirth,
        state: res?.state,
        pincode: res?.pincode || res?.pinCode,
      });
      let dob = res?.dob || res?.dateOfBirth;
      let formattedDob = dob;
      if (dob) {
        const [day, month, year] = dob.split("-");
        const date = new Date(`${month} ${day}, ${year}`);
        formattedDob = date.toISOString().split("T")[0];
      }
      if (res?.pan?.length === 10) {
        setActivepan(true);
      }

      setRawDateOfBirth(formattedDob);
    }

  }, []);

  useEffect(() => {
    indianStateApiData({}, (res, err) => {
      if (res) {
        // const data = Object.values(res?.data).sort();
        setGetState(res?.data);
      } else {
        toast.error(err?.message);
      }
    });
  }, []);

  useEffect(() => {
    const handlePopState = (event) => {
      event.preventDefault();
      // navigate("/");
      const isConfirmed = window.confirm(
        "Are you sure you want to leave? All unsaved changes will be lost."
      );
      if (isConfirmed) {
        navigate("/");
      }
    };
    const handleBeforeUnload = (event) => {
      const message =
        "Are you sure you want to leave? All unsaved changes will be lost.";
      event.returnValue = message;
      return message;
    };

    // Push a new entry to the history stack
    window.history.pushState(null, null, window.location.href);

    // Add event listener for the 'popstate' event
    window.addEventListener("popstate", handlePopState);
    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      // Clean up the event listener when the component unmounts
      window.removeEventListener("popstate", handlePopState);
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [navigate]);

  return (
    <>
      {fetchReportData?.data?.msg === "OTP SENT" &&
        fetchReportData?.data?.otpGenerationStatus === "1" &&
        stateLifitingUp === false ? (
        <>
          <OtpVerification
            UTMData={formData}
            setStateLifitingUp={setStateLifitingUp}
          />
        </>
      ) : (
        <>
          {loading && (
            <Box
              sx={{
                position: "fixed",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                backgroundColor: "rgba(252, 240, 240, 0.5)", // Semi-transparent background
                zIndex: 1300, // Higher than other elements
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <CircularProgress size={60} sx={{ color: "#ffffff" }} />
            </Box>
          )}
          <>
            <Box
              style={{
                background: "#044A67",
                width: "100%",
                minHeight: "100vh",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <HeaderComponent />

              <Grid container spacing={1}>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  lg={6}
                  md={6}
                  // style={{ paddingLeft: "20px" }}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    order: { sm: 1, xs: 2, md: 1, lg: 1 },
                  }}
                >
                  <img
                    src={banner}
                    alt="pullLogo"
                    style={{
                      maxWidth: "100%",
                      height: "auto",
                      width: "80%",
                    }}
                  />
                  <Box
                    sx={{
                      color: "white",
                      display: "flex",
                      justifyContent: "center",
                      lineHeight: "30px",
                      fontSize: "24px",
                      marginTop: "10px",
                      fontFamily: "CustomFontMedium",
                      margin: { xs: "10px", lg: "none" },
                      textAlign: "center",
                    }}
                  >
                    When you share your details, you’ll get:
                  </Box>
                  <Box
                    sx={{
                      marginTop: "10px",
                      width: { lg: "50%", sm: "50%" },
                      // marginLeft: { lg: "20%", sm: "20%" },

                      padding: { xs: "10px 20px", lg: "10px" },
                    }}
                  >
                    {showFirstSet ? (
                      <>
                        <Box
                          style={{
                            display: "flex",
                            justifyContent: "flex-start",
                          }}
                        >
                          <GiCheckMark
                            style={{
                              color: "#ffff",
                              marginTop: "5px",
                              fontSize: "12px",
                            }}
                          />
                          <Typography
                            style={{
                              color: "#ffff",
                              marginLeft: "10px",
                              fontFamily: "CustomFontMedium",
                            }}
                          >
                            Free Experian Credit Score and Report
                          </Typography>
                        </Box>
                        <Box
                          style={{
                            display: "flex",
                            justifyContent: "flex-start",
                            marginTop: "10px",
                            fontFamily: "CustomFontMedium",
                          }}
                        >
                          <GiCheckMark
                            style={{ color: "#ffff", marginTop: "5px" }}
                          />
                          <Typography
                            style={{
                              color: "#ffff",
                              marginLeft: "10px",
                              fontFamily: "CustomFontMedium",
                            }}
                          >
                            Monitor your score improvements - Report and Score
                            Refreshed Every 45 Days, on Sign In
                          </Typography>
                        </Box>
                        <Box
                          style={{
                            display: "flex",
                            justifyContent: "flex-start",
                            marginTop: "10px",
                            fontFamily: "CustomFontMedium",
                          }}
                        >
                          <GiCheckMark
                            style={{ color: "#ffff", marginTop: "5px" }}
                          />
                          <Typography
                            style={{
                              color: "#ffff",
                              marginLeft: "10px",
                              fontFamily: "CustomFontMedium",
                            }}
                          >
                            Complimentary Alerts and Recommendations from Due
                            Factory
                          </Typography>
                        </Box>
                      </>
                    ) : (
                      <>
                        <Box
                          style={{
                            display: "flex",
                            justifyContent: "flex-start",
                            marginTop: "10px",
                          }}
                        >
                          <GiCheckMark
                            style={{ color: "#ffff", marginTop: "5px" }}
                          />
                          <Typography
                            style={{
                              color: "#ffff",
                              marginLeft: "10px",
                              fontFamily: "CustomFontMedium",
                            }}
                          >
                            We help you prioritize your repayments based on deep
                            data insights.
                          </Typography>
                        </Box>
                        <Box
                          style={{
                            display: "flex",
                            justifyContent: "flex-start",
                            marginTop: "10px",
                          }}
                        >
                          <GiCheckMark
                            style={{ color: "#ffff", marginTop: "5px" }}
                          />
                          <Typography
                            style={{
                              color: "#ffff",
                              marginLeft: "10px",
                              fontFamily: "CustomFontMedium",
                            }}
                          >
                            Take decisions which change your future for the
                            better.
                          </Typography>
                        </Box>
                      </>
                    )}
                  </Box>
                  <Box
                    style={{
                      padding: "20px",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <Box
                      sx={{
                        width: { xs: "none", lg: "50%", sm: "50%" },
                        background: "#fff",
                        padding: { xs: "16px", lg: "40px" },
                        borderRadius: "5px",
                        boxShadow:
                          "0 5px 5px -3px rgba(0,0,0,.2),0 8px 10px 1px rgba(0,0,0,.14),0 3px 14px 2px rgba(0,0,0,.12)",
                      }}
                    >
                      <Typography
                        sx={{
                          fontFamily: "CustomFontMedium",
                        }}
                      >
                        Safe and Secure
                      </Typography>
                      <Box
                        sx={{
                          margin: "15px 0",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <img src={SSLLogo} alt="SSL" width={"120px"} />
                      </Box>
                      <Box>
                        <Typography
                          sx={{
                            fontFamily: "CustomFontMedium",
                            fontSize: "14px",
                            color: "#044a67",
                          }}
                        >
                          The information you provide will be transferred to us
                          through a private, secure connection.
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Grid>

                <Grid
                  // item
                  xs={12}
                  sm={12}
                  lg={6}
                  md={6}
                  sx={{
                    paddingRight: "20px",
                    paddingLeft: "25px",
                    marginBottom: "20px",
                    order: { sm: 2, xs: 1, md: 2, lg: 2 },
                    marginTop: { xs: "25px", sm: "0" },
                  }}
                >
                  {/* box where need to applly canditon */}
                  <Box
                    sx={{
                      border: "2px solid whitesmoke",
                      width: { sm: "100%", lg: "100%" },
                      background: "#FFFFFF",
                      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                      borderRadius: "5px",
                    }}
                  >
                    <Box
                      sx={{
                        padding: { xs: "20px", lg: "40px" },
                        boxShadow:
                          "0 5px 5px -3px rgba(0,0,0,.2),0 8px 10px 1px rgba(0,0,0,.14),0 3px 14px 2px rgba(0,0,0,.12)",
                      }}
                    >
                      <Typography
                        variant="h5"
                        style={{
                          marginBottom: "20px",
                          fontSize: "24px",
                          fontWeight: "400",
                        }}
                      >
                        Verify /Fill personal details
                      </Typography>
                      <form onSubmit={handleExperianSubmit}>
                        <Grid container spacing={2}>
                          <Grid item xs={12} sm={6} lg={6}>
                            <label>First Name</label>
                            <TextField
                              type="text"
                              style={{ width: "100%", marginTop: "5px" }}
                              placeholder="Enter First Name"
                              value={formData.firstName}
                              name="firstName"
                              onChange={handleChange}
                              size="small"
                              required
                            />
                          </Grid>

                          <Grid item xs={12} sm={6} lg={6}>
                            <label>Last Name</label>
                            <TextField
                              type="text"
                              style={{ width: "100%", marginTop: "5px" }}
                              placeholder="Enter Last Name"
                              value={formData.surName}
                              name="surName"
                              onChange={handleChange}
                              size="small"
                              required
                            />
                          </Grid>

                          <Grid item xs={12} sm={6} lg={6}>
                            <label>Phone Number</label>
                            <TextField
                              type="number"
                              style={{ width: "100%", marginTop: "5px" }}
                              placeholder="Enter Phone Number"
                              value={formData.mobileNo}
                              disabled
                              name="mobileNo"
                              onChange={handleChange}
                              size="small"
                              id="phone-input"
                            />
                          </Grid>

                          <Grid item xs={12} sm={6} lg={6}>
                            <label>Gender</label>
                            <FormControl fullWidth>
                              <Select
                                value={formData.gender}
                                onChange={handleChange}
                                placeholder="Gender"
                                size="small"
                                style={{ marginTop: "5px" }}
                                name="gender"
                                displayEmpty
                                required
                              >
                                <MenuItem value="" disabled>
                                  Select Gender
                                </MenuItem>
                                <MenuItem value={1}>Male</MenuItem>
                                <MenuItem value={2}>Female</MenuItem>
                              </Select>
                            </FormControl>
                          </Grid>

                          <Grid item xs={12} sm={12} lg={6}>
                            <label>Email</label>
                            <TextField
                              type="email"
                              style={{ width: "100%", marginTop: "5px" }}
                              placeholder="Enter Email"
                              value={formData.email}
                              name="email"
                              onChange={handleChange}
                              size="small"
                              required
                            />
                          </Grid>
                          <Grid item xs={12} sm={12} lg={6}>
                            <label>DOB</label>
                            <TextField
                              type="date"
                              style={{ width: "100%", marginTop: "5px" }}
                              placeholder="Enter DOB"
                              value={rawDateOfBirth}
                              name="dateOfBirth"
                              onChange={handleChange}
                              size="small"
                              required
                              InputLabelProps={{
                                shrink: true,
                              }}
                              inputProps={{
                                max: new Date().toISOString().split("T")[0],
                              }}
                            />
                          </Grid>
                          <Grid item xs={12} sm={12} lg={12}>
                            <label>Pan Number</label>
                            <TextField
                              type="text"
                              style={{ width: "100%", marginTop: "5px" }}
                              placeholder="Pan Number"
                              value={formData.pan}
                              name="pan"
                              onChange={handleChange}
                              size="small"
                              required
                              inputProps={{
                                maxLength: 10,
                                // pattern: "[A-Z]{5}[0-9]{4}[A-Z]{1}",
                              }}
                              error={error && !activePan}
                            />{" "}
                            {error && !activePan && (
                              <FormHelperText error>
                                Invalid PAN format. Example: ABCDE1234F
                              </FormHelperText>
                            )}
                          </Grid>
                          <Grid item xs={12} sm={12} lg={6}>
                            <label>Address</label>
                            <TextField
                              type="text"
                              style={{ width: "100%", marginTop: "5px" }}
                              placeholder="Address"
                              value={formData.flatno}
                              name="flatno"
                              onChange={handleChange}
                              size="small"
                              required
                            />
                          </Grid>
                          <Grid item xs={12} sm={12} lg={6}>
                            <label>City</label>
                            <TextField
                              type="text"
                              style={{ width: "100%", marginTop: "5px" }}
                              placeholder="Enter City"
                              value={formData.city}
                              name="city"
                              onChange={handleChange}
                              size="small"
                              required
                            />
                          </Grid>
                          <Grid item xs={12} sm={12} lg={6}>
                            <Box style={{ marginTop: "5px" }}>
                              <label>State</label>
                              <FormControl fullWidth>
                                <Select
                                  value={formData?.state}
                                  onChange={handleChange}
                                  name="state"
                                  size="small"
                                  required
                                  displayEmpty
                                >
                                  <MenuItem value="" disabled>
                                    Select State
                                  </MenuItem>
                                  {/* {Object.entries(getState).map(
                                    ([code, name]) => (
                                      <MenuItem key={code} value={code}>
                                        {name}
                                      </MenuItem>
                                    )
                                  )} */}
                                  {Object.entries(getState)
                                    .sort((a, b) => a[1].localeCompare(b[1]))
                                    .map(([code, name]) => (
                                      <MenuItem key={code} value={code}>
                                        {name}
                                      </MenuItem>
                                    ))}
                                </Select>
                              </FormControl>
                            </Box>
                          </Grid>
                          <Grid item xs={12} sm={12} lg={6}>
                            <label>Pincode</label>
                            <TextField
                              type="number"
                              style={{ width: "100%", marginTop: "5px" }}
                              placeholder="Enter Pincode"
                              value={formData.pincode}
                              name="pincode"
                              onChange={handleChange}
                              size="small"
                              required
                              inputProps={{ maxLength: 6 }}
                              onInput={(e) => {
                                if (e.target.value.length > 6) {
                                  e.target.value = e.target.value.slice(0, 6);
                                }
                              }}
                            />
                          </Grid>
                          {/* textfiled end */}

                          <Grid item xs={12} sm={12} lg={12}>
                            <Box style={{ marginTop: "20px" }}>
                              <FormControlLabel
                                control={
                                  <Checkbox style={{ color: "#044a67" }} />
                                }
                                checked={isChecked}
                                onChange={handleCheckboxChange}
                                label={
                                  <Box style={{ display: "flex" }}>
                                    <Typography
                                      style={{
                                        fontSize: "12px",
                                        fontFamily:
                                          "Roboto, Helvetica Neue, sans-serif;",
                                      }}
                                      
                                    >
                                      {/* I hereby appoint <b>Due Factory</b> as my
                                      authorised representative to receive my
                                      credit information from{" "} */}
                                      
                                      I hereby consent to Due Factory being appointed as my authorized representative to receive my
                                         Credit Information from Experian for the purpose of Credit Assessment for me and to advise me
                                          on the credit score enhancement and the best loan offers, on ongoing basis for not exceeding 
                                          a period of six (6) months. I also agree to <span
                                          style={{ color: "#044a67" }}
                                          onClick={handleOpenModal}
                                        >Experian T&C</span>.
                                         
                                      {/* <span
                                        style={{ color: "#044a67" }}
                                        onClick={handleOpenModal}
                                      >
                                        Experian (Terms and Conditions)
                                      </span>{" "}
                                      for credit enhancement services. */}
                                    </Typography>
                                  </Box>
                                }
                              />
                            </Box>
                          </Grid>
                          <Grid item xs={12} sm={12} lg={12}>
                            <Box style={{ marginTop: "20px" }}>
                              <Button
                                variant="contained"
                                type="submit"
                                style={{
                                  background:
                                    formData.firstName &&
                                      formData.surName &&
                                      formData.email &&
                                      formData.gender &&
                                      formData.mobileNo &&
                                      formData.flatno &&
                                      formData.city &&
                                      formData.pincode &&
                                      formData.state &&
                                      rawDateOfBirth &&
                                      isChecked &&
                                      activePan
                                      ? "#044A67"
                                      : "#d3d3d3",
                                  color:
                                    formData.firstName &&
                                      formData.surName &&
                                      formData.email &&
                                      formData.gender &&
                                      formData.mobileNo &&
                                      formData.flatno &&
                                      formData.city &&
                                      formData.pincode &&
                                      formData.state &&
                                      rawDateOfBirth &&
                                      isChecked &&
                                      activePan
                                      ? "#FFFFFF"
                                      : "rgba(0, 0, 0, .26)",
                                  width: "100%",
                                  textTransform: "capitalize",
                                }}
                                disabled={
                                  !formData.firstName ||
                                  !formData.surName ||
                                  !formData.email ||
                                  !formData.gender ||
                                  !formData.mobileNo ||
                                  !formData.flatno ||
                                  !formData.city ||
                                  !formData.pincode ||
                                  !formData.state ||
                                  !rawDateOfBirth ||
                                  !isChecked ||
                                  loading ||
                                  !activePan
                                }
                              >
                                Pull free Credit Report
                              </Button>
                            </Box>
                          </Grid>
                          <Grid item xs={12} sm={12} lg={12}>
                            <Box style={{ marginTop: "10px" }}>
                              <Typography
                                style={{
                                  fontSize: "12px",
                                  fontFamily: "Gilroy,sans-serif",
                                }}
                              >
                                Your personal information is 100% secured with
                                us. We do not share your data with any third
                                party.
                              </Typography>
                            </Box>
                          </Grid>
                          <Grid item xs={12} sm={12} lg={12}>
                            <Box style={{ marginTop: "10px" }}>
                              <FormControlLabel
                                control={<Switch />}
                                label="Get updates on WhatsApp"
                                checked={checkedWhatsApp}
                                onChange={handleChangeCheckedWhats} // <-- Handler function to update state
                              />
                            </Box>
                          </Grid>
                        </Grid>
                      </form>
                    </Box>
                  </Box>
                  {/* box where need to applly canditon */}
                </Grid>
              </Grid>
            </Box>
            {/* Modal Start */}
            <Modal
              open={openModal}
              onClose={handleCloseModal}
              aria-labelledby="modal-title"
              aria-describedby="modal-description"
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Container
                className="modal-container"
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  height: { xs: "90vh", md: "80vh", lg: "80vh" },
                  // maxHeight: "80vh",
                  backgroundColor: "white",
                  padding: { xs: "10px", md: "20px" },
                  borderRadius: "20px",
                  boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
                  width: {
                    lg: "80%",
                    sm: "80%",
                    xs: "90%",
                  },
                  overflowY: "hidden", // Hide overflow on the container
                }}
              >
                <Box
                  sx={{
                    padding: "20px",
                    flexGrow: 1,
                    overflowY: "auto", // Allow scrolling within this box
                    paddingRight: "10px", // Add some padding to prevent the scrollbar from overlapping content
                  }}
                >
                
                 
                  {/* <Typography
                    style={{
                      fontSize: "13px",
                      fontFamily: "CustomFontMedium",
                      marginTop: "10px",
                    }}
                  >
                    You hereby consent to Due Factory Services Private Limited
                    being appointed as your authorised representative to receive
                    your Credit Information from Experian for the purpose of
                    Credit Assessment of the End User to Advise him on the
                    credit score enhancement and best loan offers (End Use
                    Purpose) or expiry of 6 months from the date the consent is
                    collected; whichever is earlier.
                  </Typography> */}
                  {/* <Typography
                    style={{
                      fontSize: "13px",
                      fontFamily: "CustomFontMedium",
                      marginTop: "10px",
                    }}
                  >
                    <strong>
                      BY EXECUTING THIS AGREEMENT / CONSENT FORM, YOU ARE
                      EXPRESSLY AGREEING TO ACCESS THE EXPERIAN CREDIT
                      INFORMATION REPORT AND CREDIT SCORE, AGGREGATE SCORES,
                      INFERENCES, REFERENCES AND DETAILS (AS DEFINED
                      BELOW)(TOGETHER REFERRED AS “CREDIT INFORMATION”). YOU
                      HEREBY ALSO CONSENT TO SUCH CREDIT INFORMATION BEING
                      PROVIDED BY EXPERIAN TO YOU AND DUE FACTORY SERVICES
                      PRIVATE LIMITED BY USING EXPERIAN TOOLS, ALGORITHMS AND
                      DEVICES AND YOU HEREBY AGREE, ACKNOWLEDGE AND ACCEPT THE
                      TERMS AND CONDITIONS SET FORTH HEREIN.
                    </strong>
                  </Typography>{" "} */}
                  <Typography
                    id="modal-title"
                    variant="h6"
                    component="h2"
                    style={{
                      fontSize: "13px",
                      fontFamily: "CustomFontMedium",
                      color: "black",
                      marginTop: "10px",
                    }}
                  >
                    <strong style={{ textDecoration: "underline" }}>
                      {" "}
                      {/* Terms and Conditions: */}
                      CONSENT IN RELATION TO ACCESS TO CREDIT INFORMATION THROUGH EXPERIAN
                    </strong>
                  </Typography>
                  <Typography
                    style={{
                      fontSize: "13px",
                      fontFamily: "CustomFontMedium",
                      marginTop: "10px",
                    }}
                  >
                    This End User Agreement (the “<strong>Agreement</strong>”) is made between you (the “<strong>User</strong>”) and Due Factory Services Private Limited
                     (hereinafter referred to as “Client”), a private limited company having its registered office at 806-807, Udyog Vihar Phase V,
                      Sector 19, Gurugram, Haryana – 122016 ( “<strong>CLIENT</strong>”,  “<strong>Us</strong>”,  or  “<strong>We</strong>” , which term shall include its successors and permitted assigns).
                       The User and CLIENT shall be collectively referred to as the “<strong>Parties</strong>”  and individually as a “<strong>Party</strong>” .
                  </Typography>

                  <Typography
                    style={{
                      fontSize: "13px",
                      fontFamily: "CustomFontMedium",
                      marginTop: "10px",
                      fontWeight:"bold"
                    }}
                  >
                    BY EXECUTING THIS AGREEMENT / CONSENT FORM, YOU ARE EXPRESSLY AGREEING TO ACCESS THE EXPERIAN CREDIT INFORMATION REPORT AND CREDIT SCORE, AGGREGATE SCORES, INFERENCES, REFERENCES AND DETAILS (AS DEFINED BELOW) (TOGETHER REFERRED AS “CREDIT INFORMATION”). YOU HEREBY ALSO CONSENT TO SUCH CREDIT INFORMATION BEING PROVIDED BY EXPERIAN TO YOU AND CLIENT BY USING EXPERIAN TOOLS, ALGORITHMS AND DEVICES AND YOU HEREBY AGREE, ACKNOWLEDGE AND ACCEPT THE TERMS AND CONDITIONS SET FORTH HEREIN
                  </Typography>
                  <Typography
                    id="modal-title"
                    variant="h6"
                    component="h2"
                    style={{
                      fontSize: "13px",
                      fontFamily: "CustomFontMedium",
                      color: "black",
                      marginTop: "10px",
                    }}
                  >
                    <strong style={{ textDecoration: "underline" }}>
                      {" "}
                      Terms and Conditions:
                    </strong>
                  </Typography>

                  <Typography
                    id="modal-title"
                    variant="h6"
                    component="h2"
                    style={{
                      fontSize: "13px",
                      fontFamily: "CustomFontMedium",
                      color: "black",
                      marginTop: "10px",
                    }}
                  >
                    {/* here */}
                    <strong>
                      {" "}
                      Information Collection, Use, Confidentiality, No-Disclosure and Data Purging
                    </strong>
                  </Typography>
                  <Typography
                    style={{
                      fontSize: "13px",
                      fontFamily: "CustomFontMedium",
                      marginTop: "10px",
                    }}
                  >
                   CLIENT shall access your Credit Information as your authorized representative and CLIENT shall use the Credit Information for limited End Use Purpose consisting of and in relation to the services proposed to be availed by you from CLIENT. We shall not aggregate, retain, store, copy, reproduce, republish, upload, post, transmit, sell or rent the Credit Information to any other person and the same cannot be copied or reproduced other than as agreed herein and in furtherance to CICRA.
                  </Typography>
                  <Typography
                    style={{
                      fontSize: "13px",
                      fontFamily: "CustomFontMedium",
                      marginTop: "10px",
                    }}
                  >
                   The Parties agree to protect and keep confidential the Credit Information both online and offline.
                  </Typography>
                  <Typography
                    style={{
                      fontSize: "13px",
                      fontFamily: "CustomFontMedium",
                      marginTop: "10px",
                    }}
                  >
                   The Credit Information shared by you or received on by us on your behalf shall be destroyed, purged, erased promptly of upon the completion of the transaction/ End Use Purpose for which the Credit Information report was procured for up to a maximum of 6 months.
                  </Typography>
                  <Typography
                    id="modal-title"
                    variant="h6"
                    component="h2"
                    style={{
                      fontSize: "13px",
                      fontFamily: "CustomFontMedium",
                      color: "black",
                      marginTop: "10px",
                    }}
                  >
                    <strong>Governing Law and Jurisdiction</strong>
                  </Typography>
                  <Typography
                    style={{
                      fontSize: "13px",
                      fontFamily: "CustomFontMedium",
                      marginTop: "10px",
                    }}
                  >
                  The relationship between you and CLIENT shall be governed by laws of India and all claims or disputes arising there from shall be subject to the exclusive jurisdiction of the courts of Mumbai. 
                  </Typography>
                  <Typography
                    id="modal-title"
                    variant="h6"
                    component="h2"
                    style={{
                      fontSize: "13px",
                      fontFamily: "CustomFontMedium",
                      color: "black",
                      marginTop: "10px",
                    }}
                  >
                    <strong>Definitions:</strong>
                  </Typography>
                  <Typography
                    style={{
                      fontSize: "13px",
                      fontFamily: "CustomFontMedium",
                      marginTop: "10px",
                    }}
                  >
                   Capitalized terms used herein but not defined above shall have the following meanings:
                  </Typography>
                  <Typography
                    style={{
                      fontSize: "13px",
                      fontFamily: "CustomFontMedium",
                      marginTop: "10px",
                    }}
                  >
                    “<strong>Business Day</strong>” means a day (other than a
                    public holiday) on which banks are open for general business
                    in Mumbai.
                  </Typography>
                  <Typography
                    style={{
                      fontSize: "13px",
                      fontFamily: "CustomFontMedium",
                      marginTop: "10px",
                    }}
                  >
                   “<strong>Credit Information Report</strong>” means the
                    credit information / scores/ aggregates / variables /
                    inferences or reports which shall be generated by Experian;
                    
                  </Typography>
                  <Typography
                    style={{
                      fontSize: "13px",
                      fontFamily: "CustomFontMedium",
                      marginTop: "10px",
                    }}
                  >
                    “<strong>Credit Score</strong>” means the score which shall
                    be mentioned on the Credit Information Report which shall be
                    computed by Experian.
                  </Typography>
                  <Typography
                    style={{
                      fontSize: "13px",
                      fontFamily: "CustomFontMedium",
                      marginTop: "10px",
                    }}
                  >                  
                   “<strong>CICRA</strong>” shall mean the Credit Information Companies (Regulation) Act, 2005 read with the Credit Information Companies Rules, 2006 and the Credit Information Companies Regulations, 2006, and shall include any other rules and regulations prescribed thereunder.
                  </Typography>
                  <Typography
                    style={{
                      fontSize: "13px",
                      fontFamily: "CustomFontMedium",
                      marginTop: "10px",
                    }}
                  >
                    PLEASE READ THE ABOVEMENTIONED TERMS AND CONDITIONS AND CLICK ON “ACCEPT” FOLLOWED BY THE LINK BELOW TO COMPLETE THE AUTHORISATION PROCESS/  FOR SHARING OF YOUR CREDIT INFORMATION BY EXPERIAN WITH CLIENT IN ITS CAPACITY AS YOUR AUTHORISED REPRESENTATIVE
                  </Typography>
                  <Typography
                    style={{
                      fontSize: "13px",
                      fontFamily: "CustomFontMedium",
                      marginTop: "10px",
                    }}
                  >
                   BY CLICKING “ACCEPT” YOU AGREE AND ACCEPT THE DISCLAIMERS AND TERMS AND CONDITIONS SET OUT HEREIN.
                  </Typography>
                  <Typography
                    style={{
                      fontSize: "13px",
                      fontFamily: "CustomFontMedium",
                      marginTop: "10px",
                    }}
                  >
                  ** This document is an electronic record in terms of the Information Technology Act, 2000 and Rules made there under, and the amended provisions pertaining to electronic records.
                  </Typography>
                </Box>

                <Box style={{ display: "flex", justifyContent: "flex-end" }}>
                  <Button
                    variant="outlined"
                    color="secondary"
                    onClick={handleCloseModal}
                    style={{
                      background: "#05517a",
                      color: "white",
                      borderRadius: "10px",
                      marginTop: "20px",
                      fontFamily: "CustomFontMedium",
                    }}
                  >
                    Close
                  </Button>
                </Box>
              </Container>
            </Modal>
            {/* Modal End */}
          </>
          {/* // )} */}
        </>
      )}
    </>
  );
}
export default ExperianFullForm;
