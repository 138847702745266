import React, { useEffect, useState } from "react";
import "./style.css";
import { IoClose } from "react-icons/io5";
import CircularProgress from "@mui/material/CircularProgress";
import { postSaveUserAction } from "../../../service/ApiList";

function PaynowAction({ handleCloseModal, data }) {

    const [loading, setLoading] = useState(false);
    const [countdown, setCountdown] = useState(5); // Countdown starts from 5

    useEffect(() => {
        // setLoading(true);
        startCountdown();
    }, []);

    // Function to start countdown and close modal after 5s
    const startCountdown = () => {
        let timeLeft = 5;
        setCountdown(timeLeft);

        const interval = setInterval(() => {
            timeLeft -= 1;
            setCountdown(timeLeft);

            if (timeLeft === 0) {
                clearInterval(interval);
                handleCloseModal(); // Close modal after 5s
            }
        }, 1000);
    };

    const handleClose = () => {
        handleCloseModal();
    };

    useEffect(() => {
        const { id, bankName, loanType } = data;
        let params = {
            accountDetailsId: id,
            bankName: bankName,
            userActionType: "PAY_NOW"
        }
        postSaveUserAction(params, (res) => {
            if (res) {
                console.log(res)
            } else {
                console.log("err=" + res)
            }
        })
    }, [data])

    return (
        <div className="call-me-back-main">
            {loading ? (
                <CircularProgress />
            ) : (
                <>
                    <p>Payment link not available for this account</p>
                    <p>Closing in ({countdown}s)...</p> {/* Countdown Display */}
                    <button onClick={handleClose}>Close <IoClose /></button>
                </>
            )}
        </div>
    );
}

export default PaynowAction;
