import perfectImg from "../assets/genInsImgs/PerfecfMark.png"
import congratsImg from "../assets/genInsImgs/congratsIcon.png"
import goodMark from "../assets/genInsImgs/GoodMark.png";
import fairMark from "../assets/genInsImgs/FairMark.png";
import p_a_mark3 from "../assets/genInsImgs/payment-acct-mark3.png";
import p_a_mark4 from "../assets/genInsImgs/payment-acct-mark4.png";
import poorMark from "../assets/genInsImgs/PoorMark.png";
import alertMark from "../assets/genInsImgs/alert-triangle.png";
// remarks 

// generic stacked record

export const generic_stacked_record = (healthType, menuType, params) => {

    const checkPaymentHealthType = (type) => {
        switch (type) {
            case "type_perfect":
                return {
                    main_color: {
                        background: "#FFFFFFE5",
                        border_color: "#f2f2f2"
                    },
                    mobile_design: {
                        background_color_1: "#e7f8f1",
                        background_color_2: "#f1faf5",
                    },
                    first_line: {
                        text: `You have a perfect record with 100% on-time payments, reflecting your strong credit discipline.`,
                        text_color: "#022C3E"
                    },
                    first_img: {
                        src: congratsImg,
                        alt: "Congratulations on your perfect credit score"
                    },
                    head_main: {
                        text: "High Impact",
                        text_color: "#022C3E"
                    },
                    head_para: {
                        text: "Keep up the great work! Continue making timely payments to maintain your excellent repayment history.",
                        text_color: "#022C3E"
                    },
                    indicator_block: {
                        background: "#DCFAE6",
                        border_color: "#17B26A",
                        second_img: {
                            src: perfectImg,
                            alt: "Congratulations on your perfect credit score"
                        },
                        impact_text: {
                            text: "Perfect",
                            text_color: "#079455"
                        }
                    },
                    two_stats: [
                        {
                            background: "#E3FCEC",
                            border_color: "#17B26A",
                            impact_text: {
                                text: "Late Payments",
                                text_color: "#044A67"
                            },
                            impact_value: {
                                text: `${params.a}%`,
                                text_color: "#044A67"
                            }
                        },
                        {
                            background: "#E3FCEC",
                            border_color: "#17B26A",
                            impact_text: {
                                text: "On-time Payments",
                                text_color: "#044A67"
                            },
                            impact_value: {
                                text: `${params.b}%`,
                                text_color: "#044A67"
                            }
                        }
                    ]
                };

            case "type_good":
                return {
                    main_color: {
                        background: "#FFFFFFE5",
                        border_color: "#f2f2f2"
                    },
                    mobile_design: {
                        background_color_1: "#e7f8f1",
                        background_color_2: "#f1faf5",
                    },
                    first_line: {
                        text: `You’ve missed one or more payments in the past. To further improve your credit score, it’s important to ensure all future payments are made on time.`,
                        text_color: "#022C3E"
                    },
                    first_img: {
                        src: congratsImg,
                        alt: "Congratulations on your good credit score"
                    },
                    head_main: {
                        text: "High Impact",
                        text_color: "#022C3E"
                    },
                    head_para: {
                        text: "You’re doing well, but aim to eliminate occasional delays. Set up automatic payments or reminders to ensure all outstanding dues are paid on time. Avoid overextending your finances by taking on additional loans until these payments are streamlined.",
                        text_color: "#022C3E"
                    },
                    indicator_block: {
                        background: "#DCFAE6",
                        border_color: "#17B26A",
                        second_img: {
                            src: goodMark,
                            alt: "Congratulations on your perfect credit score"
                        },
                        impact_text: {
                            text: "Good",
                            text_color: "#079455"
                        }
                    },
                    two_stats: [
                        {
                            background: "#E3FCEC",
                            border_color: "#17B26A",
                            impact_text: {
                                text: "Late Payments",
                                text_color: "#044A67"
                            },
                            impact_value: {
                                text: `${params.a}%`,
                                text_color: "#044A67"
                            }
                        },
                        {
                            background: "#E3FCEC",
                            border_color: "#17B26A",
                            impact_text: {
                                text: "On-time Payments",
                                text_color: "#044A67"
                            },
                            impact_value: {
                                text: `${params.b}%`,
                                text_color: "#044A67"
                            }
                        }
                    ]
                };

            case "type_fair":
                return {
                    main_color: {
                        background: "#FFFFFFE5",
                        border_color: "#f2f2f2"
                    },
                    mobile_design: {
                        background_color_1: "#fff5d7",
                        background_color_2: "#fdf8e8",
                    },
                    first_line: {
                        text: `Your on-time payment rate is 70–89%, which suggests room for improvement. `,
                        text_color: "#022C3E"
                    },
                    first_img: {
                        src: congratsImg,
                        alt: "Congratulations on your good credit score"
                    },
                    head_main: {
                        text: "High Impact",
                        text_color: "#022C3E"
                    },
                    head_para: {
                        text: "While you’ve made efforts to pay on time, occasional missed payments are affecting your credit profile. Budget carefully to ensure sufficient funds for monthly repayments, and consider speaking with your lender to restructure the loan if payments feel overwhelming. Building a financial buffer can help avoid future delays.",
                        text_color: "#022C3E"
                    },
                    indicator_block: {
                        background: "#FEF0C7",
                        border_color: "#F79009",
                        second_img: {
                            src: p_a_mark3,
                            alt: "Congratulations on your perfect credit score"
                        },
                        impact_text: {
                            text: "Fair",
                            text_color: "#F79009"
                        }
                    },
                    two_stats: [
                        {
                            background: "#E3FCEC",
                            border_color: "#17B26A",
                            impact_text: {
                                text: "Late Payments",
                                text_color: "#044A67"
                            },
                            impact_value: {
                                text: `${params.a}%`,
                                text_color: "#044A67"
                            }
                        },
                        {
                            background: "#E3FCEC",
                            border_color: "#17B26A",
                            impact_text: {
                                text: "On-time Payments",
                                text_color: "#044A67"
                            },
                            impact_value: {
                                text: `${params.b}%`,
                                text_color: "#044A67"
                            }
                        }
                    ]
                };


            case "type_poor":
                return {
                    main_color: {
                        background: "#FFFFFFE5",
                        border_color: "#f2f2f2"
                    },
                    mobile_design: {
                        background_color_1: "#ffe9e7",
                        background_color_2: "#fff3f2",
                    },
                    first_line: {
                        text: `Your on-time payment rate is 70–89%, which suggests room for improvement. `,
                        text_color: "#022C3E"
                    },
                    first_img: {
                        src: congratsImg,
                        alt: "Congratulations on your good credit score"
                    },
                    head_main: {
                        text: "High Impact",
                        text_color: "#022C3E"
                    },
                    head_para: {
                        text: "While you’ve made efforts to pay on time, occasional missed payments are affecting your credit profile. Budget carefully to ensure sufficient funds for monthly repayments, and consider speaking with your lender to restructure the loan if payments feel overwhelming. Building a financial buffer can help avoid future delays.",
                        text_color: "#022C3E"
                    },
                    indicator_block: {
                        background: "#FEE4E2",
                        border_color: "#F04438",
                        second_img: {
                            src: p_a_mark4,
                            alt: "Congratulations on your perfect credit score"
                        },
                        impact_text: {
                            text: "Poor",
                            text_color: "#D92D20"
                        }
                    },
                    two_stats: [
                        {
                            background: "#E3FCEC",
                            border_color: "#17B26A",
                            impact_text: {
                                text: "Late Payments",
                                text_color: "#044A67"
                            },
                            impact_value: {
                                text: `${params.a}%`,
                                text_color: "#044A67"
                            }
                        },
                        {
                            background: "#E3FCEC",
                            border_color: "#17B26A",
                            impact_text: {
                                text: "On-time Payments",
                                text_color: "#044A67"
                            },
                            impact_value: {
                                text: `${params.b}%`,
                                text_color: "#044A67"
                            }
                        }
                    ]
                };

            default:
                return null;
        }
    };

    const checkCreditUtilisationHealthType = (type) => {
        switch (type) {
            case "type_perfect":
                return {
                    main_color: {
                        background: "#FFFFFFE5",
                        border_color: "#f2f2f2"
                    },
                    mobile_design: {
                        background_color_1: "#e7f8f1",
                        background_color_2: "#f1faf5",
                    },
                    first_line: {
                        text: `Your overall credit limit utilization is well within the optimal 30%, reflecting disciplined credit usage.`,
                        text_color: "#022C3E"
                    },
                    first_img: {
                        src: congratsImg,
                        alt: "Congratulations on your perfect credit score"
                    },
                    head_main: {
                        text: "High Impact",
                        text_color: "#022C3E"
                    },
                    head_para: {
                        text: "Your consistent low utilization of credit limit on credit cards demonstrates responsible credit management. This behavior strengthens your creditworthiness and is positively impacting your credit score.",
                        text_color: "#022C3E"
                    },
                    indicator_block: {
                        background: "#DCFAE6",
                        border_color: "#17B26A",
                        second_img: {
                            src: perfectImg,
                            alt: "Congratulations on your perfect credit score"
                        },
                        impact_text: {
                            text: "Perfect",
                            text_color: "#079455"
                        }
                    },
                    two_stats: [
                        {
                            background: "#E3FCEC",
                            border_color: "#17B26A",
                            impact_text: {
                                text: "Limits Used",
                                text_color: "#044A67"
                            },
                            impact_value: {
                                text: `₹ ${params.a}`,
                                text_color: "#044A67"
                            }
                        },
                        {
                            background: "#E3FCEC",
                            border_color: "#17B26A",
                            impact_text: {
                                text: "Credit Limit",
                                text_color: "#044A67"
                            },
                            impact_value: {
                                text: `₹ ${params.b}`,
                                text_color: "#044A67"
                            }
                        }
                    ]
                };
            case "type_high":
                return {
                    main_color: {
                        background: "#FFFFFFE5",
                        border_color: "#f2f2f2"
                    },
                    mobile_design: {
                        background_color_1: "#fff5d7",
                        background_color_2: "#fdf8e8",
                    },
                    first_line: {
                        text: `Your credit utilization ratio is moderately high at 40% against the optimal level of 30%. `,
                        text_color: "#022C3E"
                    },
                    first_img: {
                        src: congratsImg,
                        alt: "your credit limit is high"
                    },
                    head_main: {
                        text: "High Impact",
                        text_color: "#022C3E"
                    },
                    head_para: {
                        text: "Your credit utilization ratio is moderately high and could be affecting your credit profile negatively. Reducing it to below 30% will significantly improve your credit profile.",
                        text_color: "#022C3E"
                    },
                    indicator_block: {
                        background: "#FEF0C7",
                        border_color: "#F79009",
                        second_img: {
                            src: alertMark,
                            alt: "your credit limit is high"
                        },
                        impact_text: {
                            text: "High",
                            text_color: "#F79009"
                        }
                    },
                    two_stats: [
                        {
                            background: "#E3FCEC",
                            border_color: "#17B26A",
                            impact_text: {
                                text: "Limits Used",
                                text_color: "#044A67"
                            },
                            impact_value: {
                                text: `₹ ${params.a}`,
                                text_color: "#044A67"
                            }
                        },
                        {
                            background: "#E3FCEC",
                            border_color: "#17B26A",
                            impact_text: {
                                text: "Credit Limit",
                                text_color: "#044A67"
                            },
                            impact_value: {
                                text: `₹ ${params.b}`,
                                text_color: "#044A67"
                            }
                        }
                    ]
                };
            case "type_extreme_high":
                return {
                    main_color: {
                        background: "#FFFFFFE5",
                        border_color: "#f2f2f2"
                    },
                    mobile_design: {
                        background_color_1: "#ffe9e7",
                        background_color_2: "#fff3f2",
                    },
                    first_line: {
                        text: `Your high credit utilization ratio at 85%. The optimal credit utlisation ratio is 30%. `,
                        text_color: "#022C3E"
                    },
                    first_img: {
                        src: congratsImg,
                        alt: "your credit limit is high"
                    },
                    head_main: {
                        text: "High Impact",
                        text_color: "#022C3E"
                    },
                    head_para: {
                        text: "You have used approximately 85% of your credit limit on credit cards. Consistently maintaining a high credit utilization ratio is a significant risk to your credit health. Paying down balances to below 30% will help improve your credit score. ",
                        text_color: "#022C3E"
                    },
                    indicator_block: {
                        background: "#FEE4E2",
                        border_color: "#F04438",
                        second_img: {
                            src: p_a_mark4,
                            alt: "your credit limit is high"
                        },
                        impact_text: {
                            text: "Ext. High",
                            text_color: "#F04438"
                        }
                    },
                    two_stats: [
                        {
                            background: "#E3FCEC",
                            border_color: "#17B26A",
                            impact_text: {
                                text: "Limits Used",
                                text_color: "#044A67"
                            },
                            impact_value: {
                                text: `₹ ${params.a}`,
                                text_color: "#044A67"
                            }
                        },
                        {
                            background: "#E3FCEC",
                            border_color: "#17B26A",
                            impact_text: {
                                text: "Credit Limit",
                                text_color: "#044A67"
                            },
                            impact_value: {
                                text: `₹ ${params.b}`,
                                text_color: "#044A67"
                            }
                        }
                    ]
                };
            default:
                return;
        }
    }
    const checkAccountAgeHealthType = (type) => {
        switch (type) {
            case "type_perfect":
                return {
                    main_color: {
                        background: "#FFFFFFE5",
                        border_color: "#f2f2f2"
                    },
                    mobile_design: {
                        background_color_1: "#e7f8f1",
                        background_color_2: "#f1faf5",
                    },
                    first_line: {
                        text: `Your oldest active account reflects a well-established credit history.`,
                        text_color: "#022C3E"
                    },
                    first_img: {
                        src: congratsImg,
                        alt: "Congratulations on your perfect credit score"
                    },
                    head_main: {
                        text: "Medium Impact",
                        text_color: "#022C3E"
                    },
                    head_para: {
                        text: `Your oldest active account has been open for over ${params.oldest_account_age}, a clear indicator of your credit history. The insights into your credit behaviour should be sufficient for lenders to evaluate your credit worthiness.`,
                        text_color: "#022C3E"
                    },
                    indicator_block: {
                        background: "#DCFAE6",
                        border_color: "#17B26A",
                        second_img: {
                            src: perfectImg,
                            alt: "Congratulations on your perfect credit age"
                        },
                        impact_text: {
                            text: "Perfect",
                            text_color: "#079455"
                        }
                    },
                    two_stats: [
                        {
                            background: "#E3FCEC",
                            border_color: "#17B26A",
                            impact_text: {
                                text: "Age Of Accounts",
                                text_color: "#044A67"
                            },
                            impact_value: {
                                text: `${params.a}`,
                                text_color: "#044A67"
                            }
                        },
                        {
                            background: "#E3FCEC",
                            border_color: "#17B26A",
                            impact_text: {
                                text: "Active Accounts",
                                text_color: "#044A67"
                            },
                            impact_value: {
                                text: `${params.b}`,
                                text_color: "#044A67"
                            }
                        }
                    ]
                };
            case "type_good":
                return {
                    main_color: {
                        background: "#FFFFFFE5",
                        border_color: "#f2f2f2"
                    },
                    mobile_design: {
                        background_color_1: "#e7f8f1",
                        background_color_2: "#f1faf5",
                    },
                    first_line: {
                        text: `Your account history demonstrates growing financial vintage.`,
                        text_color: "#022C3E"
                    },
                    first_img: {
                        src: congratsImg,
                        alt: "Congratulations on your perfect credit score"
                    },
                    head_main: {
                        text: "Medium Impact",
                        text_color: "#022C3E"
                    },
                    head_para: {
                        text: `With an active account age of 2 to 5 years, you’ve successfully built a foundation for your credit journey.`,
                        text_color: "#022C3E"
                    },
                    indicator_block: {
                        background: "#DCFAE6",
                        border_color: "#17B26A",
                        second_img: {
                            src: goodMark,
                            alt: "Congratulations on your good credit age"
                        },
                        impact_text: {
                            text: "Good",
                            text_color: "#079455"
                        }
                    },
                    two_stats: [
                        {
                            background: "#E3FCEC",
                            border_color: "#17B26A",
                            impact_text: {
                                text: "Age Of Accounts",
                                text_color: "#044A67"
                            },
                            impact_value: {
                                text: `${params.a}`,
                                text_color: "#044A67"
                            }
                        },
                        {
                            background: "#E3FCEC",
                            border_color: "#17B26A",
                            impact_text: {
                                text: "Active Accounts",
                                text_color: "#044A67"
                            },
                            impact_value: {
                                text: `${params.b}`,
                                text_color: "#044A67"
                            }
                        }
                    ]
                };
            case "type_fair":
                return {
                    main_color: {
                        background: "#FFFFFFE5",
                        border_color: "#f2f2f2"
                    },
                    mobile_design: {
                        background_color_1: "#fff5d7",
                        background_color_2: "#fdf8e8",
                    },
                    first_line: {
                        text: `Your credit history is fairly recent.`,
                        text_color: "#022C3E"
                    },
                    first_img: {
                        src: congratsImg,
                        alt: "Congratulations on your perfect credit score"
                    },
                    head_main: {
                        text: "Medium Impact",
                        text_color: "#022C3E"
                    },
                    head_para: {
                        text: `At 1 to 2 years, your oldest active account is still growing. This period marks the beginning of your credit history, and with time, will provide decent insights for lender evealuating your credit profile.`,
                        text_color: "#022C3E"
                    },
                    indicator_block: {
                        background: "#FEF0C7",
                        border_color: "#F79009",
                        second_img: {
                            src: p_a_mark3,
                            alt: "your credit age was of fair category"
                        },
                        impact_text: {
                            text: "Fair",
                            text_color: "#F79009"
                        }
                    },
                    two_stats: [
                        {
                            background: "#E3FCEC",
                            border_color: "#17B26A",
                            impact_text: {
                                text: "Age Of Accounts",
                                text_color: "#044A67"
                            },
                            impact_value: {
                                text: `${params.a}`,
                                text_color: "#044A67"
                            }
                        },
                        {
                            background: "#E3FCEC",
                            border_color: "#17B26A",
                            impact_text: {
                                text: "Active Accounts",
                                text_color: "#044A67"
                            },
                            impact_value: {
                                text: `${params.b}`,
                                text_color: "#044A67"
                            }
                        }
                    ]
                };
            case "type_poor":
                return {
                    main_color: {
                        background: "#FFFFFFE5",
                        border_color: "#f2f2f2"
                    },
                    mobile_design: {
                        background_color_1: "#ffe9e7",
                        background_color_2: "#fff3f2",
                    },
                    first_line: {
                        text: `Your credit history is relatively new. Building a track record of responsible credit use will improve your profile in the future.`,
                        text_color: "#022C3E"
                    },
                    first_img: {
                        src: congratsImg,
                        alt: "Congratulations on your perfect credit score"
                    },
                    head_main: {
                        text: "Medium Impact",
                        text_color: "#022C3E"
                    },
                    head_para: {
                        text: `Your oldest active account is still under a year old, indicating a relatively new credit history. It’s important to continue using credit responsibly to build a solid foundation for your future creditworthiness.`,
                        text_color: "#022C3E"
                    },
                    indicator_block: {
                        background: "#FEE4E2",
                        border_color: "#F04438",
                        second_img: {
                            src: p_a_mark4,
                            alt: "your credit age was of poor category"
                        },
                        impact_text: {
                            text: "Poor",
                            text_color: "#D92D20"
                        }
                    },
                    two_stats: [
                        {
                            background: "#E3FCEC",
                            border_color: "#17B26A",
                            impact_text: {
                                text: "Age Of Accounts",
                                text_color: "#044A67"
                            },
                            impact_value: {
                                text: `${params.a}`,
                                text_color: "#044A67"
                            }
                        },
                        {
                            background: "#E3FCEC",
                            border_color: "#17B26A",
                            impact_text: {
                                text: "Active Accounts",
                                text_color: "#044A67"
                            },
                            impact_value: {
                                text: `${params.b}`,
                                text_color: "#044A67"
                            }
                        }
                    ]
                };
            default:
                return;
        }
    }
    const checkAccountsHealthType = (type) => {
        const check_part_type = (typ, value) => {
            switch (typ) {
                case "type_a":
                    return `Your credit profile consists of ${value} active accounts, including a mix of secured loans and unsecured loans, without a credit card. Expanding this mix further will improve your credit profile.`;
                case "type_b":
                    return `Your credit profile consists of ${value} active accounts, including a mix of unsecured loans and credit card without secured loans.Expanding this mix further will improve your credit profile.`;
                case "type_c":
                    return `Your credit profile consists of ${value} active accounts, including a mix of secured loans and credit card without unsecured loans. Expanding this mix further will improve your credit profile.`;
                default:
                    return ``;
            }
        }
        switch (type) {
            case "type_perfect":
                return {
                    main_color: {
                        background: "#FFFFFFE5",
                        border_color: "#f2f2f2"
                    },
                    mobile_design: {
                        background_color_1: "#e7f8f1",
                        background_color_2: "#f1faf5",
                    },
                    first_line: {
                        text: `You have a diverse mix of credit types.`,
                        text_color: "#022C3E"
                    },
                    first_img: {
                        src: congratsImg,
                        alt: "Congratulations on your perfect credit score"
                    },
                    head_main: {
                        text: "Medium Impact",
                        text_color: "#022C3E"
                    },
                    head_para: {
                        text: `Your credit profile includes a well-rounded and diverse credit mix of consisting of secured loans, unsecured loans, and credit cards. `,
                        text_color: "#022C3E"
                    },
                    indicator_block: {
                        background: "#DCFAE6",
                        border_color: "#17B26A",
                        second_img: {
                            src: perfectImg,
                            alt: "You have Diverse Mix of accounts"
                        },
                        impact_text: {
                            text: "Perfect",
                            text_color: "#079455"
                        }
                    },
                    two_stats: [
                        {
                            background: "#E3FCEC",
                            border_color: "#17B26A",
                            impact_text: {
                                text: "Active Accounts",
                                text_color: "#044A67"
                            },
                            impact_value: {
                                text: `${params.a}`,
                                text_color: "#044A67"
                            }
                        },
                        {
                            background: "#E3FCEC",
                            border_color: "#17B26A",
                            impact_text: {
                                text: "Closed Accounts",
                                text_color: "#044A67"
                            },
                            impact_value: {
                                text: `${params.b}`,
                                text_color: "#044A67"
                            }
                        }
                    ]
                };
            case "type_good":
                return {
                    main_color: {
                        background: "#FFFFFFE5",
                        border_color: "#f2f2f2"
                    },
                    mobile_design: {
                        background_color_1: "#e7f8f1",
                        background_color_2: "#f1faf5",
                    },
                    first_line: {
                        text: `You have maintained a moderate mix of credit types.`,
                        text_color: "#022C3E"
                    },
                    first_img: {
                        src: congratsImg,
                        alt: "Congratulations on your perfect credit score"
                    },
                    head_main: {
                        text: "Medium Impact",
                        text_color: "#022C3E"
                    },
                    head_para: {
                        text: check_part_type(params?.type, params?.noOfAccounts),
                        text_color: "#022C3E"
                    },
                    indicator_block: {
                        background: "#DCFAE6",
                        border_color: "#17B26A",
                        second_img: {
                            src: goodMark,
                            alt: "Congratulations on your good credit age"
                        },
                        impact_text: {
                            text: "Good",
                            text_color: "#079455"
                        }
                    },
                    two_stats: [
                        {
                            background: "#E3FCEC",
                            border_color: "#17B26A",
                            impact_text: {
                                text: "Active Accounts",
                                text_color: "#044A67"
                            },
                            impact_value: {
                                text: `${params.a}`,
                                text_color: "#044A67"
                            }
                        },
                        {
                            background: "#E3FCEC",
                            border_color: "#17B26A",
                            impact_text: {
                                text: "Closed Accounts",
                                text_color: "#044A67"
                            },
                            impact_value: {
                                text: `${params.b}`,
                                text_color: "#044A67"
                            }
                        }
                    ]
                };
            case "type_fair":
                return {
                    main_color: {
                        background: "#FFFFFFE5",
                        border_color: "#f2f2f2"
                    },
                    mobile_design: {
                        background_color_1: "#fff5d7",
                        background_color_2: "#fdf8e8",
                    },
                    first_line: {
                        text: `Your credit profile has a limited mix of credit products. `,
                        text_color: "#022C3E"
                    },
                    first_img: {
                        src: congratsImg,
                        alt: "Congratulations on your perfect credit score"
                    },
                    head_main: {
                        text: "Medium Impact",
                        text_color: "#022C3E"
                    },
                    head_para: {
                        text: `Your credit profile includes ${params?.noOfAccounts} active accounts, with limited diversity. Having a diverse mix of secured, unsecured and credit cards could enhance your credit profile and strengthen your credit score.`,
                        text_color: "#022C3E"
                    },
                    indicator_block: {
                        background: "#FEF0C7",
                        border_color: "#F79009",
                        second_img: {
                            src: p_a_mark3,
                            alt: "your credit age was of fair category"
                        },
                        impact_text: {
                            text: "Fair",
                            text_color: "#F79009"
                        }
                    },
                    two_stats: [
                        {
                            background: "#E3FCEC",
                            border_color: "#17B26A",
                            impact_text: {
                                text: "Active Accounts",
                                text_color: "#044A67"
                            },
                            impact_value: {
                                text: `${params.a}`,
                                text_color: "#044A67"
                            }
                        },
                        {
                            background: "#E3FCEC",
                            border_color: "#17B26A",
                            impact_text: {
                                text: "Closed Accounts",
                                text_color: "#044A67"
                            },
                            impact_value: {
                                text: `${params.b}`,
                                text_color: "#044A67"
                            }
                        }
                    ]
                };
            case "type_poor":
                return {
                    main_color: {
                        background: "#FFFFFFE5",
                        border_color: "#f2f2f2"
                    },
                    mobile_design: {
                        background_color_1: "#ffe9e7",
                        background_color_2: "#fff3f2",
                    },
                    first_line: {
                        text: `Your credit profile includes only a single type of account. Diversifying your credit mix can improve your credit health and overall score.`,
                        text_color: "#022C3E"
                    },
                    first_img: {
                        src: congratsImg,
                        alt: "Congratulations on your perfect credit score"
                    },
                    head_main: {
                        text: "Medium Impact",
                        text_color: "#022C3E"
                    },
                    head_para: {
                        text: `Your credit profile includes only ${params?.noOfAccounts} active credit type, limiting the strength of your credit mix. Introducing a diverse mix of credit, like an unsecured loan or credit card, will help improve your credit profile.`,
                        text_color: "#022C3E"
                    },
                    indicator_block: {
                        background: "#FEE4E2",
                        border_color: "#F04438",
                        second_img: {
                            src: p_a_mark4,
                            alt: "your credit age was of poor category"
                        },
                        impact_text: {
                            text: "Poor",
                            text_color: "#D92D20"
                        }
                    },
                    two_stats: [
                        {
                            background: "#E3FCEC",
                            border_color: "#17B26A",
                            impact_text: {
                                text: "Active Accounts",
                                text_color: "#044A67"
                            },
                            impact_value: {
                                text: `${params.a}`,
                                text_color: "#044A67"
                            }
                        },
                        {
                            background: "#E3FCEC",
                            border_color: "#17B26A",
                            impact_text: {
                                text: "Closed Accounts",
                                text_color: "#044A67"
                            },
                            impact_value: {
                                text: `${params.b}`,
                                text_color: "#044A67"
                            }
                        }
                    ]
                };
            default:
                return;
        }
    }
    const checkEnquiriesHealthType = (type) => {
        switch (type) {
            case "type_perfect":
                return {
                    main_color: {
                        background: "#FFFFFFE5",
                        border_color: "#f2f2f2"
                    },
                    mobile_design: {
                        background_color_1: "#e7f8f1",
                        background_color_2: "#f1faf5",
                    },
                    first_line: {
                        text: `You have no recent credit enquiries, which reflects responsible credit management and minimizes risk to your profile.`,
                        text_color: "#022C3E"
                    },
                    first_img: {
                        src: congratsImg,
                        alt: "Congratulations on your perfect credit score"
                    },
                    head_main: {
                        text: "Low Impact",
                        text_color: "#022C3E"
                    },
                    head_para: {
                        text: `You have 0 recent credit inquiries, demonstrating a cautious approach to credit applications, which helps maintain your credit score.`,
                        text_color: "#022C3E"
                    },
                    indicator_block: {
                        background: "#DCFAE6",
                        border_color: "#17B26A",
                        second_img: {
                            src: perfectImg,
                            alt: "Congratulations on your perfect credit age"
                        },
                        impact_text: {
                            text: "Perfect",
                            text_color: "#079455"
                        }
                    },
                    two_stats: [
                        {
                            background: "#E3FCEC",
                            border_color: "#17B26A",
                            impact_text: {
                                text: "Loan Enquiries",
                                text_color: "#044A67"
                            },
                            impact_value: {
                                text: `${params.a}`,
                                text_color: "#044A67"
                            }
                        },
                        {
                            background: "#E3FCEC",
                            border_color: "#17B26A",
                            impact_text: {
                                text: "Credit Card Enquiries",
                                text_color: "#044A67"
                            },
                            impact_value: {
                                text: `${params.b}`,
                                text_color: "#044A67"
                            }
                        }
                    ]
                };
            case "type_good":
                return {
                    main_color: {
                        background: "#FFFFFFE5",
                        border_color: "#f2f2f2"
                    },
                    mobile_design: {
                        background_color_1: "#e7f8f1",
                        background_color_2: "#f1faf5",
                    },
                    first_line: {
                        text: `You have a limited number of recent credit enquiries, indicating measured and thoughtful credit behavior.`,
                        text_color: "#022C3E"
                    },
                    first_img: {
                        src: congratsImg,
                        alt: "Congratulations on your perfect credit score"
                    },
                    head_main: {
                        text: "Low Impact",
                        text_color: "#022C3E"
                    },
                    head_para: {
                        text: `You have 1-2 recent credit inquiries, indicating careful credit management and minimizing potential impact on your credit score.`,
                        text_color: "#022C3E"
                    },
                    indicator_block: {
                        background: "#DCFAE6",
                        border_color: "#17B26A",
                        second_img: {
                            src: goodMark,
                            alt: "Congratulations on your good credit age"
                        },
                        impact_text: {
                            text: "Good",
                            text_color: "#079455"
                        }
                    },
                    two_stats: [
                        {
                            background: "#E3FCEC",
                            border_color: "#17B26A",
                            impact_text: {
                                text: "Loan Enquiries",
                                text_color: "#044A67"
                            },
                            impact_value: {
                                text: `${params.a}`,
                                text_color: "#044A67"
                            }
                        },
                        {
                            background: "#E3FCEC",
                            border_color: "#17B26A",
                            impact_text: {
                                text: "Credit Card Enquiries",
                                text_color: "#044A67"
                            },
                            impact_value: {
                                text: `${params.b}`,
                                text_color: "#044A67"
                            }
                        }
                    ]
                };
            case "type_fair":
                return {
                    main_color: {
                        background: "#FFFFFFE5",
                        border_color: "#f2f2f2"
                    },
                    mobile_design: {
                        background_color_1: "#fff5d7",
                        background_color_2: "#fdf8e8",
                    },
                    first_line: {
                        text: `You have more than ideal number of credit enquiries and its likely to affect your score. Spacing out applications is recommended.`,
                        text_color: "#022C3E"
                    },
                    first_img: {
                        src: congratsImg,
                        alt: "Congratulations on your perfect credit score"
                    },
                    head_main: {
                        text: "Low Impact",
                        text_color: "#022C3E"
                    },
                    head_para: {
                        text: `You have 3-5 recent credit inquiries, which may slightly affect your credit score. Reducing the frequency of applications can improve your credit profile.`,
                        text_color: "#022C3E"
                    },
                    indicator_block: {
                        background: "#FEF0C7",
                        border_color: "#F79009",
                        second_img: {
                            src: p_a_mark3,
                            alt: "your credit age was of fair category"
                        },
                        impact_text: {
                            text: "Fair",
                            text_color: "#F79009"
                        }
                    },
                    two_stats: [
                        {
                            background: "#E3FCEC",
                            border_color: "#17B26A",
                            impact_text: {
                                text: "Loan Enquiries",
                                text_color: "#044A67"
                            },
                            impact_value: {
                                text: `${params.a}`,
                                text_color: "#044A67"
                            }
                        },
                        {
                            background: "#E3FCEC",
                            border_color: "#17B26A",
                            impact_text: {
                                text: "Credit Card Enquiries",
                                text_color: "#044A67"
                            },
                            impact_value: {
                                text: `${params.b}`,
                                text_color: "#044A67"
                            }
                        }
                    ]
                };
            case "type_poor":
                return {
                    main_color: {
                        background: "#FFFFFFE5",
                        border_color: "#f2f2f2"
                    },
                    mobile_design: {
                        background_color_1: "#ffe9e7",
                        background_color_2: "#fff3f2",
                    },
                    first_line: {
                        text: `Frequent credit enquiries could harm your score. Limiting new applications is advised.`,
                        text_color: "#022C3E"
                    },
                    first_img: {
                        src: congratsImg,
                        alt: "Congratulations on your perfect credit score"
                    },
                    head_main: {
                        text: "Low Impact",
                        text_color: "#022C3E"
                    },
                    head_para: {
                        text: `You have 6 or more recent credit inquiries in the last 90 days, which could lower your credit score. Limiting new credit applications will help strengthen your overall credit health.`,
                        text_color: "#022C3E"
                    },
                    indicator_block: {
                        background: "#FEE4E2",
                        border_color: "#F04438",
                        second_img: {
                            src: p_a_mark4,
                            alt: "your credit age was of poor category"
                        },
                        impact_text: {
                            text: "Poor",
                            text_color: "#D92D20"
                        }
                    },
                    two_stats: [
                        {
                            background: "#E3FCEC",
                            border_color: "#17B26A",
                            impact_text: {
                                text: "Loan Enquiries",
                                text_color: "#044A67"
                            },
                            impact_value: {
                                text: `${params.a}`,
                                text_color: "#044A67"
                            }
                        },
                        {
                            background: "#E3FCEC",
                            border_color: "#17B26A",
                            impact_text: {
                                text: "Credit Card Enquiries",
                                text_color: "#044A67"
                            },
                            impact_value: {
                                text: `${params.b}`,
                                text_color: "#044A67"
                            }
                        }
                    ]
                };
            default:
                return;
        }
    }
    switch (menuType) {
        case "type_payment":
            return checkPaymentHealthType(healthType)
        case "type_credit_utilisation":
            return checkCreditUtilisationHealthType(healthType);
        case "type_account_age":
            return checkAccountAgeHealthType(healthType);
        case "type_accounts":
            return checkAccountsHealthType(healthType);
        case "type_enquiries":
            return checkEnquiriesHealthType(healthType);
        default:
            return {
            }
    }

}


export function formatTimestampForLastUpdated(timestamp) {
    const date = new Date(timestamp);

    const day = date.getDate();
    const monthNames = [
        "Jan", "Feb", "Mar", "Apr", "May", "Jun",
        "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
    ];
    const month = monthNames[date.getMonth()];
    const year = date.getFullYear();

    // Get the ordinal suffix for the day
    const ordinalSuffix = (n) => {
        if (n > 3 && n < 21) return "th";
        switch (n % 10) {
            case 1: return "st";
            case 2: return "nd";
            case 3: return "rd";
            default: return "th";
        }
    };

    return `${day}${ordinalSuffix(day)} ${month}, ${year}`;
}

