import React, { useState } from "react";
import {
  Box,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useNavigate } from "react-router-dom";

function HomeFaq() {
  const [expanded, setExpanded] = useState(false);
  const navigate = useNavigate()


  const handleSeeMoreClick = () => {
    navigate("/faq")
    window.scrollTo(0, 0);

  };
  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false); // Only the clicked panel will stay expanded
  };
    // FAQ data
    const faqData = [
      {
        question: "What is a credit score?",
        answer:
          "A credit score is a 3-digit numeric score that determines your credit worthiness. Your credit score typically falls between 350-900, and the higher your score the stronger is your credit worthiness.",
      },
      {
        question: "How is a credit score calculated?",
        answer: (
          <>
            <Typography style={{ fontSize: "13px", fontWeight: "500", fontFamily: "CustomFontMedium" }}>
              Your credit score is derived from your past history on:
            </Typography>
            <ul style={{ paddingLeft: "20px", fontWeight: "500", fontFamily: "CustomFontMedium" }}>
              <li>Amount of debt</li>
              <li>Repayment of loans, credit cards</li>
              <li>Number of open credit lines</li>
              <li>Type and sub-type of loans</li>
              <li>Tenure of loans</li>
              <li>Recency of inquiry for new credit line</li>
            </ul>
          </>
        ),
      },
      {
        question: "Who calculates my Credit Score?",
        answer: (
          <>
            In India, there are 4 credit information companies or credit bureaus licensed by the Reserve Bank of India (RBI):
            <ol style={{ paddingLeft: "20px", fontWeight: "500", fontFamily: "CustomFontMedium" }}>
              <li>
                <b>CIBIL</b> - Their credit score range is between 300 and 900.
              </li>
              <li>
                <b>Equifax</b> - Their credit score range is between 300 and 850.
              </li>
              <li>
                <b>Experian</b> - Their credit score range is between 300 and 850.
              </li>
              <li>
                <b>CRIF Highmark</b> - Their credit scores range between 300 and 900.
              </li>
            </ol>
          </>
        ),
      },  

    ];

  return (
    <>
      <Box sx={{ paddingTop: "40px" }}>
        <Typography
          sx={{
            fontSize: {
              xs: "35px",
              sm: "35px",
              md: "45px",
              lg: "58px",
            },
            padding: "10px",
            fontFamily: "CustomFont",
            textAlign: "center",
          }}
        >
          Got questions? We have the answers
        </Typography>
      </Box>

      <Box
        sx={{
          marginTop: "60px",
          paddingRight: {
            xs: "30px",
            sm: "30px",
            lg: "200px",
            md: "200px",
          },
          paddingLeft: {
            xs: "30px",
            sm: "30px",
            lg: "200px",
            md: "200px",
          },

        }}
      >

{faqData.map((faq, index) => (
          <Accordion
            key={index}
            expanded={expanded === `panel${index + 1}`}
            onChange={handleAccordionChange(`panel${index + 1}`)}
            style={{ marginTop: index === 0 ? "0" : "10px" }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`panel${index + 1}-content`}
              id={`panel${index + 1}-header`}
              style={{
                fontSize: "15px",
                fontWeight: "500",
                fontFamily: "CustomFontMedium",
                padding: "5px 20px",
                height: "auto",
              }}
            >
              {faq.question}
            </AccordionSummary>
            <AccordionDetails
              style={{
                fontSize: "13px",
                fontWeight: "500",
                fontFamily: "CustomFontMedium",
                padding: "0 20px 16px",
                letterSpacing:".0179em",
                lineHeight:"1.2rem",
              }}
            >
              {faq.answer}
            </AccordionDetails>
          </Accordion>
        ))}       
     

        <Box
          sx={{ display: "flex", justifyContent: "center", marginTop: "20px" }}
        >
          <Button
            sx={{
              width: "195px",
              height: "45px",
              color: "#030303",
              background: "#FFFFFF 0% 0% no-repeat padding-box",
              fontSize: "22px",
              borderRadius: "40px",
              boxShadow: "0 10px 40px #00000029",
              textTransform: "capitalize",
              fontFamily: 'CustomFontMedium',
            }}
            onClick={handleSeeMoreClick}
          >
            {/* {showAll ? "See Less" : "See More"} */}
            See More
          </Button>
        </Box>
      </Box>
    </>
  );
}

export default HomeFaq;
