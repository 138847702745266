import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Box, Typography, Button } from "@mui/material";

const PageNotFound = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [timer, setTimer] = useState(10); 
  const isMobile = window.innerWidth 


  useEffect(() => {
    const countdown = setInterval(() => {
      setTimer((prevTimer) => prevTimer - 1);
    }, 1000);

    const navigationTimeout = setTimeout(() => {
      const isAccessDenied = location.pathname.includes("/PageNotFound");      
      if (isAccessDenied) {
        navigate("/")
      } else {
        navigate(-1);
      }
    }, 10000);

    return () => {
      clearInterval(countdown);
      clearTimeout(navigationTimeout);
    };
  }, [navigate]);
  const handleNavigation = () => {
    const isAccessDenied = location.pathname.includes("/PageNotFound");
    if (isAccessDenied) {
      navigate("/");
    } else {
      navigate(-1);
    }
  };

  return (
    <Box
      sx={{
        padding: 2,
        height: "95vh",
        backgroundColor: "#fff",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
      }}
    >
      <Box
        sx={{
          backgroundImage: `url(https://cdn.dribbble.com/users/285475/screenshots/2083086/dribbble_1.gif)`,
          height: isMobile <= 600 ? "50%" : "100%",
          width: "100%",
          backgroundSize: "contain",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      />
      <Typography  sx={{ fontSize: "4rem",  }}>
        {location.pathname.includes("/PageNotFound") ? (
          <> <div  style={{fontFamily:"Nunito Sans",fontWeight:"700", fontSize:"20px"}}>Access Denied</div></>
        ) : (
          <><div style={{fontFamily:"Nunito Sans",fontWeight:"600", fontSize:"34px"}}>404</div></>
        )}
      </Typography>
      <Typography variant="h6">
        {location.pathname.includes("/PageNotFound") ? (
          <><div style={{fontFamily:"Nunito Sans",fontWeight:"600", fontSize:"16px"}}>You don't have permission to access this page.</div></>
        ) : (
          <><div style={{fontFamily:"Nunito Sans",fontWeight:"600", fontSize:"18px"}}>Page not found</div></>
        )}
      </Typography>

      <Button
        sx={{
          "&:hover": {
            background: "black", 
            color: "#fff", 
          },
          textTransform: "capitalize",
          fontFamily: "CustomFontMedium",
          fontWeight: "600",
          background: "#085E7F",
          color: "white",
          marginTop: "20px",
          paddingLeft: "20px",
          paddingRight: "20px",
          borderRadius: "20px",
        }}
        onClick={handleNavigation}
      >
        Redirecting in{" "}
        <span
          style={{
            margin: "5px",
            color: "red",
            fontFamily: "CustomFontMedium",
            fontWeight: "600",
          }}
        >
          {timer}
        </span>
        seconds...
      </Button>
    </Box>
  );
};

export default PageNotFound;
