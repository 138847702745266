import { configureStore, createSlice } from "@reduxjs/toolkit";

// Sidebar slice
const sidebarSlice = createSlice({
    name: "sidebar",
    initialState: {
        status: null, 
        isOpen: window.innerWidth >= 1200, // Fully open by default if width >= 1200px
        isHalfOpen: window.innerWidth >= 900 && window.innerWidth < 1200, // Half open if width is between 900px and 1200px
        sidebarWidth: window.innerWidth >= 1200 ? 240 : window.innerWidth >= 900 ? 40 : 0, // Width depends on the screen size
        isGeneralLoading:false,
    },
    reducers: {
        toggleSidebar: (state) => {
            const width = window.innerWidth;
            if (width >= 1200) {
                state.isOpen = !state.isOpen;
                state.isHalfOpen = !state.isOpen;
                state.sidebarWidth = state.isOpen ? 240 : 40;
            } else if (width >= 900 && width < 1200) {
                state.isOpen = !state.isOpen;
                state.isHalfOpen = !state.isOpen;
                state.sidebarWidth = state.isOpen ? 240 : 40;
            } else {
                state.isOpen = !state.isOpen;
                state.sidebarWidth = state.isOpen ? 240 : 0;
            }
        },
        setScreenSize: (state, action) => {
            const width = action.payload;
            if (width >= 1200) {
                state.isOpen = true;
                state.isHalfOpen = false;
                state.sidebarWidth = 240; // Fully open
            } else if (width >= 900 && width < 1200) {
                state.isOpen = false;
                state.isHalfOpen = true;
                state.sidebarWidth = 40; // Half open
            } else {
                state.isOpen = false;
                state.isHalfOpen = false;
                state.sidebarWidth = 0; // Completely closed
            }
        },   
        setGeneralLoading: (state, action) => {
            state.isGeneralLoading = action.payload;
        }
 
    },
});

export const { toggleSidebar, setScreenSize ,setGeneralLoading} = sidebarSlice.actions;

const store = configureStore({
    reducer: {
        sidebar: sidebarSlice.reducer,
    },
});

export default store;
