import React, { useEffect, useRef, useState } from 'react';

// ScoreChart component takes in `score` and `scoreChange` as props
const ScoreChart = ({ score, scoreChange, heightVsWidth }) => {
    // Create refs for the canvas and text elements
    const canvasRef = useRef(null);
    const statusTextRef = useRef(null);
    const scoreValueRef = useRef(null);
    const [animationComplete, setAnimationComplete] = useState(false); // State to track if animation is done

    useEffect(() => {
        const canvas = canvasRef.current; // Get the canvas element
        const ctx = canvas.getContext('2d'); // Get the 2D drawing context

        // Dynamically set the canvas size based on window width
        const baseSize = window.innerWidth < 480 ? (heightVsWidth ? heightVsWidth : 160) : (heightVsWidth ? heightVsWidth : 190); // Smaller size for mobile
        const dpr = window.devicePixelRatio || 1; // Handle high-DPI displays
        const canvasWidth = baseSize * dpr; // Set canvas width considering DPR
        const canvasHeight = baseSize * dpr; // Set canvas height considering DPR
        canvas.width = canvasWidth; // Set the actual canvas size
        canvas.height = canvasHeight;
        canvas.style.width = `${baseSize}px`; // Set the CSS width
        canvas.style.height = `${baseSize}px`; // Set the CSS height

        const radius = canvasWidth / 2; // Calculate the radius of the gauge
        ctx.scale(dpr, dpr); // Scale the canvas for high DPI displays

        const minValue = 300; // Minimum value for the gauge
        const maxValue = 900; // Maximum value for the gauge
        // Define color ranges for the gauge sections
        const gaugeColors = [
            { threshold: 300, color: '#F22424' },  // Red
            { threshold: 420, color: '#FE670A' },  // Orange-Red
            { threshold: 520, color: '#FE670A' },  // Orange
            { threshold: 540, color: '#FED000' },  // Yellow
            { threshold: 660, color: '#34C637' },  // Light Green
            { threshold: 760, color: '#008042' },  // Dark Green
            { threshold: maxValue, color: '#008042' }  // Dark Green
        ];

        let currentScore = 300; // Initial score to start animation
        const animationSpeed = 15; // Speed of the needle animation

        // Function to map a value from one range to another
        const mapValue = (value, min, max, startAngle, endAngle) => {
            return ((value - min) * (endAngle - startAngle)) / (max - min) + startAngle;
        };

        // Draw the gauge with color sections
        const drawGauge = () => {
            const startAngle = 0.75 * Math.PI; // Starting angle for the gauge
            const endAngle = 2.25 * Math.PI;   // Ending angle for the gauge

            ctx.clearRect(0, 0, canvasWidth, canvasHeight); // Clear the canvas
            ctx.globalCompositeOperation = 'source-over'; // Default drawing mode

            // Loop through each color section and draw it on the gauge
            gaugeColors.forEach((section, index) => {
                const nextThreshold = gaugeColors[index + 1] ? gaugeColors[index + 1].threshold : maxValue; // Get the next threshold
                const sectionStartAngle = mapValue(section.threshold, minValue, maxValue, startAngle, endAngle); // Calculate the start angle
                const sectionEndAngle = mapValue(nextThreshold, minValue, maxValue, startAngle, endAngle); // Calculate the end angle

                ctx.beginPath(); // Start drawing the arc
                ctx.arc(radius / dpr, radius / dpr, (radius - 20) / dpr, sectionStartAngle, sectionEndAngle); // Create the arc

                // Set the line width and color for each section
                ctx.lineWidth = 5; // Set line width (thicker for mobile)
                ctx.strokeStyle = section.color; // Set stroke color
                ctx.stroke(); // Draw the arc
            });

            drawTick(currentScore); // Draw the needle on the gauge
            if (animationComplete) {
                updateStatus(currentScore); // Update the status text once animation is complete
            }
        };

        // Function to draw the needle on the gauge
        const drawTick = (value) => {
            const startAngle = 1.25 * Math.PI; // Start angle for the needle
            const endAngle = 2.70 * Math.PI;   // End angle for the needle
            const angle = mapValue(value, minValue, maxValue, startAngle, endAngle); // Calculate the needle's angle

            ctx.save(); // Save the current context
            ctx.translate(radius / dpr, radius / dpr); // Move the origin to the center of the gauge
            ctx.rotate(angle); // Rotate the needle to the correct position

            // Set the needle dimensions
            const tickLength = (radius - 15) / dpr;
            const tickWidth = 8 / dpr;
            const tickHeight = 20 / dpr;
            const tickRadius = 3 / dpr;

            // Draw the needle shape
            ctx.beginPath();
            ctx.moveTo(-tickWidth / 2 + tickRadius, -tickLength);
            ctx.lineTo(tickWidth / 2 - tickRadius, -tickLength);
            ctx.quadraticCurveTo(tickWidth / 2, -tickLength, tickWidth / 2, -tickLength + tickRadius);
            ctx.lineTo(tickWidth / 2, -tickLength + tickHeight - tickRadius);
            ctx.quadraticCurveTo(tickWidth / 2, -tickLength + tickHeight, tickWidth / 2 - tickRadius, -tickLength + tickHeight);
            ctx.lineTo(-tickWidth / 2 + tickRadius, -tickLength + tickHeight);
            ctx.quadraticCurveTo(-tickWidth / 2, -tickLength + tickHeight, -tickWidth / 2, -tickLength + tickHeight - tickRadius);
            ctx.lineTo(-tickWidth / 2, -tickLength + tickRadius);
            ctx.quadraticCurveTo(-tickWidth / 2, -tickLength, -tickWidth / 2 + tickRadius, -tickLength);

            ctx.fillStyle = 'black'; // Set the needle color
            ctx.fill(); // Fill the needle shape
            ctx.restore(); // Restore the context
        };

        // Update the status text based on the score
        const updateStatus = (value) => {
            const color = gaugeColors.find((section, index) => {
                const nextThreshold = gaugeColors[index + 1] ? gaugeColors[index + 1].threshold : maxValue;
                return value >= section.threshold && value < nextThreshold;
            })?.color || 'black'; // Get the color based on the score

            if (statusTextRef.current) {
                // Update the text based on the score range
                if (value < 500) {
                    statusTextRef.current.innerText = "Very Poor";
                } else if (value >= 500 && value < 600) {
                    statusTextRef.current.innerText = "Poor";
                } else if (value >= 600 && value < 700) {
                    statusTextRef.current.innerText = "Average";
                } else if (value >= 700 && value < 800) {
                    statusTextRef.current.innerText = "Good";
                } else {
                    statusTextRef.current.innerText = "Excellent";
                }
                statusTextRef.current.style.color = color; // Set the text color based on score
            }
        };

        // Function to animate the needle moving to the score
        const animateNeedle = () => {
            if (currentScore < score) {
                currentScore += animationSpeed; // Increment the score for the animation
                if (currentScore > score) currentScore = score; // Stop if current score exceeds target score

                drawGauge(); // Redraw the gauge on each frame
                requestAnimationFrame(animateNeedle); // Continue the animation
            } else {
                setAnimationComplete(true); // Mark animation as complete
                updateStatus(currentScore); // Update the status text
            }
        };

        animateNeedle(); // Start the needle animation
    }, [score, scoreChange]); // Re-run effect when `score` or `scoreChange` changes

    // Return JSX for the component
    return (
        <div style={{ textAlign: 'center', fontFamily: 'Arial, sans-serif', position: 'relative' }}>
            <canvas ref={canvasRef} style={{ position: "relative", top: "10px" }}></canvas> {/* Canvas for drawing the gauge */}
            <div style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                textAlign: 'center',
                fontSize: '22px',
            }}>
                <span ref={scoreValueRef} style={{ fontFamily: "CustomFontMedium", fontWeight: "700", fontSize: heightVsWidth ? "16px" : '20px' }}>{score}</span><br />
                <span style={{ fontFamily: "CustomFontMedium", fontWeight: "600", fontSize: heightVsWidth ? "12px" : '16px' }}>Out of 900</span><br />
                <span ref={statusTextRef} style={{ fontSize: heightVsWidth ? "12px" : '16px', fontFamily: "CustomFontMedium", fontWeight: "600" }}></span>
            </div>
        </div>
    );
};

export default ScoreChart;  // Export the component for use in other parts of the app
