import React, { useEffect, useRef, useState } from 'react';
import './style.css';
import "./CreditInsightScreen.css";
import { Chart } from "react-google-charts";
import CreditWorthinessScreen from './CreditWorthnessScreen';
import ScoreGauge from '../../CentralMenu/ScoreGauge';
import { GET_INSIGHTS } from '../../service/ApiList';
import { MdRefresh } from 'react-icons/md';
import cred_1_img from "../../assets/img/credImg1.png";
import cred_2_img from "../../assets/img/credImg2.png";
import cred_3_img from "../../assets/img/credImg3.png";
import CreditMixScreen from './CreditMixScreen';

const CreditHistoryTimeline = ({ propData, summary, mainBlockWidth, mainLoading }) => {
    const [localWidth, setLocalWidth] = useState("");
    useEffect(() => {
        setLocalWidth(mainBlockWidth)
    }, [mainBlockWidth])

    const [isSmall, setIsSmall] = useState(false);
    const { creditHistoryTimeLine } = propData ?? {};
    const CalculateMaxInArray = () => {
        let max = 0;
        for (let i = 1; i < data.length; i++) {
            if (data[i][1] > max) {
                max = data[i][1];
            }
        }
        return max;
    }

    const data = creditHistoryTimeLine ?? [
        ["", "Bad Accounts", "Good Accounts"],
        ["2017", 4, 0],
        ["2018", 5, 1],
        ["2019", 5, 1],
    ];
    const maxValueOnY = CalculateMaxInArray(creditHistoryTimeLine)

    const options = {
        colors: ["#2C2A4A", "#DABFFF"],
        legend: { position: "none" },
        hAxis: {
            title: "",
        },
        vAxis: {
            title: "",
            gridlines: {
                count: 5,
            },
            viewWindow: {
                min: 0,
                max: maxValueOnY && 15,
            },
        },
    };
    useEffect(() => {
        const handleResize = () => {
            setIsSmall(window.innerWidth < 580);
        };

        handleResize(); // Check on mount
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    if (mainLoading) {
        return (<>
            <div className='credit-report-dash-card-2-1-block'>
                <div style={{
                    padding: isSmall ? "0px 20px" : "0px 40px",
                    height: 160, width: "80%"
                }}
                    className='skeleton-generic'
                ></div>
                <div className='premium-report-first-dash-n-12-chart-2'>
                    <div>
                        <span className='skeleton-generic' style={{ height: 10, width: 10 }}></span>
                        <p className='skeleton-generic' style={{ height: 10, width: 50, padding: 0 }}></p>
                    </div>
                    <div>
                        <span className='skeleton-generic' style={{ height: 10, width: 10 }}></span>
                        <p className='skeleton-generic' style={{ height: 10, width: 50, padding: 0 }}></p>
                    </div>
                </div>
            </div>
        </>)
    } else {
        return (<>
            <div className='credit-report-dash-card-2-1-block'>
                <Chart
                    chartType="Bar"
                    width="100%"
                    height="250px"
                    data={isSmall ? data.slice(0, 7) : data}
                    options={options}
                    loader={<div>Loading information...</div>}
                />
                <div className='credit-report-dash-card-2-1-block-chart-1'>
                    <div className='premium-report-first-dash-n-2-chart-2'>
                        <div>
                            <span style={{ background: "#DABFFF" }}></span>
                            <p>Good loans</p>
                        </div>
                        <div>
                            <span style={{ background: "#2C2A4A" }}></span>
                            <p>Bad loans</p>
                        </div>
                    </div>
                </div>
            </div>
        </>)
    }
}
const CreditScoreSteppedLineChart = ({ propData, mainLoading }) => {
    const { scoreTrendLine } = propData;
    const [isSmall, setIsSmall] = useState(false);
    const [data, setData] = useState([
        ["Month", "Credit Score", { role: "tooltip", type: "string", p: { html: true } }, { role: "style" }],
        ["Sep 24", 205, "No Change"],
        ["Oct 24", 505, "Increase"],
    ]);
    const [graphRange, setGraphRange] = useState({ maxScore: 900, minScore: 600 })

    const scoreMapping = [
        { range: [0, 300], label: 'NEW TO CREDIT', color: "#FF5733" },
        { range: [300, 400], label: 'NEED ATTENTION', color: "#FF914D" },
        { range: [400, 500], label: 'POOR', color: "#FFB533" },
        { range: [500, 600], label: 'BELOW AVERAGE', color: "#FFDB33" },
        { range: [600, 700], label: 'AVERAGE', color: "#90CD4F" },
        { range: [700, 750], label: 'GOOD', color: "#90CD4F" },
        { range: [750, 800], label: 'GREAT', color: "#76FF33" },
        { range: [800, 900], label: 'EXCELLENT', color: "#4CAF50" }
    ];
    const getScoreLabelAndColor = (score) => {
        const result = scoreMapping.find(item => score >= item.range[0] && score < item.range[1]);
        return result ? { label: result.label, color: result.color } : { label: "UNKNOWN", color: "#000000" };
    };
    const getTrendColor = (trend) => {
        switch (trend) {
            case "Increase":
                return "#90CD4F"; // Green
            case "Decrease":
                return "#FF4040"; // Red
            case "No Change":
                return "#000000"; // Black
            default:
                return "#000000"; // Default black for unknown trends
        }
    };
    const findMaxMinScores = (data) => {
        const scores = data.slice(1).map(item => item[1]);
        const maxScore = Math.max(...scores);
        const minScore = Math.min(...scores);
        return { maxScore: maxScore >= 880 ? 900 : maxScore + 20, minScore: minScore - 20 };
    };

    useEffect(() => {
        const tempData = scoreTrendLine.slice(1).map(([month, creditScore, trend], index, arr) => {
            const { label, color } = getScoreLabelAndColor(creditScore);
            const trendColor = index == arr.length - 1 ? "#b8b1b1" : getTrendColor(trend);
            return [
                month,
                creditScore,
                `<div class="custom-tooltip">
                    ${month}
                    <br>
                    <span class="highlight">
                      ${label}
                       <br>
                      ${index == arr.length - 1 ? "Predicted" : ""}
                    </span>
                </div>`,
                trendColor
            ];
        });

        setData([
            ["Month", "Line 1", { role: "tooltip", type: "string", p: { html: true } }, { role: "style" }],
            ...tempData
        ]);
        setGraphRange(findMaxMinScores(scoreTrendLine));
    }, [propData]);

    // const options = {
    //     title: "",
    //     hAxis: {
    //         title: "",
    //     },
    //     vAxis: {
    //         title: "",
    //         viewWindow: { min: graphRange.minScore, max: graphRange.maxScore },
    //         // viewWindow: { min: 0, max: 900 },
    //     },
    //     legend: "none",
    //     lineWidth: 2,
    //     pointSize: 5,
    //     series: {
    //         0: {
    //             type: "line", // Line 1 is a solid line
    //             lineWidth: 2,
    //             color: "black",
    //             pointShape: "circle",
    //             visibleInLegend: false,
    //         },
    //         1: {
    //             type: "line", // Line 2 is a dashed line
    //             lineWidth: 2,
    //             color: "#ccc",
    //             lineDashStyle: [4, 4], // Dashed line style for Line 2
    //             visibleInLegend: false,
    //         },
    //     },
    //     chartArea: {
    //         left: 40,
    //         top: 0,
    //         right: 20,
    //         bottom: 0,
    //         width: "100%",
    //         height: "40%",
    //     },
    //     titleTextStyle: {
    //         fontSize: 12,
    //         fontFamily: "'Inter', serif",
    //         fontWeight: 400,
    //     },
    //     tooltip: { isHtml: true },  // Custom tooltip and disable default tooltip
    // };
    const options = {
        title: "",
        hAxis: {
            title: "",
            ticks: data.slice(1).map(item => ({ v: item[0], f: item[0] })), // Ensure months appear on x-axis
            // slantedText: true, // Prevents overlap by angling text
        },
        vAxis: {
            title: "",
            viewWindow: { min: graphRange.minScore, max: graphRange.maxScore },
        },
        legend: "none",
        lineWidth: 2,
        pointSize: 5,
        series: {
            0: { type: "line", lineWidth: 2, color: "black", pointShape: "circle", visibleInLegend: false },
        },
        chartArea: {
            left: 40, top: 10, right: 20, bottom: 50, width: "90%", height: "60%",
        },
        titleTextStyle: { fontSize: 12, fontFamily: "'Inter', serif", fontWeight: 400 },
        tooltip: { isHtml: true },
    };
    useEffect(() => {
        const handleResize = () => {
            setIsSmall(window.innerWidth < 580);
        };

        handleResize(); // Check on mount
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    if (mainLoading) {
        return (
            <div className='premium-report-first-dash-n-12-chart'>
                <div
                    style={{
                        padding: isSmall ? "0px 20px" : "0px 40px",
                        height: 160, width: "80%"
                    }}
                    className='premium-report-first-dash-n-12-chart-1 skeleton-generic'>
                </div>
                <div className='premium-report-first-dash-n-12-chart-2'>
                    <div>
                        <span className='skeleton-generic' style={{ height: 10, width: 10 }}></span>
                        <p className='skeleton-generic' style={{ height: 10, width: 50, padding: 0 }}></p>
                    </div>
                    <div>
                        <span className='skeleton-generic' style={{ height: 10, width: 10 }}></span>
                        <p className='skeleton-generic' style={{ height: 10, width: 50, padding: 0 }}></p>
                    </div>
                    <div>
                        <span className='skeleton-generic' style={{ height: 10, width: 10 }}></span>
                        <p className='skeleton-generic' style={{ height: 10, width: 50, padding: 0 }}></p>
                    </div>
                </div>
            </div>
        );
    } else {
        return (
            <div className='premium-report-first-dash-n-12-chart'>
                <div
                    style={{ padding: isSmall ? "0px 20px" : "0px 40px" }}
                    className='premium-report-first-dash-n-12-chart-1'>
                    <Chart
                        chartType="ComboChart"
                        width={isSmall ? "100%" : "100%"}
                        height="170px"
                        data={data}
                        options={options}
                        loader={<div>Loading information...</div>}
                    />
                </div>
                <div className='premium-report-first-dash-n-12-chart-2'>
                    <div>
                        <span style={{ background: "#90CD4F" }}></span>
                        <p>Increase</p>
                    </div>
                    <div>
                        <span style={{ background: "#FF4040" }}></span>
                        <p>Decrease</p>
                    </div>
                    <div>
                        <span style={{ background: "#000000" }}></span>
                        <p>No change</p>
                    </div>
                </div>
            </div>
        );
    }
};

function CreditInsightScreen() {
    const [isLoading, setIsLoading] = useState(false);
    const mainBlockRef = useRef(null);
    const [blockWidth, setBlockWidth] = useState(0);
    const [insightData, setInsightData] = useState(
        {
            "id": "67a5a11a455ee2744add2d03",
            "createdDate": 1738907930725,
            "lastUpdated": 1738907930725,
            "userId": null,
            "userName": "",
            "mobile": "-",
            "dateOfReport": 1738607400000,
            "creditScore": 792,
            "scoreTrendLine": [],
            "creditHistoryTimeLine": [],
            "creditWorthinessTimeLine": [],
            "inquiryVelocity": [],
            "overallDelinquencyData": {},
            "insightCreditMix": {},
            "bureauInsightRemarks": {},
            "bureauInsightRecommendations": {}
        }
    );
    const insightReportApi = () => {
        setIsLoading(true)
        GET_INSIGHTS({},
            (res) => {
                if (res && res.data.success) {
                    setInsightData(res.data.response);
                    setIsLoading(false)
                }
                else {
                    setIsLoading(false)
                }
            }
        )
    };
    useEffect(() => {
        insightReportApi()
    }, [])

    const generateKeyValueSummary = (inputObject) => {
        const formattedObject = {};

        Object.keys(inputObject).forEach((key) => {
            // Convert the key to lowercase, replace spaces and special characters with underscores
            const formattedKey = key.toLowerCase()
                .replace(/[^a-zA-Z0-9]/g, '_')
                .replace(/__+/g, '_')  // replace multiple underscores with a single one
                .replace(/(^_+|_+$)/g, '');  // trim leading or trailing underscores
            formattedObject[formattedKey] = inputObject[key];
        });

        return formattedObject;
    };
    const formattedReportSummary = generateKeyValueSummary(insightData?.bureauInsightRemarks || { "-----": "------", });
    useEffect(() => {
        const updateWidth = () => {
            if (mainBlockRef.current) {
                setBlockWidth(mainBlockRef.current.offsetWidth);
            }
        };
        const resizeObserver = new ResizeObserver(() => {
            updateWidth();
        });
        if (mainBlockRef.current) {
            resizeObserver.observe(mainBlockRef.current);
            updateWidth(); // Initial width check
        }

        // Cleanup observer on unmount
        return () => {
            if (mainBlockRef.current) {
                resizeObserver.unobserve(mainBlockRef.current);
            }
        };
    }, []);

    const getListOfVerbiage = () => {
        const list = [
            { key: "verbiage-1", label: "Credit Utilisation", para: formattedReportSummary?.credit_utilization_insights, src: cred_1_img, alt: "verbiage 1" },
            { key: "verbiage-2", label: "Payment History Analysis", para: formattedReportSummary?.payment_history_analysis, src: cred_2_img, alt: "verbiage 2" },
            { key: "verbiage-3", label: "Outstanding Accounts Balance & Summary", para: formattedReportSummary?.outstanding_balances_accounts_summary, src: cred_3_img, alt: "verbiage 3" },
        ]
        return list;
    }
    if (isLoading) {
        return (
            <>
                <div ref={mainBlockRef} className="credit-report-dash-main">
                    <div
                        style={{ height: 20, width: 140 }}
                        className='credit-report-dash-head skeleton-generic'></div>
                    <div className='credit-report-dash-body'>
                        <div
                            style={{ flexDirection: blockWidth <= 1200 ? "column" : "row" }}
                            className="credit-report-dash-card-1">
                            <div
                                style={{ width: blockWidth <= 1200 ? "-webkit-fill-available" : "50%" }}
                                className="credit-report-dash-card-1-1">
                                <div style={{ display: "flex", flexDirection: "column", gap: 10 }}>
                                    <div className='skeleton-generic' style={{ height: 160, width: 160, borderRadius: "50%" }}></div>
                                    <div className='skeleton-generic' style={{ height: 30, width: 160 }}></div>
                                    <div className='skeleton-generic' style={{ height: 12, width: 160 }}></div>
                                </div>
                                <div className='credit-report-dash-card-1-1-info-box'
                                    style={{
                                        flex: window.innerWidth <= 700 ? "" : 1,
                                        padding: "0px 20px",
                                        width: window.innerWidth <= 700 ? "-webkit-fill-available" : ""
                                    }}>
                                    <p className='skeleton-generic' style={{ height: 14 }}></p>
                                    <p className='skeleton-generic' style={{ height: 14 }}></p>
                                    <p className='skeleton-generic' style={{ height: 14 }}></p>
                                    <p className='skeleton-generic' style={{ height: 14 }}></p>
                                    <p className='skeleton-generic' style={{ height: 14 }}></p>
                                    <p className='skeleton-generic' style={{ height: 14 }}></p>
                                    <div className='skeleton-generic' style={{ height: 16, marginTop: 10, width: 120, background: "#8080804d" }}></div>
                                </div>
                            </div>
                            <div
                                style={{ width: blockWidth <= 1200 ? "-webkit-fill-available" : "50%" }}
                                className="credit-report-dash-card-1-2">
                                <div

                                    className="credit-report-dash-card-1-2-head">
                                    <div style={{ height: 18, width: 140 }} className='skeleton-generic'></div>
                                </div>
                                <div className='credit-report-dash-card-1-2-body'>
                                    <CreditScoreSteppedLineChart propData={insightData} mainLoading={isLoading} />
                                </div>
                            </div>
                        </div>
                        <div className="credit-report-dash-card-2">
                            <div className="credit-report-dash-card-2-head">
                                <div style={{ height: 18, width: 140 }} className='skeleton-generic'></div>
                            </div>
                            <div
                                style={{ flexDirection: blockWidth <= 1200 ? "column" : "row" }}
                                className='credit-report-dash-card-2-body'>
                                <div
                                    style={{
                                        width: blockWidth <= 1200 ? "-webkit-fill-available" : "50%",
                                    }}
                                    className="credit-report-dash-card-2-1">
                                    <CreditHistoryTimeline mainLoading={isLoading} propData={insightData} summary={formattedReportSummary} mainBlockWidth={blockWidth} />
                                </div>
                                <div
                                    style={{
                                        width: blockWidth <= 1200 ? "-webkit-fill-available" : "50%",
                                        height: blockWidth <= 1200 ? "-webkit-fill-available" : "330px",
                                    }}
                                    className="credit-report-dash-card-2-2">
                                    {getListOfVerbiage().map((item, index) =>
                                        <div key={item.key + index}>
                                            <span>
                                                <div className='skeleton-generic' style={{ height: 30, width: 30, marginBottom: 10 }}></div>
                                                <p className='skeleton-generic' style={{ height: 10, width: 100 }}></p>
                                            </span>
                                            <div style={{ display: "flex", gap: 5, flexDirection: 'column', width: "-webkit-fill-available" }}>
                                                <p className='skeleton-generic' style={{ height: 8, width: "100%" }}></p>
                                                <p className='skeleton-generic' style={{ height: 8, width: "95%" }}></p>
                                                <p className='skeleton-generic' style={{ height: 8, width: "70%" }}></p>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                        <CreditWorthinessScreen propData={insightData} mainLoading={isLoading} />
                    </div>
                </div>
            </>
        )
    } else {
        return (
            <>
                <div ref={mainBlockRef} className="credit-report-dash-main">
                    <div className='credit-report-dash-head'>Credit Insights</div>
                    <div className='credit-report-dash-body'>
                        <div
                            style={{ flexDirection: blockWidth <= 1200 ? "column" : "row" }}
                            className="credit-report-dash-card-1">
                            <div
                                style={{ width: blockWidth <= 1200 ? "-webkit-fill-available" : "50%" }}
                                className="credit-report-dash-card-1-1">
                                <ScoreGauge score={insightData?.creditScore} lastUpdated={insightData?.dateOfReport} />
                                <div className='credit-report-dash-card-1-1-info-box'>
                                    <p>{formattedReportSummary?.credit_score_overview ?? "--"}</p>
                                    {/* <button><MdRefresh style={{ fontSize: 18, fontWeight: "800" }} />Refresh</button> */}
                                </div>
                            </div>
                            <div
                                style={{ width: blockWidth <= 1200 ? "-webkit-fill-available" : "50%" }}
                                className="credit-report-dash-card-1-2">
                                <div className="credit-report-dash-card-1-2-head">
                                    Credit Score History
                                </div>
                                <div className='credit-report-dash-card-1-2-body'>
                                    <CreditScoreSteppedLineChart propData={insightData} />
                                </div>
                            </div>
                        </div>
                        <div className="credit-report-dash-card-2">
                            <div className="credit-report-dash-card-2-head">
                                Credit Journey Snapshot
                            </div>
                            <div
                                style={{ flexDirection: blockWidth <= 1200 ? "column" : "row" }}
                                className='credit-report-dash-card-2-body'>
                                <div
                                    style={{
                                        width: blockWidth <= 1200 ? "-webkit-fill-available" : "50%",
                                    }}
                                    className="credit-report-dash-card-2-1">
                                    <CreditHistoryTimeline propData={insightData} summary={formattedReportSummary} mainBlockWidth={blockWidth} />
                                </div>
                                <div
                                    style={{
                                        width: blockWidth <= 1200 ? "-webkit-fill-available" : "50%",
                                        height: blockWidth <= 1200 ? "-webkit-fill-available" : "330px",
                                    }}
                                    className="credit-report-dash-card-2-2">
                                    {getListOfVerbiage().map((item, index) =>
                                        <div key={item.key + index}>
                                            <span>
                                                <img src={item.src} alt={item.alt} />
                                                <p>{item.label}</p>
                                            </span>
                                            <p>{item.para}</p>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                        <CreditWorthinessScreen propData={insightData} mainBlockWidth={blockWidth} />

                    </div>
                </div>
                <CreditMixScreen insightData={insightData} mainBlockWidth={blockWidth} />
            </>
        )
    }
}

export default CreditInsightScreen