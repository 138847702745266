import React from 'react'
import { Navigate, useLocation } from 'react-router-dom';
import DashboardLayout from '../../Layouts/DashboardLayout';

const AuthPermission = ({ }) => {
    const token = localStorage.getItem("TDFToken");
    const location = useLocation();
    return (
        <>
            {
                token ? (
                    <DashboardLayout />
                ) : (
                    <Navigate to="/login" state={{ from: location }} replace />
                )
            }
        </>
    )
}

export default AuthPermission