import React, { useEffect, useState } from "react";
import "./style.css";
import { IoClose } from "react-icons/io5";

function DownloadReport({ handleCloseModal }) {
    const [countdown, setCountdown] = useState(5); // Countdown starts from 5
    useEffect(() => {
        startCountdown()
    }, [])
    // Function to start countdown and close modal after 5s
    const startCountdown = () => {
        let timeLeft = 5;
        setCountdown(timeLeft);

        const interval = setInterval(() => {
            timeLeft -= 1;
            setCountdown(timeLeft);

            if (timeLeft === 0) {
                clearInterval(interval);
                handleCloseModal(); // Close modal after 5s
            }
        }, 1000);
    };

    const handleClose = () => {
        handleCloseModal();
    };

    return (
        <div className="call-me-back-main">
            <>
                <p>This service will be available soon; we are currently working on it.</p>
                <p>Closing in ({countdown}s)...</p> {/* Countdown Display */}
                <button onClick={handleClose}>Close <IoClose /></button>
            </>
        </div>
    );
}

export default DownloadReport;
