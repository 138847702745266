import React, { useEffect, useState, Suspense } from 'react';
import "./style.css";
import Chart from 'react-google-charts';
import { BsArrowRight, BsChevronCompactLeft } from "react-icons/bs";
import iciciImg from '.././assets/imgs/icici.png';
import pnbImg from '.././assets/imgs/pnb.png';
import northernImg from '.././assets/imgs/northernArc.png';
import { IoCloseCircleSharp } from "react-icons/io5";
import { BsChevronCompactRight } from "react-icons/bs";
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
const PaymentDetail = ({ item, closeModal }) => {
    const data = [
        { label: "Credit Card Payment", billedAmt: "3450", billedDate: "21/10/24", imgSrc: iciciImg, imgAlt: "bank 1" },
        { label: "Home Loan Payment", billedAmt: "23450", billedDate: "20/10/24", imgSrc: pnbImg, imgAlt: "bank 2" },
        { label: "Personal Loan Payment", billedAmt: "2450", billedDate: "27/10/24", imgSrc: northernImg, imgAlt: "bank 3" },

        { label: "Credit Card Payment", billedAmt: "3450", billedDate: "21/10/24", imgSrc: iciciImg, imgAlt: "bank 1" },
        { label: "Home Loan Payment", billedAmt: "23450", billedDate: "20/10/24", imgSrc: pnbImg, imgAlt: "bank 2" },
        { label: "Personal Loan Payment", billedAmt: "2450", billedDate: "27/10/24", imgSrc: northernImg, imgAlt: "bank 3" },
        { label: "Credit Card Payment", billedAmt: "3450", billedDate: "21/10/24", imgSrc: iciciImg, imgAlt: "bank 1" },
        { label: "Home Loan Payment", billedAmt: "23450", billedDate: "20/10/24", imgSrc: pnbImg, imgAlt: "bank 2" },
        { label: "Personal Loan Payment", billedAmt: "2450", billedDate: "27/10/24", imgSrc: northernImg, imgAlt: "bank 3" },
    ]
    return (<>
        <>
            <div className="payment-detail-main">
                <div className='payment-detail-header'>
                    <span>Payment Details</span>
                    <button onClick={closeModal}>
                        <IoCloseCircleSharp style={{ fontSize: 24 }} />
                    </button>
                </div>
                <div className='payment-detail-info'>
                    <p>
                        The total billed amount for your upcoming EMIs or dues for the current month is
                        <b> ₹29,350 </b>. This amount has been carefully grouped and displayed below for
                        your convenience, ensuring a clear and organized view of your financial obligations.
                    </p>
                </div>
                <div className="payment-reminder-blocks-2">
                    {data.map((item, key) =>
                        <div key={key} className="payment-reminder-block-2">
                            <div className="payment-reminder-block-header-2">
                                <span>{item.label}</span>
                            </div>
                            <div className='payment-reminder-block-body-2'>
                                <div className="payment-reminder-block-body-left-2">
                                    <img src={item.imgSrc} alt={item.imgAlt} />
                                </div>
                                <div className="payment-reminder-block-body-right-2">
                                    <div className='payment-reminder-block-body-due-2'>
                                        <span>Billed Amount :</span>
                                        <p> ₹{item.billedAmt}</p>
                                    </div>
                                    <div className='payment-reminder-block-body-amt-2'>
                                        <span>Billed Date :</span>
                                        <p>{item.billedDate}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}

                </div>

            </div>
        </>
    </>)
}

const CustomModal = ({ open, child }) => {
    if (!open) {
        return null;
    }
    return (
        <div className={"custom-modal-main-open"}>
            {child}
        </div>
    );
};

const PaymentReminder = () => {
    const [modalChild, setModalChild] = useState(<></>);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const setChildView = (view, item) => {
        switch (view) {
            case "paymentDetail":
                return <PaymentDetail item={item} closeModal={closeModal} />
            default:
                return;
        }
    }
    const closeModal = () => {
        setIsModalOpen(false);
        document.body.classList.remove('no-scroll');
    }
    const handleOpenModal = (view, item) => {
        const child = setChildView(view, item);
        setModalChild(child);
        setIsModalOpen(true);
        document.body.classList.add('no-scroll');
    }
    const data = [
        { label: "Credit Card Payment", billedAmt: "3450", billedDate: "21/10/24", imgSrc: iciciImg, imgAlt: "bank 1" },
        { label: "Home Loan Payment", billedAmt: "23450", billedDate: "20/10/24", imgSrc: pnbImg, imgAlt: "bank 2" },
        { label: "Personal Loan Payment", billedAmt: "2450", billedDate: "27/10/24", imgSrc: northernImg, imgAlt: "bank 3" },
    ]
    return (<>
        <div className="payment-reminder-main">
            <div className="payment-reminder-header">
                <span>Payment Reminder</span>
            </div>
            <p className='payment-reminder-para'>Set up payment reminders to ensure you never miss a due date. Timely
                payments positively impact your credit score, helping you
                maintain financial health and access better loan terms and credit opportunities.</p>
            <div className="payment-reminder-blocks">
                {data.map((item, key) =>
                    <div key={key} className="payment-reminder-block">
                        <div className="payment-reminder-block-header">
                            <span>{item.label}</span>
                            <BsArrowRight style={{ fontSize: 18 }} />
                        </div>
                        <div className='payment-reminder-block-body'>
                            <div className="payment-reminder-block-body-left">
                                <img src={item.imgSrc} alt={item.imgAlt} />
                            </div>
                            <div className="payment-reminder-block-body-right">
                                <div className='payment-reminder-block-body-due'>
                                    <span>Billed Amount :</span>
                                    <p> ₹{item.billedAmt}</p>
                                </div>
                                <div className='payment-reminder-block-body-amt'>
                                    <span>Billed Date :</span>
                                    <p>{item.billedDate}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                <div className='payment-reminder-view-all-block'>
                    <button onClick={() => {
                        handleOpenModal("paymentDetail", data)
                    }} className='payment-reminder-view-all-btn'>View All</button>
                </div>

            </div>
        </div>
        <CustomModal open={isModalOpen} child={modalChild} />
    </>)
}

const StaggeredBarChart = ({ propData }) => {
    const [isSmall, setIsSmall] = useState(false);
    const [mainReport, setMainReport] = useState([])
    useEffect(() => {
        setMainReport(propData)
    }, [propData])
    const getFirstAndLast12 = (data) => {
        if (data.length <= 12) {
            return data;
        }
        const last12 = data.slice(-12);
        return [data[0], ...last12];
    };
    let data = getFirstAndLast12(mainReport) ??
        [
            ["Month", "Late Payments", "On-Time Payments"],
            ["Oct 2024", 1, 7],
            ["Sep 2024", 0, 7],
            ["Aug 2024", 2, 9],
            ["Jul 2024", 1, 9],
            ["Jun 2024", 2, 9],
            ["May 2024", 1, 9],
            ["Apr 2024", 1, 10],
            ["Mar 2024", 2, 10],
            ["Feb 2024", 1, 10],
            ["Jan 2024", 2, 11],
            ["Dec 2023", 2, 11],
            ["Nov 2023", 1, 10],
        ]

    const barWidth = isSmall ? "15px" : "30px";

    const options = {
        title: "",
        chartArea: {
            left: 40,
            top: 20,
            right: 20,
            bottom: 40,
            width: "80%",
            height: "80%",
        },
        isStacked: true,
        colors: ["#2C2A4A", "#DABFFF"],// late - ontime
        hAxis: {
            title: "",
            textStyle: { color: "#555", fontSize: 12 },
            gridlines: { color: "transparent" },
        },
        vAxis: {
            title: "",
            minValue: 0,
            textStyle: { color: "#555", fontSize: 12 },
        },
        bar: { groupWidth: barWidth },
        legend: { position: "none" },
        tooltip: { isHtml: true },
    };

    useEffect(() => {
        const handleResize = () => {
            setIsSmall(window.innerWidth < 580);
        };

        handleResize(); // Check on mount
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <div className="credit-worth-chart">
            <Chart
                chartType="ColumnChart"
                width={isSmall ? "100%" : "100%"}
                height={isSmall ? "200px" : "350px"}
                data={isSmall ? data.slice(0, 7) : data}
                options={options}
                loader={<div>Loading information...</div>}
            />
            <div className='premium-report-first-dash-12-chart-2'>
                <div>
                    <span style={{ background: "#DABFFF" }}></span>
                    <p>On time Payment</p>
                </div>
                <div>
                    <span style={{ background: "#2C2A4A" }}></span>
                    <p>Late payment</p>
                </div>
            </div>
        </div>
    );
};

// const PaymentDelinquencies = () => {
//     const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
//     const years = [2021, 2022, 2023, 2024];

//     const delinquencyData = {
//         2021: [],
//         2022: ['on-time', 'on-time'], // Assuming Nov and Dec
//         2023: ['on-time', 'on-time', 'on-time', 'on-time', 'on-time', 'on-time', 'on-time', 'late', 'on-time', 'late', 'late', 'on-time'],
//         2024: ['on-time', 'on-time', 'on-time', 'on-time', 'on-time', 'on-time', 'on-time', 'late', 'on-time', 'on-time', 'on-time', 'on-time'],
//     };

//     const handlePaymentType = (status) => {
//         switch (status) {
//             case 'on-time':
//                 return <div className='delin-on-time'></div>;
//             case 'late':
//                 return <div className='delin-late'></div>;
//             case 'no-status':
//                 return <div className='delin-no-status'></div>;
//             default:
//                 return;
//         }
//     };

//     return (
//         <div className='payment-del-main'>
//             <div className="delinquency-table">
//                 <table>
//                     <thead>
//                         <tr>
//                             <th className='sticky-corner'></th>
//                             {months.map((month, index) => (
//                                 <th key={index}>{month}</th>
//                             ))}
//                         </tr>
//                     </thead>
//                     <tbody>
//                         {years.map((year) => {
//                             const data = delinquencyData[year] || [];
//                             const paddedData = [...Array(12 - data.length).fill('empty'), ...data];

//                             return (
//                                 <>
//                                     <tr key={year}>
//                                         <td>{year}</td>
//                                         {paddedData.map((status, index) => (
//                                             <td key={index}>
//                                                 <div className='delin-elm-block'>
//                                                     {handlePaymentType(status)}
//                                                 </div>
//                                             </td>
//                                         ))}
//                                     </tr>
//                                 </>
//                             );
//                         })}
//                     </tbody>
//                 </table>
//             </div>
//             <div className='premium-report-first-dash-12-chart-2'>
//                 <div>
//                     <span style={{ background: "#DABFFF" }}></span>
//                     <p>Delinquent</p>
//                 </div>
//                 <div>
//                     <span style={{ background: "#2C2A4A" }}></span>
//                     <p>Not Delinquent</p>
//                 </div>
//             </div>
//         </div>
//     );
// };

const PaymentDelinquencies = ({ propData }) => {
    const [showAll, setShowAll] = useState(false);
    const [mainReport, setMainReport] = useState({})
    useEffect(() => {
        setMainReport(propData)
    }, [propData])
    const handlePaymentType = (status) => {

        switch (status) {
            case 'on-time':
                return <div className='delin-on-time'></div>;
            case 'amber':
                return <div className='delin-amber'></div>;
            case 'late':
                return <div className='delin-late'></div>;
            case 'no-status':
                return <div className='delin-no-status'></div>;
            default:
                return;
        }
    };
    const [isSmall, setIsSmall] = useState(false);
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const years = Object.keys(mainReport) ?? [];

    const [currentYearIndex, setCurrentYearIndex] = useState(0);

    const convertToMonthlyStatus = (data) => {
        const delinquencyData = {};
        const monthsInYear = 12;
        const threshold = 2;
        const currentYear = new Date().getFullYear();
        const currentMonth = new Date().getMonth();

        Object.keys(data).forEach(year => {
            const monthlyData = Array(monthsInYear).fill("blank");
            const yearData = data[year];

            if (year < currentYear) {
                yearData.forEach((value, index) => {
                    // Set as "on-time" for 0, "late" for values greater than threshold, and "blank" for -1
                    if (value === 0) {
                        monthlyData[index] = "on-time";
                    } else if (value === -1) {
                        monthlyData[index] = "blank";
                    } else if (value === 1 || value === 2) {
                        console.log("11");
                        monthlyData[index] = "amber";
                    } else if (value >= threshold) {
                        monthlyData[index] = "late";
                    }
                });
            } else if (year == currentYear) {
                yearData.forEach((value, index) => {
                    if (index <= currentMonth) {
                        // Apply same conditions for the current year, but only for months up to the current month
                        if (value === 0) {
                            monthlyData[index] = "on-time";
                        } else if (value === -1) {
                            monthlyData[index] = "blank";
                        } else if (value === 1 || value === 2) {
                            console.log("22");
                            monthlyData[index] = "amber";
                        } else if (value >= threshold) {
                            monthlyData[index] = "late";
                        }
                    }
                });
            }

            delinquencyData[year] = monthlyData;
        });

        return delinquencyData;
    };


    const delinquencyData = convertToMonthlyStatus(mainReport)
    const convertDelinquencyData = (data) => {
        const delinquencyData = {};
        const threshold = 3; // Values 3 and above are marked as "late"
        const currentYear = new Date().getFullYear();
        const currentMonth = new Date().getMonth();

        Object.keys(data).forEach((year) => {
            const yearData = data[year];

            delinquencyData[year] = months.map((month, index) => {
                let value = 'blank';
                if (yearData && index < yearData.length) {
                    const status = yearData[index];
                    if (status === 0) {
                        value = 'on-time';
                    } else if (status === -1) {
                        value = 'blank';
                    } else if (status >= 1 && status <= 2) {
                        value = 'amber';
                    } else if (status >= threshold) {
                        value = 'late';
                    }
                }

                // Limit current year data to months up to the current month
                if (parseInt(year) === currentYear && index > currentMonth) {
                    value = 'blank';
                }

                return { month, value };
            });
        });

        return delinquencyData;
    };

    const delinquencyDataMobile = convertDelinquencyData(mainReport)



    useEffect(() => {
        const handleResize = () => {
            setIsSmall(window.innerWidth < 895);
        };

        handleResize(); // Check on mount
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const handleNextYear = () => {
        if (currentYearIndex < years.length - 1) {
            setCurrentYearIndex(currentYearIndex + 1);
        }
    };

    const handlePreviousYear = () => {
        if (currentYearIndex > 0) {
            setCurrentYearIndex(currentYearIndex - 1);
        }
    };
    const displayedYears = showAll ? years : years.slice(-3);
    if (isSmall) {
        return (
            <div className='payment-del-main'>
                <div className='payment-del-head' >
                    <span>Delinquencies</span>
                    <div className='payment-del-head-btn'>
                        <button
                            onClick={handlePreviousYear}
                            disabled={currentYearIndex === 0} // Disable when on the first year
                        >
                            <BsChevronCompactLeft className={currentYearIndex === 0 ? "class-btn-inactive" : "class-btn-active"} />
                        </button>
                        <p>{years[currentYearIndex]}</p> {/* Display current year */}
                        <button
                            onClick={handleNextYear}
                            disabled={currentYearIndex === years.length - 1} // Disable when on the last year
                        >
                            <BsChevronCompactRight className={currentYearIndex === years.length - 1 ? "class-btn-inactive" : "class-btn-active"} />
                        </button>
                    </div>
                </div>
                <div className='payment-del-content-blocks'>
                    {delinquencyDataMobile[years[currentYearIndex]]?.map((item, index) => (
                        <div key={index + "payment-del-content"} className='payment-del-content-block'>
                            <p>{item?.month}</p>
                            <span className={`class-${item?.value}`}></span>
                        </div>
                    ))}

                </div>
                <div className='premium-report-first-dash-12-chart-2 premium-report-first-Worth'>
                    <div>
                        <span style={{ background: "rgba(19, 211, 48, 0.821)" }}></span>
                        <p>Not Delinquent</p>
                    </div>
                    <div>
                        <span style={{ background: "rgba(255, 0, 0, 0.834)" }}></span>
                        <p>Delinquent</p>
                    </div>
                    <div>
                        <span style={{ background: "#ffbf00c4" }}></span>
                        <p>Partially  Delinquent</p>
                    </div>
                </div>
            </div>
        )
    } else {
        return (<>
            <div className='payment-del-main'>
                <div className='payment-del-head-2' >
                    <span className='payment-del-head-2-span'>Delinquencies String</span>
                </div>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: "center" }}>
                    <div className="delinquency-table">
                        <table>
                            <thead>
                                <tr>
                                    <th className='sticky-corner'></th>
                                    {months.reverse().map((month, index) => (
                                        <th key={index}>{month}</th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {displayedYears.reverse().map((year) => {
                                    const data = delinquencyData[year] || [];
                                    const paddedData = [...Array(12 - data.length).fill('empty'), ...data];

                                    return (
                                        <>
                                            <tr key={year}>
                                                <td>{year}</td>
                                                {paddedData.reverse().map((status, index) => (
                                                    <td key={index}>
                                                        <div className='delin-elm-block'>
                                                            {handlePaymentType(status)}
                                                        </div>
                                                    </td>
                                                ))}
                                            </tr>
                                        </>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
                {!(years.length > 3) ? <>

                </> :
                    <>
                        <div style={{ textAlign: 'center', marginTop: '10px' }}>

                            <button className='del-show-more-btn' onClick={() => setShowAll(!showAll)}>
                                <p> {showAll ? "Show Less" : "Show More"}
                                </p>
                                {showAll ? <FaChevronUp /> : <FaChevronDown />}
                            </button>
                        </div>
                    </>
                }

                <div className='premium-report-first-dash-12-chart-2'>
                    <div>
                        <span style={{ background: "rgba(19, 211, 48, 0.821)" }}></span>
                        <p>Not Delinquent</p>
                    </div>
                    <div>
                        <span style={{ background: "rgba(255, 0, 0, 0.834)" }}></span>
                        <p>Delinquent</p>
                    </div>
                    <div>
                        <span style={{ background: "#ffbf00c4" }}></span>
                        <p>Partially  Delinquent</p>
                    </div>
                </div>
            </div>
        </>)
    }
};



const CreditWorthiness = ({ propData }) => {
    const { overallDelinquencyData, creditWorthinessTimeLine, bureauInsightRemarks } = propData;
    const generateKeyValueSummary = (inputObject) => {
        const formattedObject = {};

        Object.keys(inputObject).forEach((key) => {
            // Convert the key to lowercase, replace spaces and special characters with underscores
            const formattedKey = key.toLowerCase()
                .replace(/[^a-zA-Z0-9]/g, '_')
                .replace(/__+/g, '_')  // replace multiple underscores with a single one
                .replace(/(^_+|_+$)/g, '');  // trim leading or trailing underscores

            formattedObject[formattedKey] = inputObject[key];
        });

        return formattedObject;
    };
    const formattedReportSummary = generateKeyValueSummary(bureauInsightRemarks);
    return (
        <>
            <div className="credit-worth-body">
                <div className='credit-worth-inner-body'>
                    <div className="credit-worth-head">
                        <span>Payment Discipline Tracker</span>
                    </div>
                    <p className='credit-worth-top-para'>
                        {/* {formattedReportSummary ? formattedReportSummary?.payment_history_analysis :
                            <>
                                Creditworthiness is a measure of an individual’s or entity’s ability
                                to repay debts based on their financial history, income, and credit
                                behavior. Lenders assess creditworthiness to determine loan eligibility
                                and interest rates. A higher credit score indicates lower risk,
                                while poor credit signals potential default risk.
                            </>
                        } */}
                        <>
                            Financial reliability measures an individual’s or entity’s commitment to meeting debt obligations on time, based on their history of payments, income consistency, and financial responsibility. Tracking payment discipline helps lenders gauge the risk of delinquency, ensuring accounts and loan histories remain in good standing. A strong financial track record demonstrates reliability and increases eligibility for loans and favorable interest rates, while lapses in payment discipline may signal potential risks.
                        </>
                    </p>
                    <div style={{ display: "flex", alignItems: 'center', justifyContent: "center", width: "100%" }}>
                        <div style={{ width: "90%" }}>
                            <StaggeredBarChart propData={creditWorthinessTimeLine} />
                        </div>
                    </div>


                    <PaymentDelinquencies propData={overallDelinquencyData} />
                </div>

            </div>
        </>
    )
}

export default CreditWorthiness