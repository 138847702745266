import React from 'react'
import check from "../assets/img/check.png"
import { ImCancelCircle } from "react-icons/im";

function AccountsCreditCard({creditMixdata }) {
    return (
        <>
            {/* <div className='SecuredCard'>

                <div className='totalamtCardCredit'>
                    <div className='textcardDiv' >
                        <div className='card-details-div-1'>
                            <div> Total Credit Limit : </div>
                            <div>Total Current Outstanding : </div>
                            <div>Billed Due: </div>
                        </div>
                        <div className='card-details-div-2'>
                            <div>₹{creditMixdata?.totalCreditAmount?.toLocaleString('en-IN') }</div>
                            <div>₹{creditMixdata?.outstandingCreditAmount?.toLocaleString('en-IN')}</div>
                            <div>₹{creditMixdata?.creditAmountPastDue?.toLocaleString('en-IN')}</div>

                        </div>
                    </div>
                </div>

                <div className='Accinfo'>
                    <div className='acctiveAccounts'>
                        <div className='text-account-num'>{creditMixdata?.creditActiveAccounts}</div>
                        <div className='text-account-text' >Active Accounts</div>
                    </div>

                    <div className='closedAccounts'>
                        <div className='text-account-num'  >{creditMixdata?.creditClosedAccounts}</div>
                        <div className='text-account-text' >Closed Accounts</div>
                    </div>



                </div>

            </div> */}
                <div className="account-information-card-creditmix">
                    <div className="account-info-heading-h1">Credit Cards</div>
                    <div className="account-ammout-information">
                      <div className="acc-div-info-1">
                        <div className="amount-text-info-text">Total Credit  Limit:</div>
                        <div className="amount-text-info-text">₹{creditMixdata?.totalCreditAmount?.toLocaleString('en-IN') }</div>
                      </div>
            
                      <div className="acc-div-info-1">
                        <div className="amount-text-info-text">Total Outstanding :</div>
                        <div className="amount-text-info-text">₹{creditMixdata?.outstandingCreditAmount?.toLocaleString('en-IN')}</div>
                      </div>
                      
            
                      
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginTop: "18px",
                      padding:"0 10px"
                    }}
                  >
                    <div>
                      <div style={{ display: "flex" }}>
                        <div>
                        <img src={check} alt="check-circle"  className="img-width-1"   />
                        </div>
                        <div className="accounts-text-gap">
                          <div className="accounts-num-coun">{creditMixdata?.creditActiveAccounts}</div>
                          <div className="user-account-status">Active Accounts</div>
                        </div>
                      </div>
                    </div>
            
                    <div>
                      <div style={{ display: "flex" }}>
                        <div>
                          <ImCancelCircle color="#D92D20" size={"24px"} className="img-width-1"  />
                        </div>
                        <div className="accounts-text-gap">
                          <div className="accounts-num-count">{creditMixdata?.creditClosedAccounts}</div>
                          <div className="user-account-status">Closed Accounts</div>
                        </div>
                      </div>
                    </div>
                  </div>
        </>
    )
}

export default AccountsCreditCard;