import React, { useState, useEffect, lazy } from 'react';
import { FaAngleDown, FaAngleLeft, FaAngleRight } from 'react-icons/fa';
import finImg from "../../assets/Bankimgs/pnb.png";
import { BANK_IMG_STACK } from '../../DashboardComponents/LoanCard';

import mark_1 from "../../assets/genInsImgs/mark1.png";
import mark_2 from "../../assets/genInsImgs/mark2.png";
import mark_3 from "../../assets/genInsImgs/mark3.png";
import mark_4 from "../../assets/genInsImgs/mark4.png";
import payment_acct_mark1 from "../../assets/genInsImgs/payment-acct-mark1.png"
import payment_acct_mark2 from "../../assets/genInsImgs/payment-acct-mark2.png"
import payment_acct_mark3 from "../../assets/genInsImgs/payment-acct-mark3.png"
import payment_acct_mark4 from "../../assets/genInsImgs/payment-acct-mark4.png"
import CallMeBackAction from './Actions/CallMeBackAction';
import { getPaymentlinkdata, postSaveUserAction } from '../../service/ApiList';
import PaynowAction from './Actions/PaynowAction';
import { useNavigate } from 'react-router-dom';
import CounterOfferScreen from './Actions/Settlement/CounterOfferScreen';
const ActionButtonBlock = ({ data }) => {
    const [showModal, setShowModal] = useState(false);
    const [modalChild, setModalChild] = useState(null);
    const currentId = data?.id || "0";
    const status = data?.status || "";
    const handleReportAnError = () => {
        const { id, bankName, loanType } = data;
        let params = {
            accountDetailsId: id,
            bankName: bankName,
            userActionType: "REPORT_ERROR"
        }
        postSaveUserAction(params, (res) => {
            if (res) {
                console.log(res)
            } else {
                console.log("err=" + res)
            }
        })
        window.open(
            "https://consumer.experian.in/ECSINDIA-DCE/view/angular/index.html#!/utiERNPage",
            "_blank"
        );
    };
    const handleChild = (type) => {
        switch (type) {
            case 'call_me_back':
                return <CallMeBackAction data={data} handleCloseModal={handleCloseModal} id={currentId} />;
            case 'pay_now':
                return <PaynowAction data={data} handleCloseModal={handleCloseModal} />;
            case 'want_to_settle':
                return <CounterOfferScreen data={data} handleCloseModal={handleCloseModal} />;
            default:
                return null;
        }
    }
    const handleAction = (type) => {
        const child = handleChild(type)
        setModalChild(child);
        setShowModal(true);
    };
    const handleCloseModal = () => setShowModal(false);
    const handlePayNow = () => {
        const { id, bankName, loanType } = data || {}
        const params = {
            productType: loanType.toUpperCase().replace(/\s+/g, "_"),
            accountDetailsId: id,
            bankName: bankName,
        }
        getPaymentlinkdata(params, (res, err) => {
            if (res && res?.data?.link) {
                window.open(res?.data?.link);
                handleAction("pay_now");
            } else {
                handleAction("pay_now");
            }
        });
    };


    return (<>
        {
            showModal
                ?
                <div className='custom-alert-box-2'>
                    {modalChild}
                </div>
                :
                null
        }
        <div className="gyaab-action-btns">
            <div
                style={{ display: status === "Closed" ? "none" : "" }}
                className='gyaab-action-btns-left'
            >
                <p>Act promptly and follow your plan to achieve desired results.</p>
                <div>
                    {data?.isAccountBad && <button onClick={() => handleAction("want_to_settle")}>Want to Settle?</button>}
                    <button onClick={handlePayNow}>Pay now</button>
                    <button onClick={() => handleAction("call_me_back")}>Call Back</button>
                </div>
            </div>
            <span style={{ display: status === "Closed" ? "none" : "" }} className='gyaab-action-btns-brk-line'></span>
            <div className='gyaab-action-btns-right'>
                <p>Inconsistent information? You can rectify it by reporting the discrepancies to the relevant credit bureau.</p>
                <button onClick={handleReportAnError}>Report an error</button>
            </div>
        </div>
    </>)
}

const Payment_Block = (propData) => {
    const [mainReport, setMainReport] = useState({})
    useEffect(() => {
        setMainReport(propData)
    }, [propData])
    const Delinquency = () => {
        const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

        // Reverse years to show the latest year first
        const years = Object.keys(mainReport?.details?.delinquencyData ?? [])
            .map(Number) // Convert string keys to numbers
            .sort((a, b) => b - a); // Sort in descending order (latest year first)

        const [currentYearIndex, setCurrentYearIndex] = useState(0);

        const elem = [
            { key: "elem-1", label: "Paid On Time", src: mark_1, alt: "mark_1" },
            { key: "elem-2", label: "1-89 Days Late", src: mark_2, alt: "mark_2" },
            { key: "elem-3", label: "90+ Days Late", src: mark_3, alt: "mark_3" },
            { key: "elem-4", label: "Not Available", src: mark_4, alt: "mark_4" }
        ];

        // Adjusted button logic
        const handleNextYear = () => {
            if (currentYearIndex > 0) { // Moving to older years
                setCurrentYearIndex(currentYearIndex - 1);
            }
        };

        const handlePreviousYear = () => {
            if (currentYearIndex < years.length - 1) { // Moving to newer years
                setCurrentYearIndex(currentYearIndex + 1);
            }
        };

        const convertDelinquencyData = (data) => {
            const delinquencyData = {};
            const threshold = 3; // Values 3 and above are marked as "late"
            const currentYear = new Date().getFullYear();
            const currentMonth = new Date().getMonth();

            Object.keys(data).forEach((year) => {
                const yearData = data[year];

                delinquencyData[year] = months.map((month, index) => {
                    let value = 'blank';
                    if (yearData && index < yearData.length) {
                        const status = yearData[index];
                        if (status === 0) {
                            value = 'on-time';
                        } else if (status === -1) {
                            value = 'blank';
                        } else if (status >= 1 && status <= 2) {
                            value = 'amber';
                        } else if (status >= threshold) {
                            value = 'late';
                        }
                    }

                    // Limit current year data to months up to the current month
                    if (parseInt(year) === currentYear && index > currentMonth) {
                        value = 'blank';
                    }

                    return { month, value };
                });
            });

            return delinquencyData;
        };

        const delinquencyDataMobile = convertDelinquencyData(mainReport?.details?.delinquencyData ?? {});

        const check_Type = (type) => {
            switch (type) {
                case 'on-time':
                    return mark_1;
                case 'amber':
                    return mark_2;
                case 'late':
                    return mark_3;
                case 'blank':
                    return mark_4;
                default:
                    return;
            }
        };

        return (
            <>
                <div className='payment-del-sub-main'>
                    <div className='payment-del-sub-head'>
                        <div className='payment-del-sub-marks'>
                            {elem.map((item) =>
                                <div key={item.key}>
                                    <img src={item.src} alt={item.alt} />
                                    <p>{item.label}</p>
                                </div>
                            )}
                        </div>
                        <div className='payment-del-sub-btn'>
                            <button
                                onClick={handlePreviousYear}
                                disabled={currentYearIndex === years.length - 1}
                            >
                                <FaAngleLeft className={currentYearIndex === years.length - 1 ? "class-btn-inactive" : "class-btn-active"} />
                            </button>
                            <span>{years[currentYearIndex]}</span>
                            <button
                                onClick={handleNextYear}
                                disabled={currentYearIndex === 0}
                            >
                                <FaAngleRight className={currentYearIndex === 0 ? "class-btn-inactive" : "class-btn-active"} />
                            </button>
                        </div>
                    </div>
                    <hr className='line-break-hr' style={{ marginTop: 10 }} />
                    <div className='payment-del-sub-content-blocks'>
                        {delinquencyDataMobile[years[currentYearIndex]]?.map((item, index) => (
                            <div key={index + "payment-del-content"} className='payment-del-sub-content-block'>
                                <p>{item?.month}</p>
                                <img src={check_Type(item?.value)} alt={item?.value} />
                            </div>
                        ))}
                    </div>
                </div>
            </>
        );
    };


    const check_Verbiage = (acct_type = "type_fair") => {

        const checkTypeForThis = (typ) => {
            switch (typ) {
                case "type_perfect":
                    return {
                        background: "#ebf9f3",
                        src: payment_acct_mark1,
                        alt: "Perfect",
                        label: "Perfect !",
                        para: "You’ve successfully maintained on-time payments for the past three months. Keep it up!"
                    }
                case "type_good":
                    return {
                        background: "#ebf9f3",
                        src: payment_acct_mark2,
                        alt: "Good",
                        label: "Good !",
                        para: "For this account, you’ve been timely with most payments. To enhance your credit standing, it’s important to make sure that you do not miss any future payments and maintain a perfect payment history. This account is impacting your credit score negatively. "
                    }
                case "type_fair":
                    return {
                        background: "#FEF0C7",
                        src: payment_acct_mark3,
                        alt: "Fair",
                        label: "Fair !",
                        para: "For this account, there have been occasional missed payments. Fund your account much ahead of due date, set reminders or automate payments to improve on time payment record. This account is impacting your credit score negatively. "
                    }
                case "type_poor":
                    return {
                        background: "#FFE9E7",
                        src: payment_acct_mark4,
                        alt: "Poor",
                        label: "Poor !",
                        para: "For this account, several payments have been missed, placing the account in a high-risk category and worsening your overall credit standing."
                    }
                default:
                    return {}
            }
        }
        const {
            background,
            src,
            alt,
            label,
            para
        } = checkTypeForThis(acct_type);
        return (<>
            <div className='payment-acct-verbiage'
                style={{ background: background }}
            >
                <img src={src} alt={alt} />
                {acct_type === "type_perfect" ? <b>{label}</b> : null}
                <p>{para}</p>
            </div>
        </>)
    }

    const convertDateToFormat = (timestamp) => {
        const date = new Date(timestamp);
        const options = { day: '2-digit', month: 'short', year: 'numeric' };
        return date.toLocaleDateString('en-GB', options);
    }

    return (<>
        <div className='gyaab-payments'>
            <div className='gyaab-payments-head'>Payment History for this account</div>
            <span className='gyaab-payments-sub-head'>Last Update on {convertDateToFormat(mainReport?.dateReported)} as reported by Experian Bureau</span>
            {Delinquency()}
            {mainReport?.status === "Closed" ? null : check_Verbiage(mainReport?.accountType)}
            <ActionButtonBlock data={mainReport} />
        </div>
    </>)
}
const CheckLimitBar = ({ value }) => {
    const [animatedValue, setAnimatedValue] = useState(0);

    useEffect(() => {
        setTimeout(() => {
            if (value > 100) {
                setAnimatedValue(100);
            } else {
                setAnimatedValue(value);
            }

        }, 300);
    }, [value]);

    return (
        <div className="check-limit-block-bar">
            <div className="check-limit-block-bar-elm"

            >
                {/* Static mark at 30% */}
                <div className="static-marker" style={{ left: "30%" }}>
                    <span className="marker-text">30%</span>
                </div>

                {/* Dynamic pointer */}
                <div
                    className="check-limit-block-bar-elm-pointer"
                    style={{
                        left: `${animatedValue}%`,
                        border: `4px solid ${value < 30 ? "#53ba6e" : "red"}`
                    }}
                >
                    <div className="tooltip"
                        style={{
                            transform: `translateX(${animatedValue < 30 ? 0 : (-100)}%)`
                        }}

                    >{value > 100 ? ">100" : (typeof animatedValue === "number" && !isNaN(animatedValue) ? animatedValue.toFixed(1) : "0")}% Utilised</div>
                </div>
            </div>
        </div>
    );
};
const Credit_Utilisation_Block = (propData) => {
    const [mainReport, setMainReport] = useState({})
    useEffect(() => {
        setMainReport(propData)
    }, [propData])

    const check_Verbiage = (acct_type, limit) => {

        const checkTypeForThis = (typ) => {
            switch (typ) {
                case "type_perfect":
                    return {
                        background: "#ebf9f3",
                        src: payment_acct_mark1,
                        alt: "Perfect",
                        label: "Perfect !",
                        para: "For this credit card, your utilisation has remained well below the ideal threshold, reflecting disciplined usage and contributing to a strong credit profile."
                    }
                case "type_high":
                    return {
                        background: "#ebf9f3",
                        src: payment_acct_mark2,
                        alt: "high",
                        label: "High !",
                        para: `For this credit card, your credit utilization ratio is at ${Number(limit) ? Number(limit).toFixed(1) : "-"}%, which may impact your overall creditworthiness. Managing your spending, increasing your credit limit or making part payments during the month can help improve your credit profile on this parameter. However, please ignore this input if your overall credit utlisation ratio is well within 30%. `
                    }
                case "type_extreme_high":
                    return {
                        background: "#FFE9E7",
                        src: payment_acct_mark4,
                        alt: "Perfect",
                        label: "Extreme High !",
                        para: `For this credit card, your utilization is above ${Number(limit) ? Number(limit).toFixed(1) : "-"}%, placing considerable strain on your credit profile. Reducing this balance will help mitigate further negative impact on your score. Howver, please ignore this input if your overall credit utlisation ratio is well within 30%`
                    }
                default:
                    return {}
            }
        }
        const {
            background,
            src,
            alt,
            label,
            para
        } = checkTypeForThis(acct_type);
        return (<>
            <div className='payment-acct-verbiage'
                style={{ background: background }}
            >
                <img src={src} alt={alt} />
                <p>{para}</p>
            </div>
        </>)
    }

    const convertDateToFormat = (timestamp) => {
        const date = new Date(timestamp);
        const options = { day: '2-digit', month: 'short', year: 'numeric' };
        return date.toLocaleDateString('en-GB', options);
    }

    const checkLimitBlock = (data) => {
        if (!data) return null;
        const { limitUtilized = 0, creditLimit = 0 } = data.details || {};
        return (
            <div className="check-limit-block" >
                <CheckLimitBar value={data?.limitUtilized || 0} />
                <div className='check-limit-block-stats'>
                    <div>
                        <span>₹ {limitUtilized}</span>
                        <p>Limit Used</p>
                    </div>
                    <hr className='line-break-hr-2' />
                    <div>
                        <span>₹ {creditLimit}</span>
                        <p>Credit Limit</p>
                    </div>
                </div>
            </div>
        );
    };

    return (<>
        <div className='gyaab-payments'>
            <div className='gyaab-payments-head'>Credit Utilization</div>
            <span className='gyaab-payments-sub-head' >
                Last Update on&nbsp;
                {convertDateToFormat(mainReport?.dateReported)}
                &nbsp;
                as reported by Experian Bureau . &nbsp;
                <span>
                    Stay under 30% credit use for good credit score
                </span>
            </span>
            {
                mainReport?.status === "Closed"
                    ? null
                    :
                    <>
                        {checkLimitBlock(mainReport)}
                        {check_Verbiage(mainReport?.accountType, mainReport?.limitUtilized)}
                    </>
            }
            <div style={{ marginTop: mainReport?.status === "Closed" ? 20 : "" }}></div>
            <ActionButtonBlock data={mainReport} />
        </div>
    </>)
}
const Account_Age_Block = (propData) => {
    const [mainReport, setMainReport] = useState({})
    useEffect(() => {
        setMainReport(propData)
    }, [propData])

    const check_Verbiage = (acct_type = "type_fair") => {
        const checkTypeForThis = (typ) => {
            switch (typ) {
                case "type_perfect":
                    return {
                        background: "#ebf9f3",
                        src: payment_acct_mark1,
                        alt: "Perfect",
                        label: "Perfect !",
                        para: "You’ve successfully maintained on-time payments for the past three months. Keep it up!"
                    }
                case "type_good":
                    return {
                        background: "#ebf9f3",
                        src: payment_acct_mark2,
                        alt: "Good",
                        label: "Good !",
                        para: "For this account, you’ve been timely with most payments. To enhance your credit standing, it’s important to make sure that you do not miss any future payments and maintain a perfect payment history. This account is impacting your credit score negatively. "
                    }
                case "type_fair":
                    return {
                        background: "#FEF0C7",
                        src: payment_acct_mark3,
                        alt: "Fair",
                        label: "Fair !",
                        para: "For this account, there have been occasional missed payments. Fund your account much ahead of due date, set reminders or automate payments to improve on time payment record. This account is impacting your credit score negatively. "
                    }
                case "type_poor":
                    return {
                        background: "#FFE9E7",
                        src: payment_acct_mark4,
                        alt: "Poor",
                        label: "Poor !",
                        para: "For this account, several payments have been missed, placing the account in a high-risk category and worsening your overall credit standing."
                    }
                default:
                    return {}
            }
        }
        const {
            background,
            src,
            alt,
            label,
            para
        } = checkTypeForThis(acct_type);
        return (<>
            <div className='payment-acct-verbiage'
                style={{ background: background, marginTop: 20 }}
            >
                <img src={src} alt={alt} />
                <p>{para}</p>
            </div>
        </>)
    }

    const convertDateToFormat = (timestamp) => {
        const date = new Date(timestamp);
        const options = { day: '2-digit', month: 'short', year: 'numeric' };
        return date.toLocaleDateString('en-GB', options);
    }

    return (<>
        <div className='gyaab-payments'>
            <div className='gyaab-payments-head'>Account Details</div>
            <span className='gyaab-payments-sub-head'>
                Last Update on&nbsp;
                {convertDateToFormat(mainReport?.dateReported)}
                &nbsp;
                as reported by Experian Bureau . &nbsp;
            </span>
            {/* {check_Verbiage(mainReport?.accountType, mainReport?.limitUtilized)} */}
            <div style={{ margin: 10 }}></div>
            <ActionButtonBlock data={mainReport} />
        </div>
    </>)
}
const Accounts_Block = (propData) => {
    const [mainReport, setMainReport] = useState({})
    useEffect(() => {
        setMainReport(propData)
    }, [propData])

    const check_Verbiage = (acct_type = "type_fair") => {
        const checkTypeForThis = (typ) => {
            switch (typ) {
                case "type_perfect":
                    return {
                        background: "#ebf9f3",
                        src: payment_acct_mark1,
                        alt: "Perfect",
                        label: "Perfect !",
                        para: "You’ve successfully maintained on-time payments for the past three months. Keep it up!"
                    }
                case "type_good":
                    return {
                        background: "#ebf9f3",
                        src: payment_acct_mark2,
                        alt: "Good",
                        label: "Good !",
                        para: "For this account, you’ve been timely with most payments. To enhance your credit standing, it’s important to make sure that you do not miss any future payments and maintain a perfect payment history. This account is impacting your credit score negatively. "
                    }
                case "type_fair":
                    return {
                        background: "#FEF0C7",
                        src: payment_acct_mark3,
                        alt: "Fair",
                        label: "Fair !",
                        para: "For this account, there have been occasional missed payments. Fund your account much ahead of due date, set reminders or automate payments to improve on time payment record. This account is impacting your credit score negatively. "
                    }
                case "type_poor":
                    return {
                        background: "#FFE9E7",
                        src: payment_acct_mark4,
                        alt: "Poor",
                        label: "Poor !",
                        para: "For this account, several payments have been missed, placing the account in a high-risk category and worsening your overall credit standing."
                    }
                default:
                    return {}
            }
        }
        const {
            background,
            src,
            alt,
            label,
            para
        } = checkTypeForThis(acct_type);
        return (<>
            <div className='payment-acct-verbiage'
                style={{ background: background, marginTop: 20 }}
            >
                <img src={src} alt={alt} />
                <p>{para}</p>
            </div>
        </>)
    }

    const convertDateToFormat = (timestamp) => {
        const date = new Date(timestamp);
        const options = { day: '2-digit', month: 'short', year: 'numeric' };
        return date.toLocaleDateString('en-GB', options);
    }

    const details_block = (items = []) => {
        return (<>
            <div className="gyaab-enquiry-elemets">
                {items.map((it, i) =>
                    <div key={i + it}>
                        <span>{it.label}</span>
                        <p>{it.value || "-"}</p>
                    </div>
                )}
            </div>
        </>)
    }

    return (<>
        <div className='gyaab-payments'>
            <div className='gyaab-payments-head'>Account Details</div>
            <span className='gyaab-payments-sub-head'>
                Last Update on&nbsp;
                {convertDateToFormat(mainReport?.dateReported)}
                &nbsp;
                as reported by Experian Bureau . &nbsp;
            </span>
            {details_block(mainReport?.details?.elements)}
            <div style={{ margin: 10 }}></div>
            {/* {check_Verbiage(mainReport?.accountType, mainReport?.limitUtilized)} */}
            <ActionButtonBlock data={mainReport} />
        </div>
    </>)
}
const Enquiries_Block = () => {
    return (<>
        Enquiry block
    </>)
}
const GeneralElementHandler = ({ type, data }) => {
    const getDetails = (val) => {
        switch (val) {
            case "type_payment":
                return Payment_Block(data);
            case "type_credit_utilisation":
                return Credit_Utilisation_Block(data);
            case "type_account_age":
                return Account_Age_Block(data);
            case "type_accounts":
                return Accounts_Block(data);
            case "type_enquiries":
                return Enquiries_Block(data);
            default:
                return "";
        }
    }

    return (<>
        <div className="gnrl-your-account-accordion-body">
            <hr className='line-break-hr' />
            {getDetails(type)}
        </div>
    </>)
}


const GeneralYourAccounts = ({ accounts, type, mainLoading }) => {
    const [openAccordionIndex, setOpenAccordionIndex] = useState(null); // Track which accordion is open
    const [searchQuery, setSearchQuery] = useState(""); // For the search input
    const [filteredAccounts, setFilteredAccounts] = useState(accounts); // Filtered accounts based on search

    useEffect(() => {
        const timeout = setTimeout(() => {
            const lowerCaseQuery = searchQuery.toLowerCase();
            const filtered = accounts.filter(account =>
                account.bankName.toLowerCase().includes(lowerCaseQuery) ||
                account.loanType.toLowerCase().includes(lowerCaseQuery)
            );
            setFilteredAccounts(filtered);
        }, 300); // Debounce delay: 300ms

        return () => clearTimeout(timeout); // Cleanup the timeout on query change
    }, [searchQuery, accounts]);

    const toggleAccordion = (index) => {
        setOpenAccordionIndex(openAccordionIndex === index ? null : index);
    };

    const getColor = (it) => {
        switch (it) {
            case "Active":
                return "green";
            case "Closed":
                return "red";
            default:
                return "";
        }
    }

    const getValue = (it) => {
        if (it.length > 6) {
            return it.slice(0, 6);
        }
        switch (it) {
            case "Active":
                return "Active";
            case "Closed":
                return "Closed";
            default:
                return it;
        }
    }
    const shortenText = (text) => {
        if (text.length <= 10) return text; // If length is 10 or less, return as is
        const lastSix = text.slice(-4); // Get last 6 characters
        const hiddenPart = "x".repeat(6); // Replace the first part with 'X'
        return hiddenPart + lastSix;
    };

    const getColorFontPercentage = (account) => {
        const per = account.paymentPercentage * 100;
        const status = account.status;
        if (status === "Closed") {
            return { color: "#918b8b", fontWeight: "700" }
        } else {
            if (per >= 100) {
                return { color: "#75c258", fontWeight: "700" }
            } else if (per < 100 && per > 90) {
                return { color: "rgb(240, 194, 75)", fontWeight: "700" }
            } else if (per <= 90 && per > 70) {
                return { color: "#f9955b", fontWeight: "700" }
            } else if (per <= 70) {
                return { color: "#ff0000", fontWeight: "700" }
            }
        }
    }

    const getCreditPercentageColor = (account) => {
        const per = account.limitUtilized;
        console.log(per)
        if (per <= 30) {
            return "green"
        } else if (per > 30 && per < 70) {
            return "rgb(240, 194, 75)"
        } else if (per >= 70) {
            return "red"
        }
    }
    const getLabelColor = (label, account, value) => {
        if (label === "On-Time Payments") {
            return getColorFontPercentage(account).color;
        } else if (label === "Limit Used") {
            return getCreditPercentageColor(account);
        } else {
            return getColor(value);
        }
    };

    if (mainLoading) {
        return (
            <>
                <div className='gnrl-your-account-stats-search'>
                    <div className='skeleton-generic' style={{ height: 16, width: 120 }}></div>
                    <div className='skeleton-generic' style={{ height: 26, width: 140 }}></div>
                </div>
                <div className='gnrl-your-account-main'>
                    {[...Array(5)].map((account, index) => (
                        <div className='gnrl-your-account-accordion'>
                            <div className='gnrl-your-account-accordion-head'>
                                <div className='gnrl-your-account-accordion-head-bank'>
                                    <div className='skeleton-generic' style={{ height: 30, width: 30, borderRadius: "50%" }}></div>
                                    <div>
                                        <p className='skeleton-generic' style={{ height: 16, width: 180, marginBottom: 10 }}></p>
                                        <span className='skeleton-generic' style={{ height: 14, width: 120 }}></span>
                                    </div>
                                </div>
                                <div className='gnrl-your-account-accordion-head-elm'>
                                    {[...Array(3)].map((it, i) =>
                                        <div  >
                                            <p className='skeleton-generic' style={{ height: 14, width: 120, marginBottom: 10 }}></p>
                                            <span className='skeleton-generic' style={{ height: 14, width: 90 }}></span>
                                        </div>
                                    )}
                                </div>
                            </div>

                            <button  >
                                <div className='skeleton-generic' style={{ height: 12, width: 100 }}></div>
                            </button>
                        </div>
                    ))}
                </div>
            </>
        )
    }
    return (
        <>
            <div className='gnrl-your-account-stats-search'>
                Your Accounts {`(${filteredAccounts.length})`}
                <input
                    type='text'
                    placeholder='🔍 Search bank'
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                />
            </div>
            <div className='gnrl-your-account-main'>
                {filteredAccounts.map((account, index) => (
                    <div className='gnrl-your-account-accordion' key={index}>
                        <div className='gnrl-your-account-accordion-head'>
                            <div className='gnrl-your-account-accordion-head-bank'>
                                <img src={BANK_IMG_STACK(account.bankName)} alt='bank-icon' />
                                <div>
                                    <p>{account.bankName}</p>
                                    <span>{account.loanType}</span>
                                </div>
                            </div>
                            <div className='gnrl-your-account-accordion-head-elm'>
                                {account.fields.map((it, i) =>
                                    <div style={{ width: i === 0 ? 100 : "" }} key={it.label}>
                                        <p>{it.label}</p>
                                        <span
                                            title={it.value || "-"}
                                            style={{ color: getLabelColor(it.label, account, it.value) }}
                                        >
                                            {it.label === "Status" ?
                                                getValue(it.value) :
                                                it.label === "Account No"
                                                    ?
                                                    shortenText(it.value)
                                                    :
                                                    it.value || "-"
                                            }
                                        </span>
                                    </div>
                                )}
                            </div>
                        </div>
                        {openAccordionIndex === index && (
                            <GeneralElementHandler type={type} data={account} />
                        )}
                        <button style={{ display: type === "type_enquiries" ? "none" : "" }} onClick={() => toggleAccordion(index)}>
                            {openAccordionIndex === index ? 'Hide Details' : 'More Details'}{' '}
                            <FaAngleDown
                                style={{
                                    transform: openAccordionIndex === index ? 'rotate(180deg)' : 'rotate(0deg)',
                                    transition: 'transform 0.3s ease',
                                }}
                            />
                        </button>
                    </div>
                ))}
            </div>
        </>
    );
};

export default GeneralYourAccounts;
