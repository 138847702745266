import React, { useEffect, useRef, useState } from 'react';
import "./style.css";
import ScoreGauge from '../../CentralMenu/ScoreGauge';
import { AiOutlineCloudDownload } from 'react-icons/ai';
import { PiShareFat, } from 'react-icons/pi';
import GeneralYourAccounts from './GeneralYourAccounts';
import { postGenericInsights } from '../../service/ApiList';
import { RxReload } from 'react-icons/rx';
import MobileBadLoanScreen from './MobileBadLoanScreen';
import { RiFileCopy2Line } from 'react-icons/ri';
import { useNavigate } from 'react-router-dom';
const GreetBlock = ({ isLoading, mainData }) => {
    const { userName } = mainData || { userName: "" };
    if (isLoading) {
        return (<><div className='greet-block-main' style={{ padding: 5 }}>
            <div className="greet-block-name">
                {/* <span><b>Hello, </b> Gaurav Badgujar</span>
            <p>It's good to see you again.</p> */}
                <span className='skeleton-generic' style={{ height: 20, width: 100 }}></span>
                <p className='skeleton-generic' style={{ height: 20, width: 120 }}> </p>
            </div>
            {/* <div className="greet-block-date" >
                <span className='skeleton-generic' style={{ height: 20, width: 120 }}> </span>
                <p className='skeleton-generic' style={{ height: 20, width: 120 }}> </p>
            </div> */}
        </div>
            <hr className='hr-line-break' />
        </>)
    } else
        return (<>
            <div className='greet-block-main'>
                <div className="greet-block-name">
                    {/* <span><b>Hello, </b> Gaurav Badgujar</span>
                <p>It's good to see you again.</p> */}
                    <span>Hello, <b>{userName}!</b> </span>
                    <p> Good to see you again.</p>
                </div>
                {/* <div className="greet-block-date">
                    <span>Last Updated on : </span>  <p> 21 sept, 24</p>
                </div> */}
            </div>
            <hr className='hr-line-break' />
        </>)
}
const CommonLandScreen = ({ isLoading, mainData, mainBlockWidth }) => {
    const navigate = useNavigate();
    const goToCreditReport = () => {
        navigate('/home/dashboard')
    }
    const {
        creditRemarks,
        dateOfReport,
        creditScore
    } = mainData || { creditRemarks: "N/A", dateOfReport: "", creditScore: "" }
    if (isLoading) {
        return (<>
            <div className='landing-page-common-main'
                style={{ height: 260 }}
            >
                <div className='landing-page-common'>
                    <div style={{ display: "flex", flexDirection: "column", gap: 10 }}>
                        <div className='skeleton-generic' style={{ height: 160, width: 160, borderRadius: "50%" }}></div>
                        <div className='skeleton-generic' style={{ height: 30, width: 160 }}></div>
                        <div className='skeleton-generic' style={{ height: 12, width: 160 }}></div>
                    </div>
                    <p style={{ height: "auto", width: "100%", display: window.innerWidth <= 650 ? "none" : "flex", flexDirection: "column", gap: 10 }}>
                        <p>
                            <div className='skeleton-generic' style={{ height: 18, width: "100%" }}></div>
                            <div className='skeleton-generic' style={{ height: 18, width: "100%" }}></div>
                            <div className='skeleton-generic' style={{ height: 18, width: "100%" }}></div>
                            <div className='skeleton-generic' style={{ height: 18, width: "50%" }}></div>
                        </p>
                        <div className='landing-page-common-btns' style={{ gap: 10 }}>
                            <div className='skeleton-generic' style={{ height: 35, width: 160, borderRadius: 48 }}></div>
                            <div className='skeleton-generic' style={{ height: 35, width: 160, borderRadius: 48 }}></div>
                        </div>
                    </p>

                </div>

            </div>
        </>)
    } else {
        return (
            <>
                <div
                    style={{ border: mainBlockWidth >= 900 ? "1px solid #f3f4f4" : "none" }}
                    className='landing-page-common-main'>
                    <div className='landing-page-common'>
                        <ScoreGauge score={creditScore} isLoading={isLoading} lastUpdated={dateOfReport} />
                        <p>
                            {creditRemarks}
                            {/* {
                                window.innerWidth >= 950
                                    ?
                                    <div className='landing-page-common-btns'>
                                        <button onClick={goToCreditReport} style={{ border: "none" }}>
                                            <RiFileCopy2Line style={{ fontSize: 16 }} />
                                            Credit Report
                                        </button>
                                        <button style={{ border: "none" }}>
                                            <RxReload style={{ fontSize: 16 }} />
                                            Refresh
                                        </button>
                                        <button>
                                            <PiShareFat style={{ fontSize: 16 }} />
                                            Share
                                        </button>
                                        <button>
                                            <AiOutlineCloudDownload style={{ fontSize: 16 }} />
                                            Download Report
                                        </button>
                                    </div>
                                    :
                                    null
                            } */}
                        </p>

                    </div>

                </div>
            </>
        )
    }

}

const reorderArray = (arr) => {
    return arr.sort((a, b) => {
        if (a.status === "Active") return -1; // Active should come first
        if (b.status === "Active") return 1;
        if (a.status === "Closed") return 1; // Closed should come last
        if (b.status === "Closed") return -1;
        return 0; // Keep others in between
    });
};


const PaymentSection = ({ mainBlockWidth, isLoading, mainData }) => {
    const [insightType, setInsightType] = useState("type_perfect");

    const [loading, setLoading] = useState(true);
    const [details, setDetails] = useState([]);
    const checkType = () => {
        const { totalPayments, onTimePayments } = mainData || {};

        // Handle cases where mainData is empty or missing required fields
        if (!totalPayments || !onTimePayments) {
            setInsightType("type_poor"); // Default or fallback value
            return;
        }

        const res = onTimePayments / totalPayments;

        if (res === 1) {
            setInsightType("type_perfect");
        } else if (res < 1 && res >= 0.9) {
            setInsightType("type_good");
        } else if (res < 0.9 && res >= 0.7) {
            setInsightType("type_fair");
        } else if (res < 0.7) {
            setInsightType("type_poor");
        }
    };
    const checkSubType = (data) => {
        const { totalPayments, onTimePayments } = data || {};

        // Handle cases where mainData is empty or missing required fields
        if (!totalPayments || !onTimePayments) {
            setInsightType("type_poor"); // Default or fallback value
            return;
        }
        const res = onTimePayments / totalPayments;

        if (res === 1) {
            return "type_perfect"
        } else if (res < 1 && res >= 0.9) {
            return "type_good"
        } else if (res < 0.9 && res >= 0.7) {
            return "type_fair"
        } else if (res < 0.7) {
            return "type_poor"
        }
    };
    const calculatePercentage = (a, b) => {
        if (b === 0) {
            return 'Error: Division by zero';
        }
        const percentage = (a / b) * 100;
        return percentage.toFixed(0);
    }

    useEffect(() => {
        checkType();
        const { insightAccountDetails = [] } = mainData;
        createDetails(insightAccountDetails)
    }, [mainData]); // Re-run when mainData changes


    const createDetails = (data) => {
        setLoading(true);
        const formatText = (text) => {
            return text
                .toLowerCase() // Convert to lowercase
                .replace(/[_\.]+/g, " ") // Replace underscores and dots with spaces
                .split(" ") // Split into words
                .map(word => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize first letter
                .join(" "); // Join words back into a sentence
        };
        const shortenText = (text) => {
            if (text.length <= 10) return text; // If length is 10 or less, return as is
            const lastSix = text.slice(-4); // Get last 6 characters
            const hiddenPart = "X".repeat(6); // Replace the first part with 'X'
            return hiddenPart + lastSix;
        };
        let res = data.filter((it) => it.isAccountBad).map((item) => ({
            data:item,
            id: item?.id,
            bankName: item?.subscriberName || "-",
            loanType: formatText(item?.loanCategory) || "-",
            fields: [
                { label: "Account No", value: item?.accountNumber || "-" },
                { label: "On-Time Payments", value: `${item?.onTimePayments}/${item?.totalPayments} ` || "0/0" },
                { label: "Status", value: formatText(item?.status) || "-" }
            ],
            dateReported: item?.dateReported || "0",
            paymentPercentage: `${item?.onTimePayments / (item?.totalPayments)}`,
            accountType: checkSubType(item),
            status: formatText(item?.status),
            isAccountBad: item?.isAccountBad,
            details: {
                delinquencyData: item?.delinquencyData || {}
            }
        }))
        let finalRes = reorderArray(res)
        setDetails(finalRes);
        setLoading(false);
    }


    return (
        <>
            <div className="generic-payment-sec-main" style={{ padding: "0px 10px 24px" }}>
                {loading ? (
                    <p>Loading...</p>
                ) :
                    <GeneralYourAccounts
                        type={"type_payment"}
                        mainLoading={isLoading}
                        accounts={details}
                    />
                }

            </div>
        </>
    );
};

const RenderBlock = ({ mainBlockWidth = 300, isLoading, mainData }) => {
    const [activeTabBody, setActiveTabBody] = useState("payments");
    const [blockWidth, setBlockWidth] = useState(300);

    useEffect(() => {
        if (mainBlockWidth >= 1200) {
            setBlockWidth("auto")
        } else if (mainBlockWidth < 1200 && mainBlockWidth >= 978) {
            setBlockWidth(878)
        } else {
            setBlockWidth(mainBlockWidth - 98)
        }
    }, [mainBlockWidth])


    const GenericInsightBody = () => {
        return <PaymentSection mainData={mainData} mainBlockWidth={mainBlockWidth} isLoading={isLoading} />
    }

    if (isLoading) {
        return (
            <>
                {GenericInsightBody()}
            </>
        );
    } else {
        return (
            <>
                {GenericInsightBody(activeTabBody)}
            </>
        );
    }

};





const BadLoanScreen = () => {
    const mainBlockRef = useRef(null);
    const [blockWidth, setBlockWidth] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [mainData, setMainData] = useState({})
    const [error, setError] = useState({
        hasError: false,
        errorMessage: "",
        errorType: "error",
    })
    useEffect(() => {
        const updateWidth = () => {
            if (mainBlockRef.current) {
                setBlockWidth(mainBlockRef.current.offsetWidth);
            }
        };
        const resizeObserver = new ResizeObserver(() => {
            updateWidth();
        });
        if (mainBlockRef.current) {
            resizeObserver.observe(mainBlockRef.current);
            updateWidth(); // Initial width check
        }

        // Cleanup observer on unmount
        return () => {
            if (mainBlockRef.current) {
                resizeObserver.unobserve(mainBlockRef.current);
            }
        };
    }, []);
    useEffect(() => {

        setIsLoading(true);
        postGenericInsights("", (result) => {
            if (result && result.data.success) {
                setMainData(result.data.response)
                setIsLoading(false);

            } else {
                setError({
                    hasError: true,
                    errorMessage: result.data.message + " , logout in few seconds",
                    errorType: "error",
                })
                setTimeout(() => {
                    window.location.href = '/'
                    localStorage.clear()
                }, 2500);
            }
        })
    }, [])
    return (
        <>
            {
                error.hasError
                    ?
                    <div className='custom-alert-box'>{error.errorMessage}</div>
                    :
                    null
            }
            <div ref={mainBlockRef} className="landing-page-main">

                {
                    blockWidth > 900 ? <>
                        <GreetBlock isLoading={isLoading} mainData={mainData} />
                        <CommonLandScreen isLoading={isLoading} mainData={mainData} mainBlockWidth={blockWidth} />
                        <div className='render-block-main' style={{ padding: "0px 24px 24px" }}>
                            <RenderBlock mainData={mainData} mainBlockWidth={blockWidth} isLoading={isLoading} />
                        </div>
                    </> :
                        <MobileBadLoanScreen mainData={mainData} mainBlockWidth={blockWidth} isLoading={isLoading} />
                }
            </div>
        </>
    );
};

export default BadLoanScreen