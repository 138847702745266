import React, { useEffect, useState, Suspense } from 'react';
import "./style.css";
import Chart from 'react-google-charts';
import { BsArrowRight, BsChevronCompactLeft } from "react-icons/bs";
import iciciImg from '../../assets/imgs/icici.png';
import pnbImg from '../../assets/imgs/pnb.png';
import northernImg from '../../assets/imgs/northernArc.png';
import { IoCloseCircleSharp } from "react-icons/io5";
import { BsChevronCompactRight } from "react-icons/bs";
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import mark_1 from "../../assets/genInsImgs/mark1.png";
import mark_2 from "../../assets/genInsImgs/mark2.png";
import mark_3 from "../../assets/genInsImgs/mark3.png";
import mark_4 from "../../assets/genInsImgs/mark4.png";
const PaymentDetail = ({ item, closeModal }) => {
    const data = [
        { label: "Credit Card Payment", billedAmt: "3450", billedDate: "21/10/24", imgSrc: iciciImg, imgAlt: "bank 1" },
        { label: "Home Loan Payment", billedAmt: "23450", billedDate: "20/10/24", imgSrc: pnbImg, imgAlt: "bank 2" },
        { label: "Personal Loan Payment", billedAmt: "2450", billedDate: "27/10/24", imgSrc: northernImg, imgAlt: "bank 3" },

        { label: "Credit Card Payment", billedAmt: "3450", billedDate: "21/10/24", imgSrc: iciciImg, imgAlt: "bank 1" },
        { label: "Home Loan Payment", billedAmt: "23450", billedDate: "20/10/24", imgSrc: pnbImg, imgAlt: "bank 2" },
        { label: "Personal Loan Payment", billedAmt: "2450", billedDate: "27/10/24", imgSrc: northernImg, imgAlt: "bank 3" },
        { label: "Credit Card Payment", billedAmt: "3450", billedDate: "21/10/24", imgSrc: iciciImg, imgAlt: "bank 1" },
        { label: "Home Loan Payment", billedAmt: "23450", billedDate: "20/10/24", imgSrc: pnbImg, imgAlt: "bank 2" },
        { label: "Personal Loan Payment", billedAmt: "2450", billedDate: "27/10/24", imgSrc: northernImg, imgAlt: "bank 3" },
    ]
    return (<>
        <>
            <div className="payment-detail-main">
                <div className='payment-detail-header'>
                    <span>Payment Details</span>
                    <button onClick={closeModal}>
                        <IoCloseCircleSharp style={{ fontSize: 24 }} />
                    </button>
                </div>
                <div className='payment-detail-info'>
                    <p>
                        The total billed amount for your upcoming EMIs or dues for the current month is
                        <b> ₹29,350 </b>. This amount has been carefully grouped and displayed below for
                        your convenience, ensuring a clear and organized view of your financial obligations.
                    </p>
                </div>
                <div className="payment-reminder-blocks-2">
                    {data.map((item, key) =>
                        <div key={key} className="payment-reminder-block-2">
                            <div className="payment-reminder-block-header-2">
                                <span>{item.label}</span>
                            </div>
                            <div className='payment-reminder-block-body-2'>
                                <div className="payment-reminder-block-body-left-2">
                                    <img src={item.imgSrc} alt={item.imgAlt} />
                                </div>
                                <div className="payment-reminder-block-body-right-2">
                                    <div className='payment-reminder-block-body-due-2'>
                                        <span>Billed Amount :</span>
                                        <p> ₹{item.billedAmt}</p>
                                    </div>
                                    <div className='payment-reminder-block-body-amt-2'>
                                        <span>Billed Date :</span>
                                        <p>{item.billedDate}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}

                </div>

            </div>
        </>
    </>)
}

const CustomModal = ({ open, child }) => {
    if (!open) {
        return null;
    }
    return (
        <div className={"custom-modal-main-open"}>
            {child}
        </div>
    );
};

const PaymentReminder = () => {
    const [modalChild, setModalChild] = useState(<></>);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const setChildView = (view, item) => {
        switch (view) {
            case "paymentDetail":
                return <PaymentDetail item={item} closeModal={closeModal} />
            default:
                return;
        }
    }
    const closeModal = () => {
        setIsModalOpen(false);
        document.body.classList.remove('no-scroll');
    }
    const handleOpenModal = (view, item) => {
        const child = setChildView(view, item);
        setModalChild(child);
        setIsModalOpen(true);
        document.body.classList.add('no-scroll');
    }
    const data = [
        { label: "Credit Card Payment", billedAmt: "3450", billedDate: "21/10/24", imgSrc: iciciImg, imgAlt: "bank 1" },
        { label: "Home Loan Payment", billedAmt: "23450", billedDate: "20/10/24", imgSrc: pnbImg, imgAlt: "bank 2" },
        { label: "Personal Loan Payment", billedAmt: "2450", billedDate: "27/10/24", imgSrc: northernImg, imgAlt: "bank 3" },
    ]
    return (<>
        <div className="payment-reminder-main">
            <div className="payment-reminder-header">
                <span>Payment Reminder</span>
            </div>
            <p className='payment-reminder-para'>Set up payment reminders to ensure you never miss a due date. Timely
                payments positively impact your credit score, helping you
                maintain financial health and access better loan terms and credit opportunities.</p>
            <div className="payment-reminder-blocks">
                {data.map((item, key) =>
                    <div key={key} className="payment-reminder-block">
                        <div className="payment-reminder-block-header">
                            <span>{item.label}</span>
                            <BsArrowRight style={{ fontSize: 18 }} />
                        </div>
                        <div className='payment-reminder-block-body'>
                            <div className="payment-reminder-block-body-left">
                                <img src={item.imgSrc} alt={item.imgAlt} />
                            </div>
                            <div className="payment-reminder-block-body-right">
                                <div className='payment-reminder-block-body-due'>
                                    <span>Billed Amount :</span>
                                    <p> ₹{item.billedAmt}</p>
                                </div>
                                <div className='payment-reminder-block-body-amt'>
                                    <span>Billed Date :</span>
                                    <p>{item.billedDate}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                <div className='payment-reminder-view-all-block'>
                    <button onClick={() => {
                        handleOpenModal("paymentDetail", data)
                    }} className='payment-reminder-view-all-btn'>View All</button>
                </div>

            </div>
        </div>
        <CustomModal open={isModalOpen} child={modalChild} />
    </>)
}


const StaggeredBarChart = ({ propData }) => {
    const [isSmall, setIsSmall] = useState(false);
    const [mainReport, setMainReport] = useState([])
    useEffect(() => {
        setMainReport(propData)
    }, [propData])

    const convertData = (data) => {
        if (data.length <= 12) {
            return data.slice(1).map(row => {
                return {
                    month: row[0],
                    latePayments: row[1],
                    onTimePayments: row[2]
                };
            });
        } else {
            const last12 = data.slice(-12);
            return last12.map(row => {
                return {
                    month: row[0],
                    latePayments: row[1],
                    onTimePayments: row[2]
                };
            });
        }

    };
    let data = convertData(mainReport) ??
        [
            { month: "Oct 2024", latePayments: 1, onTimePayments: 7 },
            { month: "Sep 2024", latePayments: 0, onTimePayments: 7 },
            { month: "Aug 2024", latePayments: 2, onTimePayments: 9 },
            { month: "Jul 2024", latePayments: 1, onTimePayments: 9 },
            { month: "Jun 2024", latePayments: 2, onTimePayments: 9 },
            { month: "May 2024", latePayments: 1, onTimePayments: 9 },
            { month: "Apr 2024", latePayments: 1, onTimePayments: 10 },
            { month: "Mar 2024", latePayments: 2, onTimePayments: 10 },
            { month: "Feb 2024", latePayments: 1, onTimePayments: 10 },
            { month: "Jan 2024", latePayments: 2, onTimePayments: 11 },
            { month: "Dec 2023", latePayments: 2, onTimePayments: 11 },
            { month: "Nov 2023", latePayments: 1, onTimePayments: 10 },
        ];
    useEffect(() => {
        const handleResize = () => {
            setIsSmall(window.innerWidth < 580);
        };

        handleResize(); // Check on mount
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    const CustomTooltip = ({ active, payload }) => {
        if (active && payload && payload.length) {
            return (
                <div className="custom-tooltip">
                    <p>{payload[0]?.payload?.month}</p>
                    {payload.map((entry, index) => (
                        <p key={index} style={{ color: entry.color }}>
                            {entry.dataKey === "onTimePayments" ? "On time payments" : "Late payments"}: {entry.value}
                        </p>
                    ))}
                </div>
            );
        }
        return null;
    };
    return (
        <div className="credit-worthiness-chart">
            <ResponsiveContainer width="100%" height={isSmall ? 250 : 350}>
                <BarChart
                    data={isSmall ? data.slice(0, 7) : data}
                    margin={{
                        left: isSmall ? -40 : -20,
                        right: isSmall ? 0 : 20,
                        top: 5,
                        bottom: 5,
                    }}
                >
                    <CartesianGrid strokeDasharray="1 1" />
                    <XAxis dataKey="month" tick={{ fontSize: isSmall ? 10 : 12 }} />
                    <YAxis tick={{ fontSize: isSmall ? 10 : 12 }} />
                    <Tooltip content={<CustomTooltip />} />
                    <Bar dataKey="latePayments" fill="#2C2A4A" stackId="a" barSize={40} />
                    <Bar dataKey="onTimePayments" fill="#DABFFF" stackId="a" barSize={30} />
                </BarChart>
            </ResponsiveContainer>

            <div className='credit-report-dash-card-2-1-block-chart-1'>
                <div className='premium-report-first-dash-n-2-chart-2'>
                    <div>
                        <span style={{ background: "#DABFFF" }}></span>
                        <p>On time Payment</p>
                    </div>
                    <div>
                        <span style={{ background: "#2C2A4A" }}></span>
                        <p>Late payment</p>
                    </div>
                </div>
            </div>
        </div>
    );
};



const PaymentDelinquencies = ({ propData, mainBlockWidth }) => {
    const [showAll, setShowAll] = useState(false);
    const [mainReport, setMainReport] = useState({})
    useEffect(() => {
        setMainReport(propData)
    }, [propData])
    const handlePaymentType = (status) => {

        switch (status) {
            case 'on-time':
                return <div className='delin-on-time'></div>;
            case 'amber':
                return <div className='delin-amber'></div>;
            case 'late':
                return <div className='delin-late'></div>;
            case 'no-status':
                return <div className='delin-no-status'></div>;
            default:
                return;
        }
    };
    const [isSmall, setIsSmall] = useState(false);
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const years = Object.keys(mainReport) ?? [];

    const [currentYearIndex, setCurrentYearIndex] = useState(0);

    const convertToMonthlyStatus = (data) => {
        const delinquencyData = {};
        const monthsInYear = 12;
        const threshold = 2;
        const currentYear = new Date().getFullYear();
        const currentMonth = new Date().getMonth();

        Object.keys(data).forEach(year => {
            const monthlyData = Array(monthsInYear).fill("blank");
            const yearData = data[year];

            if (year < currentYear) {
                yearData.forEach((value, index) => {
                    // Set as "on-time" for 0, "late" for values greater than threshold, and "blank" for -1
                    if (value === 0) {
                        monthlyData[index] = "on-time";
                    } else if (value === -1) {
                        monthlyData[index] = "blank";
                    } else if (value === 1 || value === 2) {
                        console.log("11");
                        monthlyData[index] = "amber";
                    } else if (value >= threshold) {
                        monthlyData[index] = "late";
                    }
                });
            } else if (year == currentYear) {
                yearData.forEach((value, index) => {
                    if (index <= currentMonth) {
                        // Apply same conditions for the current year, but only for months up to the current month
                        if (value === 0) {
                            monthlyData[index] = "on-time";
                        } else if (value === -1) {
                            monthlyData[index] = "blank";
                        } else if (value === 1 || value === 2) {
                            console.log("22");
                            monthlyData[index] = "amber";
                        } else if (value >= threshold) {
                            monthlyData[index] = "late";
                        }
                    }
                });
            }

            delinquencyData[year] = monthlyData;
        });

        return delinquencyData;
    };


    const delinquencyData = convertToMonthlyStatus(mainReport)
    const convertDelinquencyData = (data) => {
        const delinquencyData = {};
        const threshold = 3; // Values 3 and above are marked as "late"
        const currentYear = new Date().getFullYear();
        const currentMonth = new Date().getMonth();

        Object.keys(data).forEach((year) => {
            const yearData = data[year];

            delinquencyData[year] = months.map((month, index) => {
                let value = 'blank';
                if (yearData && index < yearData.length) {
                    const status = yearData[index];
                    if (status === 0) {
                        value = 'on-time';
                    } else if (status === -1) {
                        value = 'blank';
                    } else if (status >= 1 && status <= 2) {
                        value = 'amber';
                    } else if (status >= threshold) {
                        value = 'late';
                    }
                }

                // Limit current year data to months up to the current month
                if (parseInt(year) === currentYear && index > currentMonth) {
                    value = 'blank';
                }

                return { month, value };
            });
        });

        return delinquencyData;
    };

    const delinquencyDataMobile = convertDelinquencyData(mainReport)

    useEffect(() => {
        const handleResize = () => {
            setIsSmall(window.innerWidth < 895);
        };

        handleResize(); // Check on mount
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const handleNextYear = () => {
        if (currentYearIndex < years.length - 1) {
            setCurrentYearIndex(currentYearIndex + 1);
        }
    };

    const handlePreviousYear = () => {
        if (currentYearIndex > 0) {
            setCurrentYearIndex(currentYearIndex - 1);
        }
    };
    const displayedYears = showAll ? years : years.slice(-3);
    if (isSmall) {
        return (
            <div className='payment-delin-main'>
                <div className='payment-delin-head' >
                    <span>Delinquencies</span>
                    <div className='payment-delin-head-btn'>
                        <button
                            onClick={handlePreviousYear}
                            disabled={currentYearIndex === 0} // Disable when on the first year
                        >
                            <BsChevronCompactLeft className={currentYearIndex === 0 ? "class-btn-inactive" : "class-btn-active"} />
                        </button>
                        <p>{years[currentYearIndex]}</p> {/* Display current year */}
                        <button
                            onClick={handleNextYear}
                            disabled={currentYearIndex === years.length - 1} // Disable when on the last year
                        >
                            <BsChevronCompactRight className={currentYearIndex === years.length - 1 ? "class-btn-inactive" : "class-btn-active"} />
                        </button>
                    </div>
                </div>
                <div className='payment-delin-content-blocks'>
                    {delinquencyDataMobile[years[currentYearIndex]]?.map((item, index) => (
                        <div key={index + "payment-delin-content"} className='payment-delin-content-block'>
                            <p>{item?.month}</p>
                            <span className={`class-${item?.value}`}></span>
                        </div>
                    ))}

                </div>
            </div>
        )
    } else {
        return (<>
            <div className='payment-delin-main'>
                <div className='payment-delin-head-2' >
                    <span className='payment-delin-head-2-span'>Delinquencies String</span>
                </div>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: "center" }}>
                    <div className="delinquency-table-new">
                        <table>
                            <thead>
                                <tr>
                                    <th className='sticky-corner'></th>
                                    {months.reverse().map((month, index) => (
                                        <th key={index}>{month}</th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {displayedYears.reverse().map((year) => {
                                    const data = delinquencyData[year] || [];
                                    const paddedData = [...Array(12 - data.length).fill('empty'), ...data];

                                    return (
                                        <>
                                            <tr key={year}>
                                                <td>{year}</td>
                                                {paddedData.reverse().map((status, index) => (
                                                    <td key={index}>
                                                        <div className='delin-elm-block'>
                                                            {handlePaymentType(status)}
                                                        </div>
                                                    </td>
                                                ))}
                                            </tr>
                                        </>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
                {!(years.length > 3) ? <>

                </> :
                    <>
                        <div style={{ textAlign: 'center', marginTop: '10px' }}>

                            <button className='del-show-more-btn' onClick={() => setShowAll(!showAll)}>
                                <p> {showAll ? "Show Less" : "Show More"}
                                </p>
                                {showAll ? <FaChevronUp /> : <FaChevronDown />}
                            </button>
                        </div>
                    </>
                }
            </div>
        </>)
    }
};

const PaymentDelinquenciesString = ({ propData, mainBlockWidth }) => {
    const [localWidth, setLocalWidth] = useState(0);
    useEffect(() => {
        setLocalWidth(mainBlockWidth)
    }, [mainBlockWidth])
    const [showAll, setShowAll] = useState(false);
    const [mainReport, setMainReport] = useState({})
    useEffect(() => {
        setMainReport(propData)
    }, [propData])
    const elem = [
        { key: "elem-1", label: "Paid On Time", src: mark_1, alt: "mark_1" },
        { key: "elem-2", label: "1-89 Days Late", src: mark_2, alt: "mark_2" },
        { key: "elem-3", label: "90+ Days Late", src: mark_3, alt: "mark_3" },
        { key: "elem-4", label: "Not Available", src: mark_4, alt: "mark_4" }
    ];
    const handlePaymentType = (status) => {
        switch (status) {
            case 'on-time':
                return <img src={mark_1} alt='on-time' />;
            case 'amber':
                return <img src={mark_2} alt='partially delinquent' />;
            case 'late':
                return <img src={mark_3} alt='late payments' />;
            case 'no-status':
                return <img src={mark_4} alt='no-status' />;
            case "blank":
                return <span className={`class-blank`}> </span>
            default:
                return;
        }
    };
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    // const years = Object.keys(mainReport) ?? [];
    const years = Object.keys(mainReport).sort((a, b) => b - a) ?? [];

    const [currentYearIndex, setCurrentYearIndex] = useState(0);

    const convertToMonthlyStatus = (data) => {
        const delinquencyData = {};
        const monthsInYear = 12;
        const threshold = 2;
        const currentYear = new Date().getFullYear();
        const currentMonth = new Date().getMonth();

        Object.keys(data).forEach(year => {
            const monthlyData = Array(monthsInYear).fill("blank");
            const yearData = data[year];

            if (year < currentYear) {
                yearData.forEach((value, index) => {
                    // Set as "on-time" for 0, "late" for values greater than threshold, and "blank" for -1
                    if (value === 0) {
                        monthlyData[index] = "on-time";
                    } else if (value === -1) {
                        monthlyData[index] = "blank";
                    } else if (value === 1 || value === 2) {
                        monthlyData[index] = "amber";
                    } else if (value >= threshold) {
                        monthlyData[index] = "late";
                    }
                });
            } else if (year == currentYear) {
                yearData.forEach((value, index) => {
                    if (index <= currentMonth) {
                        // Apply same conditions for the current year, but only for months up to the current month
                        if (value === 0) {
                            monthlyData[index] = "on-time";
                        } else if (value === -1) {
                            monthlyData[index] = "blank";
                        } else if (value === 1 || value === 2) {
                            console.log("22");
                            monthlyData[index] = "amber";
                        } else if (value >= threshold) {
                            monthlyData[index] = "late";
                        }
                    }
                });
            }

            delinquencyData[year] = monthlyData;
        });

        return delinquencyData;
    };


    const delinquencyData = convertToMonthlyStatus(mainReport)
    const convertDelinquencyData = (data) => {
        const delinquencyData = {};
        const threshold = 3; // Values 3 and above are marked as "late"
        const currentYear = new Date().getFullYear();
        const currentMonth = new Date().getMonth();

        Object.keys(data).forEach((year) => {
            const yearData = data[year];

            delinquencyData[year] = months.map((month, index) => {
                let value = 'blank';
                if (yearData && index < yearData.length) {
                    const status = yearData[index];
                    if (status === 0) {
                        value = 'on-time';
                    } else if (status === -1) {
                        value = 'blank';
                    } else if (status >= 1 && status <= 2) {
                        value = 'amber';
                    } else if (status >= threshold) {
                        value = 'late';
                    }
                }

                // Limit current year data to months up to the current month
                if (parseInt(year) === currentYear && index > currentMonth) {
                    value = 'blank';
                }

                return { month, value };
            });
        });

        return delinquencyData;
    };

    const delinquencyDataMobile = convertDelinquencyData(mainReport)

    // const handleNextYear = () => {
    //     if (currentYearIndex < years.length - 1) {
    //         setCurrentYearIndex(currentYearIndex + 1);
    //     }
    // };

    // const handlePreviousYear = () => {
    //     if (currentYearIndex > 0) {
    //         setCurrentYearIndex(currentYearIndex - 1);
    //     }
    // };

    const handleNextYear = () => {
        if (currentYearIndex > 0) { // Move backward in time
            setCurrentYearIndex(currentYearIndex - 1);
        }
    };

    const handlePreviousYear = () => {
        if (currentYearIndex < years.length - 1) { // Move forward in time
            setCurrentYearIndex(currentYearIndex + 1);
        }
    };
    const displayedYears = showAll ? years : years.slice(0,3);
    return (<>
        <div className="delin-main-block">

            {
                localWidth >= 900 ?
                    <>
                        <div className="delin-main-block-head">
                            <span>Delinquencies</span>
                        </div>
                        <div className='delin-block-table'>
                            <table>
                                <thead>
                                    <tr>
                                        <th className='sticky-corner'></th>
                                        {months.reverse().map((month, index) => (
                                            <th key={index}>{month}</th>
                                        ))}
                                    </tr>
                                </thead>
                                <tbody>
                                    {displayedYears.map((year) => {
                                        const data = delinquencyData[year] || [];
                                        const paddedData = [...Array(12 - data.length).fill('empty'), ...data];

                                        return (
                                            <>
                                                <tr key={year}>
                                                    <td>{year}</td>
                                                    {paddedData.reverse().map((status, index) => (
                                                        <td key={index}>
                                                            <div className='delin-elm-block'>
                                                                {handlePaymentType(status)}
                                                            </div>
                                                        </td>
                                                    ))}
                                                </tr>
                                            </>
                                        );
                                    })}
                                </tbody>
                            </table>
                        </div>
                        {!(years.length > 3) ? null :
                            <>
                                <div style={{ textAlign: 'center', marginTop: '10px' }}>

                                    <button className='del-show-more-btn' onClick={() => setShowAll(!showAll)}>
                                        <p> {showAll ? "Show Less" : "Show More"}
                                        </p>
                                        {showAll ? <FaChevronUp /> : <FaChevronDown />}
                                    </button>
                                </div>
                            </>
                        }
                        <div className='legends-payment-delin'>
                            <div className='legends-payment-delin-item'>
                                <img src={mark_1} alt='on-time' />
                                <p>
                                    Not Delinquent
                                </p>
                            </div>
                            <div className='legends-payment-delin-item'>
                                <img src={mark_3} alt='late payments' />

                                <p>
                                    Delinquent
                                </p>
                            </div>
                            <div className='legends-payment-delin-item'>
                                <img src={mark_2} alt='partially delinquent' />
                                <p>
                                    Partially Delinquent
                                </p>
                            </div>
                        </div>
                    </>
                    :
                    <>
                        <div className='payment-delin-main'>
                            <div className='payment-delin-head' >
                                <span>Delinquencies</span>
                                <div className='payment-del-head-btn'>
                                    <button
                                        onClick={handlePreviousYear}
                                        disabled={currentYearIndex === years.length - 1} // Disabled when at the earliest year
                                    >
                                        <BsChevronCompactLeft className={currentYearIndex === years.length - 1 ? "class-btn-inactive" : "class-btn-active"} />
                                    </button>
                                    <p>{years[currentYearIndex]}</p> {/* Display current year */}
                                    <button
                                        onClick={handleNextYear}
                                        disabled={currentYearIndex === 0} // Disabled when at the latest year
                                    >
                                        <BsChevronCompactRight className={currentYearIndex === 0 ? "class-btn-inactive" : "class-btn-active"} />
                                    </button>
                                </div>
                            </div>
                            <div className='payment-delin-content-blocks'>
                                {delinquencyDataMobile[years[currentYearIndex]]?.map((item, index) => (
                                    <div key={index + "payment-del-content"} className='payment-delin-content-block'>
                                        <p>{item?.month}</p>
                                        {/* <span className={`class-${item?.value}`}>       </span> */}
                                        {handlePaymentType(item?.value)}
                                    </div>
                                ))}

                            </div>
                            <div className='legends-payment-delin'>
                                <div className='legends-payment-delin-item'>
                                    <img src={mark_1} alt='on-time' />
                                    <p>
                                        Not Delinquent
                                    </p>
                                </div>
                                <div className='legends-payment-delin-item'>
                                    <img src={mark_3} alt='late payments' />

                                    <p>
                                        Delinquent
                                    </p>
                                </div>
                                <div className='legends-payment-delin-item'>
                                    <img src={mark_2} alt='partially delinquent' />
                                    <p>
                                        Partially Delinquent
                                    </p>
                                </div>
                            </div>
                            {/* <div className='premium-report-first-dash-12-chart-2 premium-report-first-Worth'>
                                <div>
                                    <span style={{ background: "rgba(19, 211, 48, 0.821)" }}></span>
                                    <p>Not Delinquent</p>
                                </div>
                                <div>
                                    <span style={{ background: "rgba(255, 0, 0, 0.834)" }}></span>
                                    <p>Delinquent</p>
                                </div>
                                <div>
                                    <span style={{ background: "#ffbf00c4" }}></span>
                                    <p>Partially  Delinquent</p>
                                </div>
                            </div> */}
                        </div>
                    </>
            }
        </div>
    </>)
}


const CreditWorthinessScreen = ({ propData, mainLoading, mainBlockWidth }) => {
    const { overallDelinquencyData, creditWorthinessTimeLine } = propData;
    if (mainLoading) {
        return (
            <>
                <div className="credit-worthiness-body" style={{ width: "-webkit-fill-available" }}>
                    <div className='credit-worthiness-inner-body'>
                        <div className="credit-worthiness-head">
                            <div style={{ height: 18, width: 160 }} className='skeleton-generic'></div>
                        </div>
                        <p style={{ display: "flex", gap: 10, flexDirection: "column", padding: "0px 20px", width: "-webkit-fill-available" }}>
                            <div style={{ height: 12, width: "100%" }} className='skeleton-generic'></div>
                            <div style={{ height: 12, width: "95%" }} className='skeleton-generic'></div>
                            <div style={{ height: 12, width: "70%" }} className='skeleton-generic'></div>
                        </p>
                        <div style={{
                            height: 160, width: "95%",
                            marginTop: 20,
                            marginRight: 20,
                            marginLeft: 20
                        }}
                            className='skeleton-generic'
                        ></div>
                        <div className='premium-report-first-dash-n-12-chart-2'>
                            <div>
                                <span className='skeleton-generic' style={{ height: 10, width: 10 }}></span>
                                <p className='skeleton-generic' style={{ height: 10, width: 50, padding: 0 }}></p>
                            </div>
                            <div>
                                <span className='skeleton-generic' style={{ height: 10, width: 10 }}></span>
                                <p className='skeleton-generic' style={{ height: 10, width: 50, padding: 0 }}></p>
                            </div>
                        </div>
                    </div>

                </div>
            </>
        )
    } else {
        return (
            <>
                <div className="credit-worthiness-body">
                    <div className='credit-worthiness-inner-body'>
                        <div className="credit-worthiness-head">
                            Payment Discipline Tracker
                        </div>
                        <p className='credit-worthiness-top-para'>
                            <>
                                Financial reliability measures an individual’s or entity’s commitment to meeting debt obligations on time, based on their history of payments, income consistency, and financial responsibility. Tracking payment discipline helps lenders gauge the risk of delinquency, ensuring accounts and loan histories remain in good standing. A strong financial track record demonstrates reliability and increases eligibility for loans and favorable interest rates, while lapses in payment discipline may signal potential risks.
                            </>
                        </p>
                        <StaggeredBarChart propData={creditWorthinessTimeLine} />
                        <PaymentDelinquenciesString propData={overallDelinquencyData} mainBlockWidth={mainBlockWidth} />
                    </div>

                </div>
            </>
        )
    }

}

export default CreditWorthinessScreen