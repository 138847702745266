import axios from "axios";
import createEnvironment from "../global"

const ENV_VAR = createEnvironment();
const BASE_URL = ENV_VAR.BASE_URL;

const axiosInstance = axios.create({
    baseURL: BASE_URL,
});


axiosInstance.interceptors.response.use(
    (response) => response,
    (error) => {
        if (error.response) {
            const { status, data } = error.response;
            console.error(`HTTP ${status}:`, data?.message || "Unknown error");
        } else if (error.request) {
            console.error("No response received from server:", error.message);
        } else {
            console.error("Request error:", error.message);
        }
        return Promise.reject(error);
    }
);

export const PATHS = {
    GET_INSIGHTS: "/tdf/api/userBureauInsight/getInsight",
    GET_OTP: (number) => `/tdf/api/generate/otp/${number}`,
    GET_USER_TRACK: (number) => `/auth/userTrack/getUserTrack/${number}`,
    USER_GET_REPORT: "/tdf/api/user/getReport",
    POST_CONTACT_MSG: "/tdf/api/contactUsMsg",
    POST_REVOKECONSENT: "/tdf/api/user/revokeConsent",
    POST_AUTH_TOKEN: "/auth/login/token",
    POST_SAVE_USER_TRACK: "/auth/userTrack/saveUserTrack",    //need to test it for new user only
    POST_CALL_ME_BACK: "/tdf/api/user/callMeBack",
    POST_GETING_PAYMENT_LINK: "/tdf/api/user/getPaymentLink",
    POST_COUNTER_OFFER: "/tdf/api/counterOffer",
    POST_SETTLEMENT_LISTING: "/tdf/api/user/settlement/listing",
    GET_PROFILE_DETAIL: "/tdf/api/user/getProfileDetail",
    POST_ENHANCED_FETCH_REPORT: "/tdf/api/user/enhancedFetchReport",
    GET_GET_STATTE: "/tdf/api/getState",      //need to test
    POST_FETCH_REPORT: "/tdf/api/user/fetchReport",
    POST_USER_PROFILE: "/tdf/api/user/v2/submitProfile",
    POST_VALIDATE_CREDIT_OTP: "/tdf/api/user/validate/credit/otp",
    POST_GENERAL_INSIGHTS: (mob) => `/tdf/api/userBureauInsight/generalInsight`,
    POST_CALL_SETTLE_DETAILS: "/tdf/api/user/acDetail",
    POST_USER_SAVE_ACTION: "/tdf/api/userAction/saveAction"
};

// Authorization headers
const authorizeRequest = () => {
    const AUTH_RESPONSE = localStorage.getItem("TDFToken");
    return AUTH_RESPONSE
        ? { Authorization: `Bearer ${AUTH_RESPONSE}` }
        : {};
};

// error handler
const handleError = (err, cb) => {
    const errorMessage = err?.response?.data?.message || "An error occurred";
    const status = err?.response?.status || 500;
    console.error(`Error [${status}]:`, errorMessage);
    cb(null, { status, errorMessage });
};

// API methods
export const API = {
    GET: (url, body = {}, params = {}, cb) => {
        axiosInstance
            .get(url, {
                headers: authorizeRequest(),
                data: body,
                params,
            })
            .then((res) => cb(res, null))
            .catch((err) => handleError(err, cb));
    },

    POST: (url, body = {}, params = {}, cb) => {
        axiosInstance
            .post(url, body, {
                headers: authorizeRequest(),
                params,
            })
            .then((res) => cb(res, null))
            .catch((err) => handleError(err, cb));
    },

    PATCH: (url, body = {}, params = {}, cb) => {
        axiosInstance
            .patch(url, body, {
                headers: authorizeRequest(),
                params,
            })
            .then((res) => cb(res.data, null))
            .catch((err) => handleError(err, cb));
    },

    DELETE: (url, body = {}, cb) => {
        axiosInstance
            .delete(url, {
                headers: authorizeRequest(),
                data: body,
            })
            .then((res) => cb(res.data, null))
            .catch((err) => handleError(err, cb));
    },

    DOWNLOAD: (url, body = {}, params = {}, cb) => {
        axiosInstance
            .get(url, {
                responseType: "blob",
                headers: authorizeRequest(),
                data: body,
                params,
            })
            .then((res) => cb(res, null))
            .catch((err) => handleError(err, cb));
    },
    GENERAL: (url, method = "get", body = {}, params = {}, headers = {}, cb) => {
        axiosInstance({
            url: url,
            method: method,
            data: body,
            params: params,
            headers: headers
        }).then((res) => cb(res, null))
            .catch((err) => handleError(err, cb));
    },

    DOWNLOADWITHHEAD: (url, headers = {}, body = {}, cb) => {
        axios
            .post(url, body, {
                headers,
                responseType: "blob",
            })
            .then((res) => cb(res, null))
            .catch((err) => handleError(err, cb));
    },

    DOWNLOADPOST: (url, body = {}, cb) => {
        axiosInstance
            .post(url, body, {
                headers: authorizeRequest(),
                responseType: "blob",
            })
            .then((res) => {
                const url = window.URL.createObjectURL(new Blob([res.data]));
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", "template.xlsx");
                document.body.appendChild(link);
                link.click();
                cb(res, null);
            })
            .catch((err) => handleError(err, cb));
    },
};
