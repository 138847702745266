import React from 'react';
import { Navigate } from 'react-router-dom';

function RequireAuth({ children }) {
    const getLocalStorage = localStorage.getItem("TDFToken");
  const isAuthenticated = getLocalStorage // logic to determine if the user is authenticated

  if (!isAuthenticated) {
    return <Navigate to="/PageNotFound" />;
  }

  return children;
}

export default RequireAuth;

export function RequireLS ({children}){
  const Confirm = localStorage.getItem("loginConfirm");
  const isAuthenticatedLS = Confirm
  if (isAuthenticatedLS) {
    return <Navigate to="/PageNotFound" />;
  }
  return children

}
