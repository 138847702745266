import React, { useEffect, useState } from 'react'
import "./style.css"
import { PiShareFat, } from 'react-icons/pi';
import ScoreGauge from '../../CentralMenu/ScoreGauge';
import { RxReload } from 'react-icons/rx';
import { AiOutlineCloudDownload } from 'react-icons/ai';
import { FaAngleLeft, FaAngleRight, FaChevronDown, } from 'react-icons/fa';
import mark_1 from "../../assets/genInsImgs/mark1.png";
import mark_2 from "../../assets/genInsImgs/mark2.png";
import mark_3 from "../../assets/genInsImgs/mark3.png";
import mark_4 from "../../assets/genInsImgs/mark4.png";
import payment_acct_mark1 from "../../assets/genInsImgs/payment-acct-mark1.png"
import payment_acct_mark2 from "../../assets/genInsImgs/payment-acct-mark2.png"
import payment_acct_mark3 from "../../assets/genInsImgs/payment-acct-mark3.png"
import payment_acct_mark4 from "../../assets/genInsImgs/payment-acct-mark4.png"
import { BANK_IMG_STACK } from '../../DashboardComponents/LoanCard';
import { useNavigate } from 'react-router-dom';
import { RiFileCopy2Line } from 'react-icons/ri';
import CallMeBackAction from './Actions/CallMeBackAction';
import PaynowAction from './Actions/PaynowAction';
import { getPaymentlinkdata, postSaveUserAction } from '../../service/ApiList';
import CounterOfferScreen from './Actions/Settlement/CounterOfferScreen';
const CommonLandScreen = ({ isLoading, mainData, mainBlockWidth }) => {
    const navigate = useNavigate();
    const goToCreditReport = () => {
        navigate('/home/dashboard')
    }
    const {
        creditRemarks,
        dateOfReport,
        creditScore
    } = mainData || { creditRemarks: "N/A", dateOfReport: "", creditScore: "" }
    if (isLoading) {
        return (<>
            <div className='landing-page-common-main'
                style={{ height: 260 }}
            >
                <div className='landing-page-common'>
                    <div style={{ display: "flex", flexDirection: "column", gap: 10 }}>
                        <div className='skeleton-generic' style={{ height: 160, width: 160, borderRadius: "50%" }}></div>
                        <div className='skeleton-generic' style={{ height: 30, width: 160 }}></div>
                        <div className='skeleton-generic' style={{ height: 12, width: 160 }}></div>
                    </div>
                    <p style={{ height: "auto", width: "100%", display: window.innerWidth <= 650 ? "none" : "flex", flexDirection: "column", gap: 10 }}>
                        <p>
                            <div className='skeleton-generic' style={{ height: 18, width: "100%" }}></div>
                            <div className='skeleton-generic' style={{ height: 18, width: "100%" }}></div>
                            <div className='skeleton-generic' style={{ height: 18, width: "100%" }}></div>
                            <div className='skeleton-generic' style={{ height: 18, width: "50%" }}></div>
                        </p>
                        <div className='landing-page-common-btns' style={{ gap: 10 }}>
                            <div className='skeleton-generic' style={{ height: 35, width: 160, borderRadius: 48 }}></div>
                            <div className='skeleton-generic' style={{ height: 35, width: 160, borderRadius: 48 }}></div>
                        </div>
                    </p>

                </div>

            </div>
        </>)
    } else {
        return (
            <>
                <div
                    style={{ border: mainBlockWidth >= 900 ? "1px solid #f3f4f4" : "none" }}
                    className='landing-page-common-main'>
                    <div className='landing-page-common'>
                        <ScoreGauge score={creditScore} isLoading={isLoading} lastUpdated={dateOfReport} />
                        <p>
                            {creditRemarks}
                            {/* {
                                window.innerWidth >= 950
                                    ?
                                    <div className='landing-page-common-btns'>
                                        <button onClick={goToCreditReport} style={{ border: "none" }}>
                                            <RiFileCopy2Line style={{ fontSize: 16 }} />
                                            Credit Report
                                        </button>
                                        <button style={{ border: "none" }}>
                                            <RxReload style={{ fontSize: 16 }} />
                                            Refresh
                                        </button>
                                        <button>
                                            <PiShareFat style={{ fontSize: 16 }} />
                                            Share
                                        </button>
                                        <button>
                                            <AiOutlineCloudDownload style={{ fontSize: 16 }} />
                                            Download Report
                                        </button>
                                    </div>
                                    :
                                    <div className='landing-page-common-btns-n'>
                                        <div style={{ justifyContent: "flex-end" }}>
                                            <button onClick={goToCreditReport} style={{ border: "none" }}>
                                                <RiFileCopy2Line style={{ fontSize: 16 }} />
                                                Credit Report
                                            </button>
                                            <button style={{ border: "none" }}>
                                                <RxReload style={{ fontSize: 16 }} />
                                                Refresh
                                            </button>
                                        </div>
                                        <div>
                                            <button style={{ width: "40%" }}>
                                                <PiShareFat style={{ fontSize: 16 }} />
                                                Share
                                            </button>
                                            <button style={{ width: "60%" }}>
                                                <AiOutlineCloudDownload style={{ fontSize: 16 }} />
                                                Download Report
                                            </button>
                                        </div>
                                    </div>
                            } */}
                        </p>

                    </div>

                </div>
            </>
        )
    }

}
const reorderArray = (arr) => {
    return arr.sort((a, b) => {
        if (a.status === "Active") return -1; // Active should come first
        if (b.status === "Active") return 1;
        if (a.status === "Closed") return 1; // Closed should come last
        if (b.status === "Closed") return -1;
        return 0; // Keep others in between
    });
};
const Action_Button_Block = (data) => {
    const [showModal, setShowModal] = useState(false);
    const [modalChild, setModalChild] = useState(null);
    const currentId = data?.id || "0";
    const status = data?.status || "";
    const handleReportAnError = () => {
        const { id, bankName, loanType } = data;
        let params = {
            accountDetailsId: id,
            bankName: bankName,
            userActionType: "REPORT_ERROR"
        }
        postSaveUserAction(params, (res) => {
            if (res) {
                console.log(res)
            } else {
                console.log("err=" + res)
            }
        })
        window.open(
            "https://consumer.experian.in/ECSINDIA-DCE/view/angular/index.html#!/utiERNPage",
            "_blank"
        );
    };
    const handleChild = (type) => {
        switch (type) {
            case 'call_me_back':
                return <CallMeBackAction data={data} handleCloseModal={handleCloseModal} id={currentId} />;
            case 'pay_now':
                return <PaynowAction data={data} handleCloseModal={handleCloseModal} />;
            case 'want_to_settle':
                return <CounterOfferScreen data={data} handleCloseModal={handleCloseModal} />;
            default:
                return null;
        }
    }
    const handleAction = (type) => {
        const child = handleChild(type)
        setModalChild(child);
        setShowModal(true);
    };
    const handleCloseModal = () => setShowModal(false);
    const handlePayNow = () => {
        const { id, bankName, loanType } = data || {}
        const params = {
            productType: loanType.toUpperCase().replace(/\s+/g, "_"),
            accountDetailsId: id,
            bankName: bankName,
        }
        getPaymentlinkdata(params, (res, err) => {
            if (res && res?.data?.link) {
                window.open(res?.data?.link);
                handleAction("pay_now");
            } else {
                handleAction("pay_now");
            }
        });
    };
    return (<>
        {
            showModal
                ?
                <div className='custom-alert-box-2'>
                    {modalChild}
                </div>
                :
                null
        }
        <div className='mobile-action-btn-main'>
            <p
                style={{ display: status === "Closed" ? "none" : "" }}
                className='mobile-action-btn-para'>
                Act promptly and follow your plan to achieve desired results.
            </p>
            <div
                style={{ display: status === "Closed" ? "none" : "" }}
                className='mobile-action-btn-block'>
                {data?.isAccountBad && <button onClick={() => handleAction("want_to_settle")}>Want to Settle?</button>}
                <button onClick={handlePayNow}>Pay now</button>
                <button onClick={() => handleAction("call_me_back")}>Call back</button>
            </div>

            <p className='mobile-action-btn-para'>
                Inconsistent information? You can rectify it by reporting the discrepancies to the relevant credit bureau.
            </p>
            <button onClick={handleReportAnError} className='mobile-action-btn-report-an-error'>Report an error</button>
        </div>

    </>)
}

const PaymentBodyPart = ({ propData }) => {
    const [mainReport, setMainReport] = useState({})
    useEffect(() => {
        setMainReport(propData)
    }, [propData])
    const Delinquency = () => {
        const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

        // Reverse years to show the latest year first
        const years = Object.keys(mainReport?.details?.delinquencyData ?? [])
            .map(Number) // Convert string keys to numbers
            .sort((a, b) => b - a); // Sort in descending order (latest year first)

        const [currentYearIndex, setCurrentYearIndex] = useState(0);

        const elem = [
            { key: "elem-1", label: "Paid On Time", src: mark_1, alt: "mark_1" },
            { key: "elem-2", label: "1-89 Days Late", src: mark_2, alt: "mark_2" },
            { key: "elem-3", label: "90+ Days Late", src: mark_3, alt: "mark_3" },
            { key: "elem-4", label: "Not Available", src: mark_4, alt: "mark_4" }
        ];

        // Adjusted button logic
        const handleNextYear = () => {
            if (currentYearIndex > 0) { // Moving to older years
                setCurrentYearIndex(currentYearIndex - 1);
            }
        };

        const handlePreviousYear = () => {
            if (currentYearIndex < years.length - 1) { // Moving to newer years
                setCurrentYearIndex(currentYearIndex + 1);
            }
        };

        const convertDelinquencyData = (data) => {
            const delinquencyData = {};
            const threshold = 3; // Values 3 and above are marked as "late"
            const currentYear = new Date().getFullYear();
            const currentMonth = new Date().getMonth();

            Object.keys(data).forEach((year) => {
                const yearData = data[year];

                delinquencyData[year] = months.map((month, index) => {
                    let value = 'blank';
                    if (yearData && index < yearData.length) {
                        const status = yearData[index];
                        if (status === 0) {
                            value = 'on-time';
                        } else if (status === -1) {
                            value = 'blank';
                        } else if (status >= 1 && status <= 2) {
                            value = 'amber';
                        } else if (status >= threshold) {
                            value = 'late';
                        }
                    }

                    // Limit current year data to months up to the current month
                    if (parseInt(year) === currentYear && index > currentMonth) {
                        value = 'blank';
                    }

                    return { month, value };
                });
            });

            return delinquencyData;
        };

        const delinquencyDataMobile = convertDelinquencyData(mainReport?.details?.delinquencyData ?? {});

        const check_Type = (type) => {
            switch (type) {
                case 'on-time':
                    return mark_1;
                case 'amber':
                    return mark_2;
                case 'late':
                    return mark_3;
                case 'blank':
                    return mark_4;
                default:
                    return;
            }
        };

        return (
            <>
                <div className='mobile-payment-del-sub-main'>
                    <div className='mobile-payment-del-sub-head'>
                        <div className='mobile-payment-del-sub-btn'>
                            <button
                                onClick={handlePreviousYear}
                                disabled={currentYearIndex === years.length - 1}
                            >
                                <FaAngleLeft className={currentYearIndex === years.length - 1 ? "class-btn-inactive" : "class-btn-active"} />
                            </button>
                            <span>{years[currentYearIndex]}</span>
                            <button
                                onClick={handleNextYear}
                                disabled={currentYearIndex === 0}
                            >
                                <FaAngleRight className={currentYearIndex === 0 ? "class-btn-inactive" : "class-btn-active"} />
                            </button>
                        </div>
                    </div>
                    <hr className='line-break-hr' style={{ marginTop: 10 }} />
                    <div className='mobile-payment-del-sub-content-blocks'>
                        {delinquencyDataMobile[years[currentYearIndex]]?.map((item, index) => (
                            <div key={index + "payment-del-content"} className='mobile-payment-del-sub-content-block'>
                                <p>{item?.month}</p>
                                <img src={check_Type(item?.value)} alt={item?.value} />
                            </div>
                        ))}
                    </div>
                    <div className='mobile-payment-del-sub-marks'>
                        {elem.map((item) =>
                            <div key={item.key}>
                                <img src={item.src} alt={item.alt} />
                                <p>{item.label}</p>
                            </div>
                        )}
                    </div>
                </div>
            </>
        );
    };


    const check_Verbiage = (acct_type = "type_fair") => {

        const checkTypeForThis = (typ) => {
            switch (typ) {
                case "type_perfect":
                    return {
                        background: "#ebf9f3",
                        src: payment_acct_mark1,
                        alt: "Perfect",
                        label: "Perfect !",
                        para: "You’ve successfully maintained on-time payments for the past three months. Keep it up!"
                    }
                case "type_good":
                    return {
                        background: "#ebf9f3",
                        src: payment_acct_mark2,
                        alt: "Good",
                        label: "Good !",
                        para: "For this account, you’ve been timely with most payments. To enhance your credit standing, it’s important to make sure that you do not miss any future payments and maintain a perfect payment history. This account is impacting your credit score negatively. "
                    }
                case "type_fair":
                    return {
                        background: "#FEF0C7",
                        src: payment_acct_mark3,
                        alt: "Fair",
                        label: "Fair !",
                        para: "For this account, there have been occasional missed payments. Fund your account much ahead of due date, set reminders or automate payments to improve on time payment record. This account is impacting your credit score negatively. "
                    }
                case "type_poor":
                    return {
                        background: "#FFE9E7",
                        src: payment_acct_mark4,
                        alt: "Poor",
                        label: "Poor !",
                        para: "For this account, several payments have been missed, placing the account in a high-risk category and worsening your overall credit standing."
                    }
                default:
                    return {}
            }
        }
        const {
            background,
            src,
            alt,
            label,
            para
        } = checkTypeForThis(acct_type);
        return (<>
            <div className='mobile-payment-acct-verbiage'
                style={{ background: background }}
            >
                {/* <img src={src} alt={alt} /> */}
                {/* {acct_type === "type_perfect" ? <b>{label}</b> : null} */}
                <p>{para}</p>
            </div>
            <hr style={{ marginTop: 10 }} className='hr-line-break' />
        </>)
    }

    const convertDateToFormat = (timestamp) => {
        const date = new Date(timestamp);
        const options = { day: '2-digit', month: 'short', year: 'numeric' };
        return date.toLocaleDateString('en-GB', options);
    }

    return (<>
        <div className='mobile-gyaab-payments'>
            <div className='mobile-gyaab-payments-head'>Payment History for this account</div>
            <span className='mobile-gyaab-payments-sub-head'>Last Update on {convertDateToFormat(mainReport?.dateReported)} as reported by Experian Bureau</span>
            {Delinquency()}
            {mainReport?.status === "Closed" ? null : check_Verbiage(mainReport?.accountType)}
            {mainReport?.status === "Closed" ? null : Action_Button_Block(mainReport)}
        </div>
    </>)
}

const GeneralElementHandler = ({ type, data }) => {
    const getDetails = (val) => {
        switch (val) {
            case "type_payment":
                return <PaymentBodyPart propData={data} />

            default:
                return <></>;
        }
    }

    return (<>
        <div className="gnrl-your-account-accordion-body">
            <hr className='line-break-hr' />
            {getDetails(type)}
        </div>
    </>)
}

const HandleGeneralAccounts = ({ accounts, type, mainLoading }) => {
    const [searchQuery, setSearchQuery] = useState("");
    const [openAccordionIndex, setOpenAccordionIndex] = useState(null);
    const [filteredAccounts, setFilteredAccounts] = useState(accounts);
    const trim_text = (text) => {
        if (!text) return "";
        const baseLimit = 22; // Default character limit
        const extraChars = window.innerWidth > 400 ? Math.min(10, (window.innerWidth - 400) / 50) : 0;
        const charLimit = baseLimit + Math.floor(extraChars); // Adding a small buffer for wider screens
        return text.length > charLimit ? text.substring(0, charLimit) + "..." : text;
    };

    useEffect(() => {
        const timeout = setTimeout(() => {
            const lowerCaseQuery = searchQuery.toLowerCase();
            const filtered = accounts.filter(account =>
                account.bankName.toLowerCase().includes(lowerCaseQuery) ||
                account.loanType.toLowerCase().includes(lowerCaseQuery)
            );
            setFilteredAccounts(filtered);
        }, 300); // Debounce delay: 300ms

        return () => clearTimeout(timeout); // Cleanup the timeout on query change
    }, [searchQuery, accounts]);

    const toggleAccordion = (index) => {
        setOpenAccordionIndex(openAccordionIndex === index ? null : index);
    };

    const getColor = (it) => {
        switch (it) {
            case "Active":
                return "green";
            case "Closed":
                return "red";
            default:
                return "";
        }
    }

    const getValue = (it) => {
        if (it.length > 6) {
            return it.slice(0, 6);
        }
        switch (it) {
            case "Active":
                return "Active";
            case "Closed":
                return "Closed";
            default:
                return it;
        }
    }
    const shortenText = (text) => {
        if (text.length <= 10) return text; // If length is 10 or less, return as is
        const lastSix = text.slice(-4); // Get last 6 characters
        const hiddenPart = "X".repeat(6); // Replace the first part with 'X'
        return hiddenPart + lastSix;
    };

    const shortenText2 = (text) => {
        if (text.length <= 7) return text;
        const lastSix = text.slice(0, 7);
        return lastSix;
    };

    const getColorFontPercentage = (account) => {
        const per = account.paymentPercentage * 100;
        const status = account.status;
        if (status === "Closed") {
            return { color: "#918b8b", fontWeight: "700" }
        } else {
            if (per >= 100) {
                return { color: "#75c258", fontWeight: "700" }
            } else if (per < 100 && per > 90) {
                return { color: "rgb(240, 194, 75)", fontWeight: "700" }
            } else if (per <= 90 && per > 70) {
                return { color: "#f9955b", fontWeight: "700" }
            } else if (per <= 70) {
                return { color: "#ff0000", fontWeight: "700" }
            }
        }
    }

    const getCreditPercentageColor = (account) => {
        const per = account.limitUtilized;
        if (per <= 30) {
            return "green"
        } else if (per > 30 && per < 70) {
            return "rgb(240, 194, 75)"
        } else if (per >= 70) {
            return "red"
        }
    }
    const getLabelColor = (account, status) => {
        if (account.label === "Timely payments") {
            return getColorFontPercentage(account).color;
        } else if (account.label === "Limit Used") {
            return getCreditPercentageColor(account);
        }
    };




    return (<>
        <div className='landing-page-handle-general-accounts'>
            <div className='landing-page-handle-general-accounts-top'>
                <span> <b>Your Accounts</b> {`(${filteredAccounts.length})`}</span>
                <input
                    type='text'
                    placeholder='🔍 Search bank'
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                />
            </div>
            <div className="landing-page-handle-general-accounts-body">
                {
                    filteredAccounts.map((account, index) =>
                        <div key={index} className='lphga-element'>
                            <div className='lphga-element-head'>
                                <span>{account.loanType}</span>
                                <button style={{}} onClick={() => toggleAccordion(index)}>
                                    <FaChevronDown
                                        style={{
                                            transform: openAccordionIndex === index ? 'rotate(180deg)' : 'rotate(0deg)',
                                            transition: 'transform 0.3s ease',
                                            color: "#757B78"
                                        }}
                                    />
                                </button>
                            </div>
                            <div className='lphga-element-visible-part'>
                                <div className='lphga-element-visible-part-left'>
                                    <img src={BANK_IMG_STACK(account.bankName)} alt={account.bankName + " icons"} />
                                    <div className='lphga-element-visible-part-left-info'>
                                        <span>{trim_text(account.bankName)}</span>
                                        <div className='lphga-element-visible-part-left-info-elm'>
                                            <div className='lphga-element-visible-part-left-info-elm-1'>{shortenText(account.accountNumber)}</div>
                                            <span style={{ fontSize: 12 }}> | </span>
                                            <div
                                                title={account.label || "-"}
                                                style={{ color: getColor(account.status) }}
                                                className='lphga-element-visible-part-left-info-elm-2'
                                            >{shortenText2(account.status)}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='lphga-element-visible-part-right'>
                                    <div
                                        title={account.label || "-"}
                                        style={{ color: getLabelColor(account, account.status) }}
                                        className='lphga-element-visible-part-right-1'
                                    >{account.value}</div>
                                    <div className='lphga-element-visible-part-right-2'>{account.label}</div>
                                </div>
                            </div>
                            {openAccordionIndex === index && (
                                <GeneralElementHandler type={type} data={account} />
                            )}
                        </div>
                    )
                }

            </div>
        </div>
    </>)
}

const PaymentBody = ({ isLoading, mainData, mainBlockWidth }) => {
    const [insightType, setInsightType] = useState("type_perfect");
    const [loading, setLoading] = useState(true);
    const [details, setDetails] = useState([]);
    const checkType = () => {
        const { totalPayments = 0, onTimePayments = 0 } = mainData || {};
        // Handle cases where mainData is empty or missing required fields
        if (!totalPayments || !onTimePayments) {
            setInsightType("type_poor"); // Default or fallback value
            return;
        }

        const res = onTimePayments / totalPayments;

        if (res === 1) {
            setInsightType("type_perfect");
        } else if (res < 1 && res >= 0.9) {
            setInsightType("type_good");
        } else if (res < 0.9 && res >= 0.7) {
            setInsightType("type_fair");
        } else if (res < 0.7) {
            console.log(totalPayments, onTimePayments)
            setInsightType("type_poor");
        }
    };
    const checkSubType = (data) => {
        const { totalPayments, onTimePayments } = data || {};

        // Handle cases where mainData is empty or missing required fields
        if (!totalPayments || !onTimePayments) {
            setInsightType("type_poor"); // Default or fallback value
            return;
        }
        const res = onTimePayments / totalPayments;

        if (res === 1) {
            return "type_perfect"
        } else if (res < 1 && res >= 0.9) {
            return "type_good"
        } else if (res < 0.9 && res >= 0.7) {
            return "type_fair"
        } else if (res < 0.7) {
            return "type_poor"
        }
    };
    const calculatePercentage = (a, b) => {
        if (b === 0) {
            return 'Error: Division by zero';
        }
        const percentage = (a / b) * 100;
        return percentage.toFixed(0);
    }

    useEffect(() => {
        checkType();
        const { insightAccountDetails = [] } = mainData;
        createDetails(insightAccountDetails)
    }, [mainData]); // Re-run when mainData changes


    const createDetails = (data) => {
        const { mobile } = mainData || {};
        setLoading(true);
        const formatText = (text) => {
            return text
                .toLowerCase() // Convert to lowercase
                .replace(/[_\.]+/g, " ") // Replace underscores and dots with spaces
                .split(" ") // Split into words
                .map(word => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize first letter
                .join(" "); // Join words back into a sentence
        };
        const shortenText = (text) => {
            if (text.length <= 10) return text; // If length is 10 or less, return as is
            const lastSix = text.slice(-4); // Get last 6 characters
            const hiddenPart = "X".repeat(6); // Replace the first part with 'X'
            return hiddenPart + lastSix;
        };
        let res = data.filter((it) => it.isAccountBad).map((item) => ({
            data: item,
            mobileNumber: mobile,
            bankName: item?.subscriberName || "-",
            loanType: formatText(item?.loanCategory) || "-",
            fields: [
                { label: "Account No", value: item?.accountNumber || "-" },
                { label: "On-Time Payments", value: `${item?.onTimePayments}/${item?.totalPayments} ` || "0/0" },
                { label: "Status", value: formatText(item?.status) || "-" }
            ],
            dateReported: item?.dateReported || "0",
            paymentPercentage: `${item?.onTimePayments / (item?.totalPayments)}`,
            accountType: checkSubType(item),
            status: formatText(item?.status),
            accountNumber: item?.accountNumber || "-",
            label: "Timely payments",
            value: `${item?.onTimePayments}/${item?.totalPayments} ` || "0/0",
            isAccountBad: item?.isAccountBad || false,
            details: {
                delinquencyData: item?.delinquencyData || {}
            }
        }))
        let finalRes = reorderArray(res)
        setDetails(finalRes);
        setLoading(false);
    }


    return (
        <>
            <div className='mobile-landing-payment-main'>
                <HandleGeneralAccounts
                    type={"type_payment"}
                    mainLoading={isLoading}
                    accounts={details}
                />
            </div>
        </>
    );
}

const GreetBlock = ({ isLoading, mainData }) => {
    const { userName } = mainData || { userName: "" };
    if (isLoading) {
        return (<><div className='greet-block-main' style={{ padding: 5 }}>
            <div className="greet-block-name">
                {/* <span><b>Hello, </b> Gaurav Badgujar</span>
            <p>It's good to see you again.</p> */}
                <span className='skeleton-generic' style={{ height: 20, width: 100 }}></span>
                <p className='skeleton-generic' style={{ height: 20, width: 120 }}> </p>
            </div>
            {/* <div className="greet-block-date" >
                <span className='skeleton-generic' style={{ height: 20, width: 120 }}> </span>
                <p className='skeleton-generic' style={{ height: 20, width: 120 }}> </p>
            </div> */}
        </div>
            <hr className='hr-line-break' />
        </>)
    } else
        return (<>
            <div className='greet-block-main'>
                <div className="greet-block-name">
                    {/* <span><b>Hello, </b> Gaurav Badgujar</span>
                <p>It's good to see you again.</p> */}
                    <span>Hello, <b>{userName}!</b> </span>
                    <p> Good to see you again.</p>
                </div>
                {/* <div className="greet-block-date">
                    <span>Last Updated on : </span>  <p> 21 sept, 24</p>
                </div> */}
            </div>
            <hr className='hr-line-break' />
        </>)
}
function MobileBadLoanScreen({ isLoading, mainData, mainBlockWidth }) {
    const [localData, setLocalData] = useState({
        totalPayments: 1,
        latePayments: "",
        onTimePayments: 0,
        limitUtilized: 0,
        creditLimit: 1,
        age: "",
        activeAccounts: "",
        closedAccounts: "",
        activeLoanAccounts: "",
        activeSecureLoanAccounts: "",
        activeUnsecureLoanAccounts: "",
        activeCreditCards: "",
        loanEnquiries: "",
        cardEnquiries: "",
    })
    useEffect(() => {
        setLocalData(mainData)
    }, [mainData])





    const handleBodyType = (type) => {
        return <PaymentBody isLoading={isLoading} mainData={mainData} mainBlockWidth={mainBlockWidth} />
    }
    return (
        <>
            <GreetBlock isLoading={isLoading} mainData={mainData} />
            <CommonLandScreen isLoading={isLoading} mainData={mainData} mainBlockWidth={mainBlockWidth} />
            <hr className='hr-line-break' />
            {handleBodyType()}

        </>
    );

}

export default MobileBadLoanScreen