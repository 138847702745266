import React from "react";
import { ImCancelCircle } from "react-icons/im";
import check from "../assets/img/check.png"


function SAccountsCard({ creditMixdata }) {
  return (
    <>
      {/* <div className='SecuredCard'>

                <div className='totalamtCard'>
                    <div className='textcardDiv' >
                        <div className='card-details-div-1'>
                            <div>Total Amount: </div>
                            <div>Total Outstanding: </div>
                            <div>Total Amount Pass Due: </div>
                        </div>
                        <div className='card-details-div-2'>
                        <div>₹{creditMixdata?.totalUnsecuredAmount?.toLocaleString('en-IN')}</div>
                            <div>₹{creditMixdata?.outstandingUnsecureAmount.toLocaleString('en-IN')}</div>
                            <div>₹{creditMixdata?.unsecureAmountPastDue.toLocaleString('en-IN')}</div>
                        </div>
                    </div>
                </div>

                <div className='Accinfo'>
                    <div className='acctiveAccounts'>
                        <div className='text-account-num'>{creditMixdata?.unsecureActiveAccounts}</div>
                        <div className='text-account-text' >Active Accounts</div>
                    </div>

                    <div className='closedAccounts'>
                        <div className='text-account-num'  >{creditMixdata?.unsecureClosedAccounts}</div>
                        <div className='text-account-text' >Closed Accounts</div>
                    </div>
                </div>

      </div> */}
      <div className="account-information-card-creditmix">
        <div className="account-info-heading-h1">Unsecured</div>
        <div className="account-ammout-information">
          <div className="acc-div-info-1">
            <div className="amount-text-info-text">Total Amount :</div>
            <div className="amount-text-info-text">₹{creditMixdata?.totalUnsecuredAmount?.toLocaleString('en-IN')}</div>
          </div>

          <div className="acc-div-info-1">
            <div className="amount-text-info-text">Total Outstanding :</div>
            <div className="amount-text-info-text">₹{creditMixdata?.outstandingUnsecureAmount.toLocaleString('en-IN')}</div>
          </div>

          <div className="acc-div-info-1">
            <div className="amount-text-info-text">Total Amount Pass Due :</div>
            <div className="amount-text-info-text">₹{creditMixdata?.unsecureAmountPastDue.toLocaleString('en-IN')}</div>
          </div>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: "18px",
          padding:"0 10px"
        }}
      >
        <div>
          <div style={{ display: "flex" }}>
            <div>
            <img src={check} alt="check-circle"  className="img-width-1"  />
            </div>
            <div className="accounts-text-gap">
              <div className="accounts-num-coun">
                {creditMixdata?.unsecureActiveAccounts}
              </div>
              <div className="user-account-status">Active Accounts</div>
            </div>
          </div>
        </div>

        <div>
          <div style={{ display: "flex" }}>
            <div>
              <ImCancelCircle color="#D92D20" size={"24px"}  className="img-width-1"  />
            </div>
            <div className="accounts-text-gap">
              <div className="accounts-num-count">
                {creditMixdata?.unsecureClosedAccounts}
              </div>
              <div className="user-account-status">Closed Accounts</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SAccountsCard;
