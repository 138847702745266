import React, { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import SidebarScreen from '../Components/Sidebar/SidebarScreen';
import TopbarScreen from '../Components/Topbar/TopbarScreen';
import "./style.css";

const DashboardLayout = () => {
    const [innerWidth, setinnerWidth] = useState(0);
    useEffect(() => {
        const handleResize = () => {
            setinnerWidth(window.innerWidth);
        };

        handleResize();
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    return (
        <div className='dashboard-layout-2'>
            <div className="dashboard-layout__left-2">
                <SidebarScreen />
            </div>
            <div className="dashboard-layout__right-2">
                <TopbarScreen />
                <Outlet />
            </div>
        </div >
    );
};

export default DashboardLayout;
