import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  Typography,
  Tooltip,
  TextField,
  Button,
  Modal,
  Container,
  useMediaQuery,
  Popover,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import SettingsIcon from "@mui/icons-material/Settings";
import LogoutIcon from "@mui/icons-material/Logout";
import { useLocation } from "react-router-dom";
import axiosInstance from "../service/axiosInstance";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SmsIcon from "@mui/icons-material/Sms";
import Loading from "../Components/Loading";
import { counterOffterData } from "../service/ApiList";
import "./style.css";
import { generalSwitch } from "../utils/generalSwitch";
// import CryptoJS from "crypto-js";

function SettleOffer() {
  const [openModal, setOpenModal] = useState(false);

  // const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);
  const navigate = useNavigate();
  const location = useLocation();
  const accountDetailsId = location?.state?.accountDetailsId;
  const isMorebtn = location?.state?.isMorebtn;

  const [settleData, setSettleData] = useState();
  const [amountToPaid, setAmountToPaid] = useState("");
  const [loading, setLoading] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null); // State for popover

  const handleLogout = () => {
    localStorage.clear("experianData");
    localStorage.clear("TDFToken");
    localStorage.clear("loginConfirm");
    navigate("/");
  };
  const handlegoProfile = () => {
    navigate("/dashboard/profile");
  };
  const isMobile = useMediaQuery("(max-width:1100px)");
  const morebtn = isMorebtn ? "acDetail" : "settle";

  useEffect(() => {
    const token = localStorage.getItem("TDFToken");
    axiosInstance
      .post(
        `/tdf/api/user/${morebtn}`,
        { accountDetailsId },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setSettleData(res?.data);
        setAmountToPaid(res?.data?.totalOutStanding);
        if (res?.data?.status && res?.data.success === false) {
          const status = res?.data?.status;
          generalSwitch(status, navigate);
        }
      })
      .catch((err) => {
        console.log("err:", err);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handlegoBack = () => {
    navigate(-1);
  };
  const handleChange = (e) => {
    setAmountToPaid(e.target.value);
  };
  const handleSubmit = () => {
    setLoading(true);

    // const encryptedPhoneNumber = localStorage.getItem("phoneNumber");
    // const SECRET_KEY = "secret_key_for_tdf_developers";
    // let phoneNumber = null;
    // if (encryptedPhoneNumber) {
    //   phoneNumber = CryptoJS.AES.decrypt(
    //     encryptedPhoneNumber,
    //     SECRET_KEY
    //   ).toString(CryptoJS.enc.Utf8);
    // }
    const phoneNumber = localStorage.getItem("phoneNumber");

    counterOffterData(
      {
        accountDetailsId: accountDetailsId,
        amount: amountToPaid,
        phone: settleData?.accountDetails?.mobileNo
          ? settleData?.accountDetails?.mobileNo
          : phoneNumber,
      },

      (res, err) => {
        if (
          res?.status === 200 &&
          res?.data?.msg === "TDF will get back to you."
        ) {
          setOpenModal(true);
          setLoading(false);
        } else if (res?.data?.status && res?.data?.success === false) {
          setLoading(false);
          const status = res?.data?.status;
          generalSwitch(status, navigate);
        } else {
          setLoading(false);
        }
      }
    );
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget); // Open popover
  };

  const handleClose = () => {
    setAnchorEl(null); // Close popover
  };
  const handleExperLink = () => {
    window.open(
      "https://consumer.experian.in/ECSINDIA-DCE/view/angular/index.html#!/utiERNPage"
    );
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <>
      {loading ? (
        <>
          <Loading />
        </>
      ) : (
        <>
          <Box className="page-container-settle-1 ">
            <div className="content-container-settle-1">
              <Box style={{ display: "flex", justifyContent: "space-between" }}>
                <Box>
                  <Typography
                    style={{
                      color: "#044a67",
                      fontSize: "20px",
                      fontFamily: "CustomFontMedium",
                    }}
                  >
                    Hi {location?.state?.name} {location?.state?.surName}!
                  </Typography>
                  <Typography
                    style={{
                      color: "#044a67",
                      fontSize: "20px",
                      fontFamily: "CustomFontMedium",
                    }}
                  >
                    It's good to see you again.
                  </Typography>
                </Box>
                {isMobile ? (
                  <></>
                ) : (
                  <>
                    <Box style={{ display: "flex" }}>
                      <SettingsIcon
                        style={{ marginRight: "20px", cursor: "pointer" }}
                        onClick={handleClick} // Open the popover on click
                      />
                      <Tooltip title="Logout">
                        <LogoutIcon
                          onClick={handleLogout}
                          style={{ cursor: "pointer" }}
                        />
                      </Tooltip>
                    </Box>
                  </>
                )}

                {/* Popover for Settings */}
                <Popover
                  id={id}
                  open={open}
                  anchorEl={anchorEl}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                >
                  <Box p={2}>
                    <Typography
                      onClick={handlegoProfile}
                      style={{ cursor: "pointer" }}
                    >
                      Profile
                    </Typography>
                  </Box>
                </Popover>
              </Box>
              <Grid container spacing={1} style={{ marginTop: "20px" }}>
                <Grid item xs={2} sm={4} lg={4}>
                  <Box>
                    <ArrowBackIcon
                      style={{ cursor: "pointer" }}
                      onClick={handlegoBack}
                    />
                  </Box>
                </Grid>
                <Grid item xs={10} sm={8} lg={8}>
                  <Box>
                    <Typography
                      style={{
                        fontWeight: "600",
                        fontSize: "20px",
                        fontFamily: "CustomFontMedium",
                      }}
                    >
                      {settleData?.accountDetails?.subscriber_Name}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
              <Box style={{ marginTop: "10px", display: "flex" }}>
                <SmsIcon
                  style={{
                    fontSize: "14px",
                    marginTop: "6px",
                    color: "#044a67",
                    marginRight: "5px",
                  }}
                />
                <Typography style={{ fontFamily: "CustomFontMedium" }}>
                  {settleData?.accountDetails?.msg}
                </Typography>
              </Box>
              <Box style={{ marginTop: "10px", display: "flex" }}>
                <Typography
                  style={{
                    fontWeight: "600",
                    fontSize: "16px",
                    fontFamily: "CustomFontMedium",
                  }}
                >
                  Loan Type :{" "}
                  <span style={{ fontWeight: "600", fontSize: "16px" }}>
                    {settleData?.accountDetails?.loanType}{" "}
                  </span>
                </Typography>
              </Box>
              <Box style={{ marginTop: "20px" }}>
                <Typography
                  style={{ fontSize: "20px", fontFamily: "CustomFontMedium" }}
                >
                  Account Details :-
                </Typography>
              </Box>
              <Grid container style={{ marginTop: "20px" }}>
                <Grid
                  item
                  xs={12}
                  sm={3}
                  lg={3}
                  style={{
                    border: "1px solid rgba(0,0,0,.12)",
                    padding: "10px",
                  }}
                >
                  <Box style={{ fontFamily: "CustomFontMedium" }}>
                    Account Number
                  </Box>
                  <Box
                    style={{ overflow: "auto", fontFamily: "CustomFontMedium" }}
                  >
                    {settleData?.accountDetails?.account_Number}
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={3}
                  lg={3}
                  style={{
                    border: "1px solid rgba(0,0,0,.12)",
                    padding: "10px",
                    fontFamily: "CustomFontMedium",
                  }}
                >
                  <Box>Total Outstanding Amount</Box>
                  <Box>
                    ₹
                    {settleData?.totalOutStanding
                      ? settleData?.totalOutStanding
                      : 0}
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={3}
                  lg={3}
                  style={{
                    border: "1px solid rgba(0,0,0,.12)",
                    padding: "10px",
                    fontFamily: "CustomFontMedium",
                  }}
                >
                  <Box>Days Overdue</Box>
                  <Box>
                    {settleData?.daysOverdue ? settleData?.daysOverdue : 0}
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={3}
                  lg={3}
                  style={{
                    border: "1px solid rgba(0,0,0,.12)",
                    padding: "10px",
                    fontFamily: "CustomFontMedium",
                  }}
                >
                  <Box>Priority</Box>
                  <Box>{settleData?.priority}</Box>
                </Grid>
              </Grid>
              <Grid container spacing={1} style={{ marginTop: "20px" }}>
                <Grid item xs={12} sm={8} lg={8}>
                  <Box
                    style={{ display: "flex", fontFamily: "CustomFontMedium" }}
                  >
                    <Typography style={{ marginTop: "15px" }}>
                      Amount to be paid
                    </Typography>
                    <TextField
                      id="standard-basic"
                      type="number"
                      variant="standard"
                      style={{ marginLeft: "10px" }}
                      value={amountToPaid}
                      onChange={handleChange}
                    />
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  lg={12}
                  style={{ marginTop: "20px" }}
                >
                  <Button
                    style={{
                      background: "#044a67",
                      color: "#fff",
                      padding: "7px 15px",
                      textTransform: "capitalize",
                    }}
                    onClick={handleSubmit}
                  >
                    Submit Offer
                  </Button>
                </Grid>
              </Grid>
            </div>
            <Grid container spacing={1} className="footer-grid-settle-1">
              <Grid item xs={12} sm={12} lg={12}>
                <div className="dispute-div-1">
                  <div className="dispute-div-2">
                    <div className="dispute-heading">
                      Notice an error in your report?
                    </div>
                    <div className="dispute-sub-heading">
                      Get it rectified by reporting it to the bureau
                    </div>
                    <div className="settle-button-div-1">
                      <button onClick={handleExperLink}>
                        Report to Experian
                      </button>
                    </div>
                  </div>
                </div>
              </Grid>
            </Grid>
          </Box>
          <Modal
            open={openModal}
            onClose={handleCloseModal}
            aria-labelledby="modal-title"
            aria-describedby="modal-description"
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Container
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                height: "200px",
                backgroundColor: "white",
                padding: "20px",
                borderRadius: "8px",
                boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
                // width: "640px",
                width: {
                  lg: "25%",
                  sm: "50%",
                  xs: "90%",
                },
                // padding: "15px",
              }}
            >
              <Typography
                id="modal-title"
                variant="h6"
                component="h2"
                style={{ fontSize: "20px" }}
              >
                Thank you for making a counter offer.
              </Typography>
              <Typography
                id="modal-title"
                variant="h6"
                component="h2"
                style={{ fontSize: "16px" }}
              >
                Please allow us to revert to you within 48 hours.
              </Typography>
              <Box style={{ display: "flex", alignSelf: "self-end" }}>
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={handleCloseModal}
                  style={{
                    background: "#05517a",
                    color: "white",
                    borderRadius: "10px",
                    marginTop: "20px",
                  }}
                >
                  Close
                </Button>
              </Box>
            </Container>
          </Modal>
        </>
      )}
    </>
  );
}

export default SettleOffer;
