import React, { useEffect, useState } from 'react'
import "./style.css";
import { toggleSidebar } from "../../store/store";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from 'react-router-dom';
import { MdOutlineKeyboardArrowRight } from 'react-icons/md';
import LOGOUT_ICON from "../../assets/img/logoutBtn.png"
import TDF_LOGO from '../../assets/imgs/DUE-FACTORY.png';
import { CgProfile } from 'react-icons/cg';
import { AiOutlineLogout } from 'react-icons/ai';
import { IoHome } from 'react-icons/io5';
const TopbarScreen = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const navigate = useNavigate();
    const isOpen = useSelector((state) => state.sidebar.isOpen);
    const [innerWidth, setinnerWidth] = useState(0);
    useEffect(() => {
        const handleResize = () => {
            setinnerWidth(window.innerWidth);
        };
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    const pathParts = location.pathname.split("/").filter((part) => part);
    const breadcrumbs = pathParts.map((part, index) => {
        const path = `/${pathParts.slice(0, index + 1).join("/")}`;
        return { name: part.charAt(0).toUpperCase() + part.slice(1), path };
    });

    const handleChange = () => {
        dispatch(toggleSidebar());
    }
    const getGreeting = () => {
        const currentHour = new Date().getHours();
        if (currentHour < 12) {
            return "Good Morning";
        } else if (currentHour < 18) {
            return "Good Afternoon";
        } else {
            return "Good Evening";
        }
    };
    const goToHome = () => {
        navigate("/home/dashboard")
    }

    const handleLogout = () => {
        // localStorage.clear("experianData");
        // localStorage.clear("TDFToken");
        // localStorage.clear("loginConfirm");
        localStorage.clear();
        navigate("/");
    };
    const handlegoProfile = () => {
        navigate("/home/profile");
    };
    return (
        <>
            <div className='top-bar-main'>
                <div>
                    <div>
                        <div className="breadcrumb-block">
                            {innerWidth > 900 ? <>
                                {breadcrumbs.map((crumb, index) => (
                                    <div key={index} className="breadcrumb-block-item">
                                        {crumb.name == "Home" ? <>
                                            <button className='home-navigate-btn' onClick={() => goToHome()} >
                                                <IoHome style={{ fontSize: 14 }} />
                                            </button>
                                        </> : <p >{crumb.name}</p>}
                                        {index < breadcrumbs.length - 1 && <MdOutlineKeyboardArrowRight />}
                                    </div>
                                ))}
                            </> :
                                <>
                                    <img style={{ height: 25 }} src={TDF_LOGO} alt='text-logo' />
                                </>}
                        </div>
                    </div>
                    {/* <div>
                        <b>{getGreeting()}</b> ,
                        <span> Gaurav Badgujar</span>
                    </div>
                    <p>It's good to see you again.</p> */}
                </div>
                <div className='top-bar-main-right'>
                    <div className='top-bar-main-right-1'>
                        <CgProfile title="Profile" className="hover-text" onClick={handlegoProfile} style={{ fontSize: 20 }} />
                        <AiOutlineLogout title="Logout" className="hover-text" onClick={handleLogout} style={{ fontSize: 20 }} />
                    </div>
                    <div
                        style={{
                            display: innerWidth <= 900 ? "block" : "none",
                        }}
                        onClick={handleChange}
                        className={`hamburger ${isOpen ? 'is-active' : ''}`}
                        id="hamburger-9"
                    >
                        <span className="line"></span>
                        <span className="line"></span>
                        <span className="line"></span>
                    </div>
                </div>
            </div>
        </>
    )
}

export default TopbarScreen