import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import { Chart, ArcElement, Tooltip, Legend } from 'chart.js';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import HorizontalRuleIcon from '@mui/icons-material/HorizontalRule';
import { RxDash } from "react-icons/rx";

// Register the components for Chart.js
Chart.register(ArcElement, Tooltip, Legend);

const AccountChart = ({ data1, blockWidth }) => {
  const isDataZero = data1?.addedAmt === 0 && data1?.preAmt === 0;

  const data = {
    labels: isDataZero ? ['No Data'] : ['Latest Amount Added', 'Previous Amount Added'],
    datasets: [
      {
        // label: 'Amt',
        data: isDataZero ? [1] : [data1?.addedAmt, data1?.preAmt],
        backgroundColor: isDataZero ? ['#17B26A'] : ['#17B26A', '#C3D3EF'], // Blue color when data is zero
        hoverOffset: 0,
        borderWidth: 0, // ✅ Removes border
        hoverBorderWidth: 0, // ✅ Prevents border on hover
      },
    ],
  };


  
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    cutout: '80%',
    plugins: {
      tooltip: {
        enabled: false, // Disable default tooltip
        external: (context) => {
          const tooltipModel = context.tooltip;
  
          // Select or create the custom tooltip container
          let tooltipEl = document.getElementById('chartjs-tooltip');
          if (!tooltipEl) {
            tooltipEl = document.createElement('div');
            tooltipEl.id = 'chartjs-tooltip';
            tooltipEl.style.position = 'absolute';
             tooltipEl.style.background = 'rgba(0, 0, 0, 0.8)';
            tooltipEl.style.color = 'white';
            tooltipEl.style.padding = '8px';
            tooltipEl.style.borderRadius = '4px';
            tooltipEl.style.pointerEvents = 'none';
            tooltipEl.style.zIndex = '9999';
            document.body.appendChild(tooltipEl);
          }
  
          // Hide the tooltip if it's not visible
          if (tooltipModel.opacity === 0) {
            tooltipEl.style.opacity = '0';
            return;
          }
  
          // Set the tooltip content
          if (tooltipModel.body) {
            const titleLines = tooltipModel.title || [];
            const bodyLines = tooltipModel.body.map((b) => b.lines);
            let innerHtml = '<div style="text-align: left; ">'; // Set smaller font size  
  
            // Add title (labels)
            titleLines.forEach((title) => {
              innerHtml += `<div style="font-weight: bold; margin-bottom: 4px;">${title}</div>`;
            });
  
            // Add body (values)
            bodyLines.forEach((body, i) => {
              const backgroundColor = tooltipModel.labelColors[i].backgroundColor;
              const value = body[0];  // Extract the value (addedAmt or preAmt)
  
              // If data is 1 (for 'No Data'), show '0' instead
              const displayValue = value === '1' ? '0' : value;  // Show '0' if value is 1
  
              innerHtml += `
                <div style="display: flex; align-items: center;">
                  <span style="width: 12px; height: 12px; background: ${backgroundColor}; display: inline-block; margin-right: 6px;"></span>
                  ${displayValue}
                </div>`;
            });
  
            innerHtml += '</div>';
            tooltipEl.innerHTML = innerHtml;
          }
  
          // Position the tooltip
          // const { offsetLeft, offsetTop } = context.chart.canvas;
          const position = context.chart.canvas.getBoundingClientRect();
          tooltipEl.style.opacity = '1';
          tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX + 'px';
          tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY + 'px';
        },
      },
      legend: {
        display: false,
      },
      
    },
    
  };
  const customWidth = () => {
    const zoomLevel = Math.round((window.outerWidth / window.innerWidth) * 100);
    switch (true) {
      case blockWidth >=  1300:
        return 86          
      case blockWidth <= 1170 && blockWidth >= 1102 :
        return 74
        case blockWidth <= 1101 && blockWidth >= 1000:
        return 86
        case blockWidth <= 1000 && blockWidth >= 855:
          return 86        
        case blockWidth <= 1000 && blockWidth >= 701:
          return 86        
        case blockWidth <= 700:
          return 74  
      default:
        return 60 
    }
  };
  
  
  
  return (
    <div
  className='circle-chart-div-1'
  style={{width:customWidth(), height:customWidth() - 2}}
    >
      {/* Doughnut Chart */}
      <div style={{ position: 'relative', width: '100%', height: '100%',zIndex:'100' }}>
        <Doughnut data={data} options={options} />
      </div>

      {/* TrendingUpIcon in the center, with pointer-events set to none */}
      <div
        style={{
          position: 'absolute',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
          height: '100%',
          pointerEvents: 'none',
        }}
      >
        {data1?.addedAmt ? (
          <TrendingUpIcon style={{ fontSize: 24, color: '#17B26A' }} />
        ) : (
          <div style={{ fontSize: 24, color: '#C3D3EF' }}>        
     {/* <RxDash style={{ fontSize: 24, color: '#C3D3EF' }} /> */}
     --
     </div>
     
        )}
      </div>
    </div>
  );
};

export default AccountChart;