import React, { useState } from "react";
import HeaderCoponent from "../Components/HeaderCoponent";
import {
  Box,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Modal,
  Button,
  Container,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FotterComponent from "../Components/FotterComponent";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { POST_REVOKECONSENT } from "../service/ApiList";

function FaqComponents() {
  const [openModal, setOpenModal] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const navigate = useNavigate()

  const token = localStorage.getItem("TDFToken");
  const handleOpenModal = () => {
    if (token) {
      setOpenModal(true);
    } else {
      toast.error("Please Log in first!");
    }
  };
  const handleCloseModal = () => setOpenModal(false);

  const hanadleRevokeConsent =  async() => {
       
      POST_REVOKECONSENT({},(res,err) => {       
        if (res){          
        toast.success(res?.data?.message);
        handleCloseModal();
        localStorage.removeItem("loginConfirm");
        localStorage.removeItem("TDFToken"); 
        localStorage.clear();
        localStorage.clear();       
        navigate("/")      
        window.scrollTo(0, 0);  
        }
        else{
          toast.error(err?.message);     
  
        }
  
      })
  };
  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false); 
  };
  const handleGrievance =()=>{
    navigate("/grievance-redressal")
    window.scrollTo(0, 0);

  }

 
  const faqData = [
    {
      question: "What is a credit score?",
      answer:
        "A credit score is a 3-digit numeric score that determines your credit worthiness. Your credit score typically falls between 350-900, and the higher your score the stronger is your credit worthiness.",
    },
    {
      question: "How is a credit score calculated?",
      answer: (
        <>
          <Typography style={{ fontSize: "13px", fontWeight: "500", fontFamily: "CustomFontMedium" }}>
            Your credit score is derived from your past history on:
          </Typography>
          <ul style={{ paddingLeft: "20px", fontWeight: "500", fontFamily: "CustomFontMedium" }}>
            <li>Amount of debt</li>
            <li>Repayment of loans, credit cards</li>
            <li>Number of open credit lines</li>
            <li>Type and sub-type of loans</li>
            <li>Tenure of loans</li>
            <li>Recency of inquiry for new credit line</li>
          </ul>
        </>
      ),
    },
    {
      question: "Who calculates my Credit Score?",
      answer: (
        <>
          In India, there are 4 credit information companies or credit bureaus licensed by the Reserve Bank of India (RBI):
          <ol style={{ paddingLeft: "20px", fontWeight: "500", fontFamily: "CustomFontMedium" }}>
            <li>
              <b>CIBIL</b> - Their credit score range is between 300 and 900.
            </li>
            <li>
              <b>Equifax</b> - Their credit score range is between 300 and 850.
            </li>
            <li>
              <b>Experian</b> - Their credit score range is between 300 and 850.
            </li>
            <li>
              <b>CRIF Highmark</b> - Their credit scores range between 300 and 900.
            </li>
          </ol>
        </>
      ),
    },
    {
      question: "How do I check my credit score?",
      answer: (
        <>
          On Dues Factory, you can get your credit score here absolutely free. Alternatively, you can go to the websites of any of the 4 licensed credit bureaus in India, fill in your details and request for a credit score upon payment of a nominal fee.
        </>
      ),
    },
    {
      question: "   Why is my credit score important?",
      answer: (
        <>
          While evaluating your loan application, Banks and financial/lending
          institutions need to gauge your credit worthiness. To do so, they
          use the services of these authorized credit bureaus to obtain an
          extensive credit report of you or your business’s credit history to
          underwrite your loan application. A good credit score makes it
          easier for you to secure loans or extended credit lines.
        </>
      ),
    },
    {
      question: "What is a good credit score?",
      answer: (
        <>
          A credit score of 750 or above is considered a good credit score. It
          helps you qualify for loans or credit cards better because it gives
          your potential lenders more confidence in approving your requests
          for loans and other credit.
        </>
      ),
    },
    {
      question: "How is a high credit score useful?",
      answer: (
        <>
          A credit score of 750 or above allows you a faster loan approval
          process. It also helps you avail attractive credit terms like lower
          interest rates, better terms of repayment etc.
        </>
      ),
    },
    {
      question: "What are the disadvantages of having a bad credit score?",
      answer: (
        <>
          A credit score of 650 or lower is considered a low credit score and
          a credit score of 400 and lower is considered a bad credit score.
          With such scores you become too high a risk for the bigger banks and
          lending institutions
          <ul style={{ paddingLeft: "20px" }}>
            <li>You get loans at very high interest rates.</li>
            <li>
              You delay building your wealth, because a lot of it goes towards
              repaying high interest
            </li>
          </ul>
        </>
      ),
    },
    {
      question: "Can I improve my credit score?",
      answer: (
        <>
          Yes you can improve your credit score if you take conscious and
          consistent measures to improve it. However, credit scores do not get
          impacted immediately after you have prepaid a loan or paid up any
          outstanding dues. It takes at least 45-60 days for your credit score
          to climb.
        </>
      ),
    },
    {
      question: "Why is my credit score low?",
      answer: (
        <>
         Your credit score could be low because of various reasons, including non-payment or delayed payment of loan EMIs, credit cards or other credit lines. Inefficient practices like utilizing more than 60% of your credit limit on your cards, or if your portfolio mix has more unsecured funds etc can also negatively impact your credit score.
        </>
      ),
    },
    {
      question: "Does prepaying a loan impact my credit score?",
      answer: (
        <>
          Pre-paying a loan is different from paying the full outstanding
          amount on your credit cards. If you prepay your loan, your credit
          report will show it as a closed account. Which means it will not be
          considered when calculating your credit score, as it takes only open
          credit lines in consideration. So while prepaying your loan will not
          decrease your credit score, it may not necessarily increase it
          either.
          <br />
          <br />
          So if you want to build a good credit history, then it is better to
          keep the loan active, which shows that you have the capability of
          consistently paying your EMIs. This helps improve your credit score
          in the long run.
        </>
      ),
    },
    {
      question: "Do delayed credit card payments affect my credit score?",
      answer: (
        <>
          Yes. Delayed payments on your credit card can cause your credit
          score to drop. However, a single late payment may not affect your
          score that much, if you make the payment within 30 days of the due
          date. However, if you delay making payments towards your credit card
          frequently, then your credit score may get impacted by more than 100
          points. either.
        </>
      ),
    },
    {
      question: "What is a soft inquiry against my loan application?",
      answer: (
        <>
          A soft inquiry is usually when you check your own credit score,
          irrespective of whether you have applied for a loan/credit card or
          not. Soft inquiries, therefore, do not affect your credit score.
        </>
      ),
    },
    {
      question: "There have been hard inquiries against my loan? What does that mean?",
      answer: (
        <>
          Lender-initiated inquiries received by credit bureaus towards
          assessing your credit worthiness are called Hard Inquiries. If your
          profile receives a hard inquiry for your application for loan,
          mortgage or credit card, it gets recorded on your credit report and
          negatively impacts your credit score. Hard inquiries tend to stay on
          your credit report for about 2 years.
        </>
      ),
    },
    {
      question: "Why should I worry about keeping a good credit score if I don’t require a loan?",
      answer: (
        <>
          You may not require a loan or a credit card today, but you may need
          one in the future. If you have a credit card, making timely payments
          and not defaulting on any payments will keep your credit score high.
          So when it’s time to apply for that loan, your application will get
          processed faster and you will be able to enjoy better interest and
          other terms.
        </>
      ),
    },
    {
      question: "What kind of transactions are considered by the credit bureaus to calculate my credit score?",
      answer: (
        <>
          You may not require a loan or a credit card today, but you may need
          one in the future. If you have a credit card, making timely payments
          and not defaulting on any payments will keep your credit score high.
          So when it’s time to apply for that loan, your application will get
          processed faster and you will be able to enjoy better interest and
          other terms.
        </>
      ),
    },
    {
      question: "How soon can I expect my credit score to improve?",
      answer: (
        <>
          Resolution of an open credit line takes about 30 days to impact your
          credit score. <br /> <br />
          OR
          <br /> <br />
          We estimate an average 20 days for your credit score to improve
          after you have resolved one or more open credit lines.
        </>
      ),
    },
    {
      question: "Are there any charges associated with Due factory Services provided to the customers?",
      answer: (
        <>
          We do not charge for your credit score report. We do charge a
          Consulting Fee for offering custom-made suggestions and the best
          possible options for you to resolve your open credit lines.
        </>
      ),
    },
    {
      question: "I have a dispute with a lender. Can I get help from due factory services?",
      answer: (
        <>
          Due factory is not a dispute resolution platform . However, in case
          you have a dispute with a lender in terms of the amount outstanding,
          we provide help in getting the best possible settlement offers from
          the lenders and in the process improving your credit health.
        </>
      ),
    },
    {
      question: "The loan/ Credit card outstanding reflecting in my bureau report is not mine.. What should I do ?",
      answer: (
        <>
          While it is not common for credit reports to have errors, you must
          immediately raise a dispute with the credit bureau if you see a loan
          in your credit report that is not yours. The bank may have made a
          genuine mistake, or it is possible that you may be a guarantor for
          someone else’s loan on which s/he has defaulted.
        </>
      ),
    },
    {
      question: "Does taking/ getting a new loan impact my credit score?",
      answer: (
        <>
          Getting a new loan by itself will not affect your credit score.
          However, you may find it difficult taking on additional credit
          before the earlier loan is paid back.
          <br />
          <br />
          In the situation where you have had a loan application get rejected
          in the recent past, we suggest not applying for a fresh loan till
          you have improved your credit score.
        </>
      ),
    },
    {
      question: "What is FOIR?",
      answer: (
        <>
          The Fixed Obligations to Income Ratio (FOIR) is also known as
          debt-to-income ratio. It is a metric used by banks and NBFCs to
          evaluate an individual's loan eligibility. Ideally, FOIR of 40% -
          50% is considered good. This means that your total monthly expenses
          are not more than 40%-50% of your income. The lower your FOIR the
          higher the chance of getting loan approvals.
        </>
      ),
    },
    {
      question: "Can I get my late payment charges waived off my credit card ?",
      answer: (
        <>
          Almost all credit card issuers usually waive off a part or the
          complete late payment charge on a one-time basis if you make a
          request to do so. However, the waiver is at their own discretion.
          They are not obligated to do so.
        </>
      ),
    },
    {
      question: "How can I get a waiver on my loan ?",
      answer: (
        <>
          Waiver on loans is only given by the government in extraordinary
          circumstances for e.g. waiver of loans for drought affected farmers
          who were hit by dismal crop production. Lending institutions levy
          heavy fines for delayed EMIs, so we recommend taking a loan only if
          you are confident of your loan-paying ability for the entire tenure.
        </>
      ),
    },
    {
      question: "How I can revoke my consent from the Due Factory ?",
      answer: (
        <>
          We recommend keeping your consent active to benefit from regular
          credit data monitoring and informed decision-making.
          <br />
          However, if you choose to revoke your consent, be aware that all
          your bureau data with the Due Factory will be removed from your
          account.
          <br />
          To continue with revoking your consent,{" "}
          <span
            style={{ color: "blue", cursor: "pointer" }}
            onClick={handleOpenModal}
          >
            click here.
          </span>
          <br />
          If the above does not answer your query, feel free to write to us at
          care@theduefactory.com, and we will get back to you within 24 hours.
        </>
      ),
    },
    {
      question: "How can I delete my account from the Due Factory ?",
      answer: (
        <>
          We strongly suggest maintaining your Due Factory account to keep
          track of your Credit Score and make informed credit choices.
          However, if you prefer to delete your account, please email us at
          care@theduefactory.com, and we will process your request in 5-7
          working days.
          <br />
          Once your data is deleted you will receive email on the requested
          mail id.
        </>
      ),
    },
    {
      question: "Grievance Redressal Mechanism ?",
      answer: (
        <>
      Due Factory Services prioritizes prompt and transparent grievance resolution to maintain strong customer relationships and prevent recurring issues,
      To know more about it, <span style={{ color: "blue", cursor: "pointer" }} onClick={handleGrievance}>Click here.</span>
        </>
      ),
    },
  ];


  return (
    <>
      <HeaderCoponent />

      <Box style={{ marginTop: "20px" }}>
        <Box
          style={{
            width: "100%",
            height: "100%",
            background: "rgba(32, 175, 229, .16)",
            paddingTop: "75px",
            paddingBottom: "75px",
            textAlign: "center",
          }}
        >
          <Typography style={{ fontSize: "16px", fontWeight: "400", fontFamily: "CustomFontMedium" }}>
            FAQ
          </Typography>
          <Typography style={{ fontSize: "24px", fontFamily: "CustomFontMedium", fontWeight: "400" }}>
            Ask us anything
          </Typography>
          <Typography
            style={{ fontSize: "16px", fontWeight: "400", marginTop: "10px", fontFamily: "CustomFontMedium" }}
          >
            Have any questions? We're here to assist you.
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          padding: {
            xs: "60px 20px", 
            sm: "60px 80px",
            lg: "60px 350px"
          },
          background: "rgb(248, 248, 248)",

        }}
      >
        {faqData.map((faq, index) => (
          <Accordion
            key={index}
            expanded={expanded === `panel${index + 1}`}
            onChange={handleAccordionChange(`panel${index + 1}`)}
            style={{ marginTop: index === 0 ? "0" : "10px" }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`panel${index + 1}-content`}
              id={`panel${index + 1}-header`}
              style={{
                fontSize: "15px",
                fontWeight: "700",
                fontFamily: "Nunito Sans, sans-serif",
                padding: "0px 20px",
                height: "auto",
                borderBottom:"0.5px solid rgb(206, 206, 206)",
              }}
            >
              {faq.question}
            </AccordionSummary>
            <AccordionDetails
              style={{
                fontSize: "13px",
                fontWeight: "500",
                fontFamily: "CustomFontMedium",
                padding: "0 20px 16px !important",
                letterSpacing:".0179em",
                lineHeight:"1.2rem",
              }}
            >
              {faq.answer}
            </AccordionDetails>
          </Accordion>
        ))}      
      </Box>

      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Container
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            height: "200px",
            backgroundColor: "white",
            padding: "20px",
            borderRadius: "8px",
            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",

            width: {
              lg: "383px",
              sm: "383px",
              xs: "90%",
            },
          }}
        >
          <Typography
            id="modal-title"
            variant="h6"
            component="h2"
            style={{
              fontSize: "16px",
              fontWeight: "500",
              fontFamily: "CustomFontMedium",
            }}
          >
            Are you sure you want to revoke your consent from DueFactory ?
          </Typography>
          <Box
            style={{
              display: "flex",
              justifyContent: "space-around",
              marginTop: "20px",
              width: "100%",
            }}
          >
            <Button
              variant="contained"
              color="primary"
              style={{
                background: "#1cb0e7",
                color: "white",
                borderRadius: "10px",
                fontWeight: "500",
                fontFamily: "CustomFontMedium",
              }}
              onClick={hanadleRevokeConsent}
            >
              Yes,Sure
            </Button>
            <Button
              variant="outlined"
              color="secondary"
              onClick={handleCloseModal}
              style={{
                background: "#05517a",
                color: "white",
                borderRadius: "10px",
                fontWeight: "500",
                fontFamily: "CustomFontMedium",
              }}
            >
              No , Keep my record
            </Button>
          </Box>
        </Container>
      </Modal>
      <FotterComponent />
    </>
  );
}

export default FaqComponents;
