import { ToastContainer } from 'react-toastify';
import './App.css';
import 'react-toastify/dist/ReactToastify.css';
import AppRoutes from './routes';
function App() {
  return (
    <div>      
      <AppRoutes/>
      <ToastContainer  />
    </div>
  );
}

export default App;
