import React, { useEffect, useRef, useState } from 'react';
import './ScoreGauge.css';
import exp_img from "../assets/imgs/Experian_logo.svg";
import { formatTimestampForLastUpdated } from '../utils/GenericInsightStack';
const calculateNeedleAngle = (score) => {
    const minScore = 300;
    const maxScore = 900;
    const minAngle = -124;
    const maxAngle = 124;
    const scorePercentage = (score - minScore) / (maxScore - minScore);
    return minAngle + scorePercentage * (maxAngle - minAngle);
};
// const ScoreGauge = ({ score, isLoading, lastUpdated }) => {

//     return (
//         <>
//             <div className="new-new-score-gauge-main">
//                 <div className="new-new-score-gauge">
//                     <div className="new-score-gauge-arc-1"></div>
//                     <div className="new-new-score-gauge-circle">
//                         <div
//                             ref={needleRef}
//                             className="new-triangle-shape-needle"
//                         ></div>
//                         <div className="new-new-score-gauge-circle-1">
//                             <div className="new-new-score-gauge-circle-2">
//                                 <span>{animatedScore}</span>
//                                 <div style={{ color: checkColour(animatedScore) }}>
//                                     {score >= 800 ? 'Excellent' :
//                                         score >= 700 ? 'Good' :
//                                             score >= 600 ? 'Fair' : 'Bad'}
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                     <div className="new-score-gauge-milstone-1">300</div>
//                     <div className="new-score-gauge-milstone-2">600</div>
//                     <div className="new-score-gauge-milstone-3">700</div>
//                     <div className="new-score-gauge-milstone-4">800</div>
//                     <div className="new-score-gauge-milstone-5">900</div>
//                 </div>
//                 <div>
//                     <p>Powered By </p>
//                     <img className='experian-img-score' src={exp_img} alt="exp-img" />
//                 </div>
//                 {
//                     lastUpdated ? <>
//                         <div className='last-updated-date-sec'>
//                             Last Updated on &nbsp;
//                             <b>
//                                 {formatTimestampForLastUpdated(lastUpdated)}
//                             </b>
//                         </div>
//                     </> : null
//                 }
//             </div>
//         </>
//     );
// };
const ScoreGauge = ({ score, isLoading, lastUpdated }) => {
    const needleRef = useRef(null);
    const [animatedScore, setAnimatedScore] = useState(300); // Start from 300
    useEffect(() => {
        if (isLoading) return; // Wait until isLoading is false
        let currentScore = 300; // Starting score
        const interval = setInterval(() => {
            if (currentScore < score) {
                currentScore += 10; // Increment by 10
                setAnimatedScore(Math.min(currentScore, score)); // Update animated score
            } else {
                clearInterval(interval); // Stop animation at target score
            }
        }, 5);

        return () => clearInterval(interval); // Cleanup on unmount
    }, [score]);

    useEffect(() => {
        if (needleRef.current) {
            const needleAngle = calculateNeedleAngle(animatedScore);
            needleRef.current.style.transform = `translate(0%, -50%) rotate(${needleAngle}deg)`;
        }
    }, [animatedScore]);
    const checkColour = (Sc) => {
        if (Sc >= 300 && Sc < 700) {
            return `#f51111`;
        } else if (Sc >= 700 && Sc < 800) {
            return `#6dbc5d`;
        } else {
            return `#12a547`;
        }
    };
    return (<>
        <div className='main-score-guage'>
            <div className='main-score-guage-block'>
                <div className='main-score-guage-block-value-support-bar'>
                </div>
                {/* <div className='main-score-guage-block-value-support'>
                </div> */}
                <div className='main-score-guage-block-value-support-1'>
                </div>
                <div
                    ref={needleRef}
                    className="main-score-guage-block-value-support-1 triangle-shape-needle-in-gauge"
                ></div>
                <div className='main-score-guage-block-value-support-2'>

                    <div className='main-score-guage-block-values'>
                        <div className='main-score-guage-block-value-1'>{animatedScore}</div>
                        <div
                            style={{ color: checkColour(animatedScore) }}
                            className='main-score-guage-block-value-2'>
                            {score >= 800 ? 'Excellent' :
                                score >= 700 ? 'Good' :
                                    score >= 600 ? 'Fair' : 'Bad'}
                        </div>
                    </div>

                </div>
                <div className="new-score-gauge-milstone-1">300</div>
                <div className="new-score-gauge-milstone-2">600</div>
                <div className="new-score-gauge-milstone-3">700</div>
                <div className="new-score-gauge-milstone-4">800</div>
                <div className="new-score-gauge-milstone-5">900</div>
            </div>
            <div className='main-score-guage-bottom'>
                <div className='main-score-guage-bottom-1'>
                    <span>Powered BY</span>
                    <img src={exp_img} alt="exp-img" />
                </div>
                {
                    lastUpdated ?
                        <p className='main-score-guage-bottom-2'>
                            Last Updated on
                            <b>
                                {formatTimestampForLastUpdated(lastUpdated)}
                            </b>
                        </p>
                        :
                        null
                }

            </div>
        </div>
    </>)
}
export default ScoreGauge;
